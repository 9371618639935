/*
==========================
Template: Industro
Author: thewebmax team
Version: 1.0
==========================
*/

/*======================================
  || CSS TABLE CONTENT ||
=======================================

=======================================
1. GENERAL
=======================================

=======================================
2. HEADING & TITLE
=======================================

=======================================
3. TEXT & PARAGRAPH
=======================================

	-------------------------------------
	1. text align
	-------------------------------------
	2. text vertical align
	-------------------------------------
	3. text no line brack
	-------------------------------------
	4. text transform
	-------------------------------------
	5. text size
	-------------------------------------
	6. text bold
	-------------------------------------
	7. letter Spacing
	-------------------------------------
	8. text indent


======================================
4. LISTING STYLES
======================================

	-------------------------------------
	1. listing before icon color css
	-------------------------------------
	2. listing before numbers
	-------------------------------------

======================================
5. BOX SPACING (Padding & Margin)
======================================

	-------------------------------------
	1.1 padding (around)
	-------------------------------------
	1.2 Padding (top)
	-------------------------------------
	1.3 Padding (bottom)
	-------------------------------------
	1.4 Padding (left)
	-------------------------------------
	1.5 Padding (right)
	-------------------------------------
	1.6 Padding (left right)
	-------------------------------------
	1.7 Padding (top bottom)
	-------------------------------------
	2.1 margin (around)
	-------------------------------------
	2.2 marging (top)
	-------------------------------------
	2.3 marging (bottom)
	-------------------------------------
	2.4 marging (left)
.	-------------------------------------
	2.5 marging (right)
	-------------------------------------
	2.6 marging (left right)
	-------------------------------------
	2.7 marging (top bottom)
	-------------------------------------
	2.8 colum gap less
	-------------------------------------

======================================
6. POSITIONS ( absolute , relative , statice & z-index)
======================================

	-------------------------------------
	1. position
	-------------------------------------
		1.1 relative
		1.2 absolute
		1.3 static
	-------------------------------------
	2. z-index
	-------------------------------------

======================================
7. COLOR
======================================

	-------------------------------------
	1. text
	-------------------------------------
	2. background color
	-------------------------------------
	3. background image
	-------------------------------------

======================================
8. OVERLAY ( transparient box style  )
======================================

	use for box
	left right part css

======================================
9. BOXES CSS
======================================

	-------------------------------------
	1. box content
	-------------------------------------
	2. For icons with box
	-------------------------------------
	3. Border
	-------------------------------------
	4. For only icons
	-------------------------------------
	5. place icon with box
	-------------------------------------
	6. icon box left align
	-------------------------------------
	7. icon box right align
	-------------------------------------
	8. icon box center align
	-------------------------------------
	9. media with content box css
	-------------------------------------
	10. for images
	-------------------------------------

======================================
10. IMAGES OVERLAY EFFECTS (overly animation on images)
======================================

	-------------------------------------
	1. effect 1
	-------------------------------------
	2. Gradient-black
	-------------------------------------
	3. Gradient-white
	-------------------------------------

======================================
11. shortcodeS
======================================

	-------------------------------------
	1. Buttons
	-------------------------------------
		1.1 theme default button
		1.2 button text uppercase
		1.3 button size
		1.4 button rounded
		1.6 white
		1.7 black
		1.8 Gray
		1.9 pink
		1.10 Blue
		1.11 Green
		1.12 Orange
		1.13 Red
		1.14 Brown
		1.15 Yellow
		1.16 purple
		1.17 Graphical
		1.19 Outline
		1.20 Outline white
		1.21 Outline black
		1.22 Outline gray
		1.23 Outline pink
		1.24 Outline blue
		1.25 Outline green
		1.26 Outline orange
		1.27 Outline red
		1.28 Outline brown
		1.29 Outline yellow
		1.30 Outline purple
		1.31 Outline hover
		1.32 button text link
		1.33 text link white
		1.34 text link black
		1.35 text link Gray
		1.36 text link pink
		1.37 text link Blue
		1.38 text link Green
		1.39 text link Orange
		1.40 text link Red
		1.41 text link Brown
		1.42 text link Yellow
		1.43 text link purple
		1.44 app strore
		1.45 filter buttons css
		1.45(1) Masonry filter alignment [Left-Right-Center]
		1.45(2) Masonry filter link style
		1.45(3) Masonry filter button style
		1.45(4) Masonry filter button outline style
	-------------------------------------
	2. Title separators
	-------------------------------------
		Separator defualt
		Separator liner
		Separator square
		Separator icon
	-------------------------------------
	3. Deviders
	-------------------------------------
	4. TABS
	-------------------------------------
		4.1 tabs style 1 [ default ]
		tabs nav center
		4.2 tabs bg  [ tabs background ]
		4.3 tabs bg  [ tabs background ]
		4.4 tabs bg & top border  [ tabs background & top border in active ]
		4.4 tabs style 2 [ content with border outer ]
		4.5 tabs style 3  [ left-nav ]
		4.6 tabs style 4  [ left-nav & content with border ]
		4.7 tabs style 5  [ right-nav ]
		4.8 tabs style 6  [ right-nav & content with border ]
	-------------------------------------
	5. Accordians
	-------------------------------------
	Accodian open close indicator css
		1. Accordion defult
		2. Accordion outline
		3. Accordion bg gray
		4. Accordion bg primary
		5. Accordion bg dark
	-------------------------------------
	6. Carousels
	-------------------------------------
		6.1 Client carousel 1
	-------------------------------------
	7. Testimonials
	-------------------------------------
		7.1 testimonial with background image
		7.2 testimonial 6
	-------------------------------------
	8. Pricing table
	-------------------------------------
		8.1 Pricing table
		8.2 pricing table style 2
	-------------------------------------
	9. Alert box
	-------------------------------------
	10. Image effects
	-------------------------------------
		10.1 image-opacity
		10.2 image-zoom
		10.3 image-zoom-slow
		10.4 image-shrink
		10.5 image-side-pan
		10.6 image-vertical-pan
		10.7 image-fade-in
		10.8 image-fade-out
		10.9 image-rotate
		10.10 image-sepia
		10.11 image-blurr
		10.12 image-blurr-invert
		10.13 image off color
		10.14 image on color
	-------------------------------------
	11. Modal pop
	-------------------------------------
	12. Social icons
	-------------------------------------
		12.1 social icon default
		12.2 social icon with border
		12.3 social icon dark
		12.4 social share icon style 1
	-------------------------------------
	13. Breadcrumb
	-------------------------------------
		13.1 breadcrumb-row-1
		13.2 Breadcrumb-1
	-------------------------------------
	14. Google map
	-------------------------------------
	16. Countdown clock
	-------------------------------------
	17. Form elements
	-------------------------------------
		17.1 input group
		17.3 radio & checkbox
		17.4 Browse Button css
		17.8 validation symbol
		17.9 error for select box
		17.10 succes for select box
		17.11 textarea
			17.11(1) resize-vertical
			17.11(2) resize-horizontal
			17.11(3) resize-none
	-------------------------------------
	18. Loading
	-------------------------------------
		18.1 loading - 1
		18.2 loading - 2
	-------------------------------------
	19. Widgets
	-------------------------------------
		19.1 widget listing
		19.2 widget search custom
		19.3 widget search wp-default
		19.4 widget recent-posts
		19.8 widget tags-list
		19.14 widget gallery
		19.18 widget client slider
	-------------------------------------
	20. Table
	-------------------------------------
	21. Video
	-------------------------------------
======================================
12. WP DEFAULT
======================================

======================================
13. BLOG
======================================

	-------------------------------------
	3. if no image with blog post
	-------------------------------------
	10. side bar
	-------------------------------------
	11. Blog single
	-------------------------------------
	12. comment list
	-------------------------------------
	13. comment form
	-------------------------------------

======================================
14. HEADER (top part of page)
======================================

	1. header full screen
	-------------------------------------
	2. without top bar
	-------------------------------------
	3. map page header
	-------------------------------------

======================================
16. LOGO ( company identify  symbol )
======================================

	1. logo for header
	-------------------------------------
	2. when header is sticky

======================================
17. MAIN BAR ( place logo & main-navigation )
======================================

	main bar with box view
	-------------------------------------
	1. Top Part Search Bar

======================================
18. NAVIGATIONS
======================================

	-------------------------------------
	1. mena-menu
	-------------------------------------
	2. nav dark version
	-------------------------------------
	3. when header is sticky
	-------------------------------------
	4. when header is sticky
	-------------------------------------
	5. header id Fixed
	-------------------------------------
	6. header with bg primary color 1
	-------------------------------------
======================================
19. BANNER
======================================

	-------------------------------------
	1. inner page banner
	-------------------------------------

======================================
20. SLIDER
======================================

	-------------------------------------
	1. owl slider
	-------------------------------------
		1.1  next pre btn
		1.2 owl dots button
		1.3 owl button top to center
		1.4 owl button top to left
		1.5 owl button top to right
		1.6 owl button bottom to center [it is default position ]
		1.7 owl button bottom to left
		1.8 owl button bottom to right
		1.9 owl button vertical to center
		1.10 owl button show on hover
		1.11 owl slider button
		1.12 owl dots nav position

======================================
21. PAGINATION
======================================

	1. pagination style-1

======================================
22. FOOTER
======================================

	-------------------------------------
	1. footer fixed on bottom css
	-------------------------------------
		1.1 footer fixed with fullscreen view
		1.2 footer fixed with boxed view
	-------------------------------------
	5. widget Getintuch
	-------------------------------------
	6. scroll top btn css
	-------------------------------------

======================================
23. PAGE-CONTENT
======================================

	-------------------------------------
	1. Section Head Part
	-------------------------------------
	2. Section Content Part
	-------------------------------------

======================================
24. AFTER-LOGIN-CONTENT
======================================

	-------------------------------------
	2. Contact us page
	-------------------------------------

======================================
25. THEMEFOREST REQUIRED CLASSES
======================================

	-------------------------------------
	1. Text meant only for screen readers
	-------------------------------------

======================================
27. BOX MAX-WIDTH css (  )
======================================

======================================
28. SHOP & PRODUCT DETAIL (Woocommerce)
======================================

	-------------------------------------
	1. Product Box
	-------------------------------------

======================================
29. content show on image box ()
======================================

 	info box show

======================================
30. Customize  Grouping css
======================================

	-------------------------------------
	1.set-radius
	-------------------------------------
	2. transition
	-------------------------------------
	3. float-clearfix
	-------------------------------------

======================================
32. Inner pages
======================================
	-------------------------------------
	2. Out Team
	-------------------------------------
		2.6. Out Team six
	-------------------------------------
	4. product block
	-------------------------------------
======================================
33. Background Effects
======================================

======================================
34. Home Page
======================================

======================================
35. Carousel overlay content
======================================

======================================
36. Overlay Box 2
======================================

======================================
37. line-amiation-block
======================================

======================================
38. latest-blog-dark
======================================

======================================
39. background Square block
======================================

======================================
40. About us
======================================

======================================
41. Our Services
======================================

======================================
42. Contact Us
======================================

======================================
43. Work carousal
======================================

======================================
44. Work Masonry
======================================

======================================
45. our-story
======================================
*/


/*======================================
1. GENERAL
======================================*/

body {
	background-color: #FFF;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	color: #111111;
	padding: 0;
	margin: 0;
	overflow-x: hidden;
	font-size: 16px;
}

a {
	color: #111111;
	outline: 0 none;
	text-decoration: none;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: 0 none;
}

a:active,
a:hover,
a:focus {
	color: inherit;
}

p a {
	color: #111111;
}

img {
	border-style: none;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

hr {
	clear: both;
}

section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
	display: block;
}

address {
	font-style: italic;
}

table {
	background-color: transparent;
	width: 100%;
}

table thead th {
	padding: 12px 8px !important;
}

table td {
	padding: 15px 10px !important;
}

@media only screen and (max-width:360px) {
	table td {
		word-break: break-all;
	}
}

p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
	margin-bottom: 24px;
}

.overflow-hide {
	overflow: hidden;
}

.overflow-auto {
	overflow: auto;
}

.clear {
	clear: both;
}

.pull-none {
	float: none;
}

::selection {
	background: transparent;
	color: #fff;
}

::-moz-selection {
	background: #484848;
	color: #fff;
}

::-webkit-selection {
	background: #484848;
	color: #fff;
}

.job-title-wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.job-card {
	color: black;
	margin-bottom: 20px;
	font-size: 16px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #e6e6e6;

}

.job-card .job-details {
	font-size: 22px;
	width: 100%;
	padding: 0 30px;
}

.job-card .job-name {
	font-weight: bold;
	font-size: 20px;
}

.job-card .job-location {
	font-size: 18px;
	color: lightslategray;
}

.job-card img {
	max-width: 160px;
}

:root {

	/* color: rgb(28, 99, 184); */
	--background-gradient: linear-gradient(90deg, rgba(239,243,246,1) -0%, rgba(149,145,144,1) 50%, rgba(239,243,246,1) 100%); /* Borduri sus si jos la Pagini */

	/* --background-gradient: linear-gradient(90deg, rgba(53, 87, 200, 0.7) -0%, rgba(53, 87, 200, 0.8) 50%, rgba(53, 87, 200, 0.7) 100%); */
}

@media only screen and (max-width:600px) {
	.col-xs-100pc {
		width: 100%;
	}

	.wt-list-single-somefacts .col-xs-100pc {
		width: inherit !important;

	}

	.wt-list-single-somefacts .col-xs-100pc h1,
	.wt-list-single-somefacts .col-xs-100pc h2,
	.wt-list-single-somefacts .col-xs-100pc h3 {
		font-size: 22px;
		line-height: normal;
	}

	.wt-list-single-somefacts h1,
	.wt-list-single-somefacts h2,
	.wt-list-single-somefacts .col-xs-100pc h3 {
		font-size: 22px;
		line-height: normal;
	}

	body {
		font-size: 14px;
	}
}

@media only screen and (max-width:480px) {


	.wt-list-single-somefacts .col-xs-100pc {
		width: 100% !important;
	}

	.wt-list-single-somefacts-three .col-xs-4 {
		width: 100% !important;
	}
}


/*======================================
2. HEADING & TITLE
======================================*/

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 15px;
	font-family: 'Teko', sans-serif;
	font-weight: 600;
	line-height: 1.1;
}

.title-style-2 {
	font-family: 'Teko', sans-serif;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #000;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #959190;
}

h1 {
	font-size: 66px;
	color: #959190;
	font-weight: 400;
}

h2 {
	font-size: 50px;
	color: black;
}

h3 {
	font-size: 30px;
	color: black;
}

h4 {
	font-size: 20px;
	color: black;
}

h5 {
	font-weight: normal;
	font-size: 18px;
	color: black;
}

h6 {
	font-weight: normal;
	font-size: 14px;
	color: black;
}

.display-inline {
	display: inline;
}

.display-inline-block {
	display: inline-block;
}

.display-block {
	display: block;
}


/*____BOLD TRANSPERENT TITLE____*/

.section-head {
	position: relative;
	z-index: 1;
}

.section-head h1:after,
.section-head h2:after,
.section-head h3:after {
	position: absolute;
	content: attr(data-title);
	color: black;
	opacity: 0.1;
	font-family: 'Teko', sans-serif;
	font-size: 120px;
	line-height: 100px;
	bottom: -30px;
	left: 50%;
	width: 100%;
	z-index: -1;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

@media only screen and (max-width:991px) {

	.section-head h1:after,
	.section-head h2:after,
	.section-head h3:after {
		font-size: 74px;
		bottom: -10px;
	}
}

@media only screen and (max-width:720px) {

	.section-head h1:after,
	.section-head h2:after,
	.section-head h3:after {
		display: none;
	}
}


/*======================================
3. TEXT & PARAGRAPH
======================================*/

p {
	margin-bottom: 20px;
}

strong {
	font-weight: 600;
}


/*-------------------------------------
	1. text align
------------------------------------ */

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-justify {
	text-align: justify
}


/*-------------------------------------
	2. text vertical align
------------------------------------ */

.v-align-t {
	vertical-align: top;
}

.v-align-m {
	vertical-align: middle;
}

.v-align-b {
	vertical-align: bottom;
}


/*-------------------------------------
	3. text no line brack
------------------------------------ */

.text-nowrap {
	white-space: nowrap
}


/*-------------------------------------
	4. text transform
------------------------------------ */

.text-lowercase {
	text-transform: lowercase
}

.text-uppercase {
	text-transform: uppercase
}

.text-capitalize {
	text-transform: capitalize
}


/*-------------------------------------
	5. text size
------------------------------------ */

.font-12 {
	font-size: 12px;
	line-height: 18px;
}

.font-14 {
	font-size: 14px;
	line-height: 20px;
}

.font-16 {
	font-size: 16px;
	line-height: 22px;
}

.font-18 {
	font-size: 18px;
	line-height: 24px;
}

.font-20 {
	font-size: 20px;
	line-height: 26px;
}

.font-22 {
	font-size: 22px;
	line-height: 28px;
}

.font-24 {
	font-size: 24px;
	line-height: 30px;
}

.font-26 {
	font-size: 26px;
	line-height: 32px;
}

.font-28 {
	font-size: 28px;
	line-height: 34px;
}

.font-30 {
	font-size: 30px;
	line-height: 36px;
}

.font-32 {
	font-size: 32px;
	line-height: 38px;
}

.font-34 {
	font-size: 34px;
	line-height: 40px;
}

.font-36 {
	font-size: 36px;
	line-height: 42px;
}

.font-38 {
	font-size: 38px;
	line-height: 44px;
}

.font-40 {
	font-size: 40px;
	line-height: 46px;
}

.font-45 {
	font-size: 46px;
	line-height: 52px;
}

.font-50 {
	font-size: 50px;
	line-height: 56px;
}

.font-60 {
	font-size: 60px;
	line-height: 66px;
}

.font-70 {
	font-size: 70px;
	line-height: 76px;
}

.font-80 {
	font-size: 80px;
	line-height: 86px;
}

.font-90 {
	font-size: 90px;
	line-height: 96px;
}

.font-100 {
	font-size: 100px;
	line-height: 106px;
}

.font-120 {
	font-size: 120px;
	line-height: 126px;
}

.font-140 {
	font-size: 140px;
	line-height: 146px;
}

.font-160 {
	font-size: 160px;
	line-height: 166px;
}

.font-180 {
	font-size: 180px;
	line-height: 186px;
}


/*-------------------------------------
	6. text bold
------------------------------------ */

.font-weight-100 {
	font-weight: 100;
}

.font-weight-300 {
	font-weight: 300;
}

.font-weight-400 {
	font-weight: 400;
}

.font-weight-500 {
	font-weight: 500;
}

.font-weight-600 {
	font-weight: 600;
}

.font-weight-700 {
	font-weight: 700;
}

.font-weight-800 {
	font-weight: 800;
}

.font-weight-900 {
	font-weight: 900;
}


/*-------------------------------------
	7. Letter Spacing
------------------------------------ */

.letter-spacing-2 {
	letter-spacing: 2px;
}

.letter-spacing-3 {
	letter-spacing: 3px;
}

.letter-spacing-4 {
	letter-spacing: 4px;
}

.letter-spacing-5 {
	letter-spacing: 5px;
}

/*-------------------------------------
	8. text indent
------------------------------------ */

.indent-paragraph {
    text-indent: 2em;
}


/*======================================
4. LISTING STYLES
======================================*/

dl,
ul,
ol {
	list-style-position: outside;
	padding: 0;
}

ul,
ol {
	margin-bottom: 24px;
}

ul li,
ol li {
	padding: 0;
}

dl {
	margin-left: 0;
	margin-bottom: 30px;
}

dl dd {
	margin-bottom: 10px;
}

.list-simple li {
	margin-bottom: 10px;
}

.list-simple li ul {
	margin-left: 15px;
	margin-top: 10px;
}

.list-simple.list-unstyled li ul {
	margin-left: 30px;
	margin-top: 10px;
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
	margin: 0 0 20px 0;
	padding: 0;
	list-style: none;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
	padding: 5px 5px 5px 20px;
	position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
	font-family: "FontAwesome";
	position: absolute;
	left: 0;
	top: 3px;
	display: block;
	font-size: 15px;
	color: #000;
}

.list-circle li:before {
	top: 6px;
	font-size: 10px;
}

.list-circle li:before {
	content: "\f111";
}

.list-angle-right li:before {
	content: "\f105";
}

.list-arrow li:before {
	content: "\f0da";
}

.list-check li:before {
	content: "\f00c";
}

.list-checked li:before {
	content: "\f046";
}

.list-check-circle li:before {
	content: "\f058";
}

.list-chevron-circle li:before {
	content: "\f138";
}

.list-arrow-circle li:before {
	content: "\f0a9";
}

.list-times-circle li:before {
	content: "\f057";
}


/*-------------------------------------
	1. listing before icon color css
-------------------------------------*/

ul.primary li:before {
	color: #0f1221;
}

ul.secondry li:before {
	color: #253641;
}

ul.black li:before {
	color: #000;
}

ul.white li:before {
	color: #fff;
}

ul.orange li:before {
	color: #FF6600;
}

ul.green li:before {
	color: #00CC00;
}

ul.red li:before {
	color: #FF3737;
}

dl.no-margin,
ul.no-margin,
ol.no-margin {
	margin: 0px !important;
	list-style: none;
}


/*-------------------------------------
	2. listing before numbers
-------------------------------------*/

ol.list-num-count {
	counter-reset: li;
	padding-left: 0;
}

ol.list-num-count>li {
	position: relative;
	margin: 0 0 20px 40px;
	padding: 0px 8px;
	list-style: none;
}

ol.list-num-count>li:last-child {
	margin-bottom: 0px;
}

ol.list-num-count>li:before {
	content: counter(li, decimal);
	counter-increment: li;
	position: absolute;
	top: 0;
	left: -40px;
	width: 26px;
	height: 24px;
	padding: 5px;
	color: #fff;
	background: #0f1221;
	text-align: center;
	font-size: 12px;
	line-height: 14px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

ol.list-num-count.lower-alpha>li:before {
	content: counter(li, lower-alpha);
}

ol.list-num-count.upper-alpha>li:before {
	content: counter(li, upper-alpha);
}

ol.list-num-count.list-outline>li:before {
	border: 1px solid #000;
	background-color: transparent;
	color: #000;
}

ol.list-num-count.list-outline-none>li:before {
	background-color: transparent;
	color: #000;
}


/*======================================
5. BOX SPACING (Padding & Margin)
======================================*/


/*-------------------------------------
	1.1 padding (around)
-------------------------------------*/

.p-a0 {
	padding: 0;
}

.p-a5 {
	padding: 5px;
}

.p-a10 {
	padding: 10px;
}

.p-a15 {
	padding: 15px;
}

.p-a20 {
	padding: 20px;
}

.p-a25 {
	padding: 25px;
}

.p-a30 {
	padding: 30px;
}

.p-a40 {
	padding: 40px;
}

.p-a50 {
	padding: 50px;
}

.p-a60 {
	padding: 60px;
}

.p-a70 {
	padding: 70px;
}

.p-a80 {
	padding: 80px;
}

.p-a90 {
	padding: 90px;
}

.p-a100 {
	padding: 100px;
}

.p-a110 {
	padding: 110px;
}

.p-a120 {
	padding: 120px;
}

.p-a130 {
	padding: 130px;
}

.p-a140 {
	padding: 140px;
}

.p-a150 {
	padding: 150px;
}


/*-------------------------------------
	1.2 Padding (top)
-------------------------------------*/

.p-t0 {
	padding-top: 0;
}

.p-t5 {
	padding-top: 5px;
}

.p-t10 {
	padding-top: 10px;
}

.p-t15 {
	padding-top: 15px;
}

.p-t20 {
	padding-top: 20px;
}

.p-t30 {
	padding-top: 30px;
}

.p-t40 {
	padding-top: 40px;
}

.p-t50 {
	padding-top: 50px;
}

.p-t60 {
	padding-top: 60px;
}

.p-t70 {
	padding-top: 70px;
}

.p-t80 {
	padding-top: 80px;
}

.p-t90 {
	padding-top: 90px;
}

.p-t100 {
	padding-top: 100px;
}

.p-t110 {
	padding-top: 110px;
}

.p-t120 {
	padding-top: 120px;
}

.p-t130 {
	padding-top: 130px;
}

.p-t140 {
	padding-top: 140px;
}

.p-t150 {
	padding-top: 150px;
}


/*-------------------------------------
    1.3 Padding (bottom)
-------------------------------------*/

.p-b0 {
	padding-bottom: 0;
}

.p-b5 {
	padding-bottom: 5px;
}

.p-b10 {
	padding-bottom: 10px;
}

.p-b15 {
	padding-bottom: 15px;
}

.p-b20 {
	padding-bottom: 20px;
}

.p-b30 {
	padding-bottom: 30px;
}

.p-b40 {
	padding-bottom: 40px;
}

.p-b50 {
	padding-bottom: 50px;
}

.p-b60 {
	padding-bottom: 60px;
}

.p-b70 {
	padding-bottom: 70px;
}

.p-b80 {
	padding-bottom: 80px;
}

.p-b90 {
	padding-bottom: 90px;
}

.p-b100 {
	padding-bottom: 100px;
}

.p-b110 {
	padding-bottom: 110px;
}

.p-b120 {
	padding-bottom: 120px;
}

.p-b130 {
	padding-bottom: 130px;
}

.p-b140 {
	padding-bottom: 140px;
}

.p-b150 {
	padding-bottom: 150px;
}


/*-------------------------------------
	1.4 Padding (left)
-------------------------------------*/

.p-l0 {
	padding-left: 0;
}

.p-l5 {
	padding-left: 5px;
}

.p-l10 {
	padding-left: 10px;
}

.p-l15 {
	padding-left: 15px;
}

.p-l20 {
	padding-left: 20px;
}

.p-l30 {
	padding-left: 30px;
}

.p-l40 {
	padding-left: 40px;
}

.p-l50 {
	padding-left: 50px;
}

.p-l60 {
	padding-left: 60px;
}

.p-l70 {
	padding-left: 70px;
}

.p-l80 {
	padding-left: 80px;
}

.p-l90 {
	padding-left: 90px;
}

.p-l100 {
	padding-left: 100px;
}

.p-l110 {
	padding-left: 110px;
}

.p-l120 {
	padding-left: 120px;
}

.p-l130 {
	padding-left: 130px;
}

.p-l140 {
	padding-left: 140px;
}

.p-l150 {
	padding-left: 150px;
}


/*-------------------------------------
	1.5 Padding (right)
-------------------------------------*/

.p-r0 {
	padding-right: 0;
}

.p-r5 {
	padding-right: 5px;
}

.p-r10 {
	padding-right: 10px;
}

.p-r15 {
	padding-right: 15px;
}

.p-r20 {
	padding-right: 20px;
}

.p-r30 {
	padding-right: 30px;
}

.p-r40 {
	padding-right: 40px;
}

.p-r50 {
	padding-right: 50px;
}

.p-r60 {
	padding-right: 60px;
}

.p-r70 {
	padding-right: 70px;
}

.p-r80 {
	padding-right: 80px;
}

.p-r90 {
	padding-right: 90px;
}

.p-r100 {
	padding-right: 100px;
}

.p-r110 {
	padding-right: 110px;
}

.p-r120 {
	padding-right: 120px;
}

.p-r130 {
	padding-right: 130px;
}

.p-r140 {
	padding-right: 140px;
}

.p-r150 {
	padding-right: 150px;
}


/*-------------------------------------
	1.6 Padding (left right)
-------------------------------------*/

.p-lr0 {
	padding-left: 0;
	padding-right: 0;
}

.p-lr5 {
	padding-left: 5px;
	padding-right: 5px;
}

.p-lr10 {
	padding-left: 10px;
	padding-right: 10px;
}

.p-lr15 {
	padding-left: 15px;
	padding-right: 15px;
}

.p-lr20 {
	padding-left: 20px;
	padding-right: 20px;
}

.p-lr30 {
	padding-left: 30px;
	padding-right: 30px;
}

.p-lr40 {
	padding-left: 40px;
	padding-right: 40px;
}

.p-lr50 {
	padding-left: 50px;
	padding-right: 50px;
}

.p-lr60 {
	padding-left: 60px;
	padding-right: 60px;
}

.p-lr70 {
	padding-left: 70px;
	padding-right: 70px;
}

.p-lr80 {
	padding-left: 80px;
	padding-right: 80px;
}

.p-lr90 {
	padding-left: 90px;
	padding-right: 90px;
}

.p-lr100 {
	padding-left: 100px;
	padding-right: 100px;
}

.p-lr120 {
	padding-left: 120px;
	padding-right: 120px;
}

.p-lr150 {
	padding-left: 150px;
	padding-right: 150px;
}


/*-------------------------------------
	1.7 Padding (top bottom)
-------------------------------------*/

.p-tb0 {
	padding-bottom: 0;
	padding-top: 0;
}

.p-tb5 {
	padding-bottom: 5px;
	padding-top: 5px;
}

.p-tb10 {
	padding-bottom: 10px;
	padding-top: 10px;
}

.p-tb15 {
	padding-bottom: 15px;
	padding-top: 15px;
}

.p-tb20 {
	padding-bottom: 20px;
	padding-top: 20px;
}

.p-tb30 {
	padding-bottom: 30px;
	padding-top: 30px;
}

.p-tb40 {
	padding-bottom: 40px;
	padding-top: 40px;
}

.p-tb50 {
	padding-bottom: 50px;
	padding-top: 50px;
}

.p-tb60 {
	padding-bottom: 60px;
	padding-top: 60px;
}

.p-tb70 {
	padding-bottom: 70px;
	padding-top: 70px;
}

.p-tb80 {
	padding-bottom: 80px;
	padding-top: 80px;
}

.p-tb90 {
	padding-bottom: 90px;
	padding-top: 90px;
}

.p-tb100 {
	padding-bottom: 100px;
	padding-top: 100px;
}

.p-tb120 {
	padding-bottom: 120px;
	padding-top: 120px;
}

.p-tb150 {
	padding-bottom: 150px;
	padding-top: 150px;
}


/*-------------------------------------
	2.1 margin (around)
-------------------------------------*/

.m-a-1 {
	margin: -1px
}

.m-a0 {
	margin: 0;
}

.m-a5 {
	margin: 5px;
}

.m-a10 {
	margin: 10px;
}

.m-a15 {
	margin: 15px;
}

.m-a20 {
	margin: 20px;
}

.m-a30 {
	margin: 30px;
}

.m-a40 {
	margin: 40px;
}

.m-a50 {
	margin: 50px;
}

.m-a60 {
	margin: 60px;
}

.m-a70 {
	margin: 70px;
}

.m-a80 {
	margin: 80px;
}

.m-a90 {
	margin: 90px;
}

.m-a100 {
	margin: 100px;
}

.m-a110 {
	margin: 110px;
}

.m-a120 {
	margin: 120px;
}

.m-a130 {
	margin: 130px;
}

.m-a140 {
	margin: 140px;
}

.m-a150 {
	margin: 150px;
}


/*-------------------------------------
	2.2 marging (top)
-------------------------------------*/

.m-t0 {
	margin-top: 0;
}

.m-t5 {
	margin-top: 5px;
}

.m-t10 {
	margin-top: 10px;
}

.m-t15 {
	margin-top: 15px;
}

.m-t20 {
	margin-top: 20px;
}

.m-t30 {
	margin-top: 30px;
}

.m-t40 {
	margin-top: 40px;
}

.m-t50 {
	margin-top: 50px;
}

.m-t60 {
	margin-top: 60px;
}

.m-t70 {
	margin-top: 70px;
}

.m-t80 {
	margin-top: 80px;
}

.m-t90 {
	margin-top: 90px;
}

.m-t100 {
	margin-top: 100px;
}

.m-t110 {
	margin-top: 110px;
}

.m-t120 {
	margin-top: 120px;
}

.m-t130 {
	margin-top: 130px;
}

.m-t140 {
	margin-top: 140px;
}

.m-t150 {
	margin-top: 150px;
}


/*-------------------------------------
	2.3 marging (bottom)
-------------------------------------*/

.m-b0 {
	margin-bottom: 0;
}

.m-b5 {
	margin-bottom: 5px;
}

.m-b10 {
	margin-bottom: 10px;
}

.m-b15 {
	margin-bottom: 15px;
}

.m-b20 {
	margin-bottom: 20px;
}

.m-b30 {
	margin-bottom: 30px;
}

.m-b40 {
	margin-bottom: 40px;
}

.m-b50 {
	margin-bottom: 50px;
}

.m-b60 {
	margin-bottom: 60px;
}

.m-b70 {
	margin-bottom: 70px;
}

.m-b80 {
	margin-bottom: 80px;
}

.m-b90 {
	margin-bottom: 90px;
}

.m-b100 {
	margin-bottom: 100px;
}

.m-b110 {
	margin-bottom: 110px;
}

.m-b120 {
	margin-bottom: 120px;
}

.m-b130 {
	margin-bottom: 130px;
}

.m-b140 {
	margin-bottom: 140px;
}

.m-b150 {
	margin-bottom: 150px;
}


/*-------------------------------------
	2.4 marging (left)
-------------------------------------*/

.m-l0 {
	margin-left: 0;
}

.m-l5 {
	margin-left: 5px;
}

.m-l10 {
	margin-left: 10px;
}

.m-l15 {
	margin-left: 15px;
}

.m-l20 {
	margin-left: 20px;
}

.m-l30 {
	margin-left: 30px;
}

.m-l40 {
	margin-left: 40px;
}

.m-l50 {
	margin-left: 50px;
}

.m-l60 {
	margin-left: 60px;
}

.m-l70 {
	margin-left: 70px;
}

.m-l80 {
	margin-left: 80px;
}

.m-l90 {
	margin-left: 90px;
}

.m-l100 {
	margin-left: 100px;
}

.m-l110 {
	margin-left: 110px;
}

.m-l120 {
	margin-left: 120px;
}

.m-l130 {
	margin-left: 130px;
}

.m-l140 {
	margin-left: 140px;
}

.m-l150 {
	margin-left: 150px;
}


/*-------------------------------------
	2.5 marging (right)
-------------------------------------*/

.m-r0 {
	margin-right: 0;
}

.m-r5 {
	margin-right: 5px;
}

.m-r10 {
	margin-right: 10px;
}

.m-r15 {
	margin-right: 15px;
}

.m-r20 {
	margin-right: 20px;
}

.m-r30 {
	margin-right: 30px;
}

.m-r40 {
	margin-right: 40px;
}

.m-r50 {
	margin-right: 50px;
}

.m-r60 {
	margin-right: 60px;
}

.m-r70 {
	margin-right: 70px;
}

.m-r80 {
	margin-right: 80px;
}

.m-r90 {
	margin-right: 90px;
}

.m-r100 {
	margin-right: 100px;
}

.m-r110 {
	margin-right: 110px;
}

.m-r120 {
	margin-right: 120px;
}

.m-r130 {
	margin-right: 130px;
}

.m-r140 {
	margin-right: 140px;
}

.m-r150 {
	margin-right: 150px;
}


/*-------------------------------------
	2.6 marging (left right)
-------------------------------------*/

.m-lr0 {
	margin-left: 0;
	margin-right: 0;
}

.m-lr5 {
	margin-left: 5px;
	margin-right: 5px;
}

.m-lr10 {
	margin-left: 10px;
	margin-right: 10px;
}

.m-lr15 {
	margin-left: 15px;
	margin-right: 15px;
}

.m-lr20 {
	margin-left: 20px;
	margin-right: 20px;
}

.m-lr30 {
	margin-left: 30px;
	margin-right: 30px;
}

.m-lr40 {
	margin-left: 40px;
	margin-right: 40px;
}

.m-lr50 {
	margin-left: 50px;
	margin-right: 50px;
}

.m-lr60 {
	margin-left: 60px;
	margin-right: 60px;
}

.m-lr70 {
	margin-left: 70px;
	margin-right: 70px;
}

.m-lr80 {
	margin-left: 80px;
	margin-right: 80px;
}

.m-lr90 {
	margin-left: 90px;
	margin-right: 90px;
}

.m-lr100 {
	margin-left: 100px;
	margin-right: 100px;
}

.m-lr120 {
	margin-left: 120px;
	margin-right: 120px;
}

.m-lr150 {
	margin-left: 150px;
	margin-right: 150px;
}


/*-------------------------------------
	2.7 marging (top bottom)
-------------------------------------*/

.m-tb0 {
	margin-bottom: 0;
	margin-top: 0;
}

.m-tb5 {
	margin-bottom: 5px;
	margin-top: 5px;
}

.m-tb10 {
	margin-bottom: 10px;
	margin-top: 10px;
}

.m-tb15 {
	margin-bottom: 15px;
	margin-top: 15px;
}

.m-tb20 {
	margin-bottom: 20px;
	margin-top: 20px;
}

.m-tb30 {
	margin-bottom: 30px;
	margin-top: 30px;
}

.m-tb40 {
	margin-bottom: 40px;
	margin-top: 40px;
}

.m-tb50 {
	margin-bottom: 50px;
	margin-top: 50px;
}

.m-tb60 {
	margin-bottom: 60px;
	margin-top: 60px;
}

.m-tb70 {
	margin-bottom: 70px;
	margin-top: 70px;
}

.m-tb80 {
	margin-bottom: 80px;
	margin-top: 80px;
}

.m-tb90 {
	margin-bottom: 90px;
	margin-top: 90px;
}

.m-tb100 {
	margin-bottom: 100px;
	margin-top: 100px;
}

.m-tb120 {
	margin-bottom: 120px;
	margin-top: 120px;
}

.m-tb150 {
	margin-bottom: 150px;
	margin-top: 150px;
}


/*-------------------------------------
	2.8 colum gap less
-------------------------------------*/

.no-col-gap [class*="col-xs-"],
.no-col-gap [class*="col-sm-"],
.no-col-gap [class*="col-md-"],
.no-col-gap [class*="col-lg-"] {
	padding-left: 0;
	padding-right: 0;
}


/*======================================
6. POSITIONS ( absolute , relative , statice & z-index)
======================================*/


/*-------------------------------------
	1. position
-------------------------------------*/


/*1.1 relative*/

.relative {
	position: relative;
}


/*1.2 absolute*/

.absolute {
	position: absolute;
}


/*1.3 static*/

.static {
	position: static;
}


/*-------------------------------------
	2. z-index
-------------------------------------*/

.z-index1 {
	z-index: 1;
}

.z-index2 {
	z-index: 2;
}

.z-index3 {
	z-index: 3;
}

.z-index4 {
	z-index: 4;
}

.z-index5 {
	z-index: 5;
}

.z-index6 {
	z-index: 6;
}

.z-index7 {
	z-index: 7;
}

.z-index8 {
	z-index: 8;
}

.z-index9 {
	z-index: 9;
}

.z-index10 {
	z-index: 10;
}

.z-index100 {
	z-index: 100;
}

.z-index999 {
	z-index: 999;
}


/*======================================
7. COLOR
======================================*/


/*-------------------------------------
	1. text
-------------------------------------*/

.site-text-white {
	color: #fff;
}

.site-text-primary {
	color: #422775;
}

.site-text-secondry {
	color: #C71C22;
}

.text-white {
	color: #FFF;
}

.text-black {
	color: #000;
}

.text-gray {
	color: #f5f6f6;
}

.text-gray-dark {
	color: #d3d3d3;
}

.text-red {
	color: #99090A;
}

.text-green {
	color: #557131;
}

.text-yellow {
	color: #ffc100;
}

.text-light-blue {
	color: #004F83;
}


/*-------------------------------------
	2. background color
-------------------------------------*/

.bg-orange-light {
	background-color: #fff6ef;
}

.bg-orange-light2 {
	background-color: #feede7;
}

.bg-transparent {
	background-color: transparent;
}

.site-bg-primary {
	background-color: #ff5e15;
}

.site-bg-secondry {
	background-color: black;
}

.bg-dark {
	background-color: #1e1e20;
}

.bg-white {
	background-color: #FFF;
}

.bg-black {
	background-color: #000;
}

.bg-black-light {
	background-color: #212121;
}

.bg-gray {
	background-color: #e6e6e6;
}

.bg-gray-light {
	background-color: #f1f1f1;
}

.bg-gray-dark {
	background-color: #d3d3d3;
}

.bg-red {
	background-color: #fb5455;
}

.bg-orange {
	background-color: #FF4B00;
}

.bg-dark-blue {
	background-color: #161b23;
}

.bg-light-blue {
	background-color: #3097db;
}

.bg-green {
	background-color: #54ba1d;
}

.bg-yellow {
	background-color: #fff000;
}

.bg-purple {
	background-color: #5e6db3;
}

.bg-cyan {
	background-color: #00ca95;
}

.bg-blue {
	background-color: #31cff9;
}

.bg-light-orange {
	background-color: #fd7b6c;
}


.gradi-1 {
	background: rgb(78, 215, 207);
	/* Old browsers */
	background: -moz-linear-gradient(45deg, rgba(78, 215, 207, 1) 0%, rgba(25, 159, 255, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, rgba(78, 215, 207, 1) 0%, rgba(25, 159, 255, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, rgba(78, 215, 207, 1) 0%, rgba(25, 159, 255, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4ed7cf', endColorstr='#199fff', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}

.gradi-2 {
	background: rgb(234, 111, 157);
	/* Old browsers */
	background: -moz-linear-gradient(45deg, rgba(234, 111, 157, 1) 0%, rgba(238, 137, 109, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, rgba(234, 111, 157, 1) 0%, rgba(238, 137, 109, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, rgba(234, 111, 157, 1) 0%, rgba(238, 137, 109, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea6f9d', endColorstr='#ee896d', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}

.gradi-3 {
	background: rgb(98, 124, 235);
	/* Old browsers */
	background: -moz-linear-gradient(45deg, rgba(98, 124, 235, 1) 0%, rgba(110, 75, 159, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, rgba(98, 124, 235, 1) 0%, rgba(110, 75, 159, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, rgba(98, 124, 235, 1) 0%, rgba(110, 75, 159, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#627ceb', endColorstr='#6e4b9f', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}

.gradi-4 {
	background: rgb(251, 188, 117);
	/* Old browsers */
	background: -moz-linear-gradient(45deg, rgba(251, 188, 117, 1) 0%, rgba(251, 165, 106, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, rgba(251, 188, 117, 1) 0%, rgba(251, 165, 106, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, rgba(251, 188, 117, 1) 0%, rgba(251, 165, 106, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbbc75', endColorstr='#fba56a', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}


/*-------------------------------------
	3. background image
-------------------------------------*/

.bg-parallax {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@media only screen and (max-width:768px) {
	.bg-parallax {
		background-attachment: fixed !important;
		background-position: center !important;
		background-repeat: no-repeat;
		background-size: cover;
	}
}


/*======================================
8. OVERLAY ( transparient box style  )
======================================*/

.overlay-wraper {
	position: relative;
}

.overlay-main {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.opacity-01 {
	opacity: 0.1;
}

.opacity-02 {
	opacity: 0.2;
}

.opacity-03 {
	opacity: 0.3;
}

.opacity-04 {
	opacity: 0.4;
}

.opacity-05 {
	opacity: 0.5;
}

.opacity-06 {
	opacity: 0.6;
}

.opacity-07 {
	opacity: 0.7;
}

.opacity-08 {
	opacity: 0.8;
}

.opacity-09 {
	opacity: 0.9;
}

.overlay-light .overlay-main {
	opacity: 0.3;
}

.overlay-dark .overlay-main {
	opacity: 0.9;
}

.overlay-wraper>.container,
.overlay-wraper>.container-fluid,
.overlay-wraper>.wt-icon-box-wraper,
.overlay-wraper>.wt-left-part,
.overlay-wraper>.wt-right-part {
	position: relative;
	z-index: 1;
}


/*use for box*/

.overlay-bx {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	z-index: 1;
	opacity: 0;
	visibility: hidden;
}

.overlay-icon {
	list-style: none;
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	z-index: 1 !important;
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
}

.overlay-icon a {
	display: inline-block;
	padding: 0;
	margin: 0 2px;
	background-color: black;
	color: #fff;
}

.overlay-icon .wt-icon-box-xs {
	line-height: 40px;
}

.overlay-icon a:hover {
	color: #fff;
}

.mfp-gallery .overlay-icon a {
	color: #fff;
}

.mfp-gallery .overlay-icon a:hover {
	color: #0f1221;
}

.overlay-bx:hover a>i,
.wt-thum-bx:hover .overlay-bx a>i,
.wt-box:hover .overlay-bx a>i {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

.overlay-bx:hover,
.wt-thum-bx:hover .overlay-bx,
.wt-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
	opacity: 1;
	visibility: visible;
}


/*======================================
9. BOXES CSS
======================================*/

.rounded-bx,
.wt-box,
.wt-icon-box,
.wt-icon-box-small,
.wt-thum-bx,
.wt-post-thum {
	position: relative;
}


/*-------------------------------------
	1. box content
-------------------------------------*/

.wt-box {
	position: relative;
}

.wt-box.no-margin {
	margin-bottom: 0;
}


/*-------------------------------------
	2. For icons with box
-------------------------------------*/

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
	display: inline-block;
	text-align: center;
}

.wt-icon-box-xld {
	width: 180px;
	height: 180px;
}

.wt-icon-box-xl {
	width: 150px;
	height: 150px;
}

.wt-icon-box-lg {
	width: 120px;
	height: 120px;
}

.wt-icon-box-md {
	width: 100px;
	height: 100px;
}

.wt-icon-box-sm {
	width: 80px;
	height: 80px;
}

.wt-icon-box-xs {
	width: 40px;
	height: 40px;
}

.wt-icon-box-xld.radius,
.wt-icon-box-xl.radius,
.wt-icon-box-lg.radius,
.wt-icon-box-md.radius,
.wt-icon-box-sm.radius,
.wt-icon-box-xs.radius {
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
}

.wt-icon-box-xld i,
.wt-icon-box-xl i,
.wt-icon-box-lg i,
.wt-icon-box-md i,
.wt-icon-box-sm i,
.wt-icon-box-xs i {
	vertical-align: middle;
}

.wt-icon-box-xld i {
	font-size: 100px;
}

.wt-icon-box-xl i {
	font-size: 80px;
}

.wt-icon-box-lg i {
	font-size: 60px;
}

.wt-icon-box-md i {
	font-size: 45px;
}

.wt-icon-box-sm i {
	font-size: 30px;
}

.wt-icon-box-xs i {
	font-size: 20px;
}

.wt-icon-box-xld img,
.wt-icon-box-xl img,
.wt-icon-box-lg img,
.wt-icon-box-md img,
.wt-icon-box-sm img,
.wt-icon-box-xs img {
	vertical-align: middle;
	max-width: 100%;
	width: auto;
}

.wt-icon-box-xld img {
	height: 90px;
}

.wt-icon-box-xl img {
	height: 80px;
}

.wt-icon-box-lg img {
	height: 55px;
}

.wt-icon-box-md img {
	height: 60px;
}

.wt-icon-box-sm img {
	height: 30px;
}

.wt-icon-box-xs img {
	height: 20px;
}


/*-------------------------------------
	3. Border
-------------------------------------*/

.bdr-white {
	border-color: #ffffff;
}

.bdr-black {
	border-color: #000000;
}

.bdr-primary {
	border-color: #0f1221;
}

.bdr-gray {
	border-color: #dddddd;
}

.bdr-gray-light {
	border-color: #eeeeee;
}

.bdr-gray-dark {
	border-color: #333333;
}

.bdr-solid {
	border-style: solid;
}

.bdr-dot {
	border-style: dotted;
}

.bdr-dash {
	border-style: dashed;
}

.bdr-1 {
	border-width: 1px;
}

.bdr-2 {
	border-width: 2px;
}

.bdr-3 {
	border-width: 3px;
}

.bdr-4 {
	border-width: 4px;
}

.bdr-5 {
	border-width: 5px;
}

.bdr-l-1 {
	border-width: 0px 0px 0px 1px;
}

.bdr-r-1 {
	border-width: 0px 1px 0px 0px;
}

.bdr-t-1 {
	border-width: 1px 0px 0px 0px;
}

.bdr-b-1 {
	border-width: 0px 0px 1px 0px;
}

.bdr-l-2 {
	border-width: 0px 0px 0px 2px;
}

.bdr-r-2 {
	border-width: 0px 2px 0px 0px;
}

.bdr-t-2 {
	border-width: 2px 0px 0px 0px;
}

.bdr-b-2 {
	border-width: 0px 0px 2px 0px;
}

.bdr-l-3 {
	border-width: 0px 0px 0px 3px;
}

.bdr-r-3 {
	border-width: 0px 3px 0px 0px;
}

.bdr-t-3 {
	border-width: 3px 0px 0px 0px;
}

.bdr-b-3 {
	border-width: 0px 0px 3px 0px;
}

.bdr-l-4 {
	border-width: 0px 0px 0px 4px;
}

.bdr-r-4 {
	border-width: 0px 4px 0px 0px;
}

.bdr-t-4 {
	border-width: 4px 0px 0px 0px;
}

.bdr-b-4 {
	border-width: 0px 0px 4px 0px;
}

.bdr-l-5 {
	border-width: 0px 0px 0px 5px;
}

.bdr-r-5 {
	border-width: 0px 5px 0px 0px;
}

.bdr-t-5 {
	border-width: 5px 0px 0px 0px;
}

.bdr-b-5 {
	border-width: 0px 0px 5px 0px;
}


/*-------------------------------------
	4. For only icons
-------------------------------------*/

.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
	display: inline-block;
	text-align: center;
}

.icon-xld i,
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
	vertical-align: middle;
}

.icon-xld {
	width: 120px;
}

.icon-xld i {
	font-size: 100px;
}

.icon-xld.inline-icon {
	width: auto;
	text-align: left !important;
}

.icon-xl {
	width: 100px;
}

.icon-xl i {
	font-size: 80px;
}

.icon-xl.inline-icon {
	width: auto;
	text-align: left !important;
}

.icon-lg {
	width: 80px;
}

.icon-lg i {
	font-size: 60px;
}

.icon-lg.inline-icon {
	width: auto;
	text-align: left !important;
}

.icon-md {
	width: 60px;
}

.icon-md i {
	font-size: 45px;
}

.icon-md.inline-icon {
	width: auto;
	text-align: left !important;
}

.icon-sm {
	width: 40px;
}

.icon-sm i {
	font-size: 30px;
}

.icon-sm.inline-icon {
	width: auto;
	text-align: left !important;
}

.icon-xs {
	width: 30px;
}

.icon-xs i {
	font-size: 20px;
}

.icon-xs.inline-icon {
	width: auto;
	text-align: left !important;
}

.icon-xld img,
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
	vertical-align: middle;
	max-width: 100%;
	width: auto;
}

.icon-xld img {
	height: 90px;
}

.icon-xl img {
	height: 80px;
}

.icon-lg img {
	height: 70px;
}

.icon-md img {
	height: 50px;
}

.icon-sm img {
	height: 30px;
}

.icon-xs img {
	height: 20px;
}


/*-------------------------------------
	5. place icon with box
-------------------------------------*/

.wt-icon-box-wraper {
	position: relative;
}

.wt-icon-box-wraper .wt-tilte {
	margin-top: 0;
}

.wt-icon-box-wraper .after-titile-line {
	margin-bottom: 10px;
}

.wt-icon-box-wraper p:last-child {
	margin: 0px;
}

.icon-content {
	overflow: hidden;
}


/*.icon-content p{word-break:break-all;}*/

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
	display: table;
}

.wt-icon-box-xld .icon-cell,
.wt-icon-box-xl .icon-cell,
.wt-icon-box-lg .icon-cell,
.wt-icon-box-md .icon-cell,
.wt-icon-box-sm .icon-cell,
.wt-icon-box-xs .icon-cell {
	display: table-cell;
	vertical-align: middle;
}


/*-------------------------------------
	6. icon box left align
-------------------------------------*/

.wt-icon-box-wraper.left .wt-icon-box-xld,
.wt-icon-box-wraper.left .wt-icon-box-xl,
.wt-icon-box-wraper.left .wt-icon-box-lg,
.wt-icon-box-wraper.left .wt-icon-box-md,
.wt-icon-box-wraper.left .wt-icon-box-sm,
.wt-icon-box-wraper.left .wt-icon-box-xs {
	float: left;
	margin-right: 20px;
}

.wt-icon-box-wraper.left .icon-xld,
.wt-icon-box-wraper.left .icon-xl,
.wt-icon-box-wraper.left .icon-lg,
.wt-icon-box-wraper.left .icon-md,
.wt-icon-box-wraper.left .icon-sm,
.wt-icon-box-wraper.left .icon-xs {
	float: left;
	margin-right: 10px;
}


/*-------------------------------------
	7. icon box right align
-------------------------------------*/

.wt-icon-box-wraper.right {
	text-align: right;
}

.wt-icon-box-wraper.right .wt-icon-box-xld,
.wt-icon-box-wraper.right .wt-icon-box-xl,
.wt-icon-box-wraper.right .wt-icon-box-lg,
.wt-icon-box-wraper.right .wt-icon-box-md,
.wt-icon-box-wraper.right .wt-icon-box-sm,
.wt-icon-box-wraper.right .wt-icon-box-xs {
	float: right;
	display: table;
	margin-left: 20px;
}

.wt-icon-box-wraper.right .icon-xld,
.wt-icon-box-wraper.right .icon-xl,
.wt-icon-box-wraper.right .icon-lg,
.wt-icon-box-wraper.right .icon-md,
.wt-icon-box-wraper.right .icon-sm,
.wt-icon-box-wraper.right .icon-xs {
	float: right;
	margin-left: 10px;
}


/*-------------------------------------
	8. icon box center align
-------------------------------------*/

.wt-icon-box-wraper.center {
	text-align: center;
}

.wt-icon-box-wraper.center .wt-icon-box-xld,
.wt-icon-box-wraper.center .wt-icon-box-xl,
.wt-icon-box-wraper.center .wt-icon-box-lg,
.wt-icon-box-wraper.center .wt-icon-box-md,
.wt-icon-box-wraper.center .wt-icon-box-sm,
.wt-icon-box-wraper.center .wt-icon-box-xs {
	margin-left: auto;
	margin-right: auto;
}

.wt-icon-box-wraper.bx-style-1,
.wt-icon-box-wraper.bx-style-2 {
	border-width: 1px;
	border-style: solid;
	border-color: #ddd;
}

.wt-icon-box-wraper.bx-style-2.center [class*="wt-icon-box-"],
.wt-icon-box-wraper.bx-style-2.left [class*="wt-icon-box-"],
.wt-icon-box-wraper.bx-style-2.right [class*="wt-icon-box-"] {
	position: absolute;
}

.wt-icon-box-wraper.bx-style-2.center [class*="wt-icon-box-"] {
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xl {
	margin-left: -75px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-lg {
	margin-left: -60px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-md {
	margin-left: -50px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-sm {
	margin-left: -40px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xs {
	margin-left: -20px;
}

.wt-icon-box-wraper.bx-style-2.left [class*="wt-icon-box-"] {
	position: absolute;
	top: auto;
	left: 0;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.wt-icon-box-wraper.bx-style-2.right [class*="wt-icon-box-"] {
	position: absolute;
	top: auto;
	right: 0;
	-webkit-transform: translateX(50%);
	-ms-transform: translateX(50%);
	transform: translateX(50%);
}


/*-------------------------------------
	9. media with content box css
-------------------------------------*/

.wt-box,
.wt-info,
.wt-tilte,
.wt-tilte-inner {
	position: relative;
}

.wt-tilte-inner {
	display: inline-block;
}

.wt-tilte-inner.skew-title:after {
	content: "";
	position: absolute;
	right: -15px;
	top: 0;
	width: 50px;
	height: 100%;
	z-index: -1;
	-moz-transform: skewX(20deg);
	-webkit-transform: skewX(20deg);
	-o-transform: skewX(20deg);
	-ms-transform: skewX(20deg);
	transform: skewX(20deg);
}

.wt-box[class*="border-"],
.wt-info[class*="border-"],
.wt-icon-box-wraper[class*="border-"] {
	border-color: #eee;
}

.wt-info.border-1,
.wt-info.border-2,
.wt-info.border-3,
.wt-info.border-4,
.wt-info.border-5 {
	border-top: none;
}


/*-------------------------------------
	10. for images
-------------------------------------*/

.wt-thum-bx,
.wt-post-thum {
	background-color: #000;
}

.wt-thum-bx img,
.wt-post-thum img {
	width: 100%;
	height: auto;
}


/*======================================
10. IMAGES OVERLAY EFFECTS (overly animation on images)
======================================*/

.wt-img-overlay1,
.wt-img-overlay2 {
	position: relative;
}

.wt-img-overlay1:before,
.wt-img-overlay1:after {
	content: "";
	background: #000;
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	opacity: 0.4;
	z-index: 0;
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}


/*-------------------------------------
	1. effect 1
-------------------------------------*/

.wt-img-overlay1:before {
	width: 100%;
	height: 100%;
	opacity: 0;
}

.wt-img-overlay1:hover:before,
.wt-box:hover .wt-img-overlay1:before {
	opacity: 0.5;
}


/*-------------------------------------
	2. effect 2
-------------------------------------*/

.wt-img-overlay2:before {
	width: 100%;
	height: 100%;
	opacity: 0;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );*/
	/* IE6-9 */
}

.wt-img-overlay2:hover:before,
.wt-box:hover .wt-img-overlay2:before {
	opacity: 0.9;
}


/*======================================
11. shortcodeS
======================================*/


/*-------------------------------------
	1. Buttons
--------------------------------------- */


/* 1.1 theme default button */

.btn {
	padding-top: 9px;
	padding-bottom: 9px;
}

/* .site-button:active,
.site-button:focus,
.site-button:visited,
.active>.site-button {
	background-color: #ff5e15;
	color: #fff;
}*/


.site-button:hover {
	background-color: black;
	color: #fff;
}

.site-button-secondry {
	color: #fff;
	outline: none;
	background-color: black;
}


.site-button {
	outline: none;
	color: #fff;
	padding: 15px 40px;
	letter-spacing: 1px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	display: inline-block;
	background-color: #ff5e15;
	border: none;
	font-size: 14px;
	font-weight: 500;
}

.site-button-secondry {
	color: #fff;
	padding: 15px 40px;
	letter-spacing: 1px;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	display: inline-block;
	background-color: black;
	border: none;
	font-size: 14px;
}


.site-button-gradient {
	background: rgb(249, 107, 57);
	/* Old browsers */
	background: -moz-linear-gradient(-45deg, rgba(249, 107, 57, 1) 0%, rgba(26, 32, 59, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, rgba(249, 107, 57, 1) 0%, rgba(26, 32, 59, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, rgba(249, 107, 57, 1) 0%, rgba(26, 32, 59, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='black', endColorstr='#1a203b', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */

}

.site-button-gradient:hover {
	background: rgb(26, 32, 59);
	/* Old browsers */
	background: -moz-linear-gradient(-45deg, rgba(26, 32, 59, 1) 0%, rgba(249, 107, 57, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, rgba(26, 32, 59, 1) 0%, rgba(249, 107, 57, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, rgba(26, 32, 59, 1) 0%, rgba(249, 107, 57, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1a203b', endColorstr='black', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}

.mfp-video.video-play-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	line-height: 38px;
	text-align: center;
	margin: -25px;
	color: #fff;
	display: block;
	z-index: 10;
	font-size: 14px;
	border: 2px solid #fff;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.mfp-video.video-play-btn:hover {
	background-color: #0f1221;
	color: #fff;
	border: 2px solid transparent;
}

.mfp-video.video-play-btn i {
	margin-right: -5px;
}


/* 1.2 button text uppercase */

.site-button.text-uppercase {
	text-transform: uppercase;
}


/* 1.3 button size */

.button-sm {
	padding: 5px 10px;
	font-size: 12px;
}

.button-lg {
	padding: 15px 30px;
	font-size: 16px;
}

.button-xl {
	padding: 30px 50px;
	font-size: 24px;
}


/* 1.4 button rounded */

.radius-no {
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.radius-sm {
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
}

.radius-md {
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
}

.radius-xl {
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
}

.radius-bx {
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
}

.service-icon {
	filter: brightness(0) invert(1);
}

.service-icon-home {
	/* filter: brightness(0) invert(1); */
	width: 20px;
}

.service-icon-home img {
	width: auto !important;
	height: 80px !important;
	display: inline-block !important;
}

.about-us-service-icon img {
	width: auto !important;
	height: 60px !important;
	display: inline-block !important;
}

/* 1.6 white */

.site-button.white {
	background-color: #fff;
	color: #484848;
}

.site-button.white:hover,
.site-button.white:active,
.site-button.white:focus {
	background-color: #F4F4F4;
	color: #555;
}


/* 1.7 black */

.site-button.black {
	background-color: #000;
	color: #fff;
}

.site-button.black:hover,
.site-button.black:active,
.site-button.black:focus {
	background-color: #171717 !important;
	color: #fff !important;
}


/* 1.8 Gray */

.site-button.gray {
	background-color: #666666;
	color: #fff;
}

.site-button.gray:hover,
.site-button.gray:active,
.site-button.gray:focus {
	background-color: #555555;
	color: #fff;
}


/* 1.9 pink */

.site-button.pink {
	background-color: #e63f75;
	color: #fff;
}

.site-button.pink:hover,
.site-button.pink:active,
.site-button.pink:focus {
	background-color: #d22b61;
	color: #fff;
}


/* 1.10 Blue */

.site-button.blue {
	background-color: #42B8D4;
	color: #fff;
}

.site-button.blue:hover,
.site-button.blue:active,
.site-button.blue:focus {
	background-color: #2ca2be;
	color: #fff;
}


/* 1.11 Green */

.site-button.green {
	background-color: #35B494;
	color: #fff;
}

.site-button.green:hover,
.site-button.green:active,
.site-button.green:focus {
	background-color: #26a585;
	color: #fff;
}


/* 1.12 Orange */

.site-button.orange {
	background-color: #E56713;
	color: #fff;
}

.site-button.orange:hover,
.site-button.orange:active,
.site-button.orange:focus {
	background-color: #d55703;
	color: #fff;
}


/* 1.13 Red */

.site-button.red {
	background-color: #D93223;
	color: #fff;
}

.site-button.red:hover,
.site-button.red:active,
.site-button.red:focus {
	background-color: #c51e0f;
	color: #fff;
}


/*1.14 Brown */

.site-button.brown {
	background-color: #69441F;
	color: #fff;
}

.site-button.brown:hover,
.site-button.brown:active,
.site-button.brown:focus {
	background-color: #5f3a15;
	color: #fff;
}


/* 1.15 Yellow */

.site-button.yellow {
	background-color: #ecc731;
	color: #fff;
}

.site-button.yellow:hover,
.site-button.yellow:active,
.site-button.yellow:focus {
	background-color: #d4af19;
	color: #fff;
}


/* 1.16 purple */

.site-button.purple {
	background-color: #AE1AF7;
	color: #fff;
}

.site-button.purple:hover,
.site-button.purple:active,
.site-button.purple:focus {
	background-color: #9804e1;
	color: #fff;
}


/* 1.17 Graphical */

.site-button.graphical {
	color: #fff;
	text-shadow: 0 -1px rgba(0, 0, 0, 0.4);
	box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3), 0 1px 3px -1px rgba(45, 60, 72, 0.5);
	-webkit-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3), 0 1px 3px -1px rgba(45, 60, 72, 0.5);
	-moz-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3), 0 1px 3px -1px rgba(45, 60, 72, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.15);
}

.site-button.graphical:active {
	box-shadow: 0 2px 2px rgba(0, 0, 0, .25) inset;
	-webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, .25) inset;
	-moz-box-shadow: 0 2px 2px rgba(0, 0, 0, .25) inset;
}


/* 1.19 Outline */

.site-button.outline {
	color: #888;
	background: none;
	border-width: 2px;
	border-style: solid;
	border-color: #eee;
}


/* 1.20 Outline white  */

.site-button.outline.white {
	color: #e7e7e7;
	border-color: #e7e7e7;
}

.site-button.outline.white:hover {
	color: #666666;
}


/* 1.21 Outline black */

.site-button.outline.black {
	color: #171717;
	border-color: #171717;
}


/* 1.22 Outline gray */

.site-button.outline.gray {
	color: #666666;
	border-color: #666666;
}


/* 1.23 Outline pink */

.site-button.outline.pink {
	color: #e63f75;
	border-color: #e63f75;
}


/* 1.24 Outline blue */

.site-button.outline.blue {
	color: #42b8d4;
	border-color: #42b8d4;
}


/* 1.25 Outline green */

.site-button.outline.green {
	color: #35b494;
	border-color: #35b494;
}


/* 1.26 Outline orange */

.site-button.outline.orange {
	color: #e56713;
	border-color: #e56713;
}


/* 1.27 Outline red */

.site-button.outline.red {
	color: #d93223;
	border-color: #d93223;
}


/* 1.28 Outline brown */

.site-button.outline.brown {
	color: #69441f;
	border-color: #69441f;
}


/* 1.29 Outline yellow */

.site-button.outline.yellow {
	color: #0f1221;
	border-color: #0f1221;
}


/* 1.30 Outline purple */

.site-button.outline.purple {
	color: #ae1af7;
	border-color: #ae1af7;
}


/* 1.31 Outline hover */

.site-button.outline.black:hover {
	border-color: rgba(0, 0, 0, 0);
	color: #fff
}


/* 1.32 button text link */

.site-button-link {
	display: inline-block;
	position: relative;
	font-weight: 700;
}


/* 1.33 text link white */

.site-button-link.white {
	color: #e7e7e7;
}

.site-button-link.white:hover,
.site-button-link.white:active,
.site-button-link.white:focus {
	color: #CCC;
}


/* 1.34 text link black */

.site-button-link.black {
	color: #171717;
}

.site-button-link.black:hover,
.site-button-link.black:active,
.site-button-link.black:focus {
	color: #000;
}


/* 1.35 text link Gray */

.site-button-link.gray {
	color: #666666;
}

.site-button-link.gray:hover,
.site-button-link.gray:active,
.site-button-link.gray:focus {
	color: #555555;
}


/* 1.36 text link pink */

.site-button-link.pink {
	color: #e63f75;
}

.site-button-link.pink:hover,
.site-button-link.pink:active,
.site-button-link.pink:focus {
	color: #2ca2be;
}


/* 1.37 text link Blue */

.site-button-link.blue {
	color: #42B8D4;
}

.site-button-link.blue:hover,
.site-button-link.blue:active,
.site-button-link.blue:focus {
	color: #2ca2be;
}


/* 1.38 text link Green */

.site-button-link.green {
	color: #35B494;
}

.site-button-link.green:hover,
.site-button-link.green:active,
.site-button-link.green:focus {
	color: #26a585;
}


/* 1.39 text link Orange */

.site-button-link.orange {
	color: #E56713;
}

.site-button-link.orange:hover,
.site-button-link.orange:active,
.site-button-link.orange:focus {
	color: #d55703;
}


/* 1.40 text link Red */

.site-button-link.red {
	color: #D93223;
}

.site-button-link.red:hover,
.site-button-link.red:active,
.site-button-link.red:focus {
	color: #c51e0f;
}


/* 1.41 text link Brown */

.site-button-link.brown {
	color: #69441F;
}

.site-button-link.brown:hover,
.site-button-link.brown:active,
.site-button-link.brown:focus {
	color: #5f3a15;
}


/* 1.42 text link Yellow */

.site-button-link.yellow {
	color: #ecc731;
}

.site-button-link.yellow:hover,
.site-button-link.yellow:active,
.site-button-link.yellow:focus {
	color: #d4af19;
}


/* 1.43 text link purple */

.site-button-link.purple {
	color: #ae1af7;
}

.site-button-link.purple:hover,
.site-button-link.purple:active,
.site-button-link.purple:focus {
	color: #9804e1;
}


/* 1.44 app strore */

.site-button.button-app {
	text-align: left;
	padding: 15px 25px;
}

.site-button.button-app i {
	display: inline-block;
	font-size: 45px;
	margin-right: 15px;
}

.site-button.button-app strong {
	display: block;
	font-size: 16px;
}


/* 1.45 filter buttons css */

.masonry-filter {
	display: inline-block;
	margin-bottom: 0;
}

.masonry-filter>li {
	display: inline-block;
	margin-right: 0px;
}

.masonry-filter li a {
	color: #111111;
	font-size: 14px;
	position: relative;
	z-index: 1;
	display: block;
	overflow: hidden;
	position: relative;
	margin: 1px 10px;
	font-weight: 500;
	letter-spacing: 1px;
}

.masonry-filter>li.active a,
.masonry-filter>li a:hover,
.masonry-filter>li a:active,
.masonry-filter>li a:focus {
	color: #ff5e15;
}

.masonry-filter.filter-gradi>li.active a,
.masonry-filter.filter-gradi>li a:hover,
.masonry-filter.filter-gradi>li a:active,
.masonry-filter.filter-gradi>li a:focus {
	color: #fff;
}

.masonry-filter.filter-light-bg>li a {
	color: #0f1221;
}

.masonry-filter.filter-gradi>li a {
	display: inline-block;
	margin-right: 0px;
	padding: 10px 25px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	margin-right: 10px;
}

.masonry-filter.filter-gradi>li.active a,
.masonry-filter.filter-gradi>li a:hover,
.masonry-filter.filter-gradi>li a:active,
.masonry-filter.filter-gradi>li a:focus {
	background: rgb(249, 107, 57);
	/* Old browsers */
}

@media only screen and (max-width: 991px) {
	.masonry-filter.filter-gradi>li a {
		padding: 10px 10px;
	}
}

@media only screen and (max-width: 767px) {
	.masonry-filter.filter-gradi>li a {
		padding: 4px 10px;
	}

	.filter-wrap {
		text-align: left !important;
	}
}

@media only screen and (max-width: 600px) {

	.masonry-filter>li a {
		font-size: 20px !important;
	}
}


/* 1.45(1) Masonry filter alignment [Left-Right-Center] */

.filter-wrap.right>.masonry-filter {
	float: right;
}

@media only screen and (max-width:991px) {
	.filter-wrap.right>.masonry-filter {
		float: none;
	}
}

.filter-wrap.center>.masonry-filter {
	margin-left: auto;
	margin-right: auto;
}


/* 1.45(2) Masonry filter link style */

.masonry-filter.link-style>li a {
	font-size: 32px;
	font-family: 'Teko', sans-serif;
}

.masonry-filter.link-style.white>li:after {
	color: #fff;
}

.masonry-filter.link-style>li:last-child:after {
	display: none;
}

.masonry-filter.link-style.white>li.active a,
.masonry-filter.link-style.white>li a:hover,
.masonry-filter.link-style.white>li a:active,
.masonry-filter.link-style.white>li a:focus {
	color: #f7c20a;
}

.masonry-filter.has-bg>li a {
	color: #fff;
}

.masonry-filter.link-style.has-bg>li.active a,
.masonry-filter.link-style.has-bg>li:hover a,
.masonry-filter.link-style.has-bg>li:active a,
.masonry-filter.link-style.has-bg>li:focus a {
	color: #0f1221;
}


/* 1.45(3) Masonry filter button style */

.masonry-filter.button-style>li {
	margin-right: 10px;
}

@media only screen and (max-width: 767px) {
	.masonry-filter.button-style>li {
		margin-bottom: 10px;
	}
}

.masonry-filter.button-style>li a {
	background-color: #666666;
	color: #fff;
	padding: 10px 20px;
	font-weight: 600;
	display: inline-block;
}

.masonry-filter.button-style>li.active a,
.masonry-filter.button-style>li a:hover,
.masonry-filter.button-style>li a:active,
.masonry-filter.button-style>li a:focus {
	background-color: #000;
	color: #fff;
}

.masonry-filter.button-style.has-bg>li a {
	background-color: #fff;
	color: #666;
}

.masonry-filter.button-style.has-bg>li.active a,
.masonry-filter.button-style.has-bg>li a:hover,
.masonry-filter.button-style.has-bg>li a:active,
.masonry-filter.button-style.has-bg>li a:focus {
	background-color: #000;
	color: #fff;
}


/* 1.45(4) Masonry filter button outline style */

.masonry-filter.outline-style>li {
	margin-right: 10px;
}

.masonry-filter.outline-style>li a {
	border-width: 2px;
	border-style: solid;
	border-color: #666666;
	color: #666666;
	padding: 10px 20px;
	font-weight: 600;
	display: inline-block;
}

.masonry-filter.outline-style.rounded-corner>li a {
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
}

.masonry-filter.outline-style>li.active a,
.masonry-filter.outline-style>li a:hover,
.masonry-filter.outline-style>li a:active,
.masonry-filter.outline-style>li a:focus {
	border-color: #000;
	color: #000;
}

.masonry-filter.outline-style.has-bg>li a {
	border-color: #fff;
	color: #fff;
}

.masonry-filter.outline-style.has-bg>li.active a,
.masonry-filter.outline-style.has-bg>li a:hover,
.masonry-filter.outline-style.has-bg>li a:active,
.masonry-filter.outline-style.has-bg>li a:focus {
	border-color: #000;
	color: #000;
}

@media only screen and (max-width:768px) {
	.masonry-item {
		width: 100%;
	}
}


.masonry-item {
	cursor: pointer;
}


/*-------------------------------------
	2. Title separators
-------------------------------------*/
.wt-separator-two-part {
	padding-bottom: 15px;
}

.wt-separator-two-part-row {
	display: flex;
	align-items: center;
}

.wt-separator-two-part-left .section-head {
	margin-bottom: 0px;
}

.wt-separator-outer {
	overflow: hidden;
}

.wt-small-separator {
	font-family: 'Teko', sans-serif;
	font-size: 34px;
	line-height: 34px;
	margin-bottom: 15px;
	position: relative;
	display: inline-block;
}

.wt-small-separator img {
	width: 28px;
	vertical-align: baseline;
}

.wt-small-separator div {
	display: inline-block;
}

@media only screen and (max-width:767px) {
	.wt-separator-two-part-right.text-right {
		text-align: left !important;
	}

	.wt-separator-two-part {
		padding-bottom: 30px;
	}
}

@media only screen and (max-width:540px) {
	.wt-small-separator {
		font-size: 22px;
		line-height: 20px;
	}
}


/*center-title*/
.center.wt-small-separator-outer {
	text-align: center;
}

.center.wt-small-separator-outer h2 {
	max-width: 650px;
	margin: 0px auto;
}

.center .wt-small-separator div.sep-leaf-right {
	position: relative;
	margin-left: 50px;
}

.center .wt-small-separator div.sep-leaf-left {
	position: relative;
	margin-right: 50px;
}

.center .wt-small-separator div.sep-leaf-right:before,
.center .wt-small-separator div.sep-leaf-left:before {
	position: absolute;
	content: '';
	background-color: #ff5e15;
	width: 40px;
	height: 3px;
	bottom: 0px;
}

.center .wt-small-separator div.sep-leaf-right:before {
	right: 0px;
}

.center .wt-small-separator div.sep-leaf-left:before {
	left: 0px;
}

/*left-title*/

.left.wt-small-separator-outer {
	text-align: left;
}

.left .wt-small-separator div.sep-leaf-right {
	position: relative;
	margin-left: 50px;
}

.left .wt-small-separator div.sep-leaf-left {
	position: relative;
	margin-right: 50px;
	/* display: none; */
}

.left .wt-small-separator div.sep-leaf-right:before,
.left .wt-small-separator div.sep-leaf-left:before {
	position: absolute;
	content: '';
	background-color: white;
	width: 40px;
	height: 3px;
	bottom: 0px;
}

.left .wt-small-separator div.sep-leaf-right:before {
	right: 0px;
}

.left .wt-small-separator div.sep-leaf-left:before {
	left: 0px;
}



@media only screen and (max-width:540px) {
	div.sep-leaf-right:before {
		display: none;
	}

	div.sep-leaf-left:before {
		display: none;
	}

	.center .wt-small-separator div.sep-leaf-left {
		margin: 0px;
	}

	.center .wt-small-separator div.sep-leaf-right {
		margin: 0px;
	}
}

/*Separator defualt*/


/*-------------------------------------
	3. Deviders
-------------------------------------*/

.wt-divider {
	height: 1px;
	position: relative;
	margin: 0px 0px 30px 0px;
	background-color: #424242;
}

.wt-divider.divider-2px {
	height: 2px;
}

.wt-divider.divider-3px {
	height: 3px;
}

.wt-divider.divider-4px {
	height: 4px;
}

.wt-divider i {
	position: absolute;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	display: block;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.wt-divider.icon-left {
	margin-left: 40px;
}

.wt-divider.icon-left i {
	left: -40px;
}

.wt-divider.icon-right {
	margin-right: 40px;
}

.wt-divider.icon-right i {
	left: auto;
	right: -40px;
}

.wt-divider.icon-center i {
	left: 50%;
	margin-left: -5px;
}


/*-------------------------------------
	4. TABS
-------------------------------------*/

.wt-tabs:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

@media only screen and (max-width: 767px) {
	.wt-tabs .nav-tabs>li {
		float: none;
	}
}

.wt-tabs p:last-child {
	margin-bottom: 0;
}


/*4.1 tabs style 1 [ default ] */

.wt-tabs .tab-pane {
	padding: 20px 0;
}

.wt-tabs .nav-tabs>li>a {
	color: #000;
	font-size: 14px;
	padding: 15px;
	border-bottom: 1px solid transparent;
	border-radius: 0px;
	font-weight: 800;
	border-top: 2px solid transparent;
	display: block;
}

.wt-tabs .nav-tabs>li>a.active,
.wt-tabs .nav-tabs>li>a.active:focus {
	background-color: #fff;
	border-bottom: 1px solid #fff;
	color: #000;
	border-top: 2px solid #0f1221;
}

.wt-tabs .nav-tabs>li.active>a:hover {
	background-color: #fff;
	border: 1px solid #ddd;
	border-bottom: 1px solid #fff;
	color: #000;
	border-top: 2px solid #0f1221;
}

.wt-tabs .nav>li>a:focus,
.wt-tabs .nav>li>a:hover {
	background-color: transparent;
}

.wt-tabs .nav li a:hover {
	border-color: transparent;
}

.wt-tabs .nav-tabs.nav-justified>.active>a,
.wt-tabs .nav-tabs.nav-justified>.active>a:focus,
.wt-tabs .nav-tabs.nav-justified>.active>a:hover {
	border: 0px;
}

.wt-tabs .nav-tabs>li>a i {
	margin-right: 5px;
}

.wt-tabs.tabs-default.has-bg .nav-tabs>li>a {
	color: #fff;
}

.wt-tabs.tabs-default.has-bg .nav-tabs>li.active>a,
.wt-tabs.tabs-default.has-bg .nav-tabs>li.active>a:focus,
.wt-tabs.tabs-default.has-bg .nav-tabs>li.active>a:hover {
	color: #000;
	border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default.has-bg .tab-pane {
	color: #fff;
}


/* tabs nav center */

.wt-tabs.nav-center>.nav-tabs {
	display: table;
	margin-left: auto;
	margin-right: auto;
}


/*4.2 tabs bg  [ tabs background ] */

.wt-tabs.bg-tabs .nav-tabs>li>a {
	background-color: #ff5e15;
	border: 0px;
	margin-right: -1px;

}

.wt-tabs.bg-tabs .nav-tabs>li>a.active {
	background-color: black;
	color: #fff;
	border: 0px;
}

.wt-tabs.bg-tabs.has-bg .tab-pane {
	color: #fff;
}


/*4.3 tabs bg  [ tabs background ] */

.wt-tabs.vertical.bg-tabs .nav-tabs>li>a {
	border-bottom: 4px solid transparent;
}

.wt-tabs.vertical.bg-tabs .nav-tabs>li.active>a {
	border-bottom: 1px solid transparent;
	background-color: #000;
	color: #0f1221;
	border-bottom: 4px solid #0f1221;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs>li>a {
	border: 1px solid #ddd;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs>li.active>a {
	border-left: 1px solid transparent;
}


/*4.4 tabs bg & top border  [ tabs background & top border in active ] */

.wt-tabs.border-top .nav-tabs>li.active>a {
	color: #1a1a1a;
}

.wt-tabs.border-top .nav-tabs>li.active>a:after {
	content: "";
	position: absolute;
	top: -1px;
	left: 0;
	width: 100%;
	height: 3px;
	background-color: #000;
}

.wt-tabs.border-top.vertical .nav-tabs>li.active>a:after {
	top: 0px;
	left: -1px;
	width: 3px;
	height: 100%;
}


/*4.4 tabs style 2 [ content with border outer ] */

.wt-tabs.border .tab-pane {
	padding: 10px;
	border: 1px solid #ddd;
	margin-top: -1px;
}

.wt-tabs.border {
	border: none !important;
}

.wt-tabs.border .table td,
.wt-tabs.border .table th {
	border: 0px;
}

.wt-tabs.border .nav.nav-tabs {
	border: 0px;
}

/*4.5 tabs style 3  [ left-nav ] */

.wt-tabs.vertical .nav-tabs {
	float: left;
	width: 170px;
	border-bottom: none;
	border-right: 0px;
}

@media only screen and (max-width:720px) {
	.wt-tabs.vertical .nav-tabs {
		width: 100%;
		float: none;
		border-right: 0px;
	}

	.wt-tabs.vertical .tab-content {
		width: 100%;
		margin-left: 0px !important;
	}
}

.wt-tabs.vertical .tab-pane {
	padding: 10px 0 10px 20px;
}

.wt-tabs.vertical .nav-tabs li {
	float: none;
	margin-right: 0px;
}

.wt-tabs.vertical .nav-tabs li a {
	margin-right: 0;
	border-right: none;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0;
	border-radius: 0px;
	color: #000;
	border-bottom: 1px solid transparent;
}

.wt-tabs.vertical .nav-tabs li.active a {
	border-bottom: 1px solid #0f1221;
	background-color: #fff;
}

.wt-tabs.vertical .tab-content {
	border-left: 0px;
	margin-left: 169px;
}


/*4.6 tabs style 4  [ left-nav & content with border ] */

.wt-tabs.vertical.border .tab-pane {
	padding: 20px;
	margin-left: -1px;
}


/*4.7 tabs style 5  [ right-nav ] */

.wt-tabs.vertical.right .nav-tabs {
	border-left: 1px solid #ddd;
	border-right: none;
	float: right;
}

.wt-tabs.vertical.right .nav-tabs li {
	margin-right: 0;
	margin-left: -1px;
}

.wt-tabs.vertical.right .nav-tabs li a {
	border-right: 1px solid transparent;
	border-left: none;
}

.wt-tabs.vertical.right .nav-tabs li.active a {
	border-right: 1px solid #ddd;
	border-left: none;
}

.wt-tabs.vertical.right .tab-content {
	border-left: none;
	border-right: 1px solid #ddd;
	margin-right: 169px;
	margin-left: 0;
}

.wt-tabs.vertical.right .tab-pane {
	padding: 10px 20px 10px 0;
}


/*4.8 tabs style 6  [ right-nav & content with border ]*/

.wt-tabs.vertical.right.border .tab-pane {
	padding: 20px;
	margin-right: -1px;
}

.pdf-reader-title {
	height: 60px;
	background-color: #959190;
	color: white;
	display: flex;
	align-items: center;
	font-size: 20px;
	padding: 0 15px;

}

.pdf-reader-wrapper {
	border: 2px solid #1c63b8;
	margin-bottom: 50px;
}

.pdf-reader {
	width: 100%;
	height: 500px;
}

/*-------------------------------------
	5. Accordians
-------------------------------------*/

/* General Card Styles */
.card-sam {
	background-color: #eee; /* Light gray background */
	padding: 20px;
	margin: 0px auto 25px;
	max-width: 100%;
	position: relative;
	box-sizing: border-box;
  }
  
  /* Card Header */
  .card-header-sam {
	display: flex;
	justify-content: space-between; /* Ensures title and button are on opposite ends */
	align-items: center;
	position: relative;
  }
  
  .card-title-sam {
	margin: 0;
	padding-right: 50px; /* Ensures space for the button */
	font-family: Helvetica, sans-serif;
	font-weight: 500;
  }
  
  /* Red Button */
  .collapse-button-sam {
	position: absolute;
	right: 0;
	top: 0;
	background-color: #d61c1c;
	color: #fff;
	border: none;
	padding: 10px 15px;
	font-size: 24px;
	font-weight: bold;
	cursor: pointer;
	line-height: 1;
	transition: background-color 0.3s;
	margin-top: -20px;
	margin-right: -20px;
  }
  
  .collapse-button-sam:hover {
	background-color: #b81414;
  }
  
  /* Card Body */
  .card-body-sam {
	margin-top: 15px;
	padding: 10px 0 0 0;
  }
  
  .card-body-sam ul {
	list-style-type: disc;
	margin: 0;
	padding-left: 20px;
	line-height: 1.6;
	font-size: 16px;
	color: #333;
  }

.wt-accordion .wt-panel {
	background-color: transparent;
	border: none;
	margin-bottom: 10px;
	border-radius: 0;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}

.accord-head {
	position: relative;
}

.accord-head a.collapsed {
	color: black;
}

.acod-title {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Helvetica, sans-serif;
	font-weight: 700;
}

.acod-title a {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.accord-head .fa {
	margin-right: 5px;
}

.accord-head a {
	display: block;
	padding: 15px 15px 15px 15px;
	font-size: 28px;
	font-weight: 500;
}

.accord-head a,
.accord-head a:hover,
.accord-head a:active,
.accord-head a:focus {
	color: #0f1221;
}

.acod-body {
	color: #000;
}

.acod-body .acod-content p:last-child {
	margin-bottom: 0px;
}

@media only screen and (max-width:540px) {
	.accord-head a .indicator {
		font-size: 12px;
	}
}

/*Accodian open close indicator css*/

.accord-head .indicator {
	padding: 15px;
	color: #000;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	font-size: 12px;
}

.accord-head .indicator .fa {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);

}


.accord-head.acc-actives .indicator .fa:before,
.accord-head [aria-expanded="false"] .indicator .fa:before {
	content: "\f067";
	font-family: 'FontAwesome';
}

.accord-head.acc-actives .indicator .fa:before,
.accord-head [aria-expanded="true"] .indicator .fa:before {
	font-family: 'FontAwesome';
	content: "\f068"
}

.accord-head .collapsed .indicator .fa:before,
.accord-head .collapsed [aria-expanded="false"] .indicator .fa:before {
	content: "\f067";
	font-family: 'FontAwesome';
}

.accord-head .indicator .fa:before,
.accord-head [aria-expanded="true"] .indicator .fa:before {
	font-family: 'FontAwesome';
	content: "\f068"
}



/* 1. Accordion defult */

.acc-default .acod-title a {
	padding: 5px 0;
	border-bottom: 1px solid #ececec;
}

.acc-default .accord-head .indicator {
	padding: 0;
}

.acc-default.acc-has-bg .accord-head a,
.acc-default.acc-has-bg .accord-head a:hover,
.acc-default.acc-has-bg .accord-head a:active,
.acc-default.acc-has-bg .accord-head a:focus,
.acc-default.acc-has-bg .accord-head .indicator,
.acc-default.acc-has-bg .acod-body {
	color: #fff;
}


/* 2. Accordion outline */

.acc-outline .acod-title a {
	border: 1px solid #ddd;
}

.acc-outline.acc-has-bg .accord-head a,
.acc-outline.acc-has-bg .accord-head a:hover,
.acc-outline.acc-has-bg .accord-head a:active,
.acc-outline.acc-has-bg .accord-head a:focus,
.acc-outline.acc-has-bg .accord-head .indicator,
.acc-outline.acc-has-bg .acod-body {
	color: #fff;
}


/* 3. Accordion bg gray */

.acc-bg-gray a {
	background-color: #e6e6e6;
}

.acc-bg-gray .accord-head .indicator {
	color: #fff;
	background-color: #C71C22; /* Butoane de + si - */
}

.acc-bg-gray.acc-has-bg .acod-body {
	color: #fff;
}

/* 3. Accordion bg gray */

.acc-bg-white a {
	background-color: #fff;
}

.acc-bg-white .accord-head .indicator {
	color: #fff;
	background-color: black;
}

.acc-bg-white.acc-has-bg .acod-body {
	color: #fff;
}


/* 4. Accordion bg primary */

.acc-bg-primary a {
	background-color: #000;
}

.acc-bg-primary .accord-head a,
.acc-bg-primary .accord-head a:hover,
.acc-bg-primary .accord-head a:active,
.acc-bg-primary .accord-head a:focus {
	color: #fff;
}

.acc-bg-primary .accord-head .indicator {
	color: #fff;
}

.acc-bg-primary.acc-has-bg .acod-body {
	color: #fff;
}


/* 5. Accordion bg dark */

.acc-bg-dark a {
	background-color: #000;
}

.acc-bg-dark .accord-head a,
.acc-bg-dark .accord-head a:hover,
.acc-bg-dark .accord-head a:active,
.acc-bg-dark .accord-head a:focus {
	color: #fff;
}

.acc-bg-dark .accord-head .indicator {
	background-color: black;
}

.acc-bg-dark .accord-head .fa {
	color: #fff;
}

.acc-bg-dark.acc-has-bg .acod-body {
	color: #fff;
}


/*-------------------------------------
	6. Carousels
-------------------------------------*/


/* 6.1 Client carousel 1*/

.ow-client-logo {
	display: table;
	width: 100%;
}

.remove-line.ow-client-logo:after {
	visibility: hidden;
}

.client-logo {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.ow-client-logo {
	margin-left: 10px;
	margin-right: 10px;
}

.owl-carousel .ow-client-logo img {
	max-height: 100%;
	width: auto;
	display: inline-block;
}

.client-logo-media img {
	max-width: 100% !important;
	margin: 0px auto !important;
	width: auto !important;
}

@media only screen and (max-width:720px) {
	.ow-client-logo {
		margin: 0px 1px;
	}
}


/*-------------------------------------
	7. Testimonials
-------------------------------------*/



.testimonial-pic {
	background: #FFF;
	width: 65px;
	height: 65px;
	position: relative;
	display: inline-block;
}

.testimonial-pic.radius {
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
}

.testimonial-pic.radius img {
	width: 100%;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	padding: 5px;
}

.quote-left,
.quote-right {
	position: relative;
}

.quote-left:before,
.quote-right:after {
	font-family: "FontAwesome";
	position: absolute;
	z-index: 10;
	top: 15px;
}

.quote-left:before {
	content: "\f10d";
	left: 0;
}

.quote-right:after {
	content: "\f10e";
	left: auto;
	right: 0;
}

/*testimonia home 1*/
.testimonial-1-outer {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.testimonial-1-outer:after {
	position: absolute;
	content: '';
	width: 100%;
	background-color: #e6e6e6;
	z-index: -1;
	left: 0px;
	top: 0px;
	bottom: 80px;
}

.testimonial-1-content-outer {
	z-index: 1;
	top: 0px;
	position: inherit;
}

.testimonial-1-content-outer:after {
	content: '';
	left: 0px;
	top: 0px;
	bottom: 0px;
	position: absolute;
	z-index: -1;
	background-color: #ff5e15;
	width: 6000px;
	background-image: url(../images/testimonials/bg-left.png);
	background-position: left bottom;
	background-repeat: no-repeat;
}


.testimonial-1 {
	margin: 160px 0px 140px 180px;
	position: relative;
	padding: 100px 35px 30px 40px;
}

.testimonial-1 .testimonial-pic-block {
	position: absolute;
	left: -140px;
	top: -120px;
}

.testimonial-1 .testimonial-pic {
	width: 180px;
	height: 180px;
	border: 3px solid #fff;
}

.testimonial-1 .testimonial-info {
	padding: 0px;
}

.testimonial-1 .testimonial-text {
	padding: 0px;
	position: relative;
	z-index: 1;
}

.testimonial-1 .testimonial-text .fa-quote-left {
	position: absolute;
	font-size: 90px;
	top: -60px;
	z-index: -1;
	color: #ff5e15;
	opacity: 0.2;
}

.testimonial-1 .testimonial-name {
	padding-right: 0px;
	display: block;
	font-size: 30px;
}

.testimonial-1 .testimonial-name::after {
	display: none;
}

.testimonial-1 .testimonial-position {
	display: block;
	font-size: 24px;
	position: relative;
	line-height: 24px;
}

.testimonial-1-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev .owl-stage-outer {
	padding-top: 0px;
}

.testimonial-1-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev .owl-nav {
	position: absolute;
	right: 30px;
	top: 170px;
	width: auto;
}

.testimonial-1-outer .hilite-large-title {
	position: absolute;
	right: -1px;
	bottom: -20px;
	z-index: 1;
}

.testimonial-1-outer .hilite-large-title span {
	font-size: 120px;
	line-height: 100px;
	color: #fff;
	opacity: 1;
	font-family: 'Roboto', sans-serif;
	display: block;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: -2px;
}


@media only screen and (max-width:991px) {
	.testimonial-1-content-outer {
		margin-top: 23px;
	}

	.testimonial-1-content-outer:after {
		left: -150px;
	}

	.testimonial-1 {
		margin: 160px 0px 140px 140px;
	}

}

@media only screen and (max-width:767px) {
	.testimonial-1-outer .hilite-large-title {
		display: none;
	}

	.testimonial-1 {
		margin: 40px 0px 40px 0px;
		padding: 20px;
	}

	.testimonial-1 .testimonial-pic-block {
		position: inherit;
		left: auto;
		top: auto;
	}

	.testimonial-1 .testimonial-text .fa-quote-left {
		position: inherit;
		font-size: 40px;
		top: auto;
	}

	.testimonial-1-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev .owl-nav {
		position: absolute;
		right: 20px;
		top: 50px;
		width: auto;
	}
}

@media only screen and (max-width:380px) {
	.testimonial-1 .testimonial-pic {
		width: 100px;
		height: 100px;
		border: 3px solid #fff;
	}
}

/*testimonia home 2*/
.testimonial-2-outer {
	position: relative;
	z-index: 1
}

.testimonial-2-outer:before {
	position: absolute;
	left: 0px;
	bottom: 0px;
	top: 0px;
	content: '';
	background-color: #e6e6e6;
	height: 100%;
	width: 50%;
}


.testimonial-2-content-outer {
	position: relative;
	z-index: 1;
}

.testimonial-2 {
	margin: 0px 15px 30px 0px;
	position: relative;
	padding: 60px 35px 30px 40px;
}


.testimonial-2 .testimonial-detail {
	padding: 30px 0px 0px 0px;
}

.testimonial-2 .testimonial-pic-block {
	float: left;
	padding-right: 15px;
}

.testimonial-2 .testimonial-pic {
	width: 100px;
	height: 100px;
}

.testimonial-2 .testimonial-info {
	padding: 0px;
}

.testimonial-2 .testimonial-text {
	padding: 0px 0px 0px 20px;
	position: relative;
	z-index: 1;
	border-left: 5px solid #ff5e15;
}

.testimonial-2 .testimonial-text .fa-quote-left {
	position: absolute;
	font-size: 90px;
	top: -30px;
	z-index: -1;
	color: #ff5e15;
	opacity: 0.2;
}

.testimonial-2 .testimonial-name {
	padding-right: 0px;
	display: block;
	font-size: 30px;
}

.testimonial-2 .testimonial-name::after {
	display: none;
}

.testimonial-2 .testimonial-position {
	display: block;
	font-size: 24px;
	position: relative;
	line-height: 24px;
}

.testimonial-2-content.owl-carousel.owl-btn-bottom-right.long-arrow-next-prev .owl-stage-outer {
	padding-top: 0px;
}

.testimonial-2-content.owl-carousel.owl-btn-bottom-right.long-arrow-next-prev .owl-nav {
	position: absolute;
	right: 30px;
	bottom: 30px;
	width: auto;
	margin: 0px;
}


.testimonial-2-outer .hilite-large-title {
	position: absolute;
	left: 0px;
	bottom: -20px;
	z-index: 1;
}

.testimonial-2-outer .hilite-large-title span {
	font-size: 120px;
	line-height: 100px;
	color: #fff;
	opacity: 1;
	font-family: 'Roboto', sans-serif;
	display: block;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: -2px;
}


@media only screen and (max-width:991px) {
	.testimonial-2-outer:before {
		width: 100%;
	}

	.testimonial-2-outer .hilite-large-title {
		display: none
	}

	.testimonial-2 {
		margin: 0px;
		padding: 60px 35px 30px 40px;
	}
}

@media only screen and (max-width:767px) {
	.testimonial-2-content.owl-carousel.owl-btn-bottom-right.long-arrow-next-prev .owl-nav {
		bottom: 0px;
		right: 20px;
	}
}

@media only screen and (max-width:400px) {
	.testimonial-2 {
		margin: 0px 0px 0px 0px;
		position: relative;
		padding: 45px 20px 20px 20px;
	}
}


.testimonial-text {
	padding: 15px;
	position: relative;
}

.testimonial-text p:last-child {
	margin: 0px;
	color: black;
	font-style: italic;
}

.testimonial-detail {
	padding: 15px 0px 0px 0px;
}

.testimonial-name,
.testimonial-position {
	display: inline-block;
	font-size: 14px;
	position: relative;
}

.testimonial-name {
	padding-right: 15px;
}

.testimonial-name:after {
	content: "/";
	position: absolute;
	right: 0px;
	top: 0px;
	font-weight: lighter;
}

.testimonial-text p {
	margin: 0;
}

.testimonial-pic,
.testimonial-info {
	display: inline-block;
}

.testimonial-info {
	padding: 0px 60px 0px 15px;
}



.testimonial-1-thumb {
	max-width: 285px;
	position: relative;
	overflow: hidden;
	left: 50%;
	transform: translateX(50%);
	-webkit-transform: translateX(-50%);
}

.testimonial-1-thumb .wt-media {
	width: 80px;
	height: 80px;
}

.testimonial-1-thumb .wt-media img {
	width: 100%;
	border-radius: 50%;
	-webkit-border-radius: 50%;
}

.testimonial-1-thumb.center {
	margin: 0px auto 0px;
}

.testimonial-1-thumb .owl-item.current .wt-media img {
	border: 2px solid black;
	z-index: 2;

}

@media only screen and (max-width:540px) {
	.testimonial-home {
		padding: 30px;
	}

	.testimonial-1-thumb .wt-media {
		width: 50px;
		height: 50px;
	}

	.testimonial-1-thumb {
		max-width: 175px;
	}
}



/*nav*/

.testimonial-1-thumb.owl-carousel .owl-nav {
	margin-top: 10px;
	height: 23px;
}

.testimonial-1-thumb.owl-carousel .owl-nav .owl-prev,
.testimonial-1-thumb.owl-carousel .owl-nav .owl-next {
	color: #000;
	background: none;
}


/*7.1 testimonial with background image*/

.testimonial-bg {
	color: #fff;
}

@media only screen and (max-width:800px) {
	.testimonial-home .owl-item.active.center .item {
		transform: none;
	}
}

.owl-carousel.testimonial-home-2 .owl-dots .owl-dot.active {
	border: 2px solid #fff;
}



/*-------------------------------------
	8. Pricing table
 -------------------------------------*/

.pricingtable-inner {
	text-align: center;
	border: 1px solid #E9E9E9;
}

.pricing-table-top-section {
	position: relative;
	overflow: hidden;
}

.pricingtable-price {
	padding: 10px;
	margin: -1px;
	position: relative;
}

.pricingtable-sign {
	font-size: 72px;
	color: #0f1221;
	font-weight: 700;
	position: relative;
	top: 0;
}

.pricingtable-bx {
	font-size: 72px;
	color: #0f1221;
	font-weight: 700;
}

.pricingtable-type {
	font-size: 20px;
	color: #0f1221;
	display: block;
	letter-spacing: 0px;
	padding: 20px 10px 10px 10px;
}

.pricingtable-title {
	padding: 0px 20px 0px 20px;
	margin: -1px;
	-webkit-border-radius: 10px 10px 0px 0px;
	border-radius: 10px 10px 0px 0px;
	position: relative;
	z-index: 1;
}

.table-featured-title {
	font-style: italic;
	padding: 4px 50px;
	position: absolute;
	left: -30px;
	top: 30px;
	-ms-transform: rotate(42deg);
	-webkit-transform: rotate(42deg);
	transform: rotate(-42deg);
}

.pricingtable-title * {
	margin: 0px;
}

.pricingtable-features {
	margin: 0;
	padding: 0;
	list-style: none;
	color: #000;
}

.pricingtable-features li {
	padding: 15px;
}

.pricingtable-features li i {
	margin: 0 3px;
}

.pricingtable-footer {
	margin-top: -1px;
	padding: 20px;
}

.pricingtable-highlight-outer {
	position: relative;
	z-index: 10;
}

.pricingtable-highlight {
	position: relative;
	z-index: 99;
	background-color: #ff5e15;
}

.pricingtable-highlight .pricingtable-title h2 {
	color: #fff;
}

.pricingtable-highlight .pricingtable-features {
	color: #fff;
}

.pricingtable-highlight .pricingtable-type {
	color: #fff;
}

.pricingtable-highlight .pricingtable-price {
	padding: 10px 10px;
}

.pricingtable-highlight .pricingtable-price .pricingtable-bx {
	color: #fff;
}

.pricingtable-highlight .pricingtable-price .pricingtable-bx .pricingtable-sign {
	color: #fff;
}

.pricingtable-highlight .pricingtable-footer {
	padding: 20px 20px;
}

.no-col-gap .pricingtable-wrapper {
	margin-left: -1px;
}

.pt-with-icon {
	margin-top: 70px;
}

.pt-pic-outer {
	position: relative;
	top: -70px;
	margin-bottom: -45px;
}

.pt-pic {
	width: 140px;
	margin: 0px auto;
	background-color: #dff6fc;
	border-radius: 50%;
	height: 140px;
	line-height: 140px;
	text-align: center
}

.pt-pic img {
	width: 75px;
}


@media only screen and (max-width:991px) {
	.pricingtable-highlight {
		margin: 0px 0;
		box-shadow: none;
		border: 1px solid #E9E9E9;
	}

	.pricingtable-highlight .pricingtable-price {
		padding: 10px;
	}

	.pricingtable-highlight .pricingtable-footer {
		padding: 20px 20px;
	}

	.pricingtable-row.no-col-gap [class*="col-xs-"],
	.pricingtable-row.no-col-gap [class*="col-sm-"],
	.pricingtable-row.no-col-gap [class*="col-md-"],
	.pricingtable-row.no-col-gap [class*="col-lg-"] {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (max-width:767px) {

	.pricingtable-row.no-col-gap [class*="col-xs-"],
	.pricingtable-row.no-col-gap [class*="col-sm-"],
	.pricingtable-row.no-col-gap [class*="col-md-"],
	.pricingtable-row.no-col-gap [class*="col-lg-"] {
		padding-left: 0px;
		padding-right: 0px;
	}

	.pricingtable-row.m-b30 {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width:576px) {

	.pricingtable-row {
		padding: 0px 15px;

	}
}

@media only screen and (max-width:420px) {
	.pricingtable-bx {
		font-size: 40px;
	}

	.pricingtable-sign {
		font-size: 40px;
	}

	.pricingtable-type {
		font-size: 18px;
	}
}

/*-------------------------------------
	9. Alert box
-------------------------------------*/

.alert.alert-sm {
	padding: 5px 15px;
	font-size: 12px;
}

.alert.alert-lg {
	padding: 25px 15px;
	font-size: 16px;
}

.alert.alert-xl {
	padding: 35px 15px;
	font-size: 18px;
}

.alert[class*="alert-"] i {
	margin-right: 8px;
}

.alert.no-radius {
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.alert.no-bg {
	background-color: transparent;
	border-width: 2px;
}

.alert[class*="alert-"] ul {
	padding-left: 25px;
	margin-top: 10px;
}

.alert[class*="alert-"] ul li:before {
	color: #a94442;
}


/*-------------------------------------
	10. Image effects
-------------------------------------*/

.wt-img-effect {
	position: relative;
	overflow: hidden;
	display: block;
}

.wt-img-effect img {
	display: block;
	margin: 0;
	width: 100%;
	height: auto;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	-webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	-moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	transition: all 0.25s;
	-moz-transition: all 0.25s;
	-webkit-transition: all 0.25s;
	-o-transition: all 0.25s;
}


/* 10.1 image-opacity */

.wt-img-effect.opacity img:hover {
	opacity: 0.8;
	-moz-opacity: 0.8;
	-webkit-opacity: 0.8;
}


/*10.2 image-zoom */

.wt-img-effect.zoom-slow img {
	transition: all 10s;
	-moz-transition: all 10s;
	-webkit-transition: all 10s;
	-o-transition: all 10s;
}

.wt-img-effect.zoom-slow:hover img {
	-moz-transform: scale(2);
	-webkit-transform: scale(2);
	-o-transform: scale(2);
	-ms-transform: scale(2);
	transform: scale(2);
}

.bg-blur-img:hover {
	filter: blur(8px);
	-webkit-filter: blur(8px);
}


/*10.3 image-zoom-slow */

.wt-img-effect.zoom:hover img {
	-moz-transform: scale(1.5);
	-webkit-transform: scale(1.5);
	-o-transform: scale(1.5);
	-ms-transform: scale(1.5);
	transform: scale(1.5);
}


/*10.4 image-shrink */

.wt-img-effect.shrink:hover img {
	transform: scale(0.8);
	-ms-transform: scale(0.8);
	-webkit-transform: scale(0.8);
	-o-transform: scale(0.8);
	-moz-transform: scale(0.8);
}


/*10.5 image-side-pan */

.wt-img-effect.side-pan:hover img {
	margin-left: -9%;
	transform: scale(1.2);
	-ms-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-moz-transform: scale(1.2);
}


/*10.6 image-vertical-pan */

.wt-img-effect.vertical-pan:hover img {
	margin-top: -10%;
	transform: scale(1.2);
	-ms-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-moz-transform: scale(1.2);
}


/*10.7 image-fade-in */

.wt-img-effect.fade-in {
	background: #000
}

.wt-img-effect.fade-in img {
	opacity: 0.65;
	-moz-opacity: 0.65;
	-webkit-opacity: 0.65;
}

.wt-img-effect.fade-in:hover img {
	opacity: 1;
	-moz-opacity: 1;
	-webkit-opacity: 1;
}


/*10.8 image-fade-out */

.wt-img-effect.fade-out {
	background: #000
}

.wt-img-effect.fade-out:hover img {
	opacity: 0.7;
	-moz-opacity: 0.7;
	-webkit-opacity: 0.7;
}


/*10.9 image-rotate */

.wt-img-effect.rotate:hover img {
	-moz-transform: scale(1.5) rotate(-20deg);
	-webkit-transform: scale(1.5) rotate(-20deg);
	-o-transform: scale(1.5) rotate(-20deg);
	-ms-transform: scale(1.5) rotate(-20deg);
	transform: scale(1.5) rotate(-20deg);
}


/*10.10 image-sepia */

.wt-img-effect.sepia img {
	-webkit-filter: sepia(100%);
	filter: sepia(100%);
}

.algo-image-hover.sepia:hover img {
	-webkit-filter: sepia(0);
	filter: sepia(0);
}


/*10.11 image-blurr */

.wt-img-effect.blurr img {
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
}

.wt-img-effect.blurr:hover img {
	filter: blur(3px);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-o-filter: blur(3px);
	-ms-filter: blur(3px);
}


/*10.12 image-blurr-invert */

.wt-img-effect.blurr-invert img {
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
}

.wt-img-effect.blurr-invert img {
	filter: blur(3px);
	-webkit-filter: blur(3px);
	-moz-filter: blur(3px);
	-o-filter: blur(3px);
	-ms-filter: blur(3px);
}

.wt-img-effect.blurr-invert:hover img {
	filter: blur(0px);
	-webkit-filter: blur(0px);
	-moz-filter: blur(0px);
	-o-filter: blur(0px);
	-ms-filter: blur(0px);
}


/*10.13 image off color */

.wt-img-effect.off-color img {
	filter: grayscale(0);
	-webkit-filter: grayscale(0);
	-moz-filter: grayscale(0);
	-o-filter: grayscale(0);
	-ms-filter: grayscale(0);
}

.wt-img-effect.off-color:hover img {
	filter: grayscale(1);
	-webkit-filter: grayscale(1);
	-moz-filter: grayscale(1);
	-o-filter: grayscale(1);
	-ms-filter: grayscale(1);
}


/*10.14 image on color */

.wt-img-effect.on-color img {
	filter: grayscale(1);
	-webkit-filter: grayscale(1);
	-moz-filter: grayscale(1);
	-o-filter: grayscale(1);
	-ms-filter: grayscale(1);
}

.wt-img-effect.on-color:hover img {
	filter: grayscale(0);
	-webkit-filter: grayscale(0);
	-moz-filter: grayscale(0);
	-o-filter: grayscale(0);
	-ms-filter: grayscale(0);
}


/*-------------------------------------
	11. Modal pop
-------------------------------------*/

.modal-xlg {
	width: 1000px;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a {
	font-weight: 600;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a span.childcat {
	font-weight: 400;
	color: #7c7c7c;
}

@media only screen and (max-width: 1024px) {
	.modal-xlg {
		width: auto;
		margin-left: 15px;
		margin-right: 15px;
	}
}


/*-------------------------------------
	12. Social icons
-------------------------------------*/

.social-icons {
	list-style: none;
	margin-left: -3px;
	margin-right: -3px;
	font-size: 12px;
	margin: 0px;
}

.social-icons li {
	display: inline-block;
	text-align: center;
}


.social-icons li a {
	display: inline-block;
	width: 100px;
  	height: 20px;
	padding: 3px;
}

.social-icons.social-md {
	font-size: 18px;
}

.social-icons.social-lg li a {
	font-size: 24px;
}

.social-icons.has-bg li a:hover {
	color: #fff;
}

.social-icons.social-light a {
	color: #fff;
}

.social-icons.social-light.has-bg li a:hover {
	color: #777;
}

.social-icons.social-dark a {
	color: #000000;
}

.social-icons.social-dark a:hover {
	color: #fff;
}

.social-icons.social-dark.has-bg li a:hover {
	color: #777;
}

.social-icons.social-square a,
.social-icons.social-radius a {
	width: 24px;
	height: 24px;
	line-height: 24px;
	padding: 0;
}

.social-icons.social-square.social-md a,
.social-icons.social-radius.social-md a {
	width: 36px;
	height: 36px;
	line-height: 36px;
	padding: 0;
}

.social-icons.social-square.social-lg a,
.social-icons.social-radius.social-lg a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	padding: 0;
}

.social-icons.social-square.social-primary a,
.social-icons.social-radius.social-primary a {
	background-color: #000;
	color: #FFF;
}

.social-icons.social-square.social-primary a:hover,
.social-icons.social-radius.social-primary a:hover {
	background-color: #1a1a1a;
}

.social-icons.social-square.social-dark a,
.social-icons.social-radius.social-dark a {
	background-color: #fff;
}

.social-icons.social-square.social-dark a:hover,
.social-icons.social-radius.social-dark a:hover {
	background-color: #1a1a1a;
	color: #fff;
}

.social-icons.social-square.social-dark.has-bg a:hover,
.social-icons.social-radius.social-dark.has-bg a:hover {
	border: 1px solid #777;
}

.social-icons.social-darkest a {
	color: #000;
}

.social-icons.social-square.social-darkest a:hover,
.social-icons.social-radius.social-darkest a:hover {
	background-color: #000;
	color: #fff;
}

.social-icons.social-radius a {
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	border-radius: 100px;
}

.social-icons.social-square.social-dark.white-border a,
.social-icons.social-radius.social-dark.white-border a {
	background: none;
	color: #fff;
	border: 1px solid rgba(255, 255, 255, 1);
}


/*12.1 social icon default */

.wt-social-icon {
	display: inline-block;
	margin: 0 -3px;
	padding: 0;
}

.wt-social-icon li {
	display: inline-block;
	padding: 0;
	font-size: 12px;
}

.wt-social-icon li a {
	display: inline-block;
	width: 24px;
	height: 24px;
	padding: 4px;
}

.wt-social-icon li .fa {
	vertical-align: middle;
}


/*12.2 social icon with border */

.wt-social-icon.border li {
	padding: 0 3px;
}

.wt-social-icon.border li a {
	border: 1px solid #efeded;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
}


/*12.3 social icon dark */

.wt-social-links.dark li {
	padding: 0 3px;
}

.wt-social-icon.dark li a {
	border: 1px solid #777777;
	color: #777777;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
}


/*-------------------------------------
	13. Breadcrumb
-------------------------------------*/


/*13.1 breadcrumb-row-1*/

.wt-breadcrumb {
	margin: 0;
	list-style: none;
}

.wt-breadcrumb li {
	padding: 0px;
	margin-right: 7px;
	color: #fff;
	position: relative;
	display: inline-block;
	font-size: 22px;
	font-family: 'Roboto', sans-serif;
}

.wt-breadcrumb li a {
	color: #fff;
}

.wt-breadcrumb li a:hover {
	color: #ff5e15;
}

.wt-breadcrumb li:last-child {
	color: #ff5e15;
}

.wt-breadcrumb li:first-child a i {
	font-size: 18px;
	vertical-align: text-top;
}

.wt-breadcrumb li:last-child:after {
	display: none;
}


/*13.2 Breadcrumb-1*/

.breadcrumb-style-2 li:after {
	content: "\f101";
	margin-left: 7px;
	font-family: 'FontAwesome';
	color: #fff;
	font-size: 16px;
	font-weight: normal;
}


/*-------------------------------------
	14. Google map
-------------------------------------*/

.google-map {
	width: 100%;
	height: 460px;
}


/*-------------------------------------
	17. Form elements
-------------------------------------*/

label {
	font-weight: 700;
	margin-bottom: 10px;
}

.panel-default,
.panel-default>.panel-heading {
	border-color: #e7ecf1;
	color: inherit;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border-top-color: #e7ecf1;
}

.form-group {
	margin-bottom: 25px;
}

.form-control {
	border-color: #e1e6eb;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	height: 40px;
	font-size: 13px;
	line-height: 20px;
	padding: 9px 12px 9px 10px;
}

.form-control:focus {
	border-color: #e1e1e1;
	outline: 0;
}

.form-control:focus,
.has-error .form-control:focus {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control.kv-fileinput-caption {
	height: 39px;
}

.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
	margin-right: 15px;
}


/* 17.1 input group */

.input-group {
	width: 100%;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
	display: table-cell;
}

.input-group-addon {
	background: #fff;
	border-color: #e1e1e1;
	padding: 6px 14px;
	font-size: 16px;
}

.input-group-addon.font-size-20 {
	font-size: 20px;
}

.input-group-addon.fixed-w {
	text-align: center;
	padding: 6px 0;
	width: 40px;
}

.input-group-addon.v-align-t {
	line-height: 30px;
}


/* 17.3 radio & checkbox */

input[type=checkbox],
input[type=radio] {
	opacity: 0;
	margin-left: 1px !important;
}

input[type=checkbox]+label,
input[type=radio]+label {
	display: block;
	padding-left: 30px !important;
	position: relative;
}

input[type=checkbox]+label:hover:before,
input[type=radio]+label:hover:before {
	border-color: #3396d1;
}

input[type=checkbox]+label:before,
input[type=radio]+label:before {
	background-color: #fff;
	border: 2px solid #b6b7b8;
	border-radius: 0px;
	content: "";
	display: inline-block;
	height: 16px;
	line-height: 1;
	margin-left: -30px;
	position: absolute;
	top: 2px;
	transition: all linear 0.1s;
	width: 16px;
}

input[type=checkbox]+label:after {
	color: #fff;
	content: "\f00c";
	font-family: FontAwesome;
	font-size: 0px;
	-webkit-font-smoothing: antialiased;
	left: 6px;
	position: absolute;
	top: 7px;
	transition: all linear 0.1s;
}

input[type=checkbox]:checked+label:before {
	border-width: 7px;
	border-color: #3396d1;
}

input[type=checkbox]:checked+label:after {
	font-size: 10px;
	left: 2px;
	top: 2px;
}

input[type=radio]+label:before {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	content: "";
}

input[type=radio]:checked+label:before {
	border-color: #3396d1;
	border-width: 5px;
}

.form-inline .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"] {
	position: absolute;
	left: 0;
	bottom: 0;
}


/*17.4 Browse Button css */

.btn-file {
	overflow: hidden;
	position: relative;
}

.btn-file input[type="file"] {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	cursor: inherit;
	display: block;
	min-height: 100%;
	min-width: 100%;
	opacity: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
}


/*17.8 validation symbol */

.has-feedback label~.form-control-feedback {
	top: 31px;
}


/*17.9 error for select box */

.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:hover,
.has-error .bootstrap-select .dropdown-toggle:focus {
	border-color: #a94442 !important;
}


/*17.10 succes for select box */

.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:hover,
.has-success .bootstrap-select .dropdown-toggle:focus {
	border-color: #3c763d !important;
}


/*17.11 textarea */


/*17.11(1) resize-vertical*/

.resize-vertical {
	resize: vertical;
}


/*17.11(2) resize-horizontal*/

.resize-horizontal {
	resize: horizontal
}


/*17.11(3) resize-none*/

.resize-none {
	resize: none
}


/*-------------------------------------
	18. Loading
-------------------------------------*/


/*18.1 loading - 1*/

.loading-area {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	overflow: hidden;
}

.loading-box {
	width: 100%;
	height: 100%;
	background-color: #FFF;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 1;
	z-index: 9999;
}

.loading-pic {
	width: 100%;
	position: absolute;
	top: 50%;
	z-index: 99999;
	text-align: center;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}


/*18.2 loading - 2*/

.loading-cover,
.loading-srh-bar,
.loading-map {
	background: rgba(255, 255, 255, 0.8);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.loading-cover .fa-spinner,
.loading-srh-bar .fa-spinner,
.loading-map .fa-spinner {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -10px;
	font-size: 25px;
	color: #333;
}


/*-------------------------------------
	19. Widgets
-------------------------------------*/

.widget {
	margin-bottom: 40px;
}

.widget-title {
	margin: 0 0 25px;
}

.widget-title {
	margin-bottom: 20px;
}

.recent-posts-entry ul {
	margin: 0;
	list-style: none;
	padding: 0;
}


/* 19.1 widget listing*/

.site-footer .widget_services ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

.site-footer .widget_services ul li {
	margin-bottom: 13px;
	position: relative;
	padding: 10px 10px 10px 0px;
	margin-bottom: 0;
	display: inline-block;
}

.site-footer .widget_services ul li {
	font-size: 14px;
	font-weight: normal;
	text-transform: none;
}

.site-footer .widget_services ul li {
	display: table;
	width: 100%;
	padding: 2px 0px;
}

.site-footer .widget_services ul li a {
	display: table-cell;
	width: 50%;
	font-weight: 400;
}

.site-footer .widget_services ul li a {
	color: #000;
}

.site-footer .widget_services ul li a:hover {
	color: #ff5e15;
}

.site-footer.footer-light .widget_services ul li a:hover {
	color:
		black;
}

.site-footer .widget_services li {
	border-bottom: none;
	line-height: 28px;
}

.site-footer .widget_services li:before {
	top: 0;
	left: 1px;
}

.site-footer .widget_services.inline-links li {
	display: block;
	border-bottom: none;
	position: relative;
	border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
}

.site-footer .widget_services.inline-links li:last-child {
	border-bottom: 0px;
}

@media only screen and (max-width:375px) {

	.site-footer .widget_services ul li {
		display: block;
	}

	.site-footer .widget_services ul li a {
		display: block;
		width: 100%;
	}

}


/* 19.1 widget listing*/

.widget_services ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

.widget_services ul li {
	border-bottom: 1px solid #e1e1e1;
	position: relative;
	padding: 10px 0px 10px 0px;
	line-height: 20px;
}

.widget_services ul li {
	font-size: 14px;
}

.widget_services ul li:last-child {
	font-size: 14px;
	margin-bottom: 0px;
	padding-bottom: 0px;
	border-bottom: 0px;
}

.widget_services ul li a {
	color: #000;
}

.widget_services ul li a:hover {
	color: #ff5e15;
}

.widget_services ul li .badge {
	float: right;
	background: none;
	color: #111;
	font-weight: normal;
	font-size: 14px;
	font-weight: 600;
}

.widget_services li li {
	border-bottom: none;
	padding-left: 25px;
	padding-right: 5px;
	padding-top: 0;
	padding-bottom: 0;
	line-height: 28px;
}

.widget_services li li:before {
	top: 0;
	left: 1px;
}


/*----Widget----*/
.widget_archives ul {
	list-style: none;
	margin-bottom: 0px;
}

.widget_archives ul li {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #e1e1e1;
	position: relative;
	padding: 10px 0px 10px 0px;
	margin-bottom: 0;
	font-size: 14px;
	line-height: 20px;
}

.widget_archives ul li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: 0px;
}


.widget_archives ul li a {
	color: #000;
}

.widget_archives ul li a:hover {
	color: #ff5e15;
}

.widget_archives ul li .badge {
	float: right;
	background: none;
	color: #111;
	font-weight: normal;
	font-size: 14px;
	font-weight: 600;
}




/*-----All Services------*/
.all_services {
	background-color: black;
}

.all_services ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

.all_services ul li {
	border-bottom: 1px solid #010c1d;
	position: relative;
}

.all_services ul li {
	font-size: 16px;
}

.all_services ul li:last-child {
	border-bottom: 0px;
}

.all_services ul li a {
	position: relative;
	padding: 20px 10px 20px 30px;
	margin-bottom: 0;
	line-height: 20px;
	display: block;
	color: #fff;
	font-family: 'Roboto', sans-serif;
	font-size: 24px;
	letter-spacing: 1px;
}



.all_services ul li a:hover {
	background-color: #ff5e15;
}

.all_services ul li a.active {
	background-color: #ff5e15;
}

.service-side-btn a.btn-block {
	background-color: #fff;
}

.service-side-btn a.btn-block:hover {
	background-color: #ff5e15;
}

@media only screen and (max-width:767px) {
	.need-help {
		margin-bottom: 0px;
	}
}


/*19.2 widget search custom*/

.search-bx button {
	border-left-color: #FFF;
	padding: 9px 20px;
	color: #fff !important;
	background-color: #ff5e15;

	border: 0px;
	height: 56px;
	border-radius: 0px;
}

.search-bx .input-group .form-control {
	border-right: 1px solid transparent;
	height: 56px;
	background-color: #f8f8f8;
}


/*19.3 widget search wp-default*/

.widget_search .screen-reader-text {
	display: block;
}

.searchform {
	position: relative;
}

.searchform input[type="text"] {
	width: 100%;
	height: 40px;
	padding: 10px 90px 10px 15px;
	border: 1px solid #CCCCCC;
}

.searchform input[type="submit"] {
	height: 40px;
	padding: 10px 15px;
	background-color: #0f1221;
	position: absolute;
	right: 0;
	bottom: 0;
	color: #ffffff;
	border-left: none;
	border-right: none;
	border-top: none;
	border-bottom: none;
}

.searchform input[type="submit"]:hover,
.searchform input[type="submit"]:focus,
.searchform input[type="submit"]:active {
	background-color: #6ab33e;
	color: #ffffff !important;
	border-bottom-color: #5a9e2f;
}


/*19.4 widget recent-posts*/

.recent-posts-entry .post-date,
.tweets-feed-entry .tweet-date {
	color: #3396d1;
	font-style: normal;
}

.widget .post-title {
	font-size: 24px;
	line-height: 24px;
	margin-bottom: 0px;
	margin-top: 0px;
}

.widget .wt-post-meta {
	margin-bottom: 10px;
}

.widget .wt-post-meta li {
	font-size: 14px;
	color: #ff5e15;
}

.recent-posts-entry .widget-post:last-child {
	border: none;
	margin-bottom: 0px;
}

.recent-posts-entry .wt-post-date {
	background-color: #0e0e0e;
	width: 50px;
	height: 60px;
	float: left;
}

.recent-posts-entry .wt-post-media {
	width: 75px;
	float: left;
}

.recent-posts-entry .wt-post-date strong {
	display: block;
	font-size: 24px
}

.recent-posts-entry .wt-post-date span {
	display: block;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
}

.recent-posts-entry .widget-post {
	margin-bottom: 10px;
}

.recent-posts-entry .wt-post-info {
	margin-left: 85px;
}

.recent-posts-entry .wt-post-info .wt-post-meta ul li {
	font-weight: 600;
	font-size: 12px;
}

.recent-posts-entry .wt-post-info .post-title {
	margin-top: 0px;
}


/*19.8 widget tags-list*/

.widget_tag_cloud a {
	padding: 8px 14px;
	margin-right: 4px;
	margin-bottom: 4px;
	font-size: 14px;
	display: inline-block;
	color: #111111;
	position: relative;
	border: 1px solid #e8e8e8;
	position: relative;
	z-index: 1;
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;

}

.widget_tag_cloud a:before {
	position: absolute;
	content: '';
	right: 100%;
	top: 0px;
	background-color: #e8e8e8;
	width: 0%;
	height: 100%;
	z-index: -1;

	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.widget_tag_cloud a:hover:before {
	width: 100%;
	right: 0%;
}



.widget_tag_cloud a:hover {
	color: #ff5e15;
}


/* 19.14 widget gallery */

.widget_gallery ul {
	padding-left: 0;
}

.widget_gallery .wt-post-thum {
	margin-right: 2px;
	margin-bottom: 2px;
}

.widget_gallery li a {
	position: relative;
}

.widget_gallery li a:hover {
	cursor: crosshair;
}

.widget_gallery li:hover img {
	margin-bottom: 0;
	opacity: 0.3;
}

.widget_gallery li:hover a:after {
	opacity: 1;
}

.widget_gallery a {
	display: block;
}

@media only screen and (max-width:991px) {
	.widget_gallery li {
		width: 24.333%;
	}
}

@media only screen and (max-width:480px) {
	.widget_gallery li {
		width: 32.1%;
	}
}

.widget_newsletter-2 .newsletter-bx {
	background-color: #f6f7f8;
	text-align: center;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon {
	background-color: #000;
	display: block;
	width: 70px;
	height: 70px;
	margin: 0px auto;
	margin-bottom: 15px;
	border-radius: 50%;
	text-align: center;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon i {
	font-size: 32px;
	color: #fff;
	line-height: 70px;
}


/* 19.18 widget client slider */

.widget-client {
	background-color: #f6f7f8;
}


/*-------widget service-------*/

.widget_address li {
	margin-bottom: 20px;
	color: #999999;
	position: relative;
	padding-left: 30px;
}

.widget_address li i {
	position: absolute;
	left: 0px;
	top: 5px;
	font-size: 16px;
}

.widget_address_outer .social-icons li {
	padding-right: 10px;
}


/*-------widget newsletter-------*/

.widget_newsletter .newsletter-bx .form-control {
	background-color: #fff;
	border: 0px;
	border-right: 1px solid rgba(255, 255, 255, 0.3);
	color: #fff;
}


/*-------------------------------------
	20. Table
-------------------------------------*/


/* 20.1 Full-responsive */

.wt-responsive-table td,
.wt-responsive-table th {
	text-align: center;
}

.wt-responsive-table td a,
.wt-responsive-table th a {
	cursor: pointer;
}

@media only screen and (max-width: 800px) {

	#no-more-tables table,
	#no-more-tables thead,
	#no-more-tables tbody,
	#no-more-tables th,
	#no-more-tables td,
	#no-more-tables tr {
		display: block;
	}

	#no-more-tables thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
		display: none;
	}

	#no-more-tables tr {
		border: 1px solid #ccc;
	}

	#no-more-tables td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50% !important;
		white-space: normal;
		text-align: left;
	}

	#no-more-tables td:before {
		position: absolute;
		top: 18px;
		left: 18px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		text-align: left;
		font-weight: bold;
	}

	#no-more-tables td:before {
		content: attr(data-title);
	}
}


.cart-total-table table {
	margin-bottom: 0px;
}

/*-------------------------------------
	21. Video
-------------------------------------*/

.yt-thum-box img {
	margin: -9.5% 0%;
}


/*======================================
12. WP DEFAULT
======================================*/

.alignnone {
	margin: 0;
}

.aligncenter,
div.aligncenter {
	display: block;
	margin: 5px auto 15px;
}

.alignright {
	float: right;
	margin: 5px 0 25px 25px;
}

.alignleft {
	float: left;
	margin: 5px 25px 25px 0;
}

a img.alignright {
	float: right;
	margin: 5px 0 25px 25px;
}

a img.alignnone {
	margin: 5px 0 25px 0;
}

a img.alignleft {
	float: left;
	margin: 5px 25px 25px 0;
}

a img.aligncenter {
	display: block;
	margin: 5px auto 15px;
}

.wp-caption {
	max-width: 100%;
	text-align: center;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin: 0;
}

.wp-caption.alignnone {
	margin: 5px 0 25px 0;
}

.wp-caption.alignleft {
	margin: 5px 25px 25px 0;
}

.wp-caption.alignright {
	margin: 5px 0 25px 25px;
}

.wp-caption img {
	border: 0 none;
	height: auto;
	margin: 0;
	max-width: 100%;
	padding: 0;
	width: auto;
}

.wp-caption p.wp-caption-text {
	color: #9d9d9d;
	font-size: 13px;
	line-height: 18px;
	margin: 0;
	padding: 10px 0;
	text-align: left;
}

blockquote {
	color: #000;
	clear: both;
	border: none;
	position: relative;
	text-align: left;
}

blockquote .fa-quote-left {
	position: absolute;
	font-size: 50px;
	left: 20px;
	top: 30px;
	color: #ff5e15;
	line-height: 50px;
}

blockquote p {
	font-size: 30px;
	line-height: 36px;
	padding-left: 50px;
	font-family: 'Roboto', sans-serif;
}

blockquote strong {
	font-size: 18px;
	padding-right: 10px;
}

blockquote span {
	font-size: 14px;
	color: #000;
	line-height: 30px;
	margin-bottom: 0px;
	font-style: normal;

	display: inline-block;
}


@media only screen and (max-width:640px) {
	blockquote {
		padding: 80px 20px 20px 20px;
	}

	blockquote p {
		font-size: 28px;
		line-height: 28px;
		padding: 0px;
	}

	blockquote .fa-quote-left {
		position: inherit;
		left: auto;
		top: auto;
		padding-bottom: 20px;
	}
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
	max-width: 100%;
	height: auto;
}


/*======================================
13. BLOG
======================================*/

.blog-post {
	position: relative;
	margin-bottom: 30px;
}

.wt-post-title {
	margin-bottom: 15px;
}

.wt-post-title .post-title {
	margin: 0;
}

.wt-post-meta {
	margin-bottom: 20px;
}

.wt-post-meta ul {
	margin: 0px;
	list-style: none;
}

.wt-post-meta li {
	padding: 0;
	display: inline-block;
	font-size: 14px;
}

.wt-post-meta li.post-user span {
	font-weight: 600;
}

.wt-post-meta li.post-comment span {
	font-weight: 600;
}

.wt-post-meta i {
	margin-right: 5px;
}

.wt-post-meta li:after {
	content: "|";
	display: inline-block;
	font-weight: normal;
	margin-left: 5px;
	font-size: 14px;
	margin-right: 5px;
	color: #000;
}

.wt-post-meta li:last-child:after {
	display: none;
}

.wt-post-text p a {
	text-transform: uppercase;
	font-weight: 600;
	display: inline-block;
}

.wt-post-text p:last-child {
	margin: 0;
}

.wt-post-readmore {
	margin-bottom: 0px;
}

.wt-post-readmore a {
	font-size: 16px;
	position: relative;
}

.wt-post-readmore a:hover {
	color: #000;
}

.blog-post .wt-post-info {
	z-index: 1;
}

.blog-post .wt-post-info .post-author {
	padding-top: 15px;
	font-size: 13px;
	position: relative;
}

.blog-post .wt-post-info .post-author .post-author-pic {
	left: 30px;
	top: 0px;
	width: 40px;
	height: 40px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-right: 20px;
	border: 2px solid #0f1221;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

.blog-post:hover .wt-post-info .post-author .post-author-pic {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
	border: 4px solid #0f1221;
}


.blog-post-outer-3 .container {
	max-width: 1430px;
}

@media only screen and (max-width:767px) {
	.blog-post-outer-3.p-b40 {
		padding-bottom: 0px;
	}
}

.blog-post .blog-category {
	position: absolute;
	left: -20px;
	top: 30px;
	z-index: 1;
}

.blog-post .blog-category:after {
	position: absolute;
	content: '';
	right: -8px;
	width: 20px;
	height: 46px;
	border: 2px solid #fff;
	z-index: -1;
	top: -10px;
}

.blog-post .blog-category span {
	display: inline-block;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	padding: 2px 10px 1px 10px;
	position: relative;
}


/*blog-grid*/

.blog-grid-1 {
	-webkit-border-radius: 5px;
	border-radius: 5px;
	overflow: inherit;
	margin-bottom: 30px;
	margin-left: 20px;
	height: 400px;
}

.blog-grid-1 .wt-post-info {
	position: absolute;
	z-index: 1;
	bottom: 0px;
}

.blog-grid-1 .wt-post-info .post-author {
	font-size: 13px;
	position: relative;
	padding: 0px;
}

.blog-grid-1 .wt-post-meta {
	margin-bottom: 0px;
}

.blog-grid-1 .post-date {
	text-transform: uppercase;
	font-weight: 700;
}

.blog-grid-1 .wt-post-meta li::after {
	display: none;
}


.blog-grid-1 .wt-post-info .post-author .post-author-pic {
	left: 30px;
	top: 0px;
	width: 40px;
	height: 40px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-right: 20px;
	border: 2px solid #0f1221;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

.blog-grid-1:hover .wt-post-info .post-author .post-author-pic {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
	border: 4px solid #0f1221;
}

.blog-grid-1 .overlay-main {
	border-radius: 0px 0px 5px 5px;
	-webkit-border-radius: 0px 0px 5px 5px;
}


@media only screen and (max-width:991px) {

	.blog-grid-1 {
		margin-bottom: 0px;
	}

}

/*Latest Blog 1*/
.latest-blog-1 .blog-post .wt-post-info {
	position: relative;
	top: -20px;
	margin: 0px 20px;
}

.latest-blog-1 .date-style-2 .post-date {
	color: inherit;
	position: inherit;
	left: inherit;
	top: inherit;
	text-align: left;
	background-color: inherit;
	padding: inherit;
	text-transform: inherit;
}

.latest-blog-1 .date-style-2 .post-date span {
	font-size: inherit;
	font-weight: 600;
	color: black;
}



.blog-post blockquote {
	color: #fff;
	clear: both;
	border: none;
	position: relative;
	text-align: center;
	padding: 100px 30px 30px 30px;
	background-color: #ff5e15;
}

.blog-post blockquote .fa-quote-left {
	position: absolute;
	font-size: 50px;
	left: 50%;
	top: 30px;
	color: #fff;
	line-height: 50px;

	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
}

.blog-post blockquote p {
	font-size: 30px;
	line-height: 36px;
	padding-left: 0px;
	font-family: 'Roboto', sans-serif;
}

.blog-post blockquote strong {
	font-size: 18px;
}

.blog-post blockquote span {
	font-size: 16px;
	color: #fff;
	line-height: 30px;
	margin-bottom: 0px;
	font-style: normal;
	padding-left: 0px;
	display: inline-block;
	font-weight: 600;
}

/*==============================*/


.blog-list-1 {
	-webkit-border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;
}

.blog-list-1 .wt-post-info {
	position: relative;
	z-index: 1;
}

.blog-list-1 .wt-post-info .post-author {
	border-top: 1px solid #eee;
	padding-top: 15px;
	font-size: 13px;
	position: relative;
}

.blog-list-1 .wt-post-meta {
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 20px;
}

.blog-list-1 .wt-post-meta li {
	color: #000;
}

.blog-list-1 .wt-post-meta li a {
	color: #000;
}


.blog-list-1 .wt-post-info .post-author .post-author-pic {
	left: 30px;
	top: 0px;
	width: 40px;
	height: 40px;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-right: 20px;
	border: 2px solid transparent;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

.blog-list-1:hover .wt-post-info .post-author .post-author-pic {
	-moz-transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-o-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
	border: 4px solid #0f1221;
}

.blog-post-outer .blog-post:last-child {
	margin-bottom: 0px;
}


@media only screen and (max-width:420px) {
	.masonry-item .wt-post-title h2.post-title {
		line-height: 14px;
	}

	.masonry-item .wt-post-title h2.post-title a {
		font-size: 14px;
		line-height: none;
	}
}

.blog-share-btn {
	position: absolute;
	bottom: 6px;
	right: 0px;
	border-radius: 50px;
	height: 30px;
	width: 25px;
	text-align: center;
	line-height: 30px;
	padding: 0px;
	border: none;
	background: none;
}

.dropdown-menu.blog-social-icon {
	position: absolute;
	right: 0px;
	bottom: 30px;
	background: no-repeat;
	min-width: 0px;
	float: none;
	left: auto;
	top: auto;
	border: none;
	box-shadow: inherit;
	text-align: center;
	z-index: 1;
}

.blog-social-icon ul {
	margin-bottom: 0px;
}

.blog-social-icon li {
	display: block;
}

.blog-social-icon li a {
	padding: 6px;
	font-size: 14px;
	position: relative;
}

.blog-social-icon li:first-child a {
	-webkit-border-radius: 10px 10px 0px 0px;
	border-radius: 10px 10px 0px 0px;
	padding-top: 10px
}

.blog-social-icon li:last-child a {
	-webkit-border-radius: 0px 0px 10px 10px;
	border-radius: 0px 0px 10px 10px;
	padding-bottom: 10px
}

.block-shadow {
	-webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, .16);
	box-shadow: 0 10px 30px 0 rgba(50, 50, 50, .16);
}

.blog-social-icon li a .social-tooltip {
	padding: 4px 15px;
	position: absolute;
	right: 130%;
	top: 0px;
	background-color: #0f1221;
	color: #fff;
	display: block;
	width: auto;
	text-align: right;
	opacity: 0;

	font-size: 12px;
	border-radius: 3px;
}

.blog-social-icon li a:hover .social-tooltip {
	opacity: 1;
}

.social-tooltip:after,
.social-tooltip:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
}

.social-tooltip:after {
	border-left-color: #0f1221;
	border-width: 6px;
	margin-top: -6px;
}

.social-tooltip:before {
	border-left-color: #0f1221;
	border-width: 6px;
	margin-top: -6px;
}


/*-------------------------------------
	10. side bar
-------------------------------------*/

.side-bar .widget {
	margin-bottom: 20px;
	border: 1px solid #e7e7e7;
}

.widget .widget-post img {
	width: 100%;
}

@media only screen and (max-width: 991px) {
	.side-bar .widget:last-child {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width: 767px) {
	.side-bar {
		margin-bottom: 0px;
		margin-top: 0px;
	}
}

@media only screen and (max-width: 420px) {

	.side-bar {
		margin-bottom: 0px;
		padding: 0px;
		box-shadow: none !important;
	}
}

/*-------------------------------------
	12. comment list
-------------------------------------*/

.comments-area {
	padding: 0;
}

.comments-area .comments-title {
	text-transform: uppercase;
	font-size: 30px;
}

ol.comment-list {
	list-style: none;
}

ol.comment-list li.comment {
	position: relative;
	padding: 0;
}

ol.comment-list li.comment .comment-body {
	position: relative;
	padding: 30px 0px 30px 110px;
	position: relative;
	margin-bottom: 30px;
	border-bottom: 1px solid #d9dcde;
}

ol.comment-list li.comment .comment-author {
	display: block;
	margin-bottom: 10px;
}

ol.comment-list li.comment .comment-author .avatar {
	position: absolute;
	top: 30px;
	left: 0px;
	width: 90px;
	height: 90px;
	border: 4px solid transparent;

	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

ol.comment-list li.comment .comment-author .fn {
	display: inline-block;
	color: #000;
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
}

ol.comment-list li.comment .comment-author .says {
	display: none;
	color: #999999;
	font-weight: 600;
}

ol.comment-list li.comment .comment-meta {
	margin-bottom: 5px;
	font-size: 12px;
}

ol.comment-list li.comment .comment-meta a {
	color: #000;
}

ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
	font-family: "FontAwesome";
	font-size: 16px;
	vertical-align: top;
}

ol.comment-list li.comment p {
	margin: 0 0 5px;
	font-weight: 400;
}

ol.comment-list li.comment .reply {
	text-align: left;
}

ol.comment-list li.comment .reply a {
	font-size: 14px;
	position: relative;
}

ol.comment-list li .children {
	list-style: none;
	margin-left: 40px;
}

ol.comment-list li .children li {
	padding: 0;
}

ol.comment-list li.comment .comment-body:hover .comment-author .avatar {
	border: 4px solid #fff;
}

@media only screen and (max-width:991px) {
	#comment-list {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width: 767px) {
	#comment-list.p-a30 {
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 15px;
		margin-bottom: 0px;
	}

	.comments-area .padding-30 {
		padding: 15px;
	}

	ol.comment-list li.comment .comment-body {
		margin-bottom: 30px;
		margin-left: 30px;
		padding-left: 60px;
	}

	ol.comment-list li.comment .comment-author .avatar {
		left: -35px;
		height: 70px;
		width: 70px;
	}

	ol.comment-list li .children {
		margin-left: 20px;
	}
}

@media only screen and (max-width: 480px) {
	ol.comment-list li.comment .comment-body {
		margin-left: 10px;
	}

	ol.comment-list li.comment .comment-author .avatar {
		left: -15px;
		top: 12px;
		width: 60px;
		height: 60px;
	}

	ol.comment-list li.comment .comment-body {
		padding-left: 55px;
	}
}

@media only screen and (max-width: 380px) {
	ol.comment-list li.comment .comment-body {
		padding: 20px;
		margin-bottom: 20px;
		margin-left: 0px;
	}

	ol.comment-list li.comment .comment-author .avatar {
		position: inherit;
		display: block;
		margin-bottom: 20px;
	}

	.comments-title {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.comment-reply-link {
	position: relative;
	color: black;
	font-weight: 700;
}

.comment-reply-link:before {
	position: absolute;
	content: "\f178";
	font-family: 'FontAwesome';
	left: 50%;
	top: 0px;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	opacity: 0;
}

.comment-reply-link:hover:before {
	left: 110%;
	opacity: 1;
}

.comment-reply-link:hover {
	color: #000;
}


/*-------------------------------------
	13. comment form
-------------------------------------*/

.comment-respond .comment-reply-title {
	text-transform: uppercase;
	font-size: 30px;
}

.comments-area .comment-form {
	margin: 0 -15px;
}

.comments-area .comment-form .comment-notes {
	display: none;
}

.comments-area .comment-form p {
	width: 50%;
	float: left;
	padding: 0 15px;
	margin-bottom: 30px;
	position: relative;
	font-weight: 600;
}

.comments-area .comment-form p.comment-form-url {
	width: 100%;
	display: block;
	clear: both;
}

.comments-area .comment-form p.form-allowed-tags {
	width: 100%;
}

ol.comment-list li.comment .comment-respond .comment-form p {
	padding: 0 15px !important;
}

.comments-area .comment-form p label {
	display: none;
	line-height: 18px;
	margin-bottom: 10px;
}

.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p textarea {
	width: 100%;
	height: 40px;
	padding: 10px;
	border: none;
	background-color: #fff;
}

.comments-area .comment-form p.comment-form-comment {
	width: 100%;
	display: block;
	clear: both;
}

.comments-area .comment-form p textarea {
	height: 120px;
	resize: none;
}

.comments-area .comment-form p.form-submit {
	clear: both;
	float: none;
	width: 100%;
	margin: 0;
}

.comments-area .comment-form p input[type="submit"] {
	background-color: #77c04b;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-width: 0;
	color: #fff;
	display: inline-block;
	font-weight: 600;
	padding: 10px 20px;
	text-transform: uppercase;
}

.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
	background-color: #6ab33e;
	border-color: #6ab33e;
	color: #fff;
}

.comment-respond .form-submit .site-button {
	font-weight: 300;
	padding: 15px 30px;
}

.comment-form .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #000;
	font-size: 12px;
	text-transform: uppercase;
}

.comment-form .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #000;
	font-size: 12px;
	text-transform: uppercase;
}

.comment-form .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #000;
	font-size: 12px;
	text-transform: uppercase;
}

.comment-form .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #000;
	font-size: 12px;
	text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
	.comments-area .comment-form p {
		width: 100%;
		float: none;
		margin-bottom: 20px;
	}
}

@media only screen and (max-width:1280px) {
	.wt-post-info-inner {
		padding: 20px;
	}
}

@media only screen and (max-width:767px) {
	.wt-post-info-inner {
		height: auto;
	}
}


/*-------------------------------------
	15. blog grid-post
-------------------------------------*/

.blog-post .grid-post li {
	float: left;
	width: 33.33%;
	border-right: 4px solid #fff;
	border-bottom: 4px solid #fff;
	list-style: none;
}

.blog-post .grid-post li:first-child {
	width: 100%;
}

.blog-post .grid-post li .portfolio-item {
	width: 100%;
}

.portfolio-item {
	width: 100%;
	position: relative;
	overflow: hidden;
	color: #fff;
	width: 100%;
	border: 0;
	position: relative;
}

.blog-post .grid-post li img {
	width: 100%;
}


/*-------------------------------------
	16. if no image with blog post Latest-blog-3
-------------------------------------*/
.date-style-2 .wt-post-info {
	position: relative;
}

.date-style-2 .wt-post-meta ul li {
	font-family: 'Roboto', sans-serif;
	margin-right: 30px;
}

.date-style-2 .wt-post-meta ul li:last-child {
	margin-right: 0px;
}

.date-style-2 .wt-post-meta ul li.post-category {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
	margin-right: 0px;

	transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);

	transform-origin: left 0px;

	-webkit-transform-origin: left 0px;
	-moz-transform-origin: left 0px;
	-o-transform-origin: left 0px;
	-ms-transform-origin: left 0px;

	border-right: 20px solid #fff;
	border-bottom: 20px solid #fff;
	background-color: #fff;
}


.date-style-2 .wt-post-meta ul li.post-category span {
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	background-color: #ff5e15;
	font-size: 24px;
	display: block;
	padding: 2px 15px;
	letter-spacing: 2px;
}

.date-style-2 .wt-post-meta ul li.post-date,
.date-style-2 .wt-post-meta ul li.post-comment {
	display: inline-block;
	font-size: 24px;
	font-weight: 500;
	color: #ff5e15;
	position: relative;

}

.date-style-2 .wt-post-readmore {
	border-top: 1px solid #ddd;
	padding-top: 10px;
}

.date-style-2 .wt-post-readmore a {
	color: #ff5e15;
}

.date-style-2 .wt-post-meta {
	margin-bottom: 10px;
}

.date-style-2 .wt-post-meta ul li:after {
	content: '';
	position: absolute;
	width: 16px;
	height: 2px;
	background-color: black;
	top: 15px;
	right: -30px;
}

.date-style-2 .wt-post-meta ul li.post-category:after {
	display: none;
}


/*-----Blog NO Image-----*/
.blog-without-image.date-style-2 .wt-post-info {
	position: relative;
	margin-left: 60px;
	padding-left: 30px;
}

.blog-without-image.date-style-2 .wt-post-meta ul li.post-category {
	position: absolute;
	left: 0px;
	bottom: 0px;
	top: auto;
	z-index: 1;
	margin-right: 0px;

	transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);

	transform-origin: left bottom;

	-webkit-transform-origin: left bottom;
	-moz-transform-origin: left bottom;
	-o-transform-origin: left bottom;
	-ms-transform-origin: left bottom;

	border-right: 20px solid #f0f0f0;
	border-bottom: 20px solid #f0f0f0;
	background-color: #fff;
}

/*========date style outline========*/

.date-style-outline {
	padding: 40px 40px 40px 110px;
	margin-bottom: 30px;
}

.date-style-outline .post-date {
	color: #fff;
	position: absolute;
	left: 30px;
	top: 40px;
	text-align: center;
	text-transform: uppercase;
	background-color: #000;
	z-index: 1;
	padding: 10px 15px;
	border: 5px solid #fff;
}

.date-style-outline .post-date strong {
	font-size: 32px;
	line-height: 28px;
	padding: 0px;
	position: relative;
	font-family: 'Roboto Condensed', sans-serif;
}

.date-style-outline .post-date i {
	display: none;
}

.date-style-outline .post-date span {
	font-size: 12px;
	font-weight: 500;
	padding: 0px;
	display: block;
}

.date-style-outline .wt-post-meta a {
	color: #fff;
}

.date-style-outline2 {
	padding: 0px 0px 0px 90px;
	margin-bottom: 60px;
}

.date-style-outline2 .post-date {
	color: #000;
	position: absolute;
	left: 0px;
	top: 0px;
	text-align: center;
	text-transform: uppercase;
	background-color: #f1f1f1;
	z-index: 1;
	padding: 10px 15px;
}

.date-style-outline2 .post-date strong {
	font-size: 32px;
	line-height: 28px;
	padding: 0px;
	position: relative;
	font-family: 'Roboto Condensed', sans-serif;
}

.date-style-outline2 .post-date i {
	display: none;
}

.date-style-outline2 .post-date span {
	font-size: 12px;
	font-weight: 500;
	padding: 0px;
	display: block;
}

.date-style-outline2 .wt-post-meta a {
	color: #000;
}

.date-style-outline2 .readmore-line a:after {
	content: "";
	position: absolute;
	width: 30px;
	height: 1px;
	right: -40px;
	top: 10px;
	background-color: #000;
}

@media only screen and (max-width:360px) {
	.date-style-outline {
		padding: 20px;
		padding-top: 100px;
	}

	.date-style-outline .post-date span {
		display: inline;
	}

	.date-style-outline .post-date {
		left: 20px;
		top: 20px;
	}
}


/*========date style outline End========*/

.recent-posts-entry-date .widget-post:last-child {
	border: none;
}

.recent-posts-entry-date .wt-post-date {
	background-color: #0e0e0e;
	width: 50px;
	height: 60px;
	float: left;
}

.recent-posts-entry-date .wt-post-date strong {
	display: block;
	font-size: 24px;
}

.recent-posts-entry-date .wt-post-date span {
	display: block;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
}

.recent-posts-entry-date .wt-post-info {
	margin-left: 70px;
}

.recent-posts-entry-date .wt-post-info .wt-post-meta ul li {
	font-weight: normal;
	font-size: 12px;
}

.recent-posts-entry-date .wt-post-info .post-title {
	font-weight: normal;
	margin-top: 0px;
	font-size: 14px;
}


/*======================================
14. HEADER (top part of page)
======================================*/

.site-header {
	position: relative;
	z-index: 999;
}



.site-header ul,
.site-header ol {
	margin-bottom: 0;
}



/*-------------------------------------
	1. header full screen
-------------------------------------*/

.nav-wide .container {
	width: 100%;
}

.nav-wide .container {
	padding: 0px 50px;
}

.nav-full-banner .container {
	max-width: 1440px;
	width: 100%;
	padding: 0px !important;
}

@media only screen and (max-width:991px) {
	.nav-wide .container {
		padding: 0px 70px;
	}

	.nav-full-banner .container {
		padding: 0px 15px !important;
	}
}

@media only screen and (max-width:768px) {
	.nav-wide .container {
		padding: 0px 15px;
	}
}


/*-------------------------------------
	2. without top bar
-------------------------------------*/

.extra-nav {
	display: table;
	float: right;
	height: 70px;
	position: relative;
	z-index: 13;
}

.extra-nav .extra-cell {
	display: table-cell;
	padding-left: 10px;
	vertical-align: middle;
}

@media only screen and (max-width:480px) {
	.extra-nav.header-2-nav {
		padding-left: 0px;
	}

	.extra-nav.header-2-nav::after {
		display: none;
	}

	.extra-nav.header-2-nav .extra-cell {
		padding-left: 0px;
	}

	.extra-nav.header-2-nav .site-menu-share {
		font-size: 0px;
	}

	.extra-nav.header-2-nav .site-search-btn {
		font-size: 0px;
		padding: 0px;
	}

}


/*-------------------------------------
	3. map page header
-------------------------------------*/

#header-part.fix-map-header {
	height: 90px;
}

#header-part.fix-map-header .main-bar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
}

@media only screen and (max-width:480px) {
	#header-part.fix-map-header {
		height: 160px;
	}
}


/*======================================
16. LOGO ( company identify  symbol )
======================================*/


/*-------------------------------------
	1. logo for header
-------------------------------------*/

.logo-header {
	display: table;
	float: left;
	width: 190px;
	height: 70px;
	vertical-align: middle;
	padding: 0;
	font-size: 36px;
	color: #1bbce8;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	position: relative;
	z-index: 11;
}

.logo-header-inner {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
}

.logo-header-inner img {
	max-width: 100%;
	max-height: 100%;
}

@media only screen and (max-width:640px) {
	.header-style-2 .logo-header {
		width: 120px;
	}
}

@media only screen and (max-width:350px) {
	.logo-header {
		width: 120px;
	}
}


.logo-footer {
	margin-bottom: 35px;
	margin-left: 0;
	margin-right: 0;
	position: relative;
	z-index: 9;
}

.logo-footer-inner {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
}

.logo-footer-inner img {
	max-width: 100%;
	max-height: 100%;
}

.nav-wide .container {
	padding: 0px 15px;
	max-width: 100%;
}

.site-menu-share {
	background: none;
	border: none;
	outline: none;
	color: #000;
	font-size: 16px;
	display: inline-block;
	font-weight: 600;
	text-align: center;
}



/*-------------------------------------
	2. when header is sticky
-------------------------------------*/


/*.is-fixed .logo-header {	height:70px;	}*/


/*======================================
17. MAIN BAR ( place logo & main-navigation )
======================================*/

.top-bar {
	padding: 10px 0px;
	font-size: 14px;
}

.social-bx li a {
	color: #ffffff;
	font-size: 12px;
}

.wt-topbar-left {
	float: left;
}

.wt-topbar-right {
	float: right;
}

.wt-topbar-center {
	text-align: center;
}

.wt-topbar-info li {
	display: inline-block;
	margin-right: 10px;
	padding: 12px 0px;
}

.wt-topbar-info li span {
	display: inline-block;
	padding-right: 10px;
}

.wt-topbar-info li i {
	margin-right: 10px;
}

.login-controls li {
	display: inline-block;
	margin-right: 10px;
}

.login-controls li:last-child {
	margin-right: 0px;
}

.login-controls li a {
	color: black;
}

.social-bx li {
	margin-right: 15px;
}

.social-bx li:last-child {
	margin-right: 0px;
}

.login-controls {
	margin-left: 30px;
}


@media only screen and (max-width: 991px) {
	.top-bar .d-flex.justify-content-between {
		display: block !important;
	}

	.login-controls {
		margin-left: 10px;
		text-align: left;
		display: inline-block;
	}

	.login-controls li:last-child {
		display: inline-block;
		margin-right: 0px;
	}

	.social-bx {
		text-align: left;
		display: inline-block !important;
	}

	.social-bx li {
		margin-right: 15px;
		display: inline-block;
	}
}

@media only screen and (max-width: 480px) {
	.header-style-2 .wt-topbar-left {
		display: none !important;

	}
}


/*-------------------------------------
	1. eamil-phone (left)
-------------------------------------*/


/*------------- top share icon block ----------------------*/

@media only screen and (max-width: 991px) {
	.header-top-info .wt-topbar-right {
		padding-left: 0px;
		padding-right: 0px;
	}

	.header-top-info .social-bx {
		margin-left: 0px;
	}
}

@media only screen and (max-width:480px) {
	.header-top-info {
		display: none;
	}
}

/*-----------------------------------*/

.main-bar {
	position: relative;
	width: 100%;
	padding: 10px 0px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.main-bar::after,
.main-bar::after {
	clear: both;
	content: "";
	display: table;
}


/*main bar with box view*/

@media only screen and (max-width: 480px) {
	.main-bar {
		padding-left: 0px;
		padding-right: 0px;
	}
}


/*header info css*/

.my-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-info {

	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 2px;
}

.header-item {
	font-size: 18px;
	margin-right: 40px;
}

.header-item:last-child {
	margin-right: unset;
}

.header-item span:first-child {
	margin-right: 8px;
}


.header-botton {
	background-color: #1a1a1a;
}

.header-botton .header-nav .nav {
	float: none;
}

.header-botton .header-nav .nav>li>a {
	padding: 20px 12px;
	color: #fff;
}

.header-botton .extra-nav {
	padding: 13px 0;
}

.header-botton .extra-nav .site-search-btn {
	color: #fff;
	padding: 7px 15px;
}

.header-botton .extra-nav .wt-cart .woo-cart-count {
	border: 2px solid #fff;
	color: #fff;
	min-width: 36px;
	padding: 5px 5px 5px;
}

.header-botton .extra-nav .wt-cart .woo-cart-count::before {
	border-color: #fff;
}

@media only screen and (max-width:991px) {
	.header-botton .navbar-toggler {
		float: left;
		margin: 9px 0;
	}

	.header-botton .navbar-toggler span {
		background-color: #fff;
	}

	.header-botton .header-nav .nav>li>a {
		padding: 12px 12px;
		color: #777;
	}


}

@media only screen and (max-width:640px) {
	.header-middle .logo-header {
		float: none;
		margin: 9px auto;
	}
}


/*======================================
18. NAVIGATIONS
======================================*/


/*-------------------------------------
	1. mega-menu
-------------------------------------*/

.navbar-toggler {
	border: none;
	margin-top: 24px;
	margin-bottom: 24px;
	margin-left: 15px;
	margin-right: -10px;
	padding: 12px 10px;
}

.navbar-toggler .icon-bar {
	background: #111111;
	height: 3px;
	border-radius: 0;
	-webkit-border-radius: 0;
	display: block;
	width: 22px;
}

.navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-2 .navbar-toggler .icon-bar {
	background: #000;
	height: 3px;
	border-radius: 0;
	-webkit-border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-2 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

@media only screen and (max-width:480px) {
	.navbar-toggler {
		margin-left: 10px;
	}
}

.is-fixed .navbar-toggler {
	margin-top: 10px;
	margin-bottom: 14px;
}

.header-nav {
	position: relative;
	padding: 0px;
	z-index: 10;
}

.header-nav .nav {
	display: flex;
	justify-content: flex-end;
	flex-direction: inherit;
}

.header-nav .nav i {
	font-size: 9px;
	margin-left: 3px;
	margin-top: -3px;
	vertical-align: middle;
	opacity: 0.7;
}

.header-nav .nav>li {
	font-weight: 500;
	position: relative;
	margin: -10px 0;
	padding: 10px 0;
	font-family: 'Roboto', sans-serif;
}

.header-nav .nav>li>a {
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	color: #fff;
	font-size: 14px;
	padding: 35px 10px;
	cursor: pointer;
	margin: -10px 0;

}

.header-nav .nav>li>a:hover {
	background-color: transparent;
	color: #212427;
}

.header-nav .nav>li>a:active,
.header-nav .nav>li>a:focus {
	background-color: transparent;
}

.header-nav .nav>li.active>a,
.header-nav .nav>li.current-menu-item>a {
	background-color: transparent;
	color: #0f1221;
}


/* =====hover continue show effect Start=====*/

.header-nav .nav>li:hover>a {
	color: #0f1221;
}


.header-nav.nav-animation .nav>li:hover>ul.sub-menu li:hover>a {
	padding-left: 25px;
}

.header-style-1 .header-nav .nav>li:hover>a {
	color: #fff;
	background-color: #ff5e15;
}

/* =====hover continue show effect End=====*/

.header-nav .nav>li:hover>.sub-menu,
.header-nav .nav>li:hover>.mega-menu {
	opacity: 1;
	visibility: visible;
	margin-top: 0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;

}

.header-nav .nav>li .sub-menu {
	background-color: #ffffff;
	display: block;
	left: 0;
	top: 100%;
	list-style: none;
	margin: 20px 0 0;
	opacity: 0;
	padding: 0px;
	position: absolute;
	visibility: hidden;
	width: 300px;
	z-index: 10;
}


.header-nav .nav>li .sub-menu li {
	border-bottom: 1px solid #f4f4f4;
	position: relative;
	text-transform: none;
}

.header-nav .nav>li .sub-menu li a {
	color: #000;
	display: block;
	font-size: 13px;
	padding: 10px 20px;
}

.header-nav .nav>li .sub-menu li a:hover {
	color: #000;
	text-decoration: none;
}

.header-nav .nav>li .sub-menu li:hover>a {
	color: #ff5e15;
}

.header-nav .nav>li .sub-menu li:last-child {
	border-bottom: 0px;
}

.header-nav .nav>li .sub-menu li .fa {
	color: #363636;
	display: block;
	float: right;
	position: absolute;
	right: 10px;
	top: 2px;
}

.header-nav .nav>li .sub-menu li>.sub-menu {
	left: 220px;
	margin: 0 0 0 20px;
	-webkit-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	-ms-transition: all 0.1s ease;
	-o-transition: all 0.1s ease;
	transition: all 0.1s ease;
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu {
	left: 220px;
	margin: 0px;
	opacity: 1;
	top: -1px;
	visibility: visible;
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
	background-color: transparent;
	bottom: 0px;
	content: '';
	display: block;
	height: 100%;
	left: -6px;
	position: absolute;
	top: 0px;
	width: 6px;
}

.header-nav .nav>li.has-mega-menu {
	position: inherit;
}

.header-nav .nav>li .mega-menu li a>i {
	font-size: 13px;
	margin-right: 5px;
	min-width: 20px;
}

.header-nav .nav>li .mega-menu {
	background-color: #ffffff;
	border: 1px solid #e9e9e9;
	display: table;
	left: 0px;
	list-style: none;
	opacity: 0;
	position: absolute;
	right: 0px;
	visibility: hidden;
	width: 100%;
	margin-top: 20px;
	z-index: 10;
}

.header-nav .nav>li .mega-menu>li {
	display: table-cell;
	padding: 10px 0;
	position: relative;
	vertical-align: top;
	width: 25%;
	text-transform: none;
}

.header-nav .nav>li .mega-menu>li:after {
	content: "";
	background-color: rgba(204, 204, 204, 0.4);
	position: absolute;
	right: 0px;
	top: 0px;
	display: block;
	width: 1px;
	height: 100%;
}

.header-nav .nav>li .mega-menu>li:last-child:after {
	display: none;
}

.header-nav .nav>li .mega-menu>li>a {
	color: #555555;
	display: block;
	font-size: 14px;
	padding: 0 20px;
}

.header-nav .nav>li .mega-menu>li ul {
	list-style: none;
	margin: 10px 0px 0px 0px;
	padding: 0px;
}

.header-nav .nav>li .mega-menu>li ul a {
	color: #474747;
	display: block;
	font-size: 13px;
	line-height: 34px;
	padding: 2px 20px;
}

.header-nav .nav>li .mega-menu>li ul a:hover {
	color: #1BBCE8;
	background-color: #F2F2F2;
}

.header-nav .nav>li.menu-item-has-children:before {
	content: "\f078";
	display: block;
	font-family: "FontAwesome";
	right: 4px;
	position: absolute;
	top: 50%;
	color: #999;
	margin-top: -8px;
	font-size: 8px;
}


/*-- Submenu direction---*/

.header-nav .nav>li.submenu-direction .sub-menu {
	left: auto;
	right: 0;
}

.header-nav .nav>li.submenu-direction .sub-menu li>.sub-menu {
	left: auto;
	right: 220px;
	margin: 0 20px 0 0;
}

.header-nav .nav>li.submenu-direction .sub-menu li:hover>.sub-menu {
	left: auto;
	right: 220px;
	margin: 0 0 0 0;
}

.has-child .submenu-toogle {
	position: absolute;
	right: 4px;
	top: 4px;
	color: #000;
	background-color: transparent;
	z-index: 900;
	cursor: pointer;
	padding: 10px;
	font-size: 14px;
	display: none;
}

.header-nav .has-child li .submenu-toogle {
	opacity: 0.9;
}

.header-nav .has-child.nav-active>a+.submenu-toogle.glyphicon-chevron-right:before {
	content: "\e114";
}

.header-nav .has-child.nav-active>a+.submenu-toogle.glyphicon-plus::before {
	content: "\2212";
}


/*.header-nav .nav > li:last-child{display:none;}*/
@media only screen and (max-width:1200px) {
	.header-style-1 .container {
		padding: 0px 50px;
		max-width: 100%;
	}
}

@media only screen and (max-width:777px) {
	.header-style-1 .container {
		padding: 0px 15px;
	}
}

@media only screen and (max-width: 991px) {
	header .logo {
		display: block;
		padding: 4px 0;
	}

	.header-nav .nav i {
		margin-top: 6px;
	}

	.header-nav .nav li.has-child.nav-active>a {
		color: #000;
	}

	.header-style-1 .header-info>ul li strong {
		font-size: 24px;
		line-height: 32px;
		font-weight: 400;
	}

	.header-style-1 .header-info>ul li .icon-md i {
		font-size: 34px;
	}

	.header-style-1 .header-info>ul li .icon-md {
		margin-right: 0px;
		margin-top: 10px;
	}

}

@media only screen and (max-width:640px) {
	.header-style-1 .header-info {
		display: none;
	}
}


@media only screen and (max-width: 991px) {

	/* responsive strat when media screen [991px] css START*/
	.navbar-header {
		float: none;
	}

	.navbar-toggler {
		display: block;
		margin-top: 4px;
		margin-bottom: 4px;
		float: right;
		outline: none !important;
	}

	/* Responsive strat when medai screen [991px] css END*/
	.header-nav {
		clear: both;
		margin: 0 -15px;
		border-bottom: 1px solid #E9E9E9;
	}

	.header-nav .nav {
		float: none;
		margin: 0;
		background: #fff;
		display: block;
		width: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
	}

	.header-nav .nav li {
		float: none;
	}

	.header-nav .nav>li .sub-menu>li,
	.header-nav .nav>li .mega-menu>li {
		float: none;
		display: block;
		width: auto;
	}

	.header-nav .nav li .sub-menu>li a {
		padding-left: 30px;
	}

	.header-nav .nav>li>a {
		padding: 12px 15px;
		border-top: 1px solid #E9E9E9;
		color: #777;
	}

	.header-nav .nav>li.active>a,
	.header-nav .nav>li.current-menu-item>a {
		color: #000;
	}

	.header-nav .nav i {
		float: right;
	}

	.header-nav .nav>li>a:hover,
	.header-nav .nav>li>a:active,
	.header-nav .nav>li>a:focus {
		background-color: #f0f0f0;
		text-decoration: none;
	}

	.header-nav .nav>li .mega-menu>li:after {
		display: none;
	}

	.header-nav .nav>li ul,
	.header-nav .nav>li .sub-menu,
	.header-nav .nav>li .mega-menu {
		display: none;
		position: static;
		visibility: visible;
		width: auto;
		background: #f9f9f9;
		transition: none !important;
	}

	.header-nav .nav>li ul.mega-menu ul {
		display: block;
	}

	.header-nav .nav>li .mega-menu>li {
		padding: 0px;
	}

	.header-nav .nav>li ul.mega-menu li:hover ul {
		display: block;
	}

	.header-nav .nav li.has-child {
		position: relative;
	}

	.header-nav .nav li .sub-menu,
	.header-nav .nav li .mega-menu {
		opacity: 1;
		margin: 0 !important;
	}

	.has-child .submenu-toogle {
		display: block;
	}

	.header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right::before {
		content: "\f107";
	}
}

.header-nav.nav-animation .nav>li>a {
	position: relative;
}

.header-nav.nav-animation .nav>li>ul.sub-menu li>a {
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	-ms-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
	position: relative;
	z-index: 1;
}

@media only screen and (max-width:640px) {
	.navbar-toggler {
		display: block;
		margin-top: 11px;
		margin-bottom: 4px;
		float: right;
		outline: none !important;
	}
}

@media only screen and (max-width: 991px) {

	/* .is-fixed .header-nav .nav,
	.header-fixed .header-nav .nav{
		overflow: auto;
		height: 390px;
	} */
	/* .is-fixed .header-nav .nav>li>a,
	.header-fixed .header-nav .nav>li>a{
		padding: 12px 15px;
	} */
	.is-fixed#header-part .navbar-toggler,
	.header-fixed .navbar-toggler {
		margin: 15px 0;
	}

	.header-nav.nav-animation .nav>li>a:before {
		left: 30px;
	}
}



/*Header Style 1*/

.header-style-1 .top-bar {
	padding: 0px 0px;
	font-size: 14px;
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.header-style-1 .top-bar .container {
	position: relative;
}

.header-style-1 .top-bar .wt-topbar-info li {
	display: inline-block;
	margin-right: 0px;
	padding: 12px 15px 12px 15px;
	border-right: 1px solid rgba(255, 255, 255, 0.6);
}

.header-style-1 .top-bar .wt-topbar-info li:first-child {
	position: relative;
	z-index: 1;
}

.header-style-1 .top-bar .wt-topbar-info li:first-child:before {
	content: '';
	position: absolute;
	right: 0px;
	top: -2px;
	background-color: #ff5e15;
	z-index: -1;
	width: 6000px;
	bottom: 0px;
}

.header-style-1 .top-bar .wt-topbar-info li span {
	display: inline-block;
	padding-right: 10px;
}

.header-style-1 .top-bar .wt-topbar-info li i {
	margin-right: 10px;
	font-size: 20px;
}

.header-style-1 .header-search {
	text-align: center;
}

.header-style-1 .header-search-icon {
	background-color: #fff;
	display: flex;
	width: 48px;
	position: absolute;
	right: 15px;
	top: 0px;
	bottom: 0px;
	height: 100%;
	align-content: center;
	flex-wrap: wrap;
}

.header-style-1 .header-search-icon i {
	margin: 0px auto;
}

.header-style-1 .main-bar {
	border-top: 1px solid #ddd;
	position: relative;
	width: 100%;
	padding: 0px 0px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.header-style-1 .header-nav .nav>li {
	margin: 0px;
	padding: 0px;
}

.header-style-1 .header-nav .nav>li>a {
	margin: 0px;
	padding: 14px 10px;
}

.header-style-1 .header-nav {
	float: left;
}

.header-style-1 .header-nav-request {
	float: right;
}

.header-style-1 .header-nav-request a {
	background-color: #ff5e15;
	display: block;
	color: #fff;
	padding: 12px 20px 13px 20px;
	text-transform: uppercase;
	cursor: pointer;
}

.header-style-1 .header-nav-request a i {
	margin-left: 10px;
}

@media only screen and (max-width:650px) {
	.header-style-1 .header-nav-request {
		float: left;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	/* .header-style-1 .top-bar .wt-topbar-info li{display:none;} */
	.header-style-1 .top-bar .wt-topbar-info li:first-child {
		display: inline-block;
		display: none;
	}
}

/*Header Style 2*/

.header-style-2 .header-nav .nav>li:hover>a:before {
	text-shadow: 0px 0 black, -10px 0 black, 10px 0 black;
}

.header-style-2 .top-bar {
	padding: 0px 0px;
	font-size: 14px;
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.header-style-2 .top-bar .wt-topbar-info li {
	display: inline-block;
	margin-right: 0px;
	padding: 12px 15px 12px 15px;
	border-right: 1px solid rgba(255, 255, 255, 0.6);
}

.header-style-2 .top-bar .wt-topbar-info li:first-child {
	position: relative;
	z-index: 1;
}

.header-style-2 .top-bar .wt-topbar-info li:first-child:before {
	content: '';
	position: absolute;
	right: 0px;
	top: 0px;
	background-color: #ff5e15;
	z-index: -1;
	width: 6000px;
	bottom: 0px;
}

.header-style-2 .top-bar .wt-topbar-info li span {
	display: inline-block;
	padding-right: 10px;
}

.header-style-2 .top-bar .wt-topbar-info li i {
	margin-right: 10px;
	font-size: 20px;
}


.header-style-2 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
	display: inline-block;
	margin-right: 0px;
	padding: 10px 15px 10px 15px;
	border-right: 1px solid rgba(255, 255, 255, 0.6);
}

.header-style-2 .top-bar .wt-topbar-right .wt-topbar-info-2 li span {
	display: inline-block;
	padding-right: 10px;
}

.header-style-2 .top-bar .wt-topbar-right .wt-topbar-info-2 li i {
	margin-right: 10px;
	font-size: 20px;
}

.header-style-2 .social-icons {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.header-style-2 .social-icons li {
	padding-left: 10px;
}

.header-style-2 .social-icons li a {
	color: #fff;
	font-size: 12px;
}



.header-style-2 .header-search {
	display: block;
	text-align: center;
	position: relative;
	float: left;
	width: 48px;
	height: 40px;
	background-color: black;
}

.header-style-2 .header-search-icon {
	display: block;
	color: #fff;
	line-height: 40px;
}

.header-style-2 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	color: #fff;
	width: 48px;
	height: 40px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-2 .header-search-icon.close i {
	display: none;
}

.header-style-2 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	font-size: 0px;
	color: #fff;
	width: 48px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-2 .header-search-icon.close:after,
.header-style-2 .header-search-icon.close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 18px;
	background-color: #fff;
	right: 24px;
	top: 12px;
}

.header-style-2 .header-search-icon.close:after {
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari */
	transform: rotate(45deg);
}

.header-style-2 .header-search-icon.close:before {
	-ms-transform: rotate(-45deg);
	/* IE 9 */
	-webkit-transform: rotate(-45deg);
	/* Safari */
	transform: rotate(-45deg);
}

.header-style-2 .header-search-icon.close:hover:after,
#search .close:hover:before {
	cursor: pointer;
}

.header-style-2 .header-search-icon i {
	margin: 0px auto;
}

.header-style-2 .main-bar {
	border-top: 1px solid #ddd;
	position: relative;
	width: 100%;
	padding: 0px 0px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	background-color: #fff;
}

.header-style-2 .main-bar .container {
	position: relative;
}

.header-style-2 .header-nav .nav>li>a {
	color: #111;
	display: block;
}

.header-style-2 .header-nav .nav>li>a:hover {
	color: #ff5e15;
}

.header-style-2 .header-nav-request {
	float: right;
}

.header-style-2 .header-nav-request a {
	background-color: #ff5e15;
	display: block;
	color: #fff;
	text-transform: uppercase;
	padding: 8px 10px;
	cursor: pointer;
}

.header-style-2 .header-nav-request a i {
	margin-left: 10px;
}

.header-style-2 .is-fixed.color-fill .main-bar {
	background: #fff;
}

.header-style-2 .header-nav .nav>li {
	font-weight: 500;
	position: relative;
	margin: 0px 0px;
	padding: 10px 0px;
}

.header-style-2 .logo-header {
	padding-top: 20px;
}

.header-style-2 .header-2-nav {
	padding-top: 20px;
}

@media only screen and (max-width:991px) {
	.header-style-2 .header-2-nav {
		padding-top: 0px;
	}

	.header-style-2 .logo-header {
		padding-top: 0px;
	}

	.header-style-2 .social-icons {
		display: none;
	}

	.header-style-2 .wt-topbar-right {
		float: right;
	}

	.header-style-2 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
		font-size: 12px;
		padding: 9px 10px 9px 15px;
	}

	.header-style-2 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
		border-right: 0px;
		padding-right: 0px;
	}

	.header-style-2 .top-bar .wt-topbar-info li:first-child {
		font-size: 12px;
	}


	.header-style-2 .header-nav .nav>li {
		padding: 0px;
	}
}

@media only screen and (max-width:767px) {
	.header-style-2 .wt-topbar-left {
		display: none !important;
	}

	.header-style-2 .wt-topbar-right {
		float: left;
	}
}

@media only screen and (max-width:640px) {
	.header-style-2 .header-nav-request a {
		font-size: 0px;
	}

	.header-style-2 .header-nav-request a i {
		font-size: 24px;
		margin: 0px;
		width: 20px;
		text-align: center;
	}
}

@media only screen and (max-width:480px) {
	.header-style-2 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
		display: block;
		padding-right: 0px;
		padding-bottom: 0px;
		border: none;
		padding-left: 0px;
	}

	.header-style-2 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
		padding-bottom: 10px;
	}
}






/*Header Style 3*/

.header-style-3 .container {
	max-width: 100%;
	padding: 0px 50px;
}

.header-style-3 .top-bar {
	padding: 0px 0px;
	font-size: 14px;
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.header-style-3 .top-bar .wt-topbar-info li {
	display: inline-block;
	margin-right: 0px;
	padding: 12px 15px 12px 0px;
}

.header-style-3 .top-bar .wt-topbar-info li:first-child {
	position: relative;
	z-index: 1;
}

.header-style-3 .top-bar .wt-topbar-info li:first-child:before {
	content: '';
	position: absolute;
	right: 0px;
	top: 0px;
	background-color: #ff5e15;
	z-index: -1;
	width: 6000px;
	bottom: 0px;
	transform: skewX(24deg);
	-webkit-transform: skewX(24deg);
	-moz-transform: skewX(24deg);
}

.header-style-3 .top-bar .wt-topbar-info li span {
	display: inline-block;
	padding-right: 10px;
}

.header-style-3 .top-bar .wt-topbar-info li i {
	margin-right: 10px;
	font-size: 20px;
}

.header-style-3 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
	display: inline-block;
	margin-right: 0px;
	padding: 10px 15px 10px 15px;
	border-right: 1px solid rgba(255, 255, 255, 0.6);
}

.header-style-3 .top-bar .wt-topbar-right .wt-topbar-info-2 li span {
	display: inline-block;
	padding-right: 10px;
}

.header-style-3 .top-bar .wt-topbar-right .wt-topbar-info-2 li i {
	margin-right: 10px;
	font-size: 20px;
}

.header-style-3 .social-icons {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.header-style-3 .social-icons li {
	padding-left: 10px;
}

.header-style-3 .social-icons li a {
	color: #fff;
	font-size: 12px;
}

.header-style-3 .header-search {
	display: block;
	text-align: center;
	position: relative;
	float: left;
	width: 48px;
	height: 40px;
	background-color: black;
}

.header-style-3 .header-search-icon {
	display: block;
	color: #fff;
	line-height: 40px;
}

.header-style-3 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	color: #fff;
	width: 48px;
	height: 40px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-3 .header-search-icon.close i {
	display: none;
}

.header-style-3 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	font-size: 0px;
	color: #fff;
	width: 48px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-3 .header-search-icon.close:after,
.header-style-3 .header-search-icon.close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 18px;
	background-color: #fff;
	right: 24px;
	top: 12px;
}

.header-style-3 .header-search-icon.close:after {
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari */
	transform: rotate(45deg);
}

.header-style-3 .header-search-icon.close:before {
	-ms-transform: rotate(-45deg);
	/* IE 9 */
	-webkit-transform: rotate(-45deg);
	/* Safari */
	transform: rotate(-45deg);
}

.header-style-3 .header-search-icon.close:hover:after,
#search .close:hover:before {
	cursor: pointer;
}

.header-style-3 .header-search-icon i {
	margin: 0px auto;
}

.header-style-3 .main-bar {
	border-top: 1px solid #ddd;
	position: relative;
	width: 100%;
	padding: 0px 0px;
	background-color: #fff;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.header-style-3 .main-bar .container {
	position: relative;
}

.header-style-3 .header-nav .nav>li>a {
	color: #111;
	display: block;
}

.header-style-3 .header-nav .nav>li>a:hover {
	color: #ff5e15;
}

.header-style-3 .header-nav-request {
	float: right;
}

.header-style-3 .header-nav-request a {
	background-color: #ff5e15;
	display: block;
	color: #fff;
	text-transform: uppercase;
	padding: 8px 10px;
	cursor: pointer;
}

.header-style-3 .header-nav-request a i {
	margin-left: 10px;
}

.header-style-3 .is-fixed.color-fill .main-bar {
	background: #fff;
}

.header-style-3 .header-nav .nav>li {
	font-weight: 500;
	position: relative;
	margin: 0px 0px;
	padding: 10px 0px;
}

.header-style-3 .logo-header {
	padding-top: 20px;
}

.header-style-3 .header-2-nav {
	padding-top: 20px;
}

@media only screen and (max-width:991px) {
	.header-style-3 .header-2-nav {
		padding-top: 0px;
	}

	.header-style-3 .logo-header {
		padding-top: 0px;
	}

	.header-style-3 .social-icons {
		display: none;
	}

	.header-style-3 .wt-topbar-right {
		float: right;
	}

	.header-style-3 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
		font-size: 12px;
		padding: 9px 10px 9px 15px;
	}

	.header-style-3 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
		border-right: 0px;
		padding-right: 0px;
	}

	.header-style-3 .top-bar .wt-topbar-info li:first-child {
		font-size: 12px;
	}

	.header-style-3 .header-nav .nav>li {
		padding: 0px;
	}
}

@media only screen and (max-width:767px) {
	.header-style-3 .wt-topbar-left {
		display: none !important;
	}

	.header-style-3 .wt-topbar-right {
		float: left;
	}
}

@media only screen and (max-width:640px) {
	.header-style-3 .header-nav-request a {
		font-size: 0px;
	}

	.header-style-3 .header-nav-request a i {
		font-size: 24px;
		margin: 0px;
		width: 20px;
		text-align: center;
	}
}

@media only screen and (max-width:480px) {
	.header-style-3 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
		display: block;
		padding-right: 0px;
		padding-bottom: 0px;
		border: none;
		padding-left: 0px;
	}

	.header-style-3 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
		padding-bottom: 10px;
	}
}

@media only screen and (max-width:640px) {
	.header-style-3 .logo-header {
		width: 120px;
	}
}

@media only screen and (max-width: 480px) {
	.header-style-3 .wt-topbar-left {
		display: none !important;
	}
}

.header-style-3 .navbar-toggler .icon-bar {
	background: #000;
	height: 3px;
	border-radius: 0;
	-webkit-border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-3 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-3 #search-toggle-block {
	display: none;
	position: absolute;
	right: 15px;
	top: 65px;
	border: 3px solid #ff5e15;
}

.header-style-3 #search {
	position: relative;
	background-color: rgba(255, 255, 255);
}

.header-style-3 #search form {
	width: 280px;
	color: #262423;
	text-align: center;
	outline: none;
	padding: 12px;
	margin: 0px auto;
}

.header-style-3 #search input[type="search"] {
	background: none;
	border: none;
	padding: 0px;
	font-size: 14px;
	height: auto;
}

.header-style-3 #search .search-btn {
	border: none;
	background: none;
	padding: 0px 0px 0px 15px;
}

.header-style-3 #search .search-btn i {
	font-size: 18px;
}

.header-style-3 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	font-size: 0px;
	color: #fff;
	width: 48px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-3 .header-search-icon.close:after,
.header-style-3 .header-search-icon.close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 24px;
	background-color: #fff;
	right: 24px;
	top: 9px;
}

.header-style-3 .header-search-icon.close:after {
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari */
	transform: rotate(45deg);
}

.header-style-3 .header-search-icon.close:before {
	-ms-transform: rotate(-45deg);
	/* IE 9 */
	-webkit-transform: rotate(-45deg);
	/* Safari */
	transform: rotate(-45deg);
}

.header-style-3 .header-search-icon.close:hover:after,
#search .close:hover:before {
	cursor: pointer;
}





/*Header Style 4*/

.site-header.header-style-4 {
	position: absolute;
	width: 100%;
	left: 0px;
	top: 0px;
}

.site-header.header-style-4 .main-bar {
	position: fixed;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.header-style-4 .header-nav .nav>li:hover>a:before {
	text-shadow: 0px 0 black, -10px 0 black, 10px 0 black;
}

.header-style-4 .top-bar {
	padding: 0px 0px;
	font-size: 14px;
	display: flex;
	align-content: center;
	flex-wrap: wrap;
	background-color: rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 0;
}

.header-style-4 .top-bar .wt-topbar-info li {
	display: inline-block;
	margin-right: 0px;
	padding: 12px 15px 12px 15px;
	border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.header-style-4 .top-bar .wt-topbar-info li:first-child {
	position: relative;
	z-index: 1;
}


.header-style-4 .top-bar .wt-topbar-info li span {
	display: inline-block;
	padding-right: 10px;
}

.header-style-4 .top-bar .wt-topbar-info li i {
	margin-right: 10px;
	font-size: 20px;
}

.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
	display: inline-block;
	margin-right: 0px;
	padding: 10px 15px 10px 15px;
	border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li span {
	display: inline-block;
	padding-right: 10px;
}

.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li i {
	margin-right: 10px;
	font-size: 20px;
}

.header-style-4 .social-icons {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.header-style-4 .social-icons li {
	padding-left: 10px;
}

.header-style-4 .social-icons li a {
	color: #fff;
	font-size: 12px;
}

.header-style-4 .header-search {
	display: block;
	text-align: center;
	position: relative;
	float: left;
	width: 48px;
	height: 40px;
	background-color: black;
}

.header-style-4 .header-search-icon {
	display: block;
	color: #fff;
	line-height: 40px;
}

.header-style-4 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	color: #fff;
	width: 48px;
	height: 40px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-4 .header-search-icon.close i {
	display: none;
}

.header-style-4 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	font-size: 0px;
	color: #fff;
	width: 48px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-4 .header-search-icon.close:after,
.header-style-4 .header-search-icon.close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 18px;
	background-color: #fff;
	right: 24px;
	top: 12px;
}

.header-style-4 .header-search-icon.close:after {
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari */
	transform: rotate(45deg);
}

.header-style-4 .header-search-icon.close:before {
	-ms-transform: rotate(-45deg);
	/* IE 9 */
	-webkit-transform: rotate(-45deg);
	/* Safari */
	transform: rotate(-45deg);
}

.header-style-4 .header-search-icon.close:hover:after,
#search .close:hover:before {
	cursor: pointer;
}

.header-style-4 .header-search-icon i {
	margin: 0px auto;
}

.header-style-4 .main-bar {
	position: relative;
	width: 100%;
	padding: 0px 0px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	background-color: rgba(0, 0, 0, 0.2);
}

.header-style-4 .main-bar .container {
	position: relative;
}

.header-style-4 .header-nav .nav>li>a {
	color: #fff;
	display: block;
}

.header-style-4 .header-nav .nav>li>a:hover {
	color: #f00;
}

.header-style-4 .header-nav-request {
	float: right;
}

.header-style-4 .header-nav-request a {
	background-color: #ff5e15;
	display: block;
	color: #fff;
	text-transform: uppercase;
	padding: 8px 10px;
	cursor: pointer;
}

.header-style-4 .header-nav-request a i {
	margin-left: 10px;
}

.header-style-4 .is-fixed.color-fill .main-bar {
	background: rgba(0, 0, 0, 0.8);
}

.header-style-4 .header-nav .nav>li {
	font-weight: 500;
	position: relative;
	margin: 0px 0px;
	padding: 10px 0px;
}

.header-style-4 .logo-header {
	padding-top: 20px;
}

.header-style-4 .header-2-nav {
	padding-top: 20px;
}

@media only screen and (max-width:991px) {
	.header-style-4 .header-2-nav {
		padding-top: 0px;
	}

	.header-style-4 .logo-header {
		padding-top: 0px;
	}

	.header-style-4 .social-icons {
		display: none;
	}

	.header-style-4 .wt-topbar-right {
		float: right;
	}

	.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
		font-size: 12px;
		padding: 9px 10px 9px 15px;
	}

	.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
		border-right: 0px;
		padding-right: 0px;
	}

	.header-style-4 .top-bar .wt-topbar-info li:first-child {
		font-size: 12px;
	}

	.header-style-4 .header-nav .nav>li {
		padding: 0px;
	}

	.header-style-4 .header-nav .nav>li>a {
		color: #000;
	}

	.header-style-4 .navbar-toggler .icon-bar {
		background: #fff;
	}
}

@media only screen and (max-width:767px) {
	.header-style-4 .wt-topbar-left {
		display: none !important;
	}

	.header-style-4 .wt-topbar-right {
		float: left;
	}
}

@media only screen and (max-width:640px) {
	.header-style-4 .header-nav-request a {
		font-size: 0px;
	}

	.header-style-4 .header-nav-request a i {
		font-size: 24px;
		margin: 0px;
		width: 20px;
		text-align: center;
	}
}

@media only screen and (max-width:480px) {
	.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
		display: block;
		padding-right: 0px;
		padding-bottom: 0px;
		border: none;
		padding-left: 0px;
	}

	.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
		padding-bottom: 10px;
	}
}


.header-style-4 #search-toggle-block {
	display: none;
	position: absolute;
	right: 15px;
	top: 65px;
	border: 3px solid #ff5e15;
}

.header-style-4 #search {
	position: relative;
	background-color: rgba(255, 255, 255);
}

.header-style-4 #search form {
	width: 280px;
	color: #262423;
	text-align: center;
	outline: none;
	padding: 12px;
	margin: 0px auto;
}

.header-style-4 #search input[type="search"] {
	background: none;
	border: none;
	padding: 0px;
	font-size: 14px;
	height: auto;
}

.header-style-4 #search .search-btn {
	border: none;
	background: none;
	padding: 0px 0px 0px 15px;
}

.header-style-4 #search .search-btn i {
	font-size: 18px;
}

.header-style-4 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	font-size: 0px;
	color: #fff;
	width: 48px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-4 .header-search-icon.close:after,
.header-style-4 .header-search-icon.close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 24px;
	background-color: #fff;
	right: 24px;
	top: 9px;
}

.header-style-4 .header-search-icon.close:after {
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari */
	transform: rotate(45deg);
}

.header-style-4 .header-search-icon.close:before {
	-ms-transform: rotate(-45deg);
	/* IE 9 */
	-webkit-transform: rotate(-45deg);
	/* Safari */
	transform: rotate(-45deg);
}

.header-style-4 .header-search-icon.close:hover:after,
#search .close:hover:before {
	cursor: pointer;
}

/*-------------------------------------
	2. nav dark version
-------------------------------------*/

.nav-dark.header-nav .nav>li .sub-menu,
.nav-dark.header-nav .nav>li .mega-menu {
	background-color: #000;
	border: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-dark.header-nav .nav>li .sub-menu li {
	border-bottom: 1px solid #23282f;
}

.nav-dark.header-nav .nav>li .sub-menu li:last-child {
	border-bottom: 0px;
}

.nav-dark.header-nav .nav>li .sub-menu li a,
.nav-dark.header-nav .nav>li .mega-menu>li ul a,
.nav-dark.header-nav .nav>li .mega-menu>li>a {
	color: #CCCCCC;
}

.nav-dark.header-nav .nav>li .sub-menu li a:hover {
	background-color: #222;
}

.nav-dark.header-nav .nav>li .mega-menu>li ul a:hover {
	background-color: #23282f;
	color: #0f1221;
}

.nav-dark.header-nav .nav>li .mega-menu>li:after {
	background-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
	.nav-dark.header-nav {
		border-bottom: 1px solid rgba(0, 0, 0, 0.8);
	}

	.nav-dark.header-nav .nav {
		background-color: #2D3239;
	}

	.nav-dark.header-nav .nav>li>a {
		border-top: 1px solid rgba(0, 0, 0, 0.2);
	}

	.nav-dark.header-nav .nav>li>a {
		color: #999;
	}

	.nav-dark.header-nav .nav>li>a:hover,
	.nav-dark.header-nav .nav>li>a:active,
	.nav-dark.header-nav .nav>li>a:focus {
		background-color: #23282F;
		color: #0f1221;
	}

	.nav-dark.header-nav .nav>li ul,
	.nav-dark.header-nav .nav>li .sub-menu,
	.nav-dark.header-nav .nav>li .mega-menu {
		background-color: #23282F;
	}
}

/*====Mobile Side =======*/
@media only screen and (max-width: 991px) {
	.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		-moz-transition: all 0.5s linear;
		-ms-transition: all 0.5s linear;
		-o-transition: all 0.5s linear;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.mobile-sider-drawer-menu.active .header-nav {
		left: 15px;
	}

	.is-fixed.mobile-sider-drawer-menu .header-nav .nav,
	.header-fixed.mobile-sider-drawer-menu .header-nav .nav {
		height: 100vh !important;
		width: 100%;
	}

	.is-fixed.mobile-sider-drawer-menu .header-nav .nav>li>a,
	.header-fixed.mobile-sider-drawer-menu .header-nav .nav>li>a {
		padding: 12px 15px !important;
	}

	.header-nav .nav>li:hover>.sub-menu,
	.header-nav .nav>li .sub-menu li:hover>.sub-menu {
		animation: none !important
	}

	.mobile-sider-drawer-menu .icon-bar {
		-moz-transition: all 0.5s linear;
		-ms-transition: all 0.5s linear;
		-o-transition: all 0.5s linear;
		transition: all 0.5s linear;
	}


	#mobile-side-drawer {
		min-height: 41px;
	}

	.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		-moz-transform: rotate(45deg) translateX(3px) translateY(2px);
		-webkit-transform: rotate(45deg) translateX(3px) translateY(2px);
		-o-transform: rotate(45deg) translateX(3px) translateY(2px);
		-ms-transform: rotate(45deg) translateX(3px) translateY(2px);
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		-moz-transform: rotate(-45deg) translateX(3px) translateY(-2px);
		-webkit-transform: rotate(-45deg) translateX(3px) translateY(-2px);
		-o-transform: rotate(-45deg) translateX(3px) translateY(-2px);
		-ms-transform: rotate(-45deg) translateX(3px) translateY(-2px);
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;

	}

}

/*-------------------------------------
	3. when header is sticky
-------------------------------------*/

.sticky-no .main-bar {
	position: static !important;
}


/*-------------------------------------
	4. when header is sticky
-------------------------------------*/


.is-fixed .main-bar {
	position: fixed;
	top: 0;
	left: 0;
}

#page-boxed .main-bar {
	margin-left: auto;
	margin-right: auto;
	max-width: 1760px;
	width: 100%;
	left: auto;
	right: auto;
}

#page-boxed .nav-wide .container {
	padding: 0px 0px;
	max-width: 100%;
}

#page-boxed .header-style-1 .is-fixed.color-fill .main-bar {
	background:
		#fff;
}

@media only screen and (max-width:1760px) {
	#page-boxed .main-bar {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.header-style-1 .is-fixed.color-fill .main-bar {
	background: #fff;
}

.header-style-1.header-transparent .main-bar {
	position: fixed;
}

.header-style-1 .header-nav .nav>li>a {
	color: #111;
	display: block;
	text-transform: uppercase;
	font-weight: 600;
}

/*-------------------------------------
 5. header id Fixed
-------------------------------------*/
/*.is-fixed.color-fill .main-bar {    padding:0px 64px;}*/

.is-fixed.color-fill .header-nav .nav>li {
	margin: 0;
	padding: 0;
}

.is-fixed.color-fill .header-nav .nav>li>a {
	margin: 0;
}

@media only screen and (max-width:767px) {

	.main-bar,
	.is-fixed .main-bar,
	.is-fixed.color-fill .main-bar {
		padding: 0px;
	}
}


/*-------------------------------------
	6. header with bg primary color 1
-------------------------------------*/

.site-bg-primary .header-nav .nav>li>a {
	color: #fff;
}

.site-bg-primary .header-nav .nav>li:hover>a,
.site-bg-primary .header-nav .nav>li.active>a,
.site-bg-primary .header-nav .nav>li.current-menu-item>a {
	color: #1a1a1a;
}

.site-bg-primary .site-search-btn,
.site-bg-primary .wt-cart .woo-cart-total,
.site-bg-primary .wt-cart .woo-cart-count {
	color: #fff;
}

.site-bg-primary .wt-cart .woo-cart-count,
.site-bg-primary .wt-cart .woo-cart-count::before {
	border-color: #fff;
}

.site-bg-primary .navbar-toggler .icon-bar {
	background-color: #fff;
}

@media only screen and (max-width:991px) {
	.site-bg-primary .header-nav .nav>li>a {
		color: #777;
	}

	.site-bg-primary .header-nav .nav>li:hover>a,
	.site-bg-primary .header-nav .nav>li.active>a,
	.site-bg-primary .header-nav .nav>li.current-menu-item>a {
		color: #0f1221;
	}

	.header-nav .nav>li {
		margin: 0;
		padding: 0;
	}

	.header-nav .nav>li>a {
		margin: 0;
		padding: 10px 12px;
	}

	.is-fixed.color-fill .header-nav .nav>li {
		margin: 0;
		padding: 0;
	}

	/* .is-fixed.color-fill .header-nav .nav>li>a {
		margin: 0;
		padding: 10px 12px;
	} */
	.header-style-1 .header-nav .nav>li>a {
		color: #0f1221;
	}
}


/*======================================
19. BANNER
======================================*/


/*-------------------------------------
	1. inner page banner
-------------------------------------*/

.wt-bnr-inr {
	height: 100px;
	background-size: cover;
	background-position: center center;
	display: table;
	width: 100%;
}

.wt-bnr-inr .container {
	display: table;
	height: 100%;
}

.wt-bnr-inr-entry {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.wt-bnr-inr-entry .banner-title-outer {
	position: relative;
}

.wt-bnr-inr-entry .banner-title-outer h2 {
	font-size: 42px;
	margin-bottom: 0px;
}

@media only screen and (max-width: 991px) {
	.wt-bnr-inr {
		height: 80px;
		/* padding-top: 120px; */
	}

	.wt-bnr-inr-entry {
		text-align: center;
	}
}


@media only screen and (max-width: 991px) {

	.wt-bnr-inr-entry .banner-title-name h1,
	.wt-bnr-inr-entry .banner-title-name h2,
	.wt-bnr-inr-entry .banner-title-name h3 {
		font-size: 42px;
		line-height: normal;
		margin-bottom: 0px !important;
	}
}

.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
	color: #FFF;
}


/*======================================
20. SLIDER
======================================*/


/*-------------------------------------
	1. owl slider
-------------------------------------*/


/*-------------------------------------
	1.1  next pre btn
-------------------------------------*/

.owl-carousel .owl-nav {
	margin-top: 40px;
	text-align: center;
}

.owl-carousel .owl-nav .disabled {
	opacity: .5;
	cursor: default
}



/*-------------------------------------
	1.2 owl dots button
-------------------------------------*/
.owl-carousel .owl-dots {
	text-align: center;
	margin-top: 20px;
}

.owl-carousel .owl-dots .owl-dot {
	display: inline-block;
}

.owl-carousel .owl-dots .owl-dot span {
	width: 12px;
	height: 12px;
	margin: 5px 7px;
	background: #0f1221;
	display: block;
	-webkit-transition: opacity 200ms ease;
	-moz-transition: opacity 200ms ease;
	-ms-transition: opacity 200ms ease;
	-o-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	border-radius: 50%;
	-webkit-border-radius: 50%;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
	background: black;
}


/*-------------------------------------
	1.3 owl button top to center
-------------------------------------*/

.owl-btn-top-center .owl-nav,
.owl-btn-top-left .owl-nav,
.owl-btn-top-right .owl-nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin: 0;
}

.owl-btn-top-center .owl-stage-outer,
.owl-btn-top-left .owl-stage-outer,
.owl-btn-top-right .owl-stage-outer {
	padding-top: 40px;
}

.owl-btn-top-center .owl-nav {
	text-align: center;
}


/*-------------------------------------
	1.4 owl button top to left
-------------------------------------*/

.owl-btn-top-left .owl-nav {
	text-align: left;
}


/*-------------------------------------
	1.5 owl button top to right
-------------------------------------*/

.owl-btn-top-right .owl-nav {
	text-align: right;
}


/*-------------------------------------
	1.6 owl button bottom to center [it is default position ]
-------------------------------------*/

.owl-btn-bottom-center .owl-nav {
	text-align: center;
}


/*-------------------------------------
	1.7 owl button bottom to left
-------------------------------------*/

.owl-btn-bottom-left .owl-nav {
	text-align: left;
}


/*-------------------------------------
	1.8 owl button bottom to right
-------------------------------------*/

.owl-btn-bottom-right .owl-nav {
	text-align: right;
}


/*-------------------------------------
	1.9 owl button vertical to center
-------------------------------------*/

.owl-btn-vertical-center .owl-nav {
	margin: 0px;
}

.owl-btn-vertical-center .owl-nav .owl-prev,
.owl-btn-vertical-center .owl-nav .owl-next {
	position: absolute;
	top: 50%;
	margin: -15px 0;
}

.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 0;
}

.owl-btn-vertical-center .owl-nav .owl-next {
	right: 0;
}


/*-------------------------------------
	1.10 owl button show on hover
-------------------------------------*/

.owl-btn-hover .owl-nav {
	opacity: 0;
}

.owl-btn-hover:hover .owl-nav {
	opacity: 1;
}

.owl-carousel .owl-item img {
	transform-style: inherit;
}


/*-------------------------------------
    1.11 owl slider button
-------------------------------------*/

.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
	color: #fff !important;
	padding: 2px 10px !important;
	width: 35px;
	height: 35px;
	text-align: center;
	font-size: 14px;
	display: inline-block;
	cursor: pointer;
	font-weight: bold;
	background-color: black !important;
	line-height: 35px;
}

.owl-carousel .owl-nav .owl-prev {
	margin-right: 5px;
}

.owl-carousel .owl-nav .owl-next {
	margin-left: 5px;
}

.owl-prev:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-next:hover,
.owl-next:active,
.owl-next:focus {
	background-color: #ff5e15;
	border-color: #ff5e15;
	color: #ffffff;
	opacity: 0.9;
}

/*dark-next-prev*/
.dark-next-prev.owl-carousel .owl-next,
.dark-next-prev.owl-carousel .owl-prev {
	border: none;
	width: auto;
	height: auto;
}

.dark-next-prev.owl-carousel .owl-nav i {
	color: #fff;
	font-style: normal;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
}

.dark-next-prev.owl-carousel .owl-next,
.dark-next-prev.owl-carousel .owl-prev {
	position: relative;
	z-index: 1;
	background: none;
}

.dark-next-prev.owl-carousel .owl-next:hover,
.dark-next-prev.owl-carousel .owl-prev:hover {
	background: none;
}

.dark-next-prev.owl-carousel .owl-next:hover i,
.dark-next-prev.owl-carousel .owl-prev:hover i {
	color: black;
}



/*light-next-prev*/
.light-next-prev.owl-carousel .owl-next,
.light-next-prev.owl-carousel .owl-prev {
	border: none;
	width: auto;
	height: auto;
}

.light-next-prev.owl-carousel .owl-nav i {
	color: #1d2132;
	font-style: normal;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
}

.light-next-prev.owl-carousel .owl-next,
.light-next-prev.owl-carousel .owl-prev {
	position: relative;
	z-index: 1;
	background: none;
}



.light-next-prev.owl-carousel .owl-next:hover,
.light-next-prev.owl-carousel .owl-prev:hover {
	background: none;
}

.light-next-prev.owl-carousel .owl-next:hover i,
.light-next-prev.owl-carousel .owl-prev:hover i {
	color: black;
}


/*-------------------------------------
	1.12 owl dots nav position
-------------------------------------*/

.owl-dots-bottom-left .owl-dots,
.owl-dots-bottom-right .owl-dots,
.owl-dots-bottom-center .owl-dots {
	bottom: -50px;
	padding: 15px 20px;
	position: absolute;
	width: 100%;
}

.owl-dots-bottom-left .owl-dots {
	text-align: left;
}

.owl-dots-bottom-right .owl-dots {
	text-align: right;
}


/*======================================
21. PAGINATION
======================================*/


/*1. pagination style-1 */

.pagination-bx .pagination,
.cvf-universal-pagination .pagination {
	margin: 0;
}


.pagination>li:first-child>a,
.pagination>li:first-child>span {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	margin-left: 0;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.pagination>li>a,
.pagination>li>span {
	background-color: #fff;
	border: 1px solid #e0e0e0;
	color: #767676;
	font-weight: 600;
	font-size: 12px;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
	background-color: #0f1221;
	border-color: transparent;
	color: #fff;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
	background-color: #0f1221;
	border-color: transparent;
}

.pagination>.previous>a,
.pagination>.next>a {
	font-size: 12px;
}


/*======================================
22. FOOTER
======================================*/


/*-------------------------------------
	0. footer Large css
-------------------------------------*/

.footer-large .footer-bottom {
	padding: 20px 0px;
	color: #787878;
	font-size: 13px;
}


/*-------------------------------------
	1. footer fixed on bottom css
-------------------------------------*/


/*1.1 footer fixed with fullscreen view*/

.footer-fixed .site-footer {
	position: fixed;
	width: 100%;
	bottom: 0;
	border: none;
	z-index: 0;
}

.footer-fixed .page-content {
	position: relative;
	z-index: 1;
	background-color: #fff;
}

@media only screen and (max-width:1200px) {
	.footer-fixed .site-footer {
		position: static;
	}

	.footer-fixed .page-wraper {
		padding-bottom: 0 !important;
	}
}

/*1.2 footer fixed with fullscreen view*/
.header-fixed.site-header {
	position: fixed;
	width: 100%;
	border: none;
	z-index: 990;
}

.header-fixed .page-content {
	position: relative;
	z-index: 1;
	background-color: #fff;
}

/*1.2 footer fixed with boxed view*/


.site-footer p,
.site-footer li {
	line-height: 26px;
}

.site-footer {
	background-position: center;
	background-size: cover;
}

.site-footer p {
	line-height: 26px;
}

.site-footer .widget ul {
	list-style: none;
	margin-top: 0px;
}

.site-footer .widget_address {
	padding-top: 5px;
}

.footer-dark .footer-bottom .copyrights-text {
	color: #fff;
	font-size: 14px;
}

.footer-dark .footer-bottom .copyrights-nav {
	list-style: none;
	display: block;
	margin-bottom: 0px;
}

.footer-dark .footer-bottom .copyrights-nav li {
	display: inline-block;
	margin-right: 8px;
}

.footer-dark .footer-bottom .copyrights-nav li:first-child {
	margin-left: 0;
}

.footer-dark .footer-bottom .copyrights-nav li:last-child {
	margin-right: 0;
}

.footer-dark .footer-bottom .copyrights-nav li:last-child:after {
	display: none;
}

.footer-dark .footer-bottom .copyrights-nav li:after {
	content: "/";
	position: relative;
	right: -8px;
	color: #ff5e15;
}

.footer-dark .footer-bottom .copyrights-nav li a {
	color: #ff5e15;
}

.footer-dark .footer-bottom .copyrights-nav li a:hover,
.footer-dark .footer-bottom .copyrights-nav li a:active,
.footer-dark .footer-bottom .copyrights-nav li a:focus,
.footer-dark .footer-bottom .copyrights-nav li.active a {
	color: #fff;
}

.footer-dark .widget_services ul li a {
	color: #fff;
}

.footer-bottom {
	padding: 100px 0px;
	color: #787878;
	font-size: 13px;
	position: relative;
	z-index: 1;
}

.footer-bottom .copyrights-text {
	color: #fff;
	font-size: 14px;
}

.footer-light .footer-bottom .copyrights-text {
	color: #111;
}

.footer-bottom .copyrights-nav {
	list-style: none;
	display: block;
}

.footer-bottom .copyrights-nav li {
	display: inline-block;
	margin-right: 8px;
}

.footer-bottom .copyrights-nav li:first-child {
	margin-left: 0;
}

.footer-bottom .copyrights-nav li:last-child {
	margin-right: 0;
}

.footer-bottom .copyrights-nav li:last-child:after {
	display: none;
}

.footer-bottom .copyrights-nav li:after {
	content: "/";
	position: relative;
	right: -8px;
}

.footer-bottom .copyrights-nav li a {
	color: #9d9d9d;
	padding-left: 6px;
}

.footer-bottom .copyrights-nav li:first-child a {
	padding-left: 0px;
}

.footer-light .footer-bottom .copyrights-nav li a {
	color: #111;
}


.footer-bottom .copyrights-nav li a:hover,
.footer-bottom .copyrights-nav li a:active,
.footer-bottom .copyrights-nav li a:focus,
.footer-bottom .copyrights-nav li.active a {
	color: #0f1221;
}

.footer-light .footer-bottom .copyrights-nav li a:hover,
.footer-light .footer-bottom .copyrights-nav li a:active,
.footer-light .footer-bottom .copyrights-nav li a:focus,
.footer-light .footer-bottom .copyrights-nav li.active a {
	color: black;
}


@media only screen and (max-width:991px) {
	.site-footer {
		padding: 0px;
	}

	.copyright-block {
		padding-top: 5px;
	}
}

.footer-bottom .container {
	width: 100%;
}


/*-------------------------------------
	2. Footer dark version css
-------------------------------------*/

.footer-dark.site-footer {
	border-top: 0px solid #212121;
}

.footer-dark .footer-top {
	background-color: #171717;
	overflow: hidden;
}

.footer-dark .footer-top h1,
.footer-dark .footer-top h2,
.footer-dark .footer-top h3,
.footer-dark .footer-top h4,
.footer-dark .footer-top h5,
.footer-dark .footer-top h6,
.footer-dark .footer-top h1 a,
.footer-dark .footer-top h2 a,
.footer-dark .footer-top h3 a,
.footer-dark .footer-top h4 a,
.footer-dark .footer-top h5 a,
.footer-dark .footer-top h6 a {
	color: #ff5e15;
}

.footer-dark .footer-top .widget-title::before {
	border-color: #fff;
}

.footer-dark .footer-top p,
.footer-dark .footer-top strong,
.footer-dark .footer-top b,
.footer-dark .footer-top {
	color: #fff;
}

.footer-dark .site-button {
	background-color: #0f1221;
}


.footer-dark .footer-bottom {
	background-color: #111111;
	border-top: 1px solid #232323;
}

.footer-dark .social-icons li a {
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	color: #fff;
	background-color: #ff5e15;
	font-size: 16px;
}

.footer-dark .social-icons li a:hover {
	background-color: #fff;
	color: #0f1221;
}

.footer-dark .widget_address li {
	margin-bottom: 12px;
	position: relative;
	padding-left: 30px;
	color: #fff;
	font-size: 14px;
}

.footer-dark .widget_address li i {
	position: absolute;
	left: 0px;
	top: 5px;
	font-size: 18px;
	color: #ff5e15;
}


.footer-light .social-icons li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
	padding: 0px;
	color: #0f1221;
	border: 1px solid #0f1221;
}

.footer-light .social-icons li a:hover {
	background-color: #0f1221;
	color: #fff;
}

.footer-dark .footer-top a:active,
.footer-dark .footer-top a:focus,
.footer-dark .footer-top a:hover {
	color: #0f1221;
}

.footer-dark .footer-top p a {
	color: #fff;
}

.footer-dark .footer-top .widget_newsletter .form-control {
	background-color: #0e0e0e;
	border-color: #2d2d2d;
	color: #fff;
}


/*-------------------------------------
	2. Footer dark version css
-------------------------------------*/
.site-footer {
	overflow: hidden;
}

.footer-dark.site-footer {
	border-top: 0px solid #212121;
	overflow: hidden;
}

.footer-light .footer-top {
	background-color: #0f1221;
}

.footer-light .footer-top h1,
.footer-light .footer-top h2,
.footer-light .footer-top h3,
.footer-light .footer-top h4,
.footer-light .footer-top h5,
.footer-light .footer-top h6,
.footer-light .footer-top h1 a,
.footer-light .footer-top h2 a,
.footer-light .footer-top h3 a,
.footer-light .footer-top h4 a,
.footer-light .footer-top h5 a,
.footer-light .footer-top h6 a {
	color: #000;
}

.footer-light .footer-top .widget-title::before {
	border-color: #000;
}

.footer-light .footer-top p,
.footer-light .footer-top strong,
.footer-light .footer-top b,
.footer-light .footer-top {
	color: #000;
}

.footer-light .footer-top {
	background-color: #fff6ef;
}

.footer-light .footer-bottom {
	background-color: #dfba9f;
}


/*-------widget service-------*/

.footer-light .widget_address li {
	margin-bottom: 12px;
	color: #000;
	position: relative;
	padding-left: 30px;
	border-bottom: 1px solid #ddd;
	padding-bottom: 10px;
}

.footer-light .widget_address li:last-child {
	border-bottom: 0px;
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.footer-light .widget_address li i {
	position: absolute;
	left: 0px;
	top: 5px;
	font-size: 18px;
}

.footer-light .widget_address_outer .social-icons li {
	padding-right: 10px;
}

.footer-top {
	padding: 0px;
}

.footer-h-left {
	padding: 50px 40px 40px 0px;
	position: relative;
	z-index: 1;
}

.footer-h-left:before {
	content: '';
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #111111;
	z-index: -1;
	width: 6000px;
	height: 150%;
}

.footer-h-right {
	padding-top: 80px;
}


.footer-wide .container-fluid {
	width: 100%;
}

@media only screen and (max-width:1366px) {
	.footer-wide .container-fluid {
		width: 100%;
	}
}

@media only screen and (max-width:991px) {
	.footer-h-left:before {
		display: none;
	}

	.footer-h-left {
		padding: 50px 0px 0px 0px;
	}

	.footer-h-right {
		padding-top: 50px;
	}
}

@media only screen and (max-width:800px) {
	.footer-wide .container-fluid {
		padding: 0px 15px;
	}
}

.footer-payments li {
	display: inline-block;
	padding-top: 5px;
}

.footer-payments li:last-child a {
	margin-right: 0px;
}

.footer-payments li a {
	font-size: 36px;
	margin-right: 10px;
}

.footer-payments li a:hover {
	color: black;
}


/*-------------------------------------
	5. widget Getintuch
-------------------------------------*/

.footer-bottom ul {
	margin: 0;
}

@media only screen and (max-width:991px) {
	footer .container {
		width: 100%;
	}

	.col-md-3.col-sm-6.footer-col-4:nth-child(3) {
		clear: both;
	}

	.wt-footer-bot-left,
	.wt-footer-bot-right {
		float: none;
		text-align: left;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.wt-footer-bot-right .copyrights-nav {
		float: none !important;
	}

	.footer-bottom .d-flex {
		display: block !important;
	}
}

@media only screen and (max-width: 767px) {
	.footer-clear {
		display: block !important;
		clear: both;
	}

	#footer .footer-4-col {
		width: 100%;
	}

	.footer-bottom [class*="clo-"] {
		width: 100%;
	}
}

.widget_address li {
	margin-bottom: 20px;
	color: #d7d7d7;
	position: relative;
	padding-left: 20px;
}

.widget.widget_about p {
	line-height: inherit;
	margin-bottom: 15px;
	font-size: 14px;
}


/*-----Footer style2------*/
.footer-dark.footer-style2 .footer-top {
	background-color: black;
}

.footer-dark.footer-style2 .footer-h-left::before {
	display: none;
}

.footer-dark.footer-style2 .footer-bottom {
	background-color: #001029;
	border-top: 1px solid #001029;
}

.footer-dark.footer-style2 .footer-bottom .copyrights-nav li a {
	color: #fff;
}

.footer-dark.footer-style2 .footer-bottom .copyrights-nav li a:hover {
	color: #ff5e15;
}

.footer-dark.footer-style2 .footer-bottom .copyrights-nav li::after {
	color: #fff;
}

.footer-dark.footer-style2 .footer-top .widget_newsletter .form-control {
	background-color: #fff;
	border-color: #fff;
}

/*-------------------------------------
	6. scroll top btn css
-------------------------------------*/

button.scroltop {
	height: 40px;
	width: 40px;
	background: #1d1b18;
	border: none;
	position: fixed;
	right: 15px;
	bottom: 0;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	cursor: pointer;
	display: none;
	text-align: center;
	z-index: 999;
	color: #fff;
}

button.scroltop span {
	display: block;
	font-size: 24px;
	line-height: 24px;
}

.string {
	position: relative;
}

#btn-vibrate {
	animation: vibrate 2s linear 0s infinite;
	-moz-animation: vibrate 2s linear 0s infinite;
	/* Firefox */
	-webkit-animation: vibrate 2s linear 0s infinite;
	/* Safari and Chrome */
	-o-animation: vibrate 2s linear 0s infinite;
	/* Opera */
}

@keyframes vibrate {
	0% {
		top: 0px;
	}

	25% {
		top: -3px;
	}

	50% {
		top: 0px;
	}

	75% {
		top: 3px;
	}

	100% {
		top: 0px;
	}
}


/* Firefox */

@-moz-keyframes vibrate {
	0% {
		top: 0px;
	}

	25% {
		top: -3px;
	}

	50% {
		top: 0px;
	}

	75% {
		top: 3px;
	}

	100% {
		top: 0px;
	}
}


/* Safari and Chrome */

@-webkit-keyframes vibrate {
	0% {
		top: 0px;
	}

	25% {
		top: -3px;
	}

	50% {
		top: 0px;
	}

	75% {
		top: 3px;
	}

	100% {
		top: 0px;
	}
}


/* Opera */

@-o-keyframes vibrate {
	0% {
		top: 0px;
	}

	25% {
		top: -3px;
	}

	50% {
		top: 0px;
	}

	75% {
		top: 3px;
	}

	100% {
		top: 0px;
	}
}

@media only screen and (max-width:480px) {
	button.scroltop {
		font-size: 7px;
		height: 30px;
		width: 30px;
		line-height: 16px;
	}

	button.scroltop span {
		font-size: 10px;
		line-height: 10px;
	}
}


/*======================================
23. PAGE-CONTENT
======================================*/

#bg {
	background-attachment: fixed;
	background-size: cover;
}

.page-wraper {
	background: #fff;
}

#page-boxed {
	margin-left: auto;
	margin-right: auto;
	max-width: 1760px;
	width: 100%;
}

.page-content {
	position: relative;
}

.section-full {
	position: relative;
}


/*-------------------------------------
	1. Section Head Part
-------------------------------------*/

.section-head {
	margin-bottom: 60px;
}

.section-head h1.left,
.section-head h2.left,
.section-head h3.left,
.section-head h4.left,
.section-head h5.left,
.section-head h6.left {
	width: auto;
}

.section-head h1.right,
.section-head h2.right,
.section-head h3.right,
.section-head h4.right,
.section-head h5.right,
.section-head h6.right {
	width: auto;
}

.section-head h1.center,
.section-head h2.center,
.section-head h3.center,
.section-head h4.center,
.section-head h5.center,
.section-head h6.center {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}

.section-head.no-margin {
	margin-bottom: 0;
}

.section-head h1,
.section-head h2,
.section-head h3 {
	margin-top: 0;
}

.title-small {
	display: block;
	color: #7a959d;
	margin-bottom: 15px;
}

.section-head p {
	padding-top: 20px;
}

.section-head p:last-child {
	margin-bottom: 0;
}

.text-center.section-head p {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}

.text-white {
	color: #FFF;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white .title-small {
	color: #FFF;
}


/*-------------------------------------
	2. Section Content Part
-------------------------------------*/

@media only screen and (max-width:1024px) {
	.section-full {
		background-attachment: scroll !important;
	}
}

@media only screen and (max-width:540px) {

	.section-head h1,
	.section-head h2,
	.section-head h3,
	h1,
	h2,
	h3 {
		font-size: 32px;
	}
}


/*======================================
24. AFTER-LOGIN-CONTENT
======================================*/


/*-------------------------------------
	2. Contact us page
-------------------------------------*/

ul.contact-info li {
	padding: 0 0 15px 25px;
	margin-bottom: 15px;
	border-bottom: 1px solid #e1e1e1;
	position: relative;
	line-height: 20px;
}

ul.contact-info li:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none;
}

ul.contact-info li h4 ul.contact-info li h5,
ul.contact-info li h6 {
	margin: 0 0 10px;
}

ul.contact-info li p {
	line-height: 20px;
	margin: 0;
}

ul.contact-info li .fa {
	position: absolute;
	left: 0;
	top: 2px;
	color: #3396d1;
	font-size: 16px;
}


/*======================================
25. THEMEFOREST REQUIRED CLASSES
======================================*/

.sticky {
	clear: both;
}

.gallery-caption {
	clear: both;
}

.bypostauthor {
	clear: both;
}


/*-------------------------------------
	1. Text meant only for screen readers
-------------------------------------*/

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}


/*======================================
27. BOX MAX-WIDTH css (  )
======================================*/

.max-w100 {
	max-width: 100px;
}

.max-w200 {
	max-width: 200px;
}

.max-w300 {
	max-width: 300px;
}

.max-w400 {
	max-width: 400px;
}

.max-w500 {
	max-width: 500px;
}

.max-w600 {
	max-width: 600px;
}

.max-w700 {
	max-width: 700px;
}

.max-w800 {
	max-width: 800px;
}

.max-w900 {
	max-width: 900px;
}

.max-w1000 {
	max-width: 1000px;
}


/*======================================
28. SHOP & PRODUCT DETAIL (Woocommerce)
======================================*/


/*-------------------------------------
	1. Product Box
-------------------------------------*/

.wt-product-box .price {
	margin-bottom: 10px;
}

.wt-product-box .price del {
	font-size: 16px;
	color: #999999;
	margin: 4px 4px;
	font-weight: 600;
}

.wt-product-box .price ins {
	font-size: 16px;
	color: #111111;
	text-decoration: none;
	margin: 4px 4px;
	font-weight: 600;
}

.wt-product-box .rating-bx {
	padding-top: 10px;
	color: #ffa000;
}

.comment-reply-title {
	margin-top: 0;
}

.comment-form [class*="comment-form"] {
	width: 50%;
}

@media only screen and (max-width: 767px) {
	.comment-form [class*="comment-form"] {
		width: 100%;
	}
}

.comment-form [class*="-form-author"],
.comment-form [class*="-form-email"] {
	float: left;
}

.comment-form [class*="-form-comment"] {
	width: 100%;
}

.comment-form label {
	display: block;
}

.star-Rating-input i {
	font-size: 18px;
}


/*.star-Rating-input .fa-star {	color:#0f1221;}
.star-Rating-input i:hover {	color:#0f1221;}*/

.comment-form [class*="comment-form"] input,
.comment-form [class*="comment-form"] textarea {
	border: 1px solid #CCC;
	padding: 8px 10px;
	width: 100%;
}

.comment-form {
	margin: 0px -15px;
}

.comment-form [class*="comment-form"] {
	margin-bottom: 20px;
	padding: 0px 15px;
}

.comment-form .form-submit {
	padding: 0px 15px;
}

.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
	background: #037a85;
}


/*======================================
29. content show on image box ()
======================================*/

@media only screen and (max-width:768px) {

	.wt-media>img,
	.wt-media a>img {
		width: 100%;
	}
}


/*======================================
30. Customize  Grouping css
======================================*/


/*-------------------------------------
	1.set-radius
-------------------------------------*/

.btn,
.panel,
.form-control,
.img-thumbnail,
.panel-head ing,
.dropdown-menu,
.panel-group .panel,
.nav-tabs>li>a,
.modal-content,
.navbar-toggler,
.nav-pills>li>a,
.pager li>a,
.pager li>span,
.well,
.alert,
.list-group-item:first-child,
.list-group-item:last-child,
.input-group-addon,
.btn-group-divides>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-divides>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child),
.wt-booking-form .wt-extra-services li .wt-icon-box-lg,
.wt-booking-form .wt-extra-services li.active .wt-icon-box-lg:after,
.searchform input[type="text"],
.searchform input[type="submit"],
ol.comment-list li.comment .comment-body {
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}


/*-------------------------------------
	2. transition
-------------------------------------*/

a,
.btn,
.form-control,
.overlay-bx,
.overlay-icon li a i,
.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs,
.share-social-bx li,
.indicator,
.profile-menu ul li,
.staff-member .member-done,
.how-wt-work .wt-box .shadow-bx img,
.how-wt-work .wt-box .step-no-bx,
.wt-info-has,
.wt-we-find .wt-box,
.navbar-toggler,
.logo-header,
.header-nav .nav>li>a,
.extra-nav,
.accord-head .indicator .fa {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.animate-slow {
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.animate-mid {
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	-ms-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
}

.animate-fast {
	-webkit-transition: all 0.8s ease-out;
	-moz-transition: all 0.8s ease-out;
	-o-transition: all 0.8s ease-out;
	-ms-transition: all 0.8s ease-out;
	transition: all 0.8s ease-out;
}


/*-------------------------------------
	3. float-clearfix
-------------------------------------*/

.clearfix::after,
.filter-wrap::after,
.filter-wrap::after,
.wt-icon-box-wraper::after,
ol.commentlist li::after {
	content: "";
	display: table;
	clear: both;
}


/*======================================
32. Inner pages
======================================*/


/*-------------------------------------
	2. Out Team
-------------------------------------*/


/*-------------------------------------
	4. Product block
-------------------------------------*/

.product-block p {
	margin-bottom: 0px;
}


/*======================================
33. Background Effects
======================================*/

.bg-center {
	background-position: center;
}

.bg-top-left {
	background-position: top left;
}

.bg-top-right {
	background-position: top right;
}

.bg-top-center {
	background-position: top center;
}

.bg-bottom-left {
	background-position: bottom left;
}

.bg-bottom-right {
	background-position: bottom right;
}

.bg-bottom-center {
	background-position: bottom center;
}

.bg-left-center {
	background-position: left;
}

.bg-right-center {
	background-position: left;
}

.bg-auto {
	background-size: auto;
}

.bg-contain {
	background-size: contain;
}

.bg-cover {
	background-size: cover;
}

.bg-full-width {
	background-size: 100% auto;
}

.bg-full-height {
	background-size: auto 100%;
}

.bg-repeat {
	background-repeat: repeat;
}

.bg-no-repeat {
	background-repeat: no-repeat;
}

.bg-repeat-x {
	background-repeat: repeat-x;
}

.bg-repeat-y {
	background-repeat: repeat-y;
}

.bg-fixed {
	background-attachment: fixed;
}

.bg-scroll {
	background-attachment: scroll;
}


/*======================================
34. Home Page
======================================*/

@media only screen and (max-width:991px) {
	.v-text {
		position: inherit;
		right: auto;
		bottom: auto;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

@media only screen and (max-width:991px) {
	.section-full.p-tb90 {
		padding-top: 30px;
		padding-bottom: 30px;
	}

}

@media only screen and (max-width:767px) {
	.section-full.p-tb80 {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}


/*===========================
36. Overlay Box 2
===========================*/

.overlay-bx-2 {
	position: absolute;
	left: 0;
	bottom: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: #000;
	opacity: 0;
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.overlay-bx-2:hover {
	opacity: 0.9;
	bottom: 0px;
}


/*===========================
38. latest-blog-dark
===========================*/


/*=========================*/

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav {
	position: absolute;
	margin-top: 0px;
	top: -10px;
	left: -80px;
}

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-prev {
	left: 40px;
	position: relative;
	background-color: #1a1a1a;
	margin: 0px;
}

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-next {
	left: 5px;
	position: relative;
	top: 40px;
	background-color: #1a1a1a;
	margin: 0px;
}

@media only screen and (max-width:800px) {
	.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav {
		left: auto;
		right: 5px;
		top: auto;
		bottom: -47px;
	}

	.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-prev {
		left: 0px;
	}

	.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-next {
		left: 5px;
		top: 0px;
	}
}


/*======================================
42. Contact Us
======================================*/
.home-contact-section {
	margin-left: 15px;
}


.contact-one {
	position: relative;
}

.home-contact-section .form-control::-webkit-input-placeholder,
.contact-one .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #000;
	font-size: 14px;
}

.home-contact-section .form-control::-moz-placeholder,
.contact-one .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #000;
	font-size: 14px;
}

.home-contact-section .form-control:-ms-input-placeholder,
.contact-one .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #000;
	font-size: 14px;

}

.home-contact-section .form-control:-moz-placeholder .contact-one .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #000;
	font-size: 14px;
}

.contact-right-section {
	position: relative;
	z-index: 1;
	margin-left: 40px;
	margin-bottom: 70px;
}

.contact-right-section:before {
	position: absolute;
	content: '';
	z-index: -1;
	left: -40px;
	bottom: -40px;
	width: 100%;
	height: 90%;
	border: 12px solid black;
}

@media only screen and (max-width:991px) {
	.home-contact-section {
		margin-left: 0px;
	}
}

@media only screen and (max-width:767px) {
	.contact-right-section {
		margin-bottom: 40px;
	}
}


.contact-info {
	position: relative;
	z-index: 1;
}

.contact-info .icon-content p {
	word-break: normal;
	margin-bottom: 5px;
}

.contact-info-section {
	padding: 30px;
	background-color: black;
	border: 8px solid #fff;
	color: #fff;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}


@media only screen and (max-width:576px) {
	.contact-one {
		padding: 0px;
		border-radius: 0px;
		box-shadow: none !important;
	}

	.contact-info-section h3 {
		font-size: 28px;
	}
}

/*===============================================
	Modal
=============================================== */

.button-model button {
	min-width: 200px;
	text-align: center !important;
	text-transform: uppercase
}

.modal-footer button {
	min-width: inherit;
}

.button-model button.close {
	min-width: inherit;
}

@media only screen and (max-width:640px) {
	h2.font-45 {
		font-size: 32px;
		line-height: 38px;
	}

	h2 a.font-45 {
		font-size: 32px;
		line-height: 38px;
	}
}

@media only screen and (max-width:360px) {
	h2.font-45 {
		font-size: 22px;
		line-height: 28px;
	}

	h2 a.font-45 {
		font-size: 22px;
		line-height: 28px;
	}
}


/*===============================================
	Responsive container
=============================================== */

@media only screen and (max-width:540px) {
	.is-fixed .navbar-toggler {
		margin-bottom: 0px;
	}
}


/*================ Social  css=======*/


/*====revolution slider styles=======*/
#welcome_wrapper .metis.tparrows {
	background-color: #ff5e15;
}

#welcome_wrapper .metis.tparrows::before {
	color: #fff;
}

.rev-title-left-border {
	border-left: 8px solid #ff5e15
}

@media only screen and (max-width:480px) {
	#welcome_two .tp-bgimg {
		background-position: center right -480px !important;
	}
}

.light-number {
	font-size: 120px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.5);
}

.slider-block {
	border: 16px solid #fff;
}

.slider-block-2 {
	border: 16px solid black;
}

.slider-outer {
	position: relative;
}

.slider-social-info {
	width: 40px;
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%)
}

.slider-social-info ul {
	margin-bottom: 0px;
}

.slider-social-info ul {
	position: relative;
}

.slider-social-info ul:after,
.slider-social-info ul:before {
	position: absolute;
	content: '';
	width: 1px;
	height: 50px;
	background-color: black;
	left: 20px;

	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.slider-social-info ul:before {
	top: -60px;
}



.slider-social-info ul li {
	display: block;
}

.slider-social-info ul li a {
	color: #111;
	display: block;
	text-align: center;
	height: 30px;
	line-height: 30px;
}

.slider-social-info ul li a:hover {
	color: black;
}

/*social-mobile-show*/
.social-mobile-show {
	display: none;
}

.social-mobile-show .slider-social-info {
	width: auto;
	position: absolute;
	bottom: -50px;
	left: 50%;
	transform: translate(-50%, 0);
	-webkit-transform: translate(-50%, 0);
	top: auto;
}

.social-mobile-show .slider-social-info ul li {
	display: inline-block;
}

.social-mobile-show .slider-social-info ul:after,
.social-mobile-show .slider-social-info ul:before {
	position: absolute;
	content: '';
	width: 50px;
	height: 1px;
	background-color: black;


	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.social-mobile-show .slider-social-info ul:before {
	left: -60px;
}

.social-mobile-show .slider-social-info ul:after {
	right: -60px;
	left: auto;
	top: 15px;
}

.social-mobile-show .slider-social-info ul:before {
	top: 15px;
}

.social-mobile-show .slider-social-info ul li a {
	width: 24px;
}

@media only screen and (max-width:777px) {
	.social-mobile-hide {
		display: none;
	}

	.social-mobile-show {
		display: block;
	}
}

.slider-appointment-btn {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 50px;
}

.slider-appointment-btn a {
	color: #000;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2px;

	position: absolute;
	left: 50%;
	top: 50%;
	min-width: 100vh;
	text-align: center;
	-webkit-transform: rotate(90deg) translateX(-50%) translateY(-50%);
	-ms-transform: rotate(90deg) translateX(-50%) translateY(-50%);
	transform: rotate(90deg) translateX(-50%) translateY(-50%);
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;

}


.slider-appointment-btn a span {
	position: relative;
	display: inline-block;
}

.slider-appointment-btn a span:after,
.slider-appointment-btn a span:before {
	position: absolute;
	content: '';
	width: 50px;
	height: 1px;
	background-color: black;

	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}


.slider-appointment-btn a span:before {
	top: 0px;
	left: 0px;
}

.slider-appointment-btn a span:after {
	bottom: 0px;
	right: 0px;
}

.slider-appointment-btn a:hover span:before {
	width: 100%;
}

.slider-appointment-btn a:hover span:after {
	width: 100%;
}

@media only screen and (max-width:777px) {
	.slider-appointment-btn {
		width: 100%;
	}

	.slider-appointment-btn a {
		top: auto;
		bottom: 15px;
		left: 15px;
		transform: none;
		text-align: left;
		min-width: auto;
	}
}



/*-------------------------------------
Spin circle
-------------------------------------*/

.spin-circle {
	-webkit-animation: fa-spin 25s infinite linear;
	animation: fa-spin 25s infinite linear
}

@-webkit-keyframes spin-circle {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes spin-circle {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}

	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}


/*======================================
59. animated play btn
======================================*/

.play-now .icon {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 65px;
	width: 65px;
	text-align: center;
	line-height: 65px;
	background-color: #ff5e15;
	color: #fff;
	z-index: 1;
	font-size: 20px;
	padding-left: 5px;
	display: block;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform-origin: center;
	transform-origin: center center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
	-moz-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
	-ms-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
	-o-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
	box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 65px;
	width: 65px;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform-origin: center;
	transform-origin: center center;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 0 rgb(255, 94, 21);
	-moz-box-shadow: 0 0 0 0 rgb(255, 94, 21);
	-ms-box-shadow: 0 0 0 0 rgb(255, 94, 21);
	-o-box-shadow: 0 0 0 0 rgb(255, 94, 21);
	box-shadow: 0 0 0 0 rgb(255, 94, 21);
	-webkit-animation: ripple 3s infinite;
	-moz-animation: ripple 3s infinite;
	-ms-animation: ripple 3s infinite;
	-o-animation: ripple 3s infinite;
	animation: ripple 3s infinite;
}

.play-now .ripple:before {
	-webkit-animation-delay: .9s;
	-moz-animation-delay: .9s;
	-ms-animation-delay: .9s;
	-o-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.play-now .ripple:after {
	-webkit-animation-delay: .6s;
	-moz-animation-delay: .6s;
	-ms-animation-delay: .6s;
	-o-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

@-webkit-keyframes ripple {
	70% {
		box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes ripple {
	70% {
		box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.slider-play-icon .play-now {
	right: 30%;
	left: auto;
	top: 45%;
}

@media only screen and (max-width:640px) {

	.play-now .ripple,
	.play-now .ripple:before,
	.play-now .ripple:after {
		width: 30px !important;
		height: 30px !important;
	}

	@-webkit-keyframes ripple {
		70% {
			box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
		}

		100% {
			box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
		}
	}

	@keyframes ripple {
		70% {
			box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
		}

		100% {
			box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
		}
	}
}


/*====video section====*/
.video-counter-section {
	position: relative;
	margin-bottom: 40px;
}

.video-counter2-section {
	padding-top: 40px;
}

.video-section-outer {
	position: relative;
	z-index: 1;
	margin-bottom: 70px;
}

.video-section2-outer {
	padding-top: 50px;
	padding-bottom: 70px;
}


.video-section {
	position: relative;
	z-index: 1;
	display: flex;
}

.video-icon-left span a {
	padding: 10px;
	border: 2px solid #fff;
	width: 60px;
	height: 60px;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	position: relative;
}

.video-icon-left span a i {
	font-size: 24px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.video-icon-title {
	align-content: center;
	flex-wrap: wrap;
	display: flex;
	border-left: 6px solid #fff;
	padding-left: 20px;
	margin-left: 30px;
}


.how-it-work {
	position: relative;
}

.video-btn-position {
	height: 130px;
	background-color: black;
	position: absolute;
	z-index: 1;
	right: 15px;
	bottom: 15px;
	left: 15px;
}

.video-section img {
	width: 100%;
}

.want-to-talk-top {
	padding: 80px 0px 260px;
}

.video-section-dark {
	margin-bottom: 30px;
}

.video-section-dark img {
	width: 100%;
}

@media only screen and (max-width:991px) {
	.video-section-dark {
		margin-bottom: 0px;
	}

	.video-section-outer {
		margin-bottom: 40px;
	}

}

@media only screen and (max-width:767px) {
	.video-btn-position {
		right: 15px;
		bottom: 15px;
	}

	.video-section-outer {
		margin-top: 60px;
	}

}

@media only screen and (max-width:480px) {
	.video-btn-position {
		width: 100px;
		height: 100px;
	}
}



.video-section-info {
	padding: 30px;
	position: relative;
	bottom: -70px;
	margin-top: -70px;
}

.video-section-blockquote {
	border-left: 4px solid #ff5e15;
	position: relative;
	padding-left: 80px;
}

.video-section-blockquote .fa-quote-left {
	position: absolute;
	left: 10px;
	top: 0px;
	color: rgba(255, 255, 255, 0.2);
	font-size: 60px;
}

.video-section-blockquote .q-author-detail {
	font-size: 30px;
	line-height: normal;
}

.video-section-blockquote .q-author-name {
	font-size: 24px;
}

.video-section-blockquote2 {
	border-left: 4px solid #ff5e15;
	position: relative;
	padding-left: 80px;
}

.video-section-blockquote2 .fa-quote-left {
	position: absolute;
	left: 10px;
	top: 0px;
	color: #ff5e15;
	font-size: 60px;
}

.video-section-blockquote2 .q-author-detail {
	font-size: 30px;
	line-height: normal;
}

.video-section-blockquote2 .q-author-name {
	font-size: 24px;
}


@media only screen and (max-width:991px) {
	.video-section-info {
		padding: 30px;
		position: relative;
		bottom: 0px;
		margin-top: 0px;
	}

	.counter-section-outer-bottom {
		padding-bottom: 30px;
	}

	.video-counter-section {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width:540px) {
	.video-section-blockquote .q-author-detail {
		font-size: 26px;
	}

	.video-section-blockquote {
		padding-left: 20px;
	}

	.video-section-blockquote .fa-quote-left {
		position: relative;
		left: auto;
		top: auto;
		display: block;
	}
}


.video3-section-blockquote {
	border-left: 4px solid #ff5e15;
	position: relative;
	padding-left: 80px;
}

.video3-section-blockquote .fa-quote-left {
	position: absolute;
	left: 10px;
	top: 0px;
	color: #ff5e15;
	font-size: 60px;
	opacity: 0.3;
}

.video3-section-blockquote .q-author-detail {
	font-size: 30px;
	line-height: 30px;
}

.video3-section-blockquote .q-author-name {
	font-size: 24px;
}


.video2-section-info {
	padding: 54px 30px;
	position: relative;
	bottom: -70px;
	margin-top: -70px;
}

/*====Button Effect====*/

.site-btn-effect {
	color: rgba(0, 0, 0, 0) !important;
	transition: all .3s ease !important;
}

.site-btn-effect {
	text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
}

.site-btn-effect:hover {
	text-shadow: -400px 0 0 #fff, 0 0 0 #fff;
}


/*====Filter option====*/

.filter-options {
	position: relative;
}

.filter-option-more {
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}



.filter-option-title i.fa {
	transition: .2s;
	transform: rotate(0deg);
	padding-left: 10px;
}

.filter-options.active .filter-option-more {
	display: block;
}

.filter-options.active .filter-option-title i.fa {
	transform: rotate(180deg);
	padding-left: 0px;
	padding-right: 10px;
}


/*-----------effect-link--------*/

.site-button-link {
	position: relative;
}

.site-button-link:hover {
	color: #C71C22;
}

.site-button-link:before {
	position: absolute;
	content: "\f178";
	font-family: 'FontAwesome';
	left: 50%;
	top: 0px;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
	opacity: 0;
}

.site-button-link:hover:before {
	left: 110%;
	opacity: 1;
}


/*===================
	Banner 1
===================*/

.banner-image-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #0f1221;
}

.banner-wrap {
	position: relative;
}

.banner-image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	z-index: 1;
}

.banner-content-area {
	padding: 250px 0 200px;
}

.banner-wrap .banner-textrow {
	color: #fff;
	text-align: center;
	padding: 0 0 50px;
}

.banner-wrap .banner-textrow strong {
	font-size: 50px;
	color: #fff;
	display: block;
	font-weight: 600;
	padding-bottom: 15px;
}

.banner-wrap .banner-textrow span {
	font-size: 20px;
	margin-bottom: 20px;
	display: block;
}

@media only screen and (max-width:768px) {
	.banner-wrap .banner-textrow strong {
		font-size: 22px;
	}

	.banner-wrap .banner-textrow span {
		font-size: 16px;
	}
}

@media only screen and (max-width:768px) {
	.banner-content-area {
		padding: 130px 0px 60px;
	}

	.banner-wrap .banner-textrow {
		padding: 0 0 0px;
	}
}



.shadow-line {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 5px;
	-webkit-border-radius: 5px;
	padding: 10px;
}



.map-container-home-2 {
	position: relative;
}

.map-container-home-2 #map-container {
	height: 600px;
}

.map-apsolute-search .site-button-secondry {
	width: 100%;
}

.map-apsolute-search {
	position: relative;
	bottom: 130px;
	z-index: 991;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: rgb(15, 18, 33);
	padding: 10px;
	border-radius: 6px;
	width: 100%;
	margin-bottom: -65px;
}



.map-apsolute-search .form-group {
	margin-bottom: 0px;
}

@media only screen and (max-width:991px) {
	.map-apsolute-search {
		position: inherit;
		left: inherit;
		bottom: auto;
		transform: none;
		margin-top: 80px;
		margin-bottom: 0px;
	}

	.map-apsolute-search .form-group {
		margin-bottom: 10px;
	}

	.map-apsolute-search [class*='col-']:last-child .form-group {
		margin-bottom: 0px;
	}

	.map-apsolute-search [class*='col-']:nth-child(3) .form-group {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width:767px) {
	.map-apsolute-search [class*='col-']:nth-child(3) .form-group {
		margin-bottom: 10px;
	}
}

.main-categories-box-outer {
	display: block;
}

.main-categories-box .main-categories-icon {
	display: block;
	border-radius: 5px;
	border-radius: 5px;
	overflow: hidden;
}

.main-categories-box .main-categories-icon img {
	height: 45px;
	width: auto;
	display: inline;
}

.main-categories-slider-outer {
	position: relative;
	margin-bottom: 60px;
}

.main-categories-slider {
	max-width: 720px;
	margin: auto;
	padding: 20px 25px;
	width: 100%;
	text-align: center;
	background-color: #fff;
	position: absolute;
	z-index: 1;
	left: 50%;
	bottom: -60px;
	box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.2);

	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);

	-webkit-border-radius: 6px;
	border-radius: 6px;
}

.main-categories-slider ul {
	padding: 0px;
	margin: 0px;
}

.main-categories-slider ul li {
	display: inline-block;
}

.main-categories-slider .owl-prev {
	left: -20px !important;
}

.main-categories-slider .owl-next {
	right: -20px !important;
}



.main-categories-slider .owl-prev,
.main-categories-slider .owl-next {
	width: 30px;
	height: 30px;
	border: none;
	line-height: 28px;
}

.main-categories-box-outer span {
	display: block;
	margin-top: 5px;
}

@media only screen and (max-width:991px) {
	.main-categories-slider-outer {
		margin-top: 80px;
		margin-bottom: 0px;
	}

	.main-categories-slider {
		position: inherit;
		z-index: 1;
		left: inherit;
		bottom: inherit;
		transform: none;

	}
}

@media only screen and (max-width:767px) {
	.main-categories-slider-outer {
		margin-top: 30px;
	}

	.map-apsolute-search {
		margin: 30px auto 0px;
	}
}




.slider-serach-bar {
	padding-top: 80px
}

.slider-apsolute-search .site-button-secondry {
	width: 100%;
}

.slider-apsolute-search {
	background-color: rgb(15, 18, 33);
	padding: 10px;
	border-radius: 6px;
}



.slider-apsolute-search .form-group {
	margin-bottom: 0px;
}


@media only screen and (max-width:991px) {
	.slider-apsolute-search {
		position: inherit;
		left: inherit;
		bottom: auto;
		transform: none;
		margin-bottom: 0px;
	}

	.slider-apsolute-search .form-group {
		margin-bottom: 10px;
	}

	.slider-apsolute-search [class*='col-']:last-child .form-group {
		margin-bottom: 0px;
	}

	.slider-apsolute-search [class*='col-']:nth-child(3) .form-group {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width:767px) {
	.slider-apsolute-search [class*='col-']:nth-child(3) .form-group {
		margin-bottom: 10px;
	}

	.slider-serach-bar {
		padding-top: 0px;
	}

	.slider-apsolute-search {
		margin: 30px auto 0px;
	}
}

/*select box*/

.dropdown-menu {
	font-size: 13px;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
	width: 100%;
}

.bootstrap-select .dropdown-toggle {
	background-color: #fff;
	height: 50px;
	font-size: 13px;
	color: #999;

}

@media only screen and (max-width:991px) {
	.bootstrap-select .dropdown-toggle {
		height: 40px;
	}
}



.selectpicker+.dropdown-toggle {
	position: relative;
}

.selectpicker+.dropdown-toggle:after {
	display: inline-block;
	position: absolute;
	top: 18px;
	right: 8px;
	width: 0px;
	height: 0px;
	content: "";
	border-top: 6px solid #b6b6b6;
	border-right: 6px solid transparent;
	border-bottom: 0;
	border-left: 6px solid transparent;
}





.bootstrap-select .dropdown-toggle:active,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:hover {
	background-color: #fff !important;
	border: 1px solid #e1e6eb !important;
	box-shadow: none !important;
	outline: 0px !important;
}

.bootstrap-select:active,
.bootstrap-select:focus {
	border: 0px !important;
	box-shadow: none !important;
	outline: 0px !important;
}

.bootstrap-select.open {
	box-shadow: none !important;
	outline: 0px !important;
}

.bootstrap-select.open .dropdown-toggle {
	box-shadow: none !important;
	outline: 0px !important;
	background: #fff;
	border: 1px solid #e1e6eb;
}

.bootstrap-select div.dropdown-menu {
	border-radius: 5px !important;
	-webkit-border-radius: 5px !important;
	border: 1px solid #e9e9e9;
	border-radius: 0;
	box-shadow: none;
	margin-top: -1px;
	padding: 0;
	font-size: 13px;
	-webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
}


.bootstrap-select div.dropdown-menu ul li {
	padding: 0;
}

.bootstrap-select div.dropdown-menu ul li a {
	padding: 10px 15px;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
	background-color: black;
}



.bootstrap-select div.dropdown-menu ul li a:hover {
	background-color: #ededed;
}

.bootstrap-select div.dropdown-menu ul li a img {
	border-radius: 100%;
	-webkit-border-radius: 100%;
	margin-right: 5px;
}

.bootstrap-select .bs-searchbox .form-control {
	padding: 5px 10px;
	height: 30px;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
	width: 12px;
	height: 12px;
	border: none;
	margin-top: -6px;
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}

.bootstrap-select.btn-group.open .dropdown-toggle .caret {
	-moz-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.bootstrap-select.btn-group .dropdown-toggle .caret:after {
	content: "\f107";
	font-family: "FontAwesome";
	position: absolute;
	top: 0;
	right: 0;
	font-size: 18px;
	height: 12px;
	line-height: 12px;
}


/*======================
 City
======================= */

.featured-cat-box {
	height: 300px;
	position: relative;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	border-radius: 5px;
	-webkit-border-radius: 5px;

}

.cat-large {
	height: 630px;
}

.cat-large:after,
.cat-large:before {
	clear: both;
	content: '';
	position: absolute;
}

@media only screen and (max-width:991px) {
	.cat-large {
		height: 300px;
	}
}


.featured-cat-text {
	position: absolute;
	color: #fff;
	padding: 0px 30px 30px;
	z-index: 1;
	bottom: 0px;
	width: 100%;
}

.featured-cat-text .f-cat-icon {
	font-size: 50px;
	line-height: 56px;
	text-align: left;
	display: block;
}

.featured-cat-name-outer {
	padding-top: 20px;
}

.featured-cat-name {
	color: #fff;
}

.featured-cat-user i {
	margin-right: 5px;
}

.featured-cat-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: red;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#bd000000', GradientType=0);
	/* IE6-9 */
	/* IE6-9 */
}

.featured-cat-linking {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
}

.featured_cat_box2_outer {
	list-style: none
}

.featured-cat-box2 {
	height: 200px;
	position: relative;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	display: table;
	width: 100%;
}

.featured-cat-box2,
.featured-cat-name2,
.featured-cat-text2 i,
.featured-cat-text2 i.fade-icon-right,
.featured-cat-overlay2 {
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}

.featured-cat-text2 {
	display: table-cell;
	padding: 20px 30px;
	z-index: 1;
	text-align: center;
	vertical-align: middle;
	position: relative;
}

.featured-cat-name2 {
	display: block;
}

.featured-cat-text2 i {
	font-size: 60px;
	line-height: 60px;
	color: #0f1221;
}

.featured-cat-text2 i.fade-icon-right {
	font-size: 120px;
	color: rgba(241, 241, 241, 0.5);
	position: absolute;
	right: -10%;
	top: 5%;
}

.featured-cat-overlay2 {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 255);
}

.featured-cat-box2:hover .featured-cat-overlay2 {
	background-color: rgba(0, 0, 0, 0.5);
}

.featured-cat-box2:hover .featured-cat-name2,
.featured-cat-box2:hover i,
.featured-cat-box2:hover p {
	color: #fff;
}

.featured-cat-box2:hover i.fade-icon-right {
	color: rgba(255, 255, 255, 0.2);
}

.featured-cat-box2 .featured-cat-user {
	margin: 0px;
	margin-top: 10px;
	padding: 2px 40px;
	display: inline-block;
	border-radius: 40px;
	-webkit-border-radius: 40px;
}


/*highlit -lable*/
.color-lebel {
	display: inline-block;
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	padding: 2px 10px;
}

.clr-orange {
	background-color: black;
}

.clr-pink {
	background-color: #fd4a60;
}

.clr-green {
	background-color: #38cf64;
}

.clr-blue {
	background-color: #397eff;
}

.clr-cyan {
	background-color: #00abad;
}

.clr-voilet {
	background-color: #9102ff;
}

.clr-red {
	background-color: #ff0000;
}

/*======================
 Visited place
======================= */

.listing-cat-box {
	position: relative;
}

@media only screen and (max-width:767px) {
	.p-b80 {
		padding-bottom: 30px;
	}
}

.listing-cat-text {
	color: #000;
	padding: 30px;
	background-color: #fff;
	border: 1px solid#d8d8d8;
	border-radius: 6px;
	-webkit-border-radius: 8px;
	margin-top: -8px;
	position: relative;
}

.listing-category-name {
	position: absolute;
	left: 30px;
	top: 30px;
}

.listing-category-name a {
	color: #fff;
}


.listing-place-name {
	margin-top: 15px;
}

.listing-place-name a {
	color: #0f1221;
}

.listing-cat-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#8f000000', GradientType=0);
	/* IE6-9 */
}

.visited-cat-linking {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
}

.wt-rating-section.float-left {
	float: left;
}

.wt-rating-section.float-none {
	float: none;
}

.wt-listing-view-section {
	position: absolute;
	right: 30px;
	top: 30px;
}

.wt-listing-view-section ul {
	list-style: none;
	margin: 0px;
}

.wt-listing-view-section ul li {
	display: inline-block;
	position: relative;
	z-index: 1;
}

.wt-listing-view-section ul li a {
	color: #000;
	display: block;
	text-align: center;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	font-size: 18px;
	background-color: #fff;
}

.listing-cat-preview {
	position: relative;
}

.listing-cat-preview.active .fa-heart-o:before {
	content: "\f004" !important;
}

.listing-cat-preview .preview-tooltip {
	padding: 0px 15px;
	position: absolute;
	right: 150%;
	top: 0px;
	background-color: #0f1221;
	color: #fff;
	display: none;
	width: auto;
	text-align: right;

	font-size: 12px;
	line-height: 20px;
	border-radius: 3px;
	white-space: nowrap;
}

.listing-cat-preview:hover .preview-tooltip {
	display: block;
}

.listing-cat-preview .preview-tooltip:before {
	right: -12px;
	bottom: -6px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
}

.listing-cat-preview .preview-tooltip:before {
	border-left-color: #0f1221;
	border-width: 6px;
	margin-bottom: 10px;
}

.wt-listing-view-section ul li:last-child {
	margin-right: 0px;
}

.wt-listing-view-section ul li:hover {
	background: none;
}

.listing-cat-address {
	display: block;
	font-weight: 600;
}

.listing-cat-address i {
	margin-right: 10px;
}

.wt-rating-section .wt-rating,
.wt-rating-section .wt-rating-conting {
	display: inline-block;
}

.wt-rating-section .wt-rating {
	display: inline-block;
	color: #F90;
}

.corner-radius {
	border-radius: 5px;
	-webkit-border-radius: 5px;
}


.listing-cat-preview.active .fill-add-to-fav {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}



.listing-logo-outer {
	margin-bottom: 20px;
}

.listing-place-logo {
	width: 80px;
	height: 80px;
	padding: 5px;
	background-color: #fff;
	-webkit-box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.3);
	box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.3);
	-webkit-border-radius: 50%;
	border-radius: 50%;
	margin-top: -70px;
}

.listing-place-logo img {
	width: 100%;
	display: block;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.listing-place-timing {
	margin-top: -42px;
}




/*====step icon box====*/

.cities-slide-box {
	height: 350px;
	position: relative;
	overflow: hidden;
	background-size: cover;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}

.cities-slide-text {
	position: absolute;
	bottom: 0px;
	color: #fff;
	padding: 0px 30px 30px;
	z-index: 1;
	text-align: center;
	width: 100%;
}

.cities-slide-user {
	position: absolute;
	left: 30px;
	top: 30px;
}

.cities-location-sign {
	font-size: 28px;
	line-height: 28px;
}

.cities-slide-name {
	position: relative;
	margin: 0px;
	padding: 15px 10px 0px 10px;
}

.cities-slide-name:after,
.cities-slide-name:before {
	position: absolute;
	content: '';
	width: 25px;
	height: 25px;
	bottom: 0px;
	background: transparent;
}

.cities-slide-name:before {
	border-left: 1px solid #fff;
	border-bottom: 1px solid #fff;
	left: 0px;
}

.cities-slide-name:after {
	border-right: 1px solid #fff;
	border-bottom: 1px solid #fff;
	right: 0px;
}


.cities-slide-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: red;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#bd000000', GradientType=0);
	/* IE6-9 */
	/* IE6-9 */
}

.cities-slide-linking {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
}

.city-home-2 .cities-slide-box {
	height: 250px;
}


/*rating*/

.client-rating {
	display: block;
	color: #F90;
}


/*News Letter Section*/
.newsletter-title {
	padding-right: 100px;
}

.newsletter-title span {
	font-size: 16px;
}

.newsletter-input {
	position: relative;
	z-index: 1;
}

.newsletter-input .input-group-text {
	font-size: 20px;
	text-transform: uppercase;
	color: #fff;
}



.newsletter-input input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #484848;
	font-size: 14px;
}

.newsletter-input input::-moz-placeholder {
	/* Firefox 19+ */
	color: #484848;
	font-size: 14px;
	text-transform: uppercase;
}

.newsletter-input input:-ms-input-placeholder {
	/* IE 10+ */
	color: #484848;
	font-size: 14px;
	text-transform: uppercase;
}

.newsletter-input input:-moz-placeholder {
	/* Firefox 18- */
	color: #484848;
	font-size: 14px;
	text-transform: uppercase;
}

.nl-search-btn {
	border: none;
	outline: none;
	border-radius: 0px;
	padding: 0px 30px;
}

.nl-search-btn:hover {
	background-color: black;
	color: #fff;
}

@media only screen and (max-width:991px) {
	.newsletter-title span {
		margin-bottom: 20px;
		display: block;
	}
}

/*Error-search*/

.error-input {
	width: 100%;
	margin: 30px auto;
}

.error-input .form-control {
	background-color: #efefef;
}

.error-input span:first-child {
	padding: 0px;
}

.error-input span:last-child {
	overflow: hidden;
	padding: 0px;
}

.error-input .input-group-addon {
	border: 0px;
	border-radius: 0px;
}

.error-input input {
	border: 0px;
}

.error-search-btn {
	background: #111111;
	border: none;
	color: #fff;
	height: 50px;
	padding: 0px 20px;
}


/*======map animation====*/
.mobile-map-animation-padding {
	padding-top: 130px;
}

.map-animation-section {
	position: relative;
	z-index: 2;
}

.map-animation-left {
	width: 100%;
	position: relative;
}

.map-animation-block {
	position: relative;
}

.map-marker-block {
	position: absolute;
	width: 100%;
	max-width: 900px;
	margin: 0px auto;
	left: 50%;
	top: 40px;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
}

.map-marker-position {
	position: absolute;
}

.map-marker {
	position: relative;
}

.user-hand img {
	position: absolute;
	left: 50%;
	margin-left: -58px;
	bottom: -120px;
	width: 116px;

	transform-origin: bottom center;
	-webkit-transform-origin: bottom center;
	-webkit-animation: moveIt 3.5s ease-in-out forwards infinite;
	animation: moveIt 3.5s ease-in-out forwards infinite;
}

@media only screen and (max-width:768px) {
	.user-hand img {
		bottom: -70px;
		width: 100px;
		margin-left: -50px;
	}
}




/*==========Moveing Animation=========*/

@keyframes moveIt {

	0%,
	100% {
		transform: rotate(5deg);
	}

	50% {
		transform: rotate(-5deg);
	}
}


/*==========Moveing Animation END=========*/


.map-animation-right {
	width: 40%;
	margin-left: 15px;
	float: right;
}

.map-marker-block .map-bg {
	width: 100%
}



.scale-75 {
	-ms-transform: scale(0.7);
	/* IE 9 */
	-webkit-transform: scale(0.7);
	/* Safari */
	transform: scale(0.7);
	/* Standard syntax */
}

.scale-50 {
	-ms-transform: scale(0.5);
	/* IE 9 */
	-webkit-transform: scale(0.5);
	/* Safari */
	transform: scale(0.5);
	/* Standard syntax */
}

.position-1 {
	z-index: 10;
	left: 50%;
	top: 15%;
}

.position-2 {
	z-index: 9;
	left: 25%;
	top: 30%;
}

.position-3 {
	z-index: 8;
	right: 25%;
	top: 30%;
}

.position-4 {
	z-index: 7;
	left: 40%;
	top: 6%;
}

.position-5 {
	z-index: 6;
	left: 10%;
	top: 20%;
}

.position-6 {
	z-index: 5;
	right: 40%;
	top: -5%;
}

.position-7 {
	z-index: 4;
	right: 20%;
	top: -10%;
}


@media only screen and (max-width:991px) {

	.position-4,
	.position-6 {
		top: 8%;
	}

	.position-5,
	.position-7 {
		top: 10%;
	}
}



/*Ipad Animation 2*/
.map-animation-ipad .map-marker-block {
	position: relative;
	margin: 0px auto;
	z-index: 1;
	top: 0px;
}

.map-animation-ipad .map-animation-right {
	width: 100%;
	float: none;
	margin-bottom: 50px;
	text-align: center;
}

.map-animation-ipad .map-animation-right .wt-icon-box-wraper {
	display: inline-block;
}

.map-animation-ipad .map-animation-left {
	width: 100%;
	float: none;
	text-align: center;
}

@media only screen and (max-width: 767px) {
	.map-animation-ipad .map-animation-left {
		padding-bottom: 0px;
	}
}


.map-animation-ipad .position-1 {
	z-index: 10;
	left: 50%;
	top: 35%;
}

.map-animation-ipad .position-2 {
	z-index: 9;
	left: 42%;
	top: 66%;
}

.map-animation-ipad .position-3 {
	z-index: 8;
	right: 25%;
	top: 32%;
}

.map-animation-ipad .position-4 {
	z-index: 7;
	left: 35%;
	top: 38%;
}

.map-animation-ipad .position-5 {
	z-index: 6;
	left: 15%;
	top: 44%;
}

.map-animation-ipad .position-6 {
	z-index: 5;
	right: 55%;
	top: 15%;
}

.map-animation-ipad .position-7 {
	z-index: 4;
	right: 34%;
	top: 15%;
}


@media only screen and (max-width:600px) {
	.map-animation-ipad .map-animation-right {
		text-align: left;
	}
}

/*=========up down Animation=========*/

.vert-move1 {
	-webkit-animation: mover 1s infinite alternate;
	animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-10px);
	}
}

@keyframes mover {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-10px);
	}
}

.vert-move2 {
	-webkit-animation: mover2 1s infinite alternate;
	animation: mover2 1s infinite alternate;
}

@-webkit-keyframes mover2 {
	0% {
		transform: translateY(-10px);
	}

	100% {
		transform: translateY(0);
	}
}

@keyframes mover2 {
	0% {
		transform: translateY(-10px);
	}

	100% {
		transform: translateY(0);
	}
}

/*======== page-notfound =======*/

.page-notfound {
	padding: 50px;
	background-color: #fff;
}

.page-notfound strong {
	font-size: 150px;
	line-height: 150px;
	font-weight: 900;
	font-family: 'Roboto', sans-serif;
}

.page-notfound h4 {
	font-size: 50px;
	color: #111111;
}

.error-403 .row {
	align-items: center;
}

.page-notfound.error-405 {
	background-color: transparent;
}

@media only screen and (max-width:640px) {
	.page-notfound strong {
		font-size: 80px;
		line-height: 80px;
	}

	.page-notfound h4 {
		font-size: 40px;
	}

	.page-notfound {
		padding: 20px;
		background-color: #fff;
	}
}


/*-----Error Full Page-----*/
.error-full-page {
	display: table;
	width: 100%;
	height: 100vh;
}

.error-full-page-inner {
	display: table-cell;
	width: 100%;
	text-align: center;
	padding: 50px 50px;
	max-width: 700px;
	margin: auto;
	vertical-align: middle;
	position: relative;
}

.error-full-page-inner-info {
	background-color: #fff;
	padding: 50px;
	max-width: 600px;
	margin: 0px auto;
}

.error-full-page-inner-info strong {
	font-size: 120px;
	line-height: 150px;
	font-weight: 900;
	color: #111111;
	font-family: 'Roboto', sans-serif;
}

.error-full-page-inner-info h4 {
	font-size: 40px;
	color: #111111;
	display: flex;
	justify-content: center;
}


@media only screen and (max-width:991px) {
	.error-full-page-inner-info h4 {
		font-size: 36px;
	}
}

@media only screen and (max-width:640px) {

	.e-left-img,
	.e-right-img {
		display: none;
	}

	.error-full-page-inner-info strong {
		font-size: 90px;
		line-height: 90px;

	}
}

/*radio & checkbox*/

.wt-filter-features-wrap {
	margin-top: 0px;
}

.wt-filter-features-wrap {
	margin-top: 0px;
}

.wt-radio-checkbox {
	margin-top: 0px;
	margin-bottom: 20px;
}

.wt-radio-checkbox.radio-inline,
.wt-radio-checkbox.checkbox-inline {
	padding-left: 0 !important;
}

.wt-radio-checkbox label {
	font-weight: normal !important;
}

.wt-radio-checkbox input[type=checkbox],
.wt-radio-checkbox input[type=radio] {
	display: none;
	opacity: 0;
	margin-left: 1px !important;
}

.wt-radio-checkbox input[type=checkbox]+label,
.wt-radio-checkbox input[type=radio]+label {
	display: block;
	padding-left: 35px !important;
	min-height: 24px;
	padding: 0px 0px;
	position: relative;
	cursor: pointer;
	font-size: 14px;
}

.wt-radio-checkbox input[type=radio]+label:hover:before {
	border-color: #fff;
	background: #3396d1;
	box-shadow: 0 0 1px 2px #3396d1;
	-webkit-box-shadow: 0 0 1px 2px #3396d1;
}

.wt-radio-checkbox input[type=checkbox]+label:hover:before {
	border-color: black;
}

.wt-radio-checkbox input[type=radio]+label:before {
	background-color: #fff;
	border: 5px solid #fff;
	content: "";
	display: inline-block;
	height: 24px;
	line-height: 1;
	margin-left: -33px;
	position: absolute;
	top: 2px;
	transition: all linear 0.1s;
	width: 24px;
	border-radius: 2px;
	-webkit-border-radius: 2px;
	box-shadow: 0 0 1px 2px #CCCCCC;
	-webkit-box-shadow: 0 0 1px 2px #CCCCCC;
}

.wt-radio-checkbox input[type=checkbox]+label:before {
	background-color: #fff;
	border: 2px solid #CCCCCC;
	content: "";
	display: inline-block;
	line-height: 1;
	margin-left: -35px;
	position: absolute;
	top: 0px;
	transition: all linear 0.1s;
	width: 20px;
	height: 20px;
	border-radius: 0px;
	-webkit-border-radius: 0px;
}

.wt-radio-checkbox input[type=checkbox]+label:after {
	color: black;
	content: "\f00c";
	font-family: FontAwesome;
	font-size: 0px;
	-webkit-font-smoothing: antialiased;
	left: 6px;
	position: absolute;
	top: 7px;
	transition: all linear 0.1s;
}

.wt-radio-checkbox input[type=checkbox]:checked+label:before {
	border-width: 2px;
	border-color: black;
}

.wt-radio-checkbox input[type=checkbox]:checked+label:after {
	font-size: 12px;
	left: 5px;
	top: 2px;
}

.wt-radio-checkbox input[type=radio]+label:before {
	border-radius: 50%;
	content: "";
}

.wt-radio-checkbox input[type=radio]:checked+label:before {
	border-color: #fff;
	border-width: 5px;
	background: #3396d1;
	box-shadow: 0 0 1px 2px #f11749;
	-webkit-box-shadow: 0 0 1px 2px #3396d1;
}


/*===================
	INNER PAGE BANNER
====================*/


/*review form*/

.wt-input-icon .input-group-addon {
	position: absolute;
	left: 18px;
	top: 12px;
	z-index: 4;
	border: none;
	padding: 0;
	-webkit-border-radius: 0px;
	border-radius: 6px;
	background: none;
}

.wt-input-icon .input-group {
	position: relative;
}

.wt-input-icon .form-control {
	-webkit-border-radius: 6px !important;
	border-radius: 6px !important;
	padding-left: 40px !important;
	border: 1px solid #e1e6eb !important;
}

.wt-input-icon .star-Rating-input {
	font-size: 20px;
	display: inline-block;
}

.comment-form-rating label {
	display: inline-block;
}

.comment-form-rating .star-Rating-input {
	display: inline-block;
	color: #ffc100 !important;
	font-size: 14px;
	margin-right: 10px;
}

.wt-input-icon .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #707070;
	font-size: 12px;
}

.wt-input-icon .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #707070;
	font-size: 12px;
	text-transform: uppercase;
}

.wt-input-icon .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #707070;
	font-size: 12px;
	text-transform: uppercase;
}

.wt-input-icon .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #707070;
	font-size: 12px;
	text-transform: uppercase;
}


/*review overview*/

.review-overview {
	padding: 0px 0px 30px 0px;
}

.review-rate-box {
	margin-bottom: 20px;
	text-align: center;
	border-radius: 0;
	border-bottom: 1px solid #e8e8e8;
	padding-bottom: 10px;
}

.rating-rate-box-total {
	font-size: 62px;
	font-weight: 700;
	line-height: 1em;
	display: block;
	color: #0f1221;
}

.rating-rate-box-percent {
	font-size: 15px;
	margin-bottom: 0;
	color: #888;
	display: block;
}

.review-rate-box .star-Rating-input {
	display: inline-block;
	color: #ffc100 !important;
	font-size: 14px;
	margin-right: 10px;
}

.rating-bars {
	overflow: hidden;
}

.rate-bar-category {
	margin-bottom: 10px;
}

.rate-bars-name {
	font-weight: 600;
	color: #333;
	display: block;
	font-size: 14px;
	line-height: 22px;
	position: relative
}

.rate-bars-line .wt-probar-2 {
	height: 10px;
}

.rate-bars-name .rate-count {
	display: inline-block;
	margin-bottom: 5px;
	font-size: 12px;
	font-weight: 600;
	position: absolute;
	right: 0px;
	top: 0px;
}

@media only screen and (max-width:540px) {
	.review-rate-box {
		float: none;
		width: 100%;
		margin: 0px 0px 30px 0px;
		padding: 0px;
	}
}


.add-review-overview {
	margin-bottom: 30px;
	padding: 30px 30px 20px 30px;
	background-color: #fff9f7;
}

.add-rate-bar-category {
	margin-bottom: 10px;
	display: inline-block;
	width: 49%;
	padding-right: 2%;
}

.add-rate-bars-name {
	font-weight: 600;
	color: #000;
	display: block;
	font-size: 16px;
	line-height: 22px;
	position: relative;
}

.add-star-Rating-input {
	display: inline-block;
	color: #ffc100 !important;
	font-size: 14px;
	margin-right: 10px;
}

@media only screen and (max-width:540px) {
	.add-rate-bar-category {
		display: block;
		margin-right: 0px;
	}
}

.site-button-small {
	color: #000;
	display: inline-block;
	white-space: nowrap;
	line-height: 20px;
	padding: 3px 15px;
	font-weight: 600;
	border: none;
	font-size: 14px;
	margin: 0 5px 5px 0px;
}


.site-button-small:hover {
	opacity: 0.8;
}

button {
	outline: none !important;
}


/*==========touchspin input type number===============*/

.bootstrap-touchspin .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
	display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding: 10px 10px 9px;
	margin-left: -1px;
	position: relative;
	border-width: 1px;
	border-style: solid;
	border-color: #e1e1e1;
	background: #FFF;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0;
	border-top-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	margin-top: -2px;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-bottom-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i {
	position: absolute;
	top: 4px;
	left: 5px;
	font-size: 9px;
	font-weight: normal;
	color: #9fa0a1;
}

/*verticle icon animation*/

.v-icon {
	display: block;
	text-align: center;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.v-icon:before {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	display: block;
}


/* Effect 5 */

.v-icon-effect:hover .v-icon:before {
	-webkit-animation: toTopFromBottom 0.3s forwards;
	-moz-animation: toTopFromBottom 0.3s forwards;
	animation: toTopFromBottom 0.3s forwards;
}

.v-icon-effect:hover img.v-icon {
	-webkit-animation: toTopFromBottom 0.3s forwards;
	-moz-animation: toTopFromBottom 0.3s forwards;
	animation: toTopFromBottom 0.3s forwards;
}

@-webkit-keyframes toTopFromBottom {
	49% {
		-webkit-transform: translateY(-100%);
	}

	50% {
		opacity: 0;
		-webkit-transform: translateY(100%);
	}

	51% {
		opacity: 1;
	}
}

@-moz-keyframes toTopFromBottom {
	49% {
		-moz-transform: translateY(-100%);
	}

	50% {
		opacity: 0;
		-moz-transform: translateY(100%);
	}

	51% {
		opacity: 1;
	}
}

@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}

	50% {
		opacity: 0;
		transform: translateY(100%);
	}

	51% {
		opacity: 1;
	}
}

.btn-quantity .form-control {
	height: 40px;
	border: 1px solid #ddd;
}

.filter-destination-slider .slider .tooltip {
	z-index: 1;
}




/*wave Animation*/

canvas {
	display: block;
}


/*load More*/

#myList li {
	display: none;
}


/*circle-animation Start*/

.ripple-background {
	position: relative;
	left: 50%;
	bottom: -25px;
	opacity: 0.2;
}

.pricingtable-inner:hover .ripple-background {
	opacity: 0.4;
}

.ls-circle {
	position: absolute;
	border-radius: 50%;
	background: white;
	animation: ripple-1 5s infinite;
	box-shadow: 0px 0px 1px 0px #508fb9;
}

.ls-small {
	width: 80px;
	height: 80px;
	left: -40px;
	top: -40px;
}

.ls-medium {
	width: 160px;
	height: 160px;
	left: -80px;
	top: -80px;
}

.ls-large {
	width: 240px;
	height: 240px;
	left: -120px;
	top: -120px;
}

.ls-xlarge {
	width: 320px;
	height: 320px;
	left: -160px;
	top: -160px;
}

.ls-xxlarge {
	width: 400px;
	height: 400px;
	left: -200px;
	top: -200px;
}

.ls-shade1 {
	opacity: 0.1;
}

.ls-shade2 {
	opacity: 0.2;
}

.ls-shade3 {
	opacity: 0.3;
}

.ls-shade4 {
	opacity: 0.4;
}

.ls-shade5 {
	opacity: 0.5;
}

@keyframes ripple-1 {
	0% {
		transform: scale(0.8);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(0.8);
	}
}


/*circle-animation End*/


/*====icon font====*/

h4.headline {
	font-size: 22px;
	line-height: 32px;
	margin: -5px 0 30px;
}

.im-icon-outer {
	font-size: 14px;
	width: 33%;
	display: inline-block;
	transition: .3s;
	text-align: center;
	border: 1px solid #ddd;
	margin-bottom: 5px;
	background-color: #fff5f2;
}

.im-icon-outer span {
	display: block;
}

.im-icon-outer span:first-child {
	font-size: 34px;
}

.im-icon-outer .mls {
	font-family: 'Poppins', sans-serif;
}

.sl-icons-demo i {
	font-size: 32px;
	padding-right: 10px;
}

.fontawesome-icons .text-muted {
	display: none;
}

@media only screen and (max-width:991px) {

	.im-icon-outer {
		width: 49%;
	}
}

@media only screen and (max-width:767px) {
	.im-icon-outer {
		width: 49%;
	}

	h4.headline {
		font-size: 14px;
	}
}

@media only screen and (max-width:767px) {
	.im-icon-outer {
		width: 100%;
	}

	.section-full.p-b80 {
		padding-bottom: 30px;
	}

	.section-full.p-b50 {
		padding-bottom: 30px;
	}

	.section-full.p-b40 {
		padding-bottom: 30px;
	}

	.section-full.p-t80 {
		padding-top: 30px;
	}

	.section-full.p-tb80 {
		padding-top: 30px;
	}

	.section-full.p-b70 {
		padding-bottom: 20px;
	}

	.section-full.p-t50 {
		padding-top: 30px;
	}

	.section-head {
		margin-bottom: 30px;
	}
}

@media only screen and (max-width:640px) {

	.section-full.p-t80 {
		padding-top: 30px;
	}

	.section-full.p-b70 {
		padding-bottom: 20px;
	}

	.section-full.p-t50 {
		padding-top: 30px;
	}

	.section-full.p-b80 {
		padding-bottom: 30px;
	}

	.section-full.p-b50 {
		padding-bottom: 30px;
	}
}



/*==================================================
 * Effect 5
 * ===============================================*/

.icon-circle-box {
	position: relative;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.icon-circle-box .wt-icon-box-wraper {
	padding: 30px 20px 30px 20px;
}

.icon-circle-box .icon-content {
	overflow: inherit;
	z-index: 1;
	position: relative;

}

.icon-circle-box .icon-circle {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: #fff;
	position: relative;
	z-index: 1;
}

.icon-circle-box .icon-circle:before {
	content: "";
	left: -20px;
	top: -10px;
	position: absolute;
	z-index: -1;
	width: 140px;
	height: 120px;
	background-repeat: no-repeat;
	background-position: center center;
	/* background-image:url("../images/background/count-bg.png"); */
}

.icon-circle-box span.icon-cell {
	line-height: 100px;
}

.icon-count-number {
	position: absolute;
	top: -40px;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);

	font-size: 100px;
	line-height: 100px;
	font-weight: 900;
	opacity: 0.1;
	color: #0f1221;
	z-index: -1;
}

.icon-circle-box {
	text-align: center;
	position: relative;
}



@media only screen and (max-width:767px) {

	.row.d-flex [class*="col-"]:last-child .icon-circle-box .wt-icon-box-wraper {
		margin-bottom: 0px;
	}

}

/* box-active */

.icon-circle-box.active {
	position: relative;
	border: 0px;
}

.icon-circle-box.active {
	background-color: #0f1221;
	border-radius: 5px;
	-webkit-border-radius: 5px;
}


.icon-circle-box.active .icon-circle {
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3) inset;
	-webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3) inset;
}

.icon-circle-box.active .icon-circle:before {
	border: 10px solid black;
}

.icon-circle-box.active .wt-tilte,
.icon-circle-box.active p {
	color: #fff;
}

.icon-circle-box.active .icon-count-number {
	color: black;
	opacity: 0.3;
}



.form-group {
	margin-bottom: 25px;
}

.form-control {
	height: 50px;
	font-size: 13px;
	line-height: 20px;
	padding: 10px 12px;
	border-radius: 0px;
	border: none;
}

.form-control:focus {
	border-color: #d7dce1;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
	background-color: #F8F8F8;
}

.form-control2 {
	border: none;
	border-bottom: 1px solid #e8e8e8;
	width: 100%;
	height: 44px;
	font-size: 13px;
	line-height: 20px;
	padding: 10px 12px 10px 20px;
}

.form-control2:focus {
	border-bottom-color: #f11749;
}

.wt-input-icon .input-group-addon2 {
	position: absolute;
	left: 0px;
	top: 12px;
	z-index: 4;
	border: none;
	padding: 0;
	color: #222;
	-webkit-border-radius: 0px;
	border-radius: 6px;
	background: none;
}

textarea.form-control2 {
	height: auto;
}

.form-control.kv-fileinput-caption {
	height: 39px;
}

.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
	margin-right: 15px;
}


/*Icon Grediant*/

.overlay-gradient {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: red;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#bd000000', GradientType=0);
	/* IE6-9 */
	/* IE6-9 */
}

/*About us page*/
@media only screen and (max-width:991px) {
	.how-it-right {
		margin-bottom: 30px;
	}

}

.video-banner-outer {
	position: relative;
	z-index: 1;
}

.video-banner-outer .banner-wrap {
	position: absolute;
	width: 1140px;
	top: 0px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

@media only screen and (max-width:1200px) {
	.video-banner-outer .banner-wrap {
		position: absolute;
		width: 100%;
		top: 0px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
}

@media only screen and (max-width: 768px) {
	.video-banner-outer .banner-content-area {
		padding: 150px 0px 60px;
	}
}

.Call-btn {
	font-size: 26px;
	font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width:480px) {
	.Call-btn {
		font-size: 14px;
	}
}

/*Welcome Section*/
.welcome-section-outer {
	position: relative;
	z-index: 1;
}


.video-counter-bg-white {
	position: relative;
}

.welcom-to-section {
	padding-right: 0px;
}

.welcom-sign-info {
	text-align: left;
}

.welcom-sign-info span,
.welcom-sign-info strong {
	display: block;
	color: black;
}


@media only screen and (max-width:1199px) {
	.welcom-sign-pic {
		max-width: 110px;
	}
}

@media only screen and (max-width:991px) {
	.welcom-to-section {
		padding-right: 0px;
	}
}

@media only screen and (max-width:767px) {
	.welcome-section-top {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

@media only screen and (max-width:480px) {
	.welcom-to-section-bottom {
		display: inherit !important;
	}

	.welcom-btn-position {
		display: inline-block;
	}

	.welcom-sign-pic {
		max-width: 150px;
	}

	.welcom-to-section-bottom img {
		padding-top: 20px;
	}
}

.since-year-outer {
	position: relative
}

.since-year {
	position: absolute;
	right: 0px;
	top: -30px;
	z-index: 1;
}

.since-year span {
	color: black;
	font-size: 106px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	display: inline-block;
	line-height: 0px;
	position: relative;
	bottom: -16px;
	padding-right: 10px;
}

.since-year strong {
	color: #fff;
	font-size: 120px;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
	display: inline-block;
	background-color: #ff5e15;
	line-height: 50px;
	padding: 40px 10px 20px 12px;
}

.site-list-style-one {
	list-style: none;
	margin-bottom: 30px;

}

.site-list-style-one li {
	font-family: 'Roboto', sans-serif;
	color: black;
	font-size: 24px;
	position: relative;
	padding-left: 30px;
	font-weight: 500;
}

.site-list-style-one li:after {
	position: absolute;
	content: '';
	left: 0px;
	top: 17px;
	width: 20px;
	height: 2px;
	background-color: #ff5e15;
}

@media only screen and (max-width:991px) {
	.since-year-outer {
		text-align: right;
	}

	.since-year {
		position: inherit;
		top: -30px;
	}
}

@media only screen and (max-width:767px) {
	.since-year {
		position: inherit;
		top: -45px;
	}
}

@media only screen and (max-width:480px) {
	.since-year span {
		font-size: 36px;
	}

	.since-year strong {
		font-size: 60px;
		padding: 15px 10px 5px 12px;
	}
}

/*Large Title*/
.hilite-large-title {
	position: relative;
}

.hilite-large-title span {
	font-size: 120px;
	line-height: 100px;
	color: black;
	opacity: 0.1;
	font-family: 'Roboto', sans-serif;
	display: inline-block;
}

@media only screen and (max-width:991px) {
	.hilite-large-title span {
		font-size: 72px;
		line-height: 76px;
	}
}

@media only screen and (max-width:480px) {
	.hilite-large-title span {
		font-size: 40px;
		line-height: 46px;
	}
}


.img-colarge {
	position: relative;
	height: 100%;
	margin-bottom: 80px;
}

.colarge-1 {
	position: relative;
	margin: 40px 30px 0px 40px;
	z-index: 1;
}

.colarge-1:before,
.colarge-1:after {
	position: absolute;
	border: 15px solid #ff5e15;
	content: '';
	width: 80%;
	height: 80%;
	z-index: -1;
}

.colarge-1:before {
	left: -40px;
	top: -40px;
}

.colarge-1:after {
	right: -30px;
	bottom: 0px;
}



.img-colarge2 {
	position: relative;
	height: 100%;
	margin-bottom: 110px;
}

.colarge-2 {
	position: relative;
	margin-right: 180px;
	z-index: 1;
	border: 10px solid #ff5e15;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}

.colarge-2-1 {
	position: absolute;
	left: 160px;
	bottom: 0px;
	max-width: 370px;
}




.since-year-outer2 {
	position: relative
}

.since-year2 {
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
}

.since-year2 span {
	color: black;
	font-size: 50px;
	font-weight: 300;
	font-family: 'Teko', sans-serif;
	display: block;
	line-height: 60px;
	position: relative;
}

.since-year2 strong {
	color: #ff5e15;
	font-size: 80px;
	font-weight: 500;
	font-family: 'Teko', sans-serif;
	display: block;
	line-height: 50px;
}

@media only screen and (max-width:991px) {
	.colarge-2 {
		margin-right: 0px;
		max-width: 420px;
	}

	.colarge-2 img {
		width: 100%;
	}

	.colarge-2-1 {
		left: auto;
		right: 0px;
	}
}

@media only screen and (max-width:767px) {
	.colarge-2 {
		max-width: 100%;
		width: 50%;
	}

	.colarge-2 img {
		width: 100%;
	}

	.colarge-2-1 {
		width: 54%;
		top: 0px;
		max-width: 100%;
	}
}


.counter2-section-outer-bottom {
	padding-bottom: 0px;
}

.counter2-section-outer-top {
	padding-bottom: 50px;
}

.counter-section-outer-top {
	padding-top: 50px;
	padding-bottom: 20px;
}

.counter-outer .counter {
	font-size: 70px;
	position: relative;
	display: inline-block;
	margin-left: -30px;
}

.counter-outer .counter:after {
	font-size: 70px;
	position: absolute;
	content: '+';
	right: -30px;
}



.counter-outer span {
	font-size: 28px;
	display: block;
	font-weight: 600;
}

@media only screen and (max-width:991px) {
	.img-colarge {
		padding-top: 30px;
		margin: -10px;
	}

	.colarge-1 {
		position: relative;
		z-index: 1;
	}

	.colarge-1 img {
		width: 100%;
	}
}

@media only screen and (max-width:767px) {
	.img-colarge {
		margin: 0px;
		padding-top: 0px;
	}

	.colarge-1 {
		max-height: inherit;
		margin-bottom: 30px;
		padding: 0px;
	}


}


/*Services Box*/
.services-main-section {
	margin-bottom: 60px;
	position: relative;
	z-index: 1;
}

.services-section-content-left {
	max-width: 550px;
	margin-right: 0px;
	float: right;
}

.services-section-content {
	padding: 0px 15px;
}


.services-section-content-right {
	/* padding: 120px 0px; */
	background-repeat: repeat;
	position: relative;
	bottom: -130px;
	margin-top: -130px;
	right: -15px;
}

.services-section-content-right .services-slider {
	margin-left: -50px !important;
}

@media only screen and (max-width:1199px) {
	.services-section-content-left {
		max-width: 960px;

		padding: 0px 15px;
		margin-right: 0px;
		float: none;
		margin: 0px auto 70px;
		display: block;
	}

	.services-section-content-right .services-slider {
		max-width: 960px;
		padding: 0px 15px;
		margin: 0px auto;
	}

	.services-section-content-right {
		padding: 80px 0px;
		bottom: 0px;
		margin-top: 0px;
		right: 0px;
	}

}


@media only screen and (max-width:991px) {
	.services-section-content-left {
		max-width: 720px;
	}

	.services-section-content-right {
		padding: 75px 0px;
	}

	.services-section-content-right .services-slider {
		max-width: 720px;
	}

	.services-main-section {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width:767px) {
	.services-section-content-left {
		max-width: 540px;
		margin: 0px auto 12px;
	}

	.services-section-content-right .services-slider {
		max-width: 540px;
	}

	.services-section-content-right {
		bottom: 0px;
		margin: 0px 0px;
		padding: 10px 0px;
		background: none;
	}

	.services-main-section {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}
}

@media only screen and (max-width:575px) {

	.services-section-content-right .services-slider {
		max-width: 100%;
		padding: 0px;
	}

	.services-section-content-left {
		max-width: 100%;
		margin: 0px auto 12px;
		padding: 0px;
	}

}

.service-box-1 {
	height: 580px;
	position: relative;
	padding: 20px;
	background-color: #f8f8f8 !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.service-box-1 {
		height: 720px;
	}
}

.service-box-2 {
	padding: 0px;

}

.service-box-2-media img {
	width: 100%;
}

.service-border-box {
	position: relative;
	z-index: 1;
	margin: 0px 10px;
}

.service-border-box:before {
	content: '';
	position: absolute;
	left: -10px;
	top: -10px;
	right: -10px;
	bottom: -10px;
	background-color: rgb(238, 238, 238);
	z-index: -1;

	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.service-border-box:hover:before {
	background-color: #ff5e15;
}

.service-box-title {
	border-left: 5px solid #ff5e15;
	padding-left: 20px;
	margin-left: 0px;
	margin-bottom: 25px;
}

.service-box-title span {
	font-size: 30px;
	display: block;
	text-align: left;
	line-height: 36px;
}

.logos-slider.owl-btn-vertical-center .owl-nav .owl-next {
	right: -1px;
}

.logos-slider.owl-btn-vertical-center .owl-nav .owl-next,
.logos-slider.owl-btn-vertical-center .owl-nav .owl-prev {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}


.services-slider.owl-btn-vertical-center .owl-nav .owl-next {
	right: -1px;
}

.services-slider.owl-btn-vertical-center .owl-nav .owl-next,
.services-slider.owl-btn-vertical-center .owl-nav .owl-prev {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.services-slider:hover.owl-btn-vertical-center .owl-nav .owl-next {
	right: 2%;
}

.services-slider:hover.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 2%;
}

.logos-slider:hover.owl-btn-vertical-center .owl-nav .owl-next {
	right: 2%;
}

.logos-slider:hover.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 2%;
}

.services-slider .owl-nav {
	opacity: 0;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.services-slider:hover .owl-nav {
	opacity: 1;
}

.logos-slider .owl-nav {
	opacity: 0;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.logos-slider:hover .owl-nav {
	opacity: 1;
}


.wt-icon-number {
	position: absolute;
	right: 0px;
	top: 0px;
}

.wt-icon-number span {
	font-size: 120px;
	line-height: 100px;
	color: rgba(0, 0, 0, 0.1);
	font-weight: 500;
	opacity: 0.5;
	display: block;
	font-family: 'Roboto', sans-serif;
}

.service-box-content {
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 20px;
	border-bottom: 1px solid #ddd;
	padding-bottom: 20px;
}


.service-image-box .service-box-content {
	margin-bottom: 0;
	border-bottom: 0px;
	padding-bottom: 0;
}

/*Pricing list block*/
.pricing-list-block ul {
	list-style: none;
	margin-bottom: 0px;
}

.pricing-list-block ul li {
	display: flex;
	justify-content: space-between;
	padding-bottom: 15px;
	border-bottom: 1px dashed black;
	margin-bottom: 15px;
}

.pricing-list-block ul li:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: 0px;
}

.pricing-list-block ul li p {
	margin-bottom: 0px;
}

/*Discount Section*/
.discount-section-outer {
	position: relative;
	z-index: 1;
}

.discount-add-info {
	position: relative;
}

.discount-add-info .hilite-large-title {
	position: absolute;
}

.discount-add-info .hilite-large-title span {
	position: absolute;
	left: -70px;
	transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	transform-origin: left bottom;
}

.discount-add-info {
	padding: 80px 50px;
}

.discount-add-pic {
	position: relative;
}

@media only screen and (max-width:991px) {
	.discount-add-pic {
		min-height: 280px;
	}

	.discount-add-info {
		padding: 30px;
	}
}

.discount-section-outer:after {
	position: absolute;
	content: '';
	background-color: #fff6ef;
	width: 100%;
	height: 50%;
	bottom: 0px;
	left: 0px;
	z-index: -1;
}

@media only screen and (max-width:1340px) {
	.discount-add-info .hilite-large-title span {
		display: none;
	}
}

/*Team 1*/

.team-bg-section-outer {
	position: relative;
	z-index: 1
}

.team-bg-map {
	position: absolute;
	width: 100%;
	height: 40%;
	z-index: -1;
	left: 0px;
	bottom: 0px;
}

@media only screen and (max-width:991px) {
	.team-bg-map {
		height: 60%;
	}
}

@media only screen and (max-width:767px) {
	.team-bg-map {
		height: 80%;
	}
}


.wt-team-1 .team-name a:hover {
	color: #ff5e15;
}

.wt-team-1 .team-position {
	font-size: 24px;
	line-height: 20px;
	display: block;
}

.wt-team-1 .wt-media {
	position: relative;
	overflow: hidden;
	text-align: center;
}

.wt-team-1 .wt-media img {
	width: 100%;
}

.wt-team-1 .team-social-center {
	position: absolute;
	top: 100%;
	right: 0px;
	bottom: 0px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	border: 7px solid #ff5e15;

	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
	overflow: hidden;
}


.wt-team-1 .team-social-center ul {
	list-style: none;
	position: absolute;
	top: 50%;
	left: 50%;
	overflow: hidden;
	text-align: left;

	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
}

.wt-team-1 .team-social-center ul li {
	display: inline-block;
	position: relative;
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.wt-team-1 .team-social-center ul li a:hover {
	color: #ff5e15;
}


.wt-team-1 .team-social-center ul li a {
	color: #fff;
	font-size: 20px;
	padding: 5px 5px;
	display: inline-block;
}

.wt-team-1 .wt-info {
	position: relative;
}

.wt-team-1:hover .team-social-center {
	top: 0px;
}

@media only screen and (max-width:540px) {
	.wt-team-1 .team-position {
		font-size: 22px;
	}
}

/*Instagram Follows*/
.instagram-follows-section {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
	padding-right: 200px;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.instagram-pics-slide .owl-carousel .owl-nav {
	margin-top: 10px;
}

.instagram-box-1 {
	position: relative;
}

.instagram-box-media img {
	width: 100%;
}

.instagram-pics-slide {
	margin-top: 40px;
	margin-left: -200px;
}

.instagram-box-info {
	background-color: rgba(84, 31, 92, 0.8);
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	transform: scale(0, 0);
	-webkit-transform: scale(0, 0);

	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.instagram-box-1:hover .instagram-box-info {
	transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
}

.instagram-box-info a {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.instagram-box-info a i,
.instagram-box-info a span {
	display: block;
	font-size: 28px;
}

@media only screen and (max-width:1400px) {
	.instagram-pics-slide {
		margin-top: 40px;
		margin-left: -140px;
	}
}

@media only screen and (max-width:1024px) {
	.instagram-pics-slide {
		margin-left: 0px;
		margin-top: 0px;
	}

	.instagram-follows-section {
		padding-right: 15px;
		background: transparent !important;
		box-shadow: none;
	}

	.instagram-follows-section .p-a30 {
		padding: 0px;
	}
}

/*=========== Flat Icons ============*/

.icon-font-block {
	display: grid;
	width: 100%;
	text-align: center;
	vertical-align: top;
	background: black;
	padding: 10px;
	min-height: 170px;
	color: #fff;
}

.icon-font-block .flat-icon {
	padding: 10px;
	display: block;
	font-size: 64px;
	line-height: 1;
	color: #fff;
}

/*Service Detail*/
.service-detail-video .video-section-2 {
	position: relative;
	z-index: 1;
	display: flex;
	padding: 250px 0px;
}

/*Single Team*/

.wt-team-1-single-info {
	padding: 30px;
	margin-bottom: 0px;
}

.wt-team-1-single-list li {
	margin-bottom: 5px;
	font-weight: 600;
	position: relative;
	list-style: none;
	padding-left: 35px;
}


.wt-team-1-single-info li {
	list-style: none;
	clear: both;
}

.wt-team-1-single-info li span:first-child {
	font-weight: 500;
	padding-right: 30px;
	letter-spacing: 1px;
	color: black;
	width: 140px;
	font-family: 'Roboto', sans-serif;
	font-size: 24px;
	float: left;
}

.wt-team-1-single-info li span:last-child {
	overflow: hidden;
}

@media only screen and (max-width:640px) {
	.wt-team-1-single-info li span:first-child {
		display: block;
	}
}

@media only screen and (max-width: 540px) {
	.wt-team-1-single-info li span:first-child {
		float: none;
	}
}

/*Gallery image effects*/

.project-img-effect-1 {
	position: relative;
	overflow: hidden;
	width: 100%;
	background-color: #ff5e15;
	line-height: 1.5em;

	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

}

.project-img-effect-1 img {
	width: 100%;
}


.project-img-effect-1 .wt-info {
	padding: 20px 20px;
	opacity: 1;
	background-color: #fff;
	position: relative;
	bottom: 0%;
	z-index: 2;

	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.project-img-effect-1 a.mfp-link {
	position: absolute;
	width: 60px;
	height: 60px;
	line-height: 60px;
	bottom: 0px;
	right: 0%;
	color: #fff;
	text-align: center;
	background-color: black;
	font-size: 24px;

	z-index: 1;

	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}



/* .project-img-effect-1:hover .wt-info {
	position: relative;
	bottom: 60px;

	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;

	opacity: 1;
} */

.project-img-effect-1:hover img {
	zoom: 1.5;
	/* filter: alpha(opacity=30);
	-webkit-opacity: 0.3;
	opacity: 0.3; */
}


.project-detail-right p:last-child {
	margin-bottom: 0px;
}

/*=============contact Popup =================*/

.contact-slide-hide {
	position: fixed;
	right: -100%;
	top: 0px;
	height: 100%;
	min-width: 100%;
	max-width: 100%;
	background: #fff;
	color: #000;
	z-index: 999;
	overflow-x: hidden;
	border-left: 1px solid #ddd;
}

.contact-nav {
	max-width: 1000px;
	margin: 0px auto;
	display: table;
	width: 100%;
	height: 100%;
}

.contact-nav .contact-nav-form {
	padding-top: 30px;
	display: table-cell;
	vertical-align: middle;

}

.contact-nav .contact-nav-form .form-control {
	border: 1px solid #e5e5e5;
}

.contact-nav .contact_close {
	position: absolute;
	right: 20px;
	top: 20px;
	width: 100%;
	border: none;
	text-align: right;
	font-size: 36px;
}

.contact-nav-media-section {
	position: relative;
	height: 100%;
}

.contact-nav-media {
	position: absolute;
	left: 0px;
	bottom: -30px;
}

.contact-nav-inner {
	background-color: #f5f5f5;
	padding: 30px;
}

.contact-nav-inner p {
	margin: 0px;
}

@media only screen and (max-width:991px) {
	.contact-nav-inner .row [class*="col-"] {
		margin-bottom: 30px;
	}

	.contact-nav-inner .row [class*="col-"]:last-child {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width:767px) {
	.contact-nav-media-section {
		display: none;
	}

}





.about-section-outer {
	position: relative;
}

@media only screen and (max-width:991px) {
	.about-section-outer {
		padding-top: 30px;
	}
}

.bg-brush-img {
	position: relative;
	z-index: 1;
}

.bg-brush-img:before {
	content: '';
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	width: 100%;
	z-index: -1;
	/* background-image:url(../images/background/brush-left.png); */
	background-size: auto;
	background-repeat: no-repeat;
	background-position: left top;
}

@media only screen and (max-width:991px) {
	.bg-brush-img:before {
		background-size: 50%;
	}
}

/*media margin css*/
@media only screen and (max-width:767px) {
	.row.justify-content-center [class*="col-"]:last-child.m-b30 {
		margin-bottom: 0px;
	}

	.row.justify-content-center [class*="col-"]:last-child.m-b40 {
		margin-bottom: 0px;
	}

	.row.justify-content-center [class*="col-"]:last-child.m-b50 {
		margin-bottom: 10px;
	}

	.wt-team-1-single p:last-child {
		margin-bottom: 0px;
	}

	.home-contact-section {
		margin-bottom: 0px;
		margin-left: 0px;
	}
}

/*left right slide animation*/
.slide-right {
	-webkit-animation: slide-right 1.5s ease-in-out infinite alternate both;
	animation: slide-right 1.5s ease-in-out infinite alternate both;
}

@-webkit-keyframes slide-right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
}

@keyframes slide-right {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	100% {
		-webkit-transform: translateX(-20px);
		transform: translateX(-20px);
	}
}

/*Pre info box*/
.pre-info-box {
	position: relative;
}

.pre-info-box .wt-info {
	padding: 30px;
	padding-bottom: 0px;
}

.pre-info-bg-box h2,
.pre-info-bg-box p {
	color: #fff;
}

.pre-info-bg-box {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
	height: 100%;
	padding: 20px;
}

@media only screen and (max-width:767px) {
	.pre-info-bg-box {
		padding: 80px 15px;
		margin-bottom: 30px;
	}
}

/*makeover-section*/
.makeover-section p {
	max-width: 700px;
	padding-bottom: 20px;
}

.makeover-section h1 {
	max-width: 600px;
}

.makeover-section-outer {
	position: relative;
	z-index: 1;
}

.makeover-section-outer:after {
	content: '';
	position: absolute;
	right: 0px;
	bottom: 0px;
	/* background-image:url(../images/background/girl-right.png); */
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 60%;
	width: 100%;
	height: 100%;
	z-index: -1;
}

@media only screen and (max-width:991px) {
	.makeover-section {
		padding-top: 0px;
	}
}

@media only screen and (max-width:767px) {
	.makeover-section-outer:after {
		background-size: 75%;
	}

	.makeover-section {
		padding-top: 0px;
	}
}

/*Gallery Slider Center Mode*/

.gallery-center-slider {
	position: relative;
}

.gallery-center-slider .owl-item.active.center {
	transform: scale(1.3);
	-webkit-transform: scale(1.3);
	z-index: 1;

	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.gallery-center-slider.owl-carousel .owl-stage-outer {
	padding: 100px 0px;
}

.gallery-center-slider.owl-carousel .owl-stage-outer .item {
	padding: 0px 30px;
}


.gallery-center-slider.owl-carousel .owl-item img {
	width: 100%;
}

.gallery-center-slider.owl-carousel .project-img-effect-1:hover img {
	-webkit-transform: translateX(0px) scale(1.3);
	transform: translateX(0px) scale(1.3);
}

/*-----Project 1-----*/
.project-1-outer-section {
	margin-bottom: 30px;
}

.project-1-outer {
	position: relative;
}

.project-1-left {
	position: absolute;
	z-index: 3;
	bottom: -40px;
}

.crane-image-section {
	position: relative;
}

.crane-image-section img {
	max-width: 555px;
}

@media only screen and (max-width:1199px) {
	.crane-image-section img {
		max-width: 400px;
	}
}

@media only screen and (max-width:991px) {
	.crane-image-section img {
		display: none;
	}

	.project-1-outer-section {
		margin-bottom: 0px;
	}
}

.project-1-right {
	max-width: 700px;
	float: right;
	margin-bottom: -30px;
	z-index: 2;
	position: relative
}


.project-1-right .project-1-slider.owl-carousel .owl-stage-outer {
	padding-bottom: 30px;
	padding-top: 0px;
}


.project-1-inner-media {
	max-width: 370px;
	float: left;
	position: relative;
	z-index: 1;
	margin-right: 30px;
}

.project-1-inner-pic:after {
	position: absolute;
	content: '';
	border: 6px solid #ff5e15;
	right: -30px;
	bottom: -30px;
	left: 30px;
	top: 30px;
	width: 100%;
	z-index: -1;
}

.project-1-inner-info {
	overflow: hidden;
	padding: 100px 0px 20px 30px;
}

@media only screen and (max-width:991px) {
	.project-1-inner-media {
		margin: 80px 30px 110px 0px;
	}
}

@media only screen and (max-width:767px) {
	.project-1-right {
		float: none;
		max-width: inherit;
		margin-bottom: 30px;
	}

	.project-1-inner-media {
		max-width: 100%;
		float: none;
		position: relative;
		z-index: 1;
		margin: 0px auto;
		margin-right: 30px;
		margin-top: 80px;
	}

	.project-1-slider.long-arrow-next-prev.owl-btn-top-right .owl-nav {
		top: 15px;
	}

	.project-1-inner-info {
		padding: 60px 0px 0px 0px;
	}

	.project-1-right .project-1-slider.owl-carousel .owl-stage-outer {
		padding-bottom: 0px;
		padding-top: 0px;
	}


}

.long-arrow-next-prev.owl-btn-top-right .owl-nav {
	position: absolute;
}




.long-arrow-next-prev.owl-carousel .owl-nav .owl-prev,
.long-arrow-next-prev.owl-carousel .owl-nav .owl-next {
	color: black !important;
	padding: 0px;
	width: auto;
	height: auto;
	text-align: center;
	font-size: 38px;
	display: inline-block;
	background-color: transparent !important;
	line-height: inherit;
}

.long-arrow-next-prev.owl-carousel .owl-nav .owl-prev:hover,
.long-arrow-next-prev.owl-carousel .owl-nav .owl-prev:active,
.long-arrow-next-prev.owl-carousel .owl-nav .owl-prev:focus,
.long-arrow-next-prev.owl-carousel .owl-nav .owl-next:hover,
.long-arrow-next-prev.owl-carousel .owl-nav .owl-next:active,
.long-arrow-next-prev.owl-carousel .owl-nav .owl-next:focus {
	background-color: transparent !important;
	border-color: inherit !important;
	color: #ff5e15 !important;
	opacity: 1;
}

/*-----What we do section-----*/
.what-we-do-section {
	position: relative;
	top: -60px;
	padding-top: 140px;
}

.what-we-do-section .hilite-large-title {
	position: absolute;
	right: -2px;
	bottom: -20px;
	z-index: 1;
}

.what-we-do-section .hilite-large-title span {
	font-size: 120px;
	line-height: 100px;
	color: #fff;
	opacity: 1;
	font-family: 'Roboto', sans-serif;
	display: block;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: -2px;
}

.what-we-do-content {
	position: relative;
	bottom: -130px;
	margin-top: -130px;
	z-index: 3;
}

.whatWedo-media-section {
	position: relative;
	margin-left: 30px;
	margin-right: 0px;
}

.whatWedo-media-section img {
	width: 100%;
}


.whatWedo-media-content {
	position: absolute;
	bottom: 60px;
	left: -30px;
	right: 82px;
}

@media only screen and (max-width:1199px) {
	.what-we-do-content {
		position: relative;
		bottom: 0px;
		margin-top: 0px;
		z-index: 3;
		margin-bottom: 30px;
	}

	.what-we-do-section .whatWedo-info-section .wt-icon-card {
		margin-right: 0px;
	}

	.what-we-do-section {
		top: -60px;
		padding-top: 80px;
	}

}

@media only screen and (max-width:991px) {
	.what-we-do-section {
		position: relative;
		top: 0px;
		padding-top: 80px;
	}

	.what-we-do-content .whatWedo-info-section {
		margin-left: 0px;
		margin-top: 30px;
	}
}

@media only screen and (max-width:767px) {
	.whatWedo-media-content {
		right: 30px;
	}

	.what-we-do-section .hilite-large-title {
		display: none;
	}

}

@media only screen and (max-width:400px) {
	.whatWedo-media-section {
		margin-left: 0px;
	}

	.whatWedo-media-content {
		position: relative;
		right: auto;
		top: auto;
		left: auto;
		bottom: auto;
	}

	.whatWedo-media-content:before {
		display: none;
	}

	.whatWedo-media-content:after {
		display: none;
	}
}

.whatWedo-media-inner {
	position: relative;
	z-index: 1;
	padding: 20px 30px;
	background: rgb(252, 93, 22);
	/* Old browsers */
	background: -moz-linear-gradient(left, rgba(252, 93, 22, 1) 0%, rgba(2, 23, 60, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(252, 93, 22, 1) 0%, rgba(2, 23, 60, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(252, 93, 22, 1) 0%, rgba(2, 23, 60, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc5d16', endColorstr='#02173c', GradientType=1);
	/* IE6-9 */
}

.whatWedo-media-content h3 {
	margin-bottom: 2px;
}

.whatWedo-media-content p {
	margin-bottom: 0px;
}

.whatWedo-media-content:before {
	position: absolute;
	top: -15px;
	right: -15px;
	bottom: 15px;
	left: 15px;
	content: '';
	background-color: #f1f1f1;
	z-index: 0;
}

.whatWedo-media-content:after {
	position: absolute;
	left: 0px;
	bottom: -60px;
	content: '';
	z-index: 0;
	width: 0;
	height: 0;
	border-top: 60px solid #c34d1f;
	border-left: 30px solid transparent;
}

.whatWedo-info-section {
	margin-left: 0px;
}

@media only screen and (max-width:991px) {
	.whatWedo-info-section {
		margin-left: 0px;
	}
}

.wt-icon-card-outer {
	padding-top: 15px;
}

.wt-icon-card {
	padding: 20px;
}

.wt-icon-card .wt-card-header {
	padding-bottom: 10px;
}

.wt-icon-card .wt-card-header i {
	font-size: 48px;
	padding-right: 10px;
	line-height: 48px;
	display: inline-block;
}

.wt-icon-card .wt-card-header span {
	font-size: 30px;
}

.wt-icon-card .wt-card-content p {
	margin-bottom: 0px;
}

.whatWedo-info-section .wt-icon-card {
	margin-right: 0px;
	margin-bottom: 30px
}

@media only screen and (max-width:1199px) {
	.what-we-do-section2 .whatWedo-info-section .wt-icon-card {
		margin-right: 0px;
	}
}

@media only screen and (max-width:991px) {
	.what-we-do-section2 .whatWedo-info-section {
		margin-left: 0px;
	}
}

.what-we-do-section2 {
	position: relative;

}

.what-we-do-section2 .hilite-large-title {
	position: absolute;
	right: -2px;
	bottom: -20px;
	z-index: 1;
}

.what-we-do-section2 .hilite-large-title span {
	font-size: 120px;
	line-height: 100px;
	color: #fff;
	opacity: 1;
	font-family: 'Roboto', sans-serif;
	display: block;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: -2px;
}

.what-we-do-section2 .what-we-do-content {
	position: relative;
	bottom: 0px;
	margin-top: 0px;
	z-index: 1;

}

@media only screen and (max-width:767px) {
	.what-we-do-section2 .hilite-large-title {
		display: none;
	}
}

/*-----Top Half Section-----*/
.top-half-section {
	margin-top: -100px;
}

@media only screen and (max-width:1024px) {
	.top-half-section {
		margin-top: 90px;
	}
}

@media only screen and (max-width:767px) {
	.top-half-section {
		margin-top: 40px;
	}
}

/*-----Project Two-----*/
.project-stamp .stamp {
	position: absolute;
	left: 0px;
	top: 0px;
}

.project-stamp .project-stamp-list .masonry-filter {
	display: block;
}

.project-stamp .project-stamp-list {
	border: 2px solid #ff5e15;
	padding: 10px 10px;
}

.project-stamp .project-stamp-list li {
	display: block;
	margin-bottom: 16px;
	border-bottom: 1px solid #e4e4e4;
}

.project-stamp .project-stamp-list li:last-child {
	margin-bottom: 0px;
	border-bottom: 0px;
}

.project-stamp .project-stamp-list li a {
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
}

.project-stamp .project-stamp-list li a i {
	color: #ff5e15;
	font-size: 24px;
	padding-right: 10px;
}

.project-2-block .wt-title {
	border-left: 4px solid #ff5e15;
	padding: 3px 0px 3px 10px
}

.project-2-block .wt-title a:hover {
	color: #ff5e15
}

/*----Self intro-----*/
.self-intro-section-outer {
	position: relative;
}

.self-intro-top {
	position: relative;
	z-index: 1;
}

.self-intro-pic-block {
	position: absolute;
	left: 15px;
	bottom: 0px;
	z-index: 1;
	padding: 50px 50px 0px 50px;
	background-color: #e6e6e6;
	max-width: 460px;
	background-image: url('../images/background/self-info-bg.png');
	background-position: top center;
	background-repeat: no-repeat;
}

.self-info-detail .icon-content p {
	margin-bottom: 0px;
}


.self-intro-pic-block2 {
	position: absolute;
	left: 15px;
	bottom: 0px;
	z-index: 1;
	padding: 50px 50px 0px 50px;
	background-color: #fff;
	max-width: 460px;
	background-image: url('../images/background/self-info-bg.png');
	background-position: top center;
	background-repeat: no-repeat;
}

@media only screen and (max-width:991px) {
	.self-intro-pic-block {
		max-width: 320px;
	}

	.self-intro-pic-block2 {
		max-width: 320px;
	}
}

@media only screen and (max-width:767px) {
	.self-intro-pic-block {
		display: none;
	}

	.self-intro-pic-block2 {
		display: none;
	}

	.self-intro-top {
		padding-top: 40px;
		padding-bottom: 20px;
	}

	.self-intro-bottom {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

/*======================================
    SHOP & PRODUCT DETAIL (Woocommerce)
======================================*/
/*-------------------------------------
	1. Product Box
-------------------------------------*/
.wt-product-box .price {
	margin-bottom: 10px;
}

.wt-product-box .price del {
	font-size: 16px;
	color: #999999;
	margin: 4px 4px;
	font-weight: 600;
}

.wt-product-box .price ins {
	font-size: 18px;
	color: #ff5e15;
	text-decoration: none;
	margin: 4px 4px;
	font-weight: 600;
}

.wt-product-box .rating-bx {
	padding-top: 10px;
	color: #ffa000;
}

/*-------------------------------------
	2. Product Detail
-------------------------------------*/
.btn-quantity {
	width: 150px;
}

.product-description.dex-tabs.border-top .nav-tabs>li.active>a {
	border-top: 2px solid #efbb20;
}

.product-description.dex-tabs .nav-tabs>li>a i {
	color: #efbb20;
}

.dex-tabs.bg-tabs .nav-tabs>li>a {
	background-color: #fcfcfc;
}

/*-------------------------------------
	3.  Product Review
-------------------------------------*/
ol.commentlist {
	list-style: none;
	margin: 0;
}

ol.commentlist li {
	position: relative;
	padding: 0;
	margin-bottom: 20px;
	background: #F7F8FA;
	padding: 20px;
	border: 1px dashed #EEEEEE;
}

ol.commentlist li img {
	float: left;
	border: 5px solid #FFF;
	width: 80px;
	height: auto;
}

ol.commentlist li .comment-text {
	padding-left: 100px;
}

ol.commentlist li .meta {
	margin-bottom: 5px;
}

ol.commentlist li .meta strong {
	font-size: 16px;
	padding-right: 7px;
}

ol.commentlist li .meta .time {
	color: #999;
	display: block;
	font-size: 14px;
}

ol.commentlist li .description p {
	margin: 0;
}

ol.commentlist li .star-rating {
	position: absolute;
	top: 20px;
	right: 20px;
}

@media only screen and (max-width: 767px) {
	ol.commentlist li .star-rating {
		position: inherit;
	}
}

.comment-reply-title {
	margin-top: 0;
}

.comment-form [class*="comment-form"] {
	width: 50%;
}

@media only screen and (max-width: 767px) {
	.comment-form [class*="comment-form"] {
		width: 100%;
	}
}

.comment-form [class*="-form-author"],
.comment-form [class*="-form-email"] {
	float: left;
}

.comment-form [class*="-form-comment"] {
	width: 100%;
}

.comment-form label {
	display: block;
}

.star-Rating-input i {
	font-size: 18px;
	padding-right: 7px;
}

.star-Rating-input .fa-star {
	color: #FFBC13;
}

.star-Rating-input i:hover {
	color: #FFBC13;
}

.comment-form [class*="comment-form"] input,
.comment-form [class*="comment-form"] textarea {
	border: 1px solid #CCC;
	padding: 8px 10px;
	width: 100%;
}

.comment-form {
	margin: 0 -15px;
}

.comment-form [class*="comment-form"],
.comment-form .form-submit {
	padding: 0 15px;
}

.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
	background: #037a85;
}


/*17.5 touchspin input type number */
.bootstrap-touchspin .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 1%;
	vertical-align: middle;
	display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding: 10px 10px 9px;
	margin-left: -1px;
	position: relative;
	border-width: 1px;
	border-style: solid;
	border-color: #e1e1e1;
	background: #FFF;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0;
	border-top-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	margin-top: -2px;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-bottom-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i {
	position: absolute;
	top: 4px;
	left: 5px;
	font-size: 9px;
	font-weight: normal;
	color: #9fa0a1;
}

/*17.6 rating star */
.input-rating input[type=radio] {
	display: none;
}

.input-rating input[type=radio]+label {
	display: inline-block;
	margin-left: -4px;
	padding-left: 0px !important;
	padding-right: 0px;
	width: 24px;
}

.input-rating input[type=radio]+label:first-of-type {
	margin-left: 0px;
}

.input-rating input[type=radio]+label:before {
	background-color: transparent;
	border: none;
	color: #ffd925;
	content: "\f006";
	display: inline-block;
	font-family: FontAwesome;
	font-size: 20px;
	-webkit-font-smoothing: antialiased;
	height: 14px;
	line-height: 1;
	margin-left: 0px;
	position: static;
	text-align: center;
	top: 2px;
	transition: none;
	width: 14px;
}

.input-rating input[type=radio]:checked+label:before,
.input-rating input[type=radio]+label.marked:before,
.input-rating input[type=radio]+label.filled:before,
.input-rating input[type=radio]+label.hovered.filled:before {
	content: "\f005";
}

.input-rating input[type=radio]+label.hovered:before {
	content: "\f006";
}

/*17.7 simple rating list */
.rating-bx {
	color: #ffd925;
	font-size: 14px;
}

.rating-bx i {
	margin: 0 2px;
}

.rating-container .rating-stars::before {
	text-shadow: none;
}

.rating-container {
	color: #c8c8c8;
}

.rating-container .rating-stars {
	color: #3396d1;
}

/*17.8 validation symbol */
.has-feedback label~.form-control-feedback {
	top: 31px;
}

.site-list-style-two {
	list-style: none;

}

.site-list-style-two li {
	font-family: 'Roboto', sans-serif;
	color: black;
	font-size: 24px;
	position: relative;
	left: 30px;
	font-weight: 500;
}

.site-list-style-two li:after {
	position: absolute;
	content: '';
	left: -30px;
	top: 17px;
	width: 20px;
	height: 2px;
	background-color: #ff5e15;
}



/*======================================

48. shopping-table

======================================*/
.shopping-table .cf th {
	padding: 5px 10px !important;
	color: #060000;
}



/*======================================

49. shopping-table

======================================*/

.your-order-list ul {
	list-style: none;
	margin-bottom: 0px;
}

.your-order-list ul li {
	padding: 15px 10px;
	clear: both;
	border-bottom: 1px solid #eee;
	color: #fff;
}

.your-order-list ul li:last-child {
	background-color: #ff5e15;
	border-bottom: none;
	text-transform: uppercase;
	color: #000;
}


/*-------------------------------------
	1. Top Part Search Bar
-------------------------------------*/
.header-style-1 #search-toggle-block {
	display: none;
}

.header-style-1 #search {
	position: relative;
	background-color: rgba(255, 255, 255);
}

.header-style-1 #search form {
	max-width: 440px;
	color: #262423;
	text-align: center;
	outline: none;
	padding: 12px;
	margin: 0px auto;
}

.header-style-1 #search input[type="search"] {
	background: none;
	border: none;
	padding: 0px;
	font-size: 20px;
	height: auto;
}

.header-style-1 #search .search-btn {
	border: none;
	background: none;
	padding: 0px 30px;
}

.header-style-1 #search .search-btn i {
	font-size: 18px;
}


.header-style-1 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 15px;
	font-size: 0px;
	color: #fff;
	width: 48px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-1 .header-search-icon.close:after,
.header-style-1 .header-search-icon.close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 24px;
	background-color: #262423;
	right: 24px;
	top: 12px;
}

.header-style-1 .header-search-icon.close:after {
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari */
	transform: rotate(45deg);
}

.header-style-1 .header-search-icon.close:before {
	-ms-transform: rotate(-45deg);
	/* IE 9 */
	-webkit-transform: rotate(-45deg);
	/* Safari */
	transform: rotate(-45deg);
}

.header-style-1 .header-search-icon.close:hover:after,
#search .close:hover:before {
	cursor: pointer;
}

@media only screen and (max-width:1200px) {
	.header-style-1 .header-search-icon {
		right: 50px;
	}

	.header-style-1 .header-search-icon.close {
		right: 50px;
	}
}

@media only screen and (max-width:777px) {
	.header-style-1 .header-search-icon {
		right: 0px;
	}

	.header-style-1 .header-search-icon.close {
		right: 0px;
	}
}


.header-style-2 #search-toggle-block {
	display: none;
	position: absolute;
	right: 15px;
	top: 65px;
	border: 3px solid #ff5e15;
}

@media only screen and (max-width:991px) {
	.header-style-2 #search-toggle-block {
		top: 70px;
	}
}

.header-style-2 #search {
	position: relative;
	background-color: rgba(255, 255, 255);
}

.header-style-2 #search form {
	width: 280px;
	color: #262423;
	text-align: center;
	outline: none;
	padding: 12px;
	margin: 0px auto;
}

.header-style-2 #search input[type="search"] {
	background: none;
	border: none;
	padding: 0px;
	font-size: 14px;
	height: auto;
}

.header-style-2 #search .search-btn {
	border: none;
	background: none;
	padding: 0px 0px 0px 15px;
}

.header-style-2 #search .search-btn i {
	font-size: 18px;
}

.header-style-2 .header-search-icon.close {
	position: absolute;
	top: 0px;
	right: 0px;
	font-size: 0px;
	color: #fff;
	width: 48px;
	bottom: 0px;
	opacity: 1 !important;
}

.header-style-2 .header-search-icon.close:after,
.header-style-2 .header-search-icon.close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 24px;
	background-color: #fff;
	right: 24px;
	top: 9px;
}

.header-style-2 .header-search-icon.close:after {
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari */
	transform: rotate(45deg);
}

.header-style-2 .header-search-icon.close:before {
	-ms-transform: rotate(-45deg);
	/* IE 9 */
	-webkit-transform: rotate(-45deg);
	/* Safari */
	transform: rotate(-45deg);
}

.header-style-2 .header-search-icon.close:hover:after,
#search .close:hover:before {
	cursor: pointer;
}



.faq-accorfion {
	position: relative
}

@media only screen and (max-width:767px) {
	.faq-accorfion {
		margin-bottom: 30px;
	}
}



/*======================*/
.hover-effect-1 {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	color: #fff;

	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
}

.hover-effect-1 .hover-effect-content {
	position: absolute;
	padding: 20px 20px 20px 50px;
	overflow: hidden;
	bottom: 0px;
}

.hover-effect-1 .hover-effect-content p {
	padding-top: 50px;
	-webkit-transition: all 0.5s linear;
	-moz-transition: all 0.5s linear;
	-ms-transition: all 0.5s linear;
	-o-transition: all 0.5s linear;
	transition: all 0.5s linear;
	opacity: 0;
}

.h-category {
	top: 0px;
	left: 35px;
	width: 200%;
	position: absolute;
	white-space: nowrap;
	margin-left: 0px;
	background-color: #ff5e15;
	padding: 5px 20px 5px 37px;

	-ms-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);

	-ms-transform-origin: left top 0;
	-moz-transform-origin: left top 0;
	-webkit-transform-origin: left top 0;
	transform-origin: left top 0;

	text-transform: uppercase;
	text-transform: 1px 1px 0px rgba(0, 0, 0, 0.5);
}

.line-filter-media {
	position: relative;
}

.line-filter .filter-content {
	border-left: 4px solid #ff5e15;
	padding-left: 20px;
}

.line-filter-outer:hover .hover-effect-1 {
	top: 0px;
	opacity: 1;
}

.line-filter-outer:hover .hover-effect-1 .hover-effect-content p {
	padding-top: 0px;
	opacity: 1;
}

.hover-effect-content .mfp-link {
	width: 40px;
	height: 40px;
	line-height: 40px;
	display: block;
	background-color: #fff;
	text-align: center;
}

.hover-effect-content .mfp-link:hover {
	background-color: #ff5e15;
}

.hover-effect-content .mfp-link:focus {
	color: #000;
}

/*____BOLD TRANSPERENT TITLE____*/

.bold-title {
	position: relative;
	z-index: 1;
	overflow: inherit;
}


.bold-title h1:after,
.bold-title h2:after,
.bold-title h3:after,
.bold-title h4:after {
	position: absolute;
	content: attr(data-title);
	color: rgba(210, 210, 210, 0.4);
	font-weight: 500;
	font-size: 114px;
	text-transform: uppercase;
	top: -40px;
	left: 0%;
	width: 100%;
	z-index: -1;
}

@media only screen and (max-width:480px) {

	.bold-title h1:after,
	.bold-title h2:after,
	.bold-title h3:after {
		display: none;
	}

}

/*======================================
. Animate line
======================================*/
.animate_line {
	position: relative;
	z-index: 0;
	padding: 15px 15px;
}

.animate_line:after,
.animate_line:before {
	content: "";
	height: 4px;
	width: 0px;
	position: absolute;
	transition: all 0.5s ease 0s;
	-webkit-transition: all 0.5s ease 0s;
	z-index: -1;
}

.animate_line:after {
	left: 5px;
	bottom: 5px;
}

.animate_line:before {
	right: 5px;
	top: 5px;
}

.animate_line:hover:after,
.animate_line:hover:before {
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	background: #ff5e15;
}

@media only screen and (max-width:768px) {
	.animate_line:after {
		bottom: 5px;
	}

	.animate_line:before {
		top: 5px;
	}

}

.full-container-margin .container-fluid {
	padding-left: 100px;
	padding-right: 100px;
}

@media only screen and (max-width:767px) {
	.full-container-margin .container-fluid {
		max-width: 540px;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
}

/*-----img up down animation----*/
.img-top-bottom-aanimation {
	-webkit-animation: img-animation 5s ease-in-out infinite alternate-reverse both;
	animation: img-animation 5s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes img-animation {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
}

@keyframes img-animation {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(-20px);
		transform: translateY(-20px);
	}
}

/*-----how we work box-----*/
.how-we-work-box {
	position: relative;
	z-index: 1;
	margin: 0px 10px;
}

.how-we-work-box:hover:before {
	background-color: #ff5e15;
}

.how-we-work-box-1 {
	position: relative;
	padding: 50px 20px;
	text-align: center;

}

.how-we-work-title {
	margin-left: 0px;
	margin-bottom: 20px;
}

.how-we-work-title span {
	font-size: 30px;
	display: block;
	line-height: 36px;
}

.how-we-work.owl-btn-vertical-center .owl-nav .owl-next {
	right: -1px;
}

.how-we-work.owl-btn-vertical-center .owl-nav .owl-next,
.how-we-work.owl-btn-vertical-center .owl-nav .owl-prev {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.how-we-work:hover.owl-btn-vertical-center .owl-nav .owl-next {
	right: 5%;
}

.how-we-work:hover.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 5%;
}

.how-we-work .owl-nav {
	opacity: 0;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.how-we-work:hover .owl-nav {
	opacity: 1;
}


.how-we-work .box-icon-number {
	position: inherit;
}

.how-we-work .box-icon-number span {
	font-size: 120px;
	line-height: 100px;
	font-weight: 500;
	display: block;
	font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width:767px) {
	.how-we-work-box {
		position: relative;
		z-index: 1;
		margin: 0px;
	}
}

/*----box-style1----*/

.box-style1 {
	padding: 20px;

	position: relative;
	z-index: 1;

	background: rgb(252, 252, 252);
	/* Old browsers */
	background: -moz-linear-gradient(left, rgba(252, 252, 252, 1) 0%, rgba(244, 244, 244, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(252, 252, 252, 1) 0%, rgba(244, 244, 244, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(252, 252, 252, 1) 0%, rgba(244, 244, 244, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc', endColorstr='#f4f4f4', GradientType=1);
	/* IE6-9 */


}

.box-style1-title span {
	font-size: 30px;
	display: block;
	text-align: left;
	line-height: 36px;
	color: black;
}

.box-style1-title {
	border-left: 5px solid #ff5e15;
	padding-left: 20px;
	margin-left: 0px;
	margin-bottom: 25px;
}

.box-style1 .box-style1-content {
	padding-bottom: 30px;
}

.box-style1 .wt-icon-number span {
	font-size: 72px;
	line-height: 100px;
	color: #ff5e15;
	font-weight: 500;
	opacity: 1;
	display: block;
	font-family: 'Roboto', sans-serif;
}

.box-style1:hover .wt-icon-number span {
	color: #fff;
}

.box-style1:hover .box-style1-title span {
	color: #fff;
}

.box-style1:hover .box-style1-content p {
	color: #fff;
}

.box-style1:hover .box-style1-content a {
	color: #fff;
}

.box-style1 .wt-icon-number {
	position: absolute;
	right: 0px;
	top: 0px;
}


.box-style1:after {
	content: '';
	position: absolute;
	left: 100%;
	bottom: 0px;
	top: 0px;
	height: 100%;
	width: 0px;
	z-index: -1;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;

	background: rgb(255, 94, 21);
	background: linear-gradient(0deg, rgba(255, 94, 21, 1) 0%, rgba(0, 23, 60, 1) 100%);

}

.box-style1:hover:after {
	width: 100%;
	left: 0;
	-webkit-transition: width 0.3s ease;
	transition: width 0.3s ease;
	z-index: -1;
}

.arc-home-about-right .row [class*="col-"] {
	padding-left: 20px;
	padding-right: 0px;
}

.arc-home-about-right span.title-small {
	font-weight: 600;
	display: block;
}

.arc-about-year-pic {
	position: relative;
	background-color: #fff;
}

.arc-about-year-pic img {
	width: 100%;
}


@media only screen and (max-width:991px) {

	.arc-home-about-right {
		margin: 30px -10px -20px -10px;
	}

	.arc-home-about-right .row [class*="col-"] {
		padding: 0px 10px;
	}
}

@media only screen and (max-width:768px) {
	.arc-home-about-left:before {
		left: 0px;
		bottom: -50px;
	}

	.arc-home-about-left:after {
		right: 0px;
		top: -50px;
	}

	.arc-about-year {
		text-align: right;
	}

	.arc-home-about-right .row [class*="col-"].masonry-item {
		width: 50%;
	}

	.arc-home-about-right .row [class*="col-"] {
		padding: 0px 10px;
	}
}

@media only screen and (max-width:480px) {
	.arc-home-about-right .row [class*="col-"].masonry-item {
		width: 100%;
	}

	.arc-home-left-content {
		margin: 30px 15px;
	}

	.arc-home-about-left:after {
		right: -3px;
		top: -25px;
	}

	.arc-home-about-left:before {
		bottom: -25px;
		left: -3px;
	}

	.arc-home-about-left:before,
	.arc-home-about-left:after {
		height: 79%;
	}
}




/*=======================================================================================
					Home Page New Css
 =======================================================================================*/
.bg-gray-light {
	background-color: #f1f1f1;
}

/*welcome section*/
.img-colarge-new {
	position: relative;
	height: 100%;
	margin-bottom: 80px;
}

.colarge-1-new {
	position: relative;
	margin: 40px 30px 0px 40px;
	z-index: 1;
}

.colarge-1-new:before {
	position: absolute;
	border: 10px solid #ff5e15;
	content: '';
	width: 60%;
	z-index: -1;
	left: -40px;
	top: -40px;
	bottom: -40px;
}



.welcom-to-section-new {
	padding-top: 40px;
}

.welcom-to-section-new p {
	margin-bottom: 50px;
	line-height: 28px;
}

@media only screen and (max-width:991px) {
	.img-colarge-new .colarge-1-new img {
		width: 100%;
	}

	.welcom-to-section-new {
		padding-top: 0px;
	}

	.welcom-to-section-new p {
		margin-bottom: 20px;
	}
}

.site-list-style-one-new {
	list-style: none;
	margin-bottom: 30px;
	margin-left: 0;
	margin-top: 20px;
	float: left;
	width: 100%;
}

.site-list-style-one-new li {
	font-family: 'Poppins', sans-serif;
	color: black;
	font-size: 16px;
	position: relative;
	padding-left: 30px;
	font-weight: 600;
	margin-bottom: 10px;
}

.site-list-style-one-new li::after {
	position: absolute;
	content: '';
	left: 0;
	top: 11px;
	width: 20px;
	height: 2px;
	background-color: #ff5e15;
}

/*product section*/
.icon-xl {
	line-height: 80px;
}

.icon-xl i {
	font-size: 80px;
	line-height: 80px;
}

.service-box-1-new {
	height: 600px;
	position: relative;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.service-box-title-new {
	margin-left: 0px;
	margin-bottom: 20px;
	font-size: 30px;
}

.services-style-new.owl-btn-vertical-center .owl-nav .owl-next {
	right: -1px;
}

.services-style-new.owl-btn-vertical-center .owl-nav .owl-next,
.services-style-new.owl-btn-vertical-center .owl-nav .owl-prev {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.services-style-new:hover.owl-btn-vertical-center .owl-nav .owl-next {
	right: 2%;
}

.services-style-new:hover.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 2%;
}

.services-style-new .owl-nav {
	opacity: 0;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.services-style-new:hover .owl-nav {
	opacity: 1;
}

.wt-icon-box-wraper-new {
	position: relative;
	margin-bottom: 40px;
}

.wt-icon-number-new {
	position: absolute;
	right: 0px;
	top: 0px;
}

.wt-icon-number-new span {
	font-size: 120px;
	line-height: 100px;
	color: rgba(0, 0, 0, 0.1);
	font-weight: 500;
	opacity: 0.5;
	display: block;
	font-family: 'Teko', sans-serif;
}

.service-box-new-link-border {
	border-top: 1px solid #ddd;
	padding-top: 10px;
}

.service-box-content-new p {
	margin-bottom: 0px;
}

/*What we do section */
.what-we-do-section2-new {
	position: relative;
}

.whatWedo-media-section-new {
	position: relative;
	margin-right: 30px;
}

@media only screen and (max-width:991px) {
	.whatWedo-media-section-new {
		margin-right: 0px;
	}
}

.whatWedo-media-section-new img {
	width: 100%;
}


.whatWedo-media-content-new {
	position: absolute;
	bottom: 0;
	left: 0;
	max-width: 355px;
}

.whatWedo-media-inner-new {
	position: relative;
	z-index: 1;
	padding: 30px;
}

.whatWedo-media-inner-new p {
	margin-bottom: 0px;
}


.whatWedo-info-section-new {
	margin-left: 0px;
}

.wt-icon-card-outer-new {
	padding-top: 15px;
}

.wt-icon-card-new {
	padding: 20px;
}

.wt-icon-card-new .wt-card-header-new {
	padding-bottom: 10px;
}

.wt-icon-card-new .wt-card-header-new i {
	font-size: 48px;
	padding-right: 10px;
	line-height: 48px;
	display: inline-block;
}

.wt-icon-card-new .wt-card-header-new span {
	font-size: 30px;
}

.wt-icon-card-new .wt-card-content-new p {
	margin-bottom: 0px;
}

.whatWedo-info-section-new .wt-icon-card-new {
	margin-bottom: 0px
}

.whatWedo-info-section-new .wt-icon-card-new:last-child {
	margin-bottom: 0px
}

/*Blog section */
.date-style-3 .wt-post-info {
	position: relative;
	padding: 30px;
}

.date-style-3.blog-post {
	margin-bottom: 0px;
}

.date-style-3 .wt-post-meta ul li {
	margin-right: 30px;
}

.date-style-3 .wt-post-meta ul li:last-child {
	margin-right: 0px;
}

.date-style-3 .wt-post-meta ul li.post-category {
	position: absolute;
	right: 0px;
	top: -40px;
	z-index: 1;
	margin-right: 0px;
}


.date-style-3 .wt-post-meta ul li.post-category span {
	color: #fff;
	text-align: center;
	background-color: #ff5e15;
	font-size: 16px;
	display: block;
	padding: 8px 15px;
}

.date-style-3 .wt-post-meta ul li.post-author,
.date-style-3 .wt-post-meta ul li.post-date,
.date-style-3 .wt-post-meta ul li.post-comment {
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	color: #ff5e15;
	position: relative;
	padding-top: 0px;

}

.date-style-3 .wt-post-readmore {
	padding-top: 10px;
}

.date-style-3 .wt-post-readmore a {
	color: #ff5e15;
}

.date-style-3 .wt-post-meta {
	margin-bottom: 10px;
}

.date-style-3 .wt-post-meta ul li:after {
	content: '';
	position: absolute;
	width: 16px;
	height: 2px;
	background-color: black;
	top: 12px;
	right: -30px;
}

.date-style-3 .wt-post-meta ul li.post-category:after {
	display: none;
}

.client-logo {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	padding: 20px 20px;
}

/*Testimonial 3*/

.testimonial-4-outer {
	position: relative;
}

.testimonial-4-content-outer {
	z-index: 1;
	top: 0px;
	position: inherit;
}

.testimonial-4-content .owl-nav .owl-prev {
	left: 15px
}

.testimonial-4-content .owl-nav .owl-next {
	right: 15px
}


.testimonial-4 {
	position: relative;
	text-align: center;
}

.testimonial-4 .testimonial-content {
	padding: 40px 55px;
	text-align: center;
}

.testimonial-4 .testimonial-pic {
	width: 114px;
	height: 114px;
	border: 3px solid #ececec;
}

.testimonial-4 .testimonial-info {
	padding: 0px;
}

.testimonial-4 .testimonial-text {
	padding: 0px;
	position: relative;
	z-index: 1;
}

.testimonial-4 .testimonial-text .fa-quote-left {
	font-size: 58px;
	color: #ff5e15;
	opacity: 0.2;
	margin: 20px 0px;
}

.testimonial-4 .testimonial-name {
	padding-right: 0px;
	display: block;
	font-size: 30px;
	font-weight: 500;
}

.testimonial-4 .testimonial-name::after {
	display: none;
}

.testimonial-4 .testimonial-position {
	display: block;
	font-size: 24px;
	position: relative;
	line-height: 24px;
}

.testimonial-4-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev .owl-stage-outer {
	padding-top: 0px;
}

.testimonial-4-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev .owl-nav {
	position: absolute;
	right: 30px;
	top: 170px;
	width: auto;
}


/*product style new*/
.btn-filter-wrap {
	list-style: none;
	margin: 90px 0 0;
}

.btn-filter-wrap li {
	position: relative;
	display: inline-block;
	font-size: 16px;
	color: #000;
	padding: 0 20px;
	cursor: pointer;
}

.btn-filter-wrap li:last-child {
	padding-right: 0;
}

.btn-filter-wrap li.btn-active {
	color: #FF5E15;
}

.btn-filter-wrap li:after {
	content: "/";
	position: absolute;
	left: -2px;
	top: 4px;
	font-size: 14px;
	opacity: 0.2;
	color: #000;
}

.btn-filter-wrap li:first-child:after {
	display: none;
}

.owl-carousel-filter .owl-item {
	opacity: 0;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.owl-carousel-filter .owl-item.active {
	opacity: 1;
}

.owl-carousel-filter .owl-item>.owl-item {
	opacity: 1;
}


.project-new-content {
	padding: 20px 0px 0px 0px;
}

.project-new-content span {
	display: block;
	padding-bottom: 10px;
	text-transform: uppercase;
	color: #ff5e15;
	letter-spacing: 1px;
}

.project-new-content .wt-title {
	margin-bottom: 0px;
}

@media only screen and (max-width:1199px) {
	.btn-filter-wrap {
		text-align: left;
		margin-top: 10px;
	}

	.btn-filter-wrap li:first-child {
		padding-left: 0px;
	}
}

/*New Video Section*/
.video-section-new-outer {
	padding-top: 0px;
	position: relative;
}

.video-new-info {
	padding: 30px;
	position: absolute;
	bottom: 0px;
	z-index: 1;
	max-width: 315px;
}

.video-new-info span {
	display: block;
	font-weight: 500;
	text-transform: uppercase;
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
	margin-bottom: 10px;
}

.video-new-info .wt-tilte {
	color: #fff;
	margin-bottom: 0px;
}

.video3-left-section {
	position: relative;
	height: 100%;
}

.video3-left-section .wt-media {
	position: absolute;
	bottom: 0px;
	width: auto;
}

.video3-left-section .wt-media img {
	width: 100%;
}

@media only screen and (max-width:991px) {
	.video3-left-section .wt-media {
		position: inherit;
	}
}

@media only screen and (max-width:767px) {
	.video-new-info {
		position: inherit;
	}
}


.counter3-outer {
	margin-top: 30px;
	margin-bottom: 50px;
}

.counter3-outer .counter:after {
	font-size: 70px;
	position: absolute;
	content: '+';
	right: -30px;
}

.counter3-outer span {
	font-size: 30px;
	display: block;
}

.counter3-outer .counter {
	font-size: 70px;
	position: relative;
	display: inline-block;
	margin-left: -30px;
}

.counter3-outer .counter::after {
	font-size: 70px;
	position: absolute;
	content: '+';
	right: -30px;
}

@media only screen and (max-width:1199px) {
	.counter3-outer .counter {
		font-size: 40px;
		line-height: 40px;
	}

	.counter3-outer .counter::after {
		font-size: 30px;
		right: -15px;
	}

	.counter3-outer span {
		font-size: 24px;
	}

	.video-new-info {
		padding: 15px;
		max-width: 100%;
		width: 100%;
	}
}

@media only screen and (max-width:767px) {
	.counter3-outer {
		margin-bottom: 20px;
	}
}


/*Footer*/
.footer-style3 p {
	font-size: 14px;
}

.footer-style3 .footer-top {
	padding-top: 80px;
	padding-bottom: 40px;
}

.footer-style3 .footer-bottom {
	border-top: 0px;
}

.footer-style3 .widget-title {
	margin-bottom: 42px;
}

.footer-style3 .logo-footer {
	margin-bottom: 25px;
}

.footer-style3.site-footer .widget_address {
	padding-top: 0px;
}

.footer-style3.footer-dark .footer-top .widget_newsletter .form-control {
	background-color: #fff;
	color: #000;
	margin-bottom: 10px;
}

.footer-style3 .nl-search-btn {
	color: #fff;
	display: block;
	width: 100%;
	font-size: 26px;
	padding: 3px 10px;
	margin-bottom: 28px;
}

.footer-style3 .newsletter-input input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #484848;
	font-size: 14px;
}

.footer-style3 .newsletter-input input::-moz-placeholder {
	/* Firefox 19+ */
	color: #484848;
	font-size: 14px;
	text-transform: uppercase;
}

.footer-style3 .newsletter-input input:-ms-input-placeholder {
	/* IE 10+ */
	color: #484848;
	font-size: 14px;
	text-transform: uppercase;
}

.footer-style3 .newsletter-input input:-moz-placeholder {
	/* Firefox 18- */
	color: #484848;
	font-size: 14px;
	text-transform: uppercase;
}

@media only screen and (max-width:767px) {
	.footer-style3 .footer-top {
		padding-top: 60px;
		padding-bottom: 10px;
	}
}

.new_blink {
	margin: 0 auto;
	text-align: center;
	color: red;
	font-size: 16px;
	font-weight: bold;
	position: absolute;
	-webkit-animation: colorchange 2s infinite alternate;
	animation: colorchange 2s infinite alternate;
	right: 15px;
}

@keyframes colorchange {
	0% {
		color: red;
	}

	10% {
		color: #8e44ad;
	}

	20% {
		color: #1abc9c;
	}

	30% {
		color: #d35400;
	}

	40% {
		color: blue;
	}

	50% {
		color: #34495e;
	}

	60% {
		color: red;
	}

	70% {
		color: #2980b9;
	}

	80% {
		color: #f1c40f;
	}

	90% {
		color: #2980b9;
	}

	100% {
		color: red;
	}
}

@-webkit-keyframes colorchange {
	0% {
		color: red;
	}

	10% {
		color: #8e44ad;
	}

	20% {
		color: #1abc9c;
	}

	30% {
		color: #d35400;
	}

	40% {
		color: blue;
	}

	50% {
		color: #34495e;
	}

	60% {
		color: red;
	}

	70% {
		color: #2980b9;
	}

	80% {
		color: #f1c40f;
	}

	90% {
		color: #2980b9;
	}

	100% {
		color: red;
	}
}


/*=====================================================================================
	New Home Page Update
=======================================================================================*/
.slider-block-3 {
	border: 40px solid #ea130e;
	position: relative;
	margin: 0px !important;
}

#webmax-two .rev-btn .site-button {
	padding: 8px 30px !important;
}

/*wide navigation*/
.wide-navigation .container {
	max-width: 100%;
	padding: 0px 50px;
}

@media only screen and (max-width:991px) {
	.wide-navigation .container {
		padding: 0px 15px;
	}
}

.header-style-4.wide-navigation .top-bar .wt-topbar-info li:first-child {
	padding-left: 0px;
}

.header-style-4.wide-navigation .top-bar {
	background-color: #000;
}


/*About two Section*/
.about-max-two {
	position: relative;
	z-index: 1;
	padding-right: 50px;
}

@media (max-width: 420px) {
	.about-max-two {
		padding-right: 0px;
	}
}

.about-max-two:after {
	content: '';
	background-color: #ea130e;
	position: absolute;
	z-index: -1;
	left: 0px;
	top: 0;
	bottom: 50%;
	width: 75%;
}

.about-max-two:before {
	background-image: url(../images/ui-9/background/bg-dott2.png);
	content: '';
	position: absolute;
	z-index: -1;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 45%;
}

.about-max-two .about-max-two-media {
	padding: 40px 0px 40px 40px;
}

.about-max-two .about-max-two-media img {
	width: 100%;
}

.about-section-two-right .section-head {
	margin-bottom: 40px;
}

.about-section-two-right .ab-two-info p {
	margin-bottom: 30px;
}


/*About Year*/
.about-two .about-year {
	padding: 0px;
}

.about-two .about-year .about-year-info {
	padding: 30px;
	background-color: rgba(0, 0, 0, 0.9);
	position: absolute;
	right: 0px;
	bottom: 60px;
	z-index: 1;
	display: flex;
	align-items: center;
	left: 60px;
}

.about-two .about-year .about-year-info span {
	display: block;
	font-size: 50px;
	line-height: 50px;
	color: #fff;
	font-weight: 700;
	border-right: 4px solid #ea130e;
	margin-right: 14px;
	padding-right: 10px;
}

.about-two .about-year .about-year-info p {
	font-size: 16px;
	margin-bottom: 0px;
	color: #b9b9b9;
	letter-spacing: 1px;
}

@media (max-width: 420px) {
	.about-two .about-year .about-year-info {
		position: inherit;
		width: 100%;
		max-width: 100%;
	}
}



/*----Hover Background---*/

.card1 {
	z-index: 1;
	overflow: hidden;
}

.card1:before {
	content: "";
	position: absolute;
	z-index: -1;
	bottom: -32px;
	left: 50%;
	background-color: #ea130e;
	height: 32px;
	width: 32px;
	border-radius: 32px;

	-webkit-transform: scale(1);
	transform: translateX(-50%) scale(1);

	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;

	-webkit-transition: -webkit-transform 1s ease-out;
	transition: -webkit-transform 1s ease-out;

	transition: transform 1s ease-out;
	transition: transform 1s ease-out, -webkit-transform 1s ease-out;
}

.card1:hover:before {
	-webkit-transform: scale(50);
	transform: scale(50);
}

.card1 .site-text-primary,
.card1 .s-title-one,
.card1 .d-icon-box-content p,
.card1 .wt-icon-number-new span {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;

}


.card1:hover .site-text-primary,
.card1:hover .s-title-one,
.card1:hover .service-box-content-new p {
	color: #fff;
}

.card1:hover .site-button-link,
.card1:hover .site-button-link:before {
	color: #fff
}

.card1:hover .wt-icon-number-new span {
	color: rgba(255, 255, 255, 0.5);
}




/*----quality-section-outer----*/
.quality-section-outer {
	position: relative;
}

.quality-section-content {
	margin-top: 220px;
	position: relative;
	top: -150px;
	margin-bottom: -150px;
}

.quality-video-section {
	padding-bottom: 35px;
	padding-top: 50px;
}

.quality-video-section span {
	display: block;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	max-width: 600px;
	margin: 0px auto;
}

.quality-video {
	position: relative;
	padding: 80px 0px;
}

@media only screen and (max-width:540px) {
	.quality-section-content {
		margin-top: 130px;
		top: -100px;
		margin-bottom: -100px;
	}

	.quality-video {

		padding: 100px 0px 0px 0px;
		margin-top: 40px;
	}
}



/*Project Style one*/
.project-gallery-style1-wrapper {
	overflow: hidden;
}

.project-gallery-block-outer {
	position: relative;
}

.project-gallery-style1 .owl-carousel .owl-stage-outer {
	display: table;
}

/*project style one*/
.project-box-style1 {
	position: relative;
	overflow: hidden;
}

.project-box-style1:after {
	content: '';
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	opacity: 0;
	background-color: #fff;
	transform-origin: 0 0;
	transform: scaleX(0);
	transition: transform 0.5s cubic-bezier(0.57, 0.04, 0.06, 0.84), opacity 0s 0.5s;
}

.project-box-style1:hover:after {
	opacity: 0.6;
	transform: scaleX(1);
	transition: transform 0.5s cubic-bezier(0.57, 0.04, 0.06, 0.84), opacity 0s;
}

.project-box-style1 .project-content {
	padding: 20px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	z-index: 1;
}

.project-box-style1 .project-content .project-title {
	margin-bottom: 15px;
	display: inline-block;
	font-weight: 600;
	font-size: 12px;
	background-color: #000;
	padding: 8px 16px;
	color: #fff;
	opacity: 0;
	transform: translate3d(0%, -100%, 0);
	transition: transform .4s, opacity .2s;
}

.project-box-style1 .project-content .project-title-large {
	margin-bottom: 0px;
	position: relative;
	opacity: 0;
	transform: translate3d(0, -100%, 0);
	transition: transform .4s, opacity .2s;
}

.project-box-style1 .project-content .project-title-large a {
	color: #000;
	transition: 0.5s all ease;
}

.project-box-style1 .project-content .project-title-large a:hover {
	color: #ea130e;
}

.project-box-style1 .project-view {
	position: absolute;
	top: 20px;
	left: 50%;
	opacity: 0;
	transform-origin: 0 0;
	transform: scaleY(0) translateX(-50%);
	transition: transform 0.5s cubic-bezier(0.57, 0.04, 0.06, 0.84), opacity 0s 0.5s;
}

.project-box-style1 .project-view .project-view-btn {
	position: relative;
	display: block;
	width: 80px;
	height: 80px;
}

.project-box-style1 .project-view .project-view-btn i {
	width: 80px;
	height: 80px;
	display: block;
	background-color: #ea130e;
	position: relative;
}

.project-box-style1 .project-view .project-view-btn i:after,
.project-box-style1 .project-view .project-view-btn i:before {
	content: '';
	background-color: #fff;
	position: absolute;
}

.project-box-style1 .project-view .project-view-btn i:after {
	width: 1px;
	height: 24px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.project-box-style1 .project-view .project-view-btn i:before {
	width: 24px;
	height: 1px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.project-box-style1:hover .project-view {
	opacity: 1;
	z-index: 3;
	transform: scaleY(1) translateX(-50%);
	transition: transform 0.5s cubic-bezier(0.57, 0.04, 0.06, 0.84), opacity 0s;
}

.project-box-style1:hover .project-title {
	opacity: 1;
	transform: translate3d(0, 0%, 0);
	transition: transform 1s cubic-bezier(0.57, 0.04, 0.06, 0.84) 0.1s, opacity 0.2s 0.1s;
}

.project-box-style1:hover .project-title-large {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: transform 0.6s cubic-bezier(0.57, 0.04, 0.06, 0.84) 0.1s, opacity 0.2s 0.1s;
}

.project-box-style1 .project-media img {
	width: 100%;
}


.project-gallery-one.owl-btn-vertical-center .owl-nav .owl-next {
	right: -1px;
}

.project-gallery-one.owl-btn-vertical-center .owl-nav .owl-next,
.project-gallery-one.owl-btn-vertical-center .owl-nav .owl-prev {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.project-gallery-one:hover.owl-btn-vertical-center .owl-nav .owl-next {
	right: 5%;
}

.project-gallery-one:hover.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 5%;
}

.project-gallery-one .owl-nav {
	opacity: 0;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.project-gallery-one:hover .owl-nav {
	opacity: 1;
}



/*----Team Style 10--*/
.wt-team-10 {
	margin-left: 30px;
	position: relative;
}

.wt-team-10 .team-name a:hover {
	color: #ea130e;
}

.wt-team-10 .team-position {
	font-size: 20px;
	line-height: 20px;
	text-transform: uppercase;
	font-weight: 600;
	position: absolute;
	left: -50%;
	top: -8px;
	margin-left: -15px;
	width: 100%;
	white-space: nowrap;

	letter-spacing: 1px;
	transform: rotate(-90deg) translateX(-50%);
	-webkit-transform: rotate(-90deg) translateX(-50%);
	-moz-transform: rotate(-90deg) translateX(-50%);

	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.wt-team-10 .wt-media img {
	width: 100%;
}

.wt-team-10 .team-social-center {
	position: relative;
}

.wt-team-10 .team-social-center ul {
	list-style: none;
	margin-bottom: 0px;
}

.wt-team-10 .team-social-center ul li {
	display: inline-block;
	padding-right: 16px;
}

.wt-team-10 .team-social-center ul li:last-child {
	padding-right: 0px;
}

.wt-team-10 .team-social-center ul li a {
	font-size: 16px;
	font-weight: 500;
	position: relative;
	color: #777;
}

.wt-team-10 .team-social-center ul li a:after {
	position: absolute;
	right: -14px;
	content: '';
	width: 8px;
	height: 1px;
	background-color: #777;
	top: 10px;
}

.wt-team-10 .team-social-center ul li:last-child a:after {
	display: none;
}

.wt-team-10 .wt-info {
	position: static;
	text-align: center;
}

.wt-team-10:hover .team-social-center {
	top: 0px;
}

.wt-team-10 .team-social-center ul li a:hover {
	color: #ea130e;
}


/*-------------------------------------
	16. Latest-blog-3
-------------------------------------*/
.latest-blog-3-pattern {
	position: relative;
	z-index: 1;
}

.latest-blog-3.post-overlay .wt-post-info {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: table;
}

.latest-blog-3.post-overlay {
	height: 520px;
}

.latest-blog-3.post-overlay .wt-post-info .post-overlay-position {
	display: table-cell;
	vertical-align: bottom;
}

.latest-blog-3.post-overlay .wt-post-meta a {
	color: #fff;
}

.latest-blog-3.post-overlay .wt-post-meta i {
	color: #fff;
}

.latest-blog-3.large-date .post-date {
	position: absolute;
	top: 30px;
	right: 30px;
	background-color: #f00;
	padding: 20px;
	text-align: center;
}

.latest-blog-3.large-date .post-date strong {
	font-size: 50px;
	line-height: 50px;
	display: block;
	font-weight: 900;
	opacity: 1;
	color: #fff;
}

.latest-blog-3.large-date .post-date span {
	font-size: 14px;
	display: block;
}

.latest-blog-3 .wt-post-meta li:after {
	display: none;
}

.latest-blog-3 .wt-post-readmore a {
	color: #ff0000;
}





/*============================================================================================================================================
	New Home Page 2
===============================================================================================================================================*/
@media only screen and (max-width:767px) {
	.icon-circle-box-outer.m-b30 {
		margin-bottom: 0px;
	}
}

.icon-circle-box {
	position: relative;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.icon-circle-box .wt-icon-box-wraper {
	padding: 30px 0px 20px 0px;
}

.icon-circle-box .icon-content {
	overflow: inherit;
	z-index: 1;
	position: relative;

}

.icon-circle-box .icon-circle {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	background-color: #fff;
	position: relative;
	z-index: 1;
	margin-bottom: 100px;
}

.icon-circle-box .icon-circle:before {
	content: "";
	left: -10px;
	top: -10px;
	position: absolute;
	z-index: -1;
	width: 120px;
	height: 120px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	border: 10px solid #ff0000;
}

.icon-circle-box .icon-circle:after {
	content: "";
	left: -20px;
	top: -20px;
	right: -20px;
	position: absolute;
	z-index: -1;
	border: 1px dashed #f00;
	bottom: -20px;
	border-radius: 50%;
}

.icon-circle-box span.icon-cell {
	line-height: 100px;
}

.icon-count-number {
	position: absolute;
	top: -70px;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	font-family: 'Roboto', sans-serif;
	font-size: 70px;
	line-height: 70px;
	font-weight: 900;
	opacity: 0.1;
	color: #0f1221;
	z-index: -1;
}

.icon-circle-box {
	text-align: center;
	position: relative;
}

.icon-circle-box .icon-content p span {
	display: block;
}



/*About Three Section*/
.about-max-three {
	position: relative;
	z-index: 1;
	padding-right: 50px;
}

@media (max-width: 420px) {
	.about-max-three {
		padding-right: 0px;
	}
}


.about-max-three:before {
	background-image: url(../images/ui-9/background/bg-dott2.png);
	content: '';
	position: absolute;
	z-index: -1;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 45%;
}

.about-max-three .about-max-three-media {
	padding: 40px 0px 40px 0px;
}

.about-max-three .about-max-three-media img {
	width: 100%;
}

.about-section-three-right .section-head {
	margin-bottom: 40px;
}

.about-section-three-right .ab-three-info p {
	margin-bottom: 30px;
}


/*About Year*/
.about-three .about-year {
	padding: 0px;
}

.about-three .about-year .about-year-info {
	padding: 30px;
	background-color: rgba(255, 0, 0, 0.8);
	position: absolute;
	right: 70px;
	bottom: 60px;
	z-index: 1;
	display: block;
	max-width: 250px;
}

.about-three .about-year .about-year-info span {
	display: block;
	font-size: 50px;
	line-height: 50px;
	color: #fff;
	border-bottom: 4px solid #fff;
	margin-bottom: 15px;
	padding-bottom: 10px;
}

.about-three .about-year .about-year-info .wt-tilte {
	margin-bottom: 0px;
	color: #fff;
}

@media (max-width: 460px) {
	.ab-three-info {
		display: block;
	}

	.ab-three-info img {
		max-width: 110px;
		float: right;
		padding-top: 20px;
	}
}

@media (max-width: 420px) {
	.about-three .about-year .about-year-info {
		position: inherit;
		width: 100%;
		max-width: 100%;
	}
}



/*Project*/

.projects-two-info {
	border: 6px solid #e6e6e6;
	padding: 0px 30px 30px 30px;
	margin-left: 70px;
	position: relative;
	z-index: 1;
}

.projects-two-info:after {
	content: '';
	position: absolute;
	left: 100%;
	bottom: 0px;
	top: 0px;
	height: 100%;
	width: 0px;
	z-index: -1;
	background-color: #000;
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;

}

.projects-two-info:hover:after {
	width: 100%;
	left: 0;
	-webkit-transition: width 0.3s ease;
	transition: width 0.3s ease;
	z-index: -1;
}

.projects-two-info.bg-white:hover .wt-tilte a,
.projects-two-info.bg-white:hover p {
	color: #fff;
}

.projects-two-info.bg-white:hover .wt-tilte a:hover {
	color: #ff0000;
}

.projects-two-info .wt-media {
	margin-top: 30px;
	margin-left: -100px;
}

.projects-two-info .wt-tilte {
	position: relative;
	z-index: 1;
	margin-bottom: 0px;
	padding-top: 80px;
	padding-right: 26px;
}

.projects-two-info .wt-tilte:after {
	font-family: 'Roboto', sans-serif;
	position: absolute;
	letter-spacing: 2px;
	content: attr(data-title);
	opacity: 0.5;
	color: #000;
	font-weight: 800;
	font-size: 50px;
	line-height: 60px;
	text-transform: uppercase;
	top: 20px;
	left: 0px;
	z-index: -1;

	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;

	-webkit-text-fill-color: rgba(0, 0, 0, 0);
	-ms-text-fill-color: rgba(0, 0, 0, 0);
	-moz-text-fill-color: rgba(0, 0, 0, 0);
	-o-text-fill-color: rgba(0, 0, 0, 0);

	-webkit-text-stroke-width: 1px;
	-ms-text-stroke-width: 1px;
	-moz-text-stroke-width: 1px;
	-o-text-stroke-width: 1px;

	-webkit-text-stroke-color: #fff;
	-ms-text-stroke-color: #fff;
	-moz-text-stroke-color: #fff;
	-o-text-stroke-color: #fff;

}

.projects-two-info.bg-white .wt-tilte:after {
	-webkit-text-stroke-color: #000;
	-ms-text-stroke-color: #000;
	-moz-text-stroke-color: #000;
	-o-text-stroke-color: #000;
}

.projects-two-info.bg-white:hover .wt-tilte:after {
	-webkit-text-stroke-color: #fff;
	-ms-text-stroke-color: #fff;
	-moz-text-stroke-color: #fff;
	-o-text-stroke-color: #fff;
}

/*cardbg*/
.cardbg {
	position: relative;
	z-index: 1;
	border: 1px solid #ddd;

	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;

}

.cardbg-pic {
	background-size: cover;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	z-index: -1;
	opacity: 0;

	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

.cardbg:hover {
	background-color: #000 !important;
	border: 1px solid transparent;
	color: #fff;
}

.cardbg:hover .s-title-one,
.cardbg:hover .site-button-link {
	color: #fff;
}


.cardbg:hover .cardbg-pic {
	opacity: 0.2;

}


/*latest-blog-2*/
.latest-blog-2 .wt-post-text {
	margin-bottom: 20px;
}

.latest-blog-2 .wt-post-meta {
	margin-bottom: 15px;
}

@media only screen and (max-width:767px) {
	.latest-blog-2-wraper {
		padding-bottom: 10px !important;
	}
}

/*Our Team*/

@media only screen and (max-width:767px) {
	.arc2-team-wrapper {
		padding-bottom: 10px !important;
	}
}

@media only screen and (max-width:575px) {
	.arc2-team-wrapper {
		padding-bottom: 30px !important;
	}
}

.wt-team-arc2 {
	padding: 20px;
	position: relative;
	z-index: 1
}

.wt-team-arc2:after {
	content: "";
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	border: 3px solid #2f3b42;
	z-index: -1;

	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
}


.wt-team-arc2:hover:after {
	width: 50%;
	border: 3px solid #d5dd02;
}

.wt-team-arc2 .wt-media {
	position: relative;
	overflow: hidden;
}

.wt-team-arc2 .team-social-center {
	position: absolute;
	left: -100%;
	top: 0px;
	z-index: 2;

	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;


	height: 100%;
}

.wt-team-arc2:hover .team-social-center {
	left: 0%;
}

.wt-team-arc2 .team-social-icon {
	list-style: none;
	margin-bottom: 0px;
	position: relative;
	left: 0%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.wt-team-arc2 .team-social-icon li {
	display: block;
	margin: 0px 10px;
	background-color: #000;
}

.wt-team-arc2 .team-social-icon li a {
	color: #fff;
	font-size: 14px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
}

.wt-team-arc2 .team-social-icon li a:hover {
	color: #ff0000;

}

.wt-team-arc2 .wt-info {
	-webkit-box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
}

.wt-team-arc2 .wt-info p {
	font-weight: 400;
	margin-bottom: 0px;
}


.wt-team-arc2.team-style2 {
	margin-top: 100px;
}

.wt-team-arc2:hover.team-style2 .team-social-center {
	bottom: -40px;
}

.wt-team-arc2.team-style2 .wt-media:after {
	display: none;
}

.wt-team-arc2.team-style2 .wt-media {
	padding: 0px;
}


.wt-team-arc2.wt-team-arc2-no-border {
	padding: 0px;
}

.wt-team-arc2.wt-team-arc2-no-border .wt-info {
	box-shadow: none;
}

.wt-team-arc2.wt-team-arc2-no-border:after {
	display: none;
}


/*testimonial 5*/

.testimonial-slider-5 {
	margin-top: 50px;
}

.testimonial-5 {
	padding: 0px 2px;
	margin-bottom: -30px;
}

.testimonial-5 .testimonial-text {
	background-color: #191919;
	padding: 50px;
	position: relative;
}

.testimonial-5 .testimonial-text p {
	margin-bottom: 20px;
	color: #d4d4d4;
	font-style: normal;
}

.testimonial-5 .testimonial-pic-block {
	position: relative;
	width: 100%;
	left: 50px;
	bottom: 35px;
	right: 0px;
}

.testimonial-5 .testimonial-pic {
	border: 12px solid #fff;
	width: 100px;
	height: 100px;
}

.testimonial-5 .testimonial-paragraph {
	position: relative;
	padding-top: 50px;
}

.testimonial-5 .testimonial-detail .testimonial-name {
	padding: 0px;
	font-size: 30px;
	color: #fff;
	margin-bottom: 0px;
}

.testimonial-5 .testimonial-detail .testimonial-name:after {
	display: none;
}

.testimonial-5 .testimonial-detail .testimonial-position {
	font-size: 14px;
	color: #fff;
	padding: 0px 0px 15px 0px;
}

.testimonial-5 .testimonial-detail {
	padding-top: 0px;
}

.testimonial-5 .quote-left::before,
.testimonial-5 .quote-right::after {
	font-family: "FontAwesome";
	position: absolute;
	z-index: 10;
	font-size: 36px;
	opacity: 1;
}

.testimonial-5 .quote-left::before {
	content: "\f10d";
	left: 0px;
	top: -60px;
	color: #f00;
}



/*Video Section*/
.quality-video2 {
	position: relative;
}













/*====================================================================================================================================
	Home Page 11 Css Start
=====================================================================================================================================*/
/*header style 11 css start*/
.header-style-11 {
	position: relative;
}

.header-style-11 .top-bar {
	border-bottom: 1px solid #dcdcdc;
	padding: 0px;
	overflow: hidden;
}

.header-style-11 .top-bar .wt-topbar-left-info {
	margin: 0px;
}

.header-style-11 .top-bar .wt-topbar-left-info li {
	display: inline-block;
	color: #000;
	padding: 0px 15px;
	border-right: 1px solid #dcdcdc;
	line-height: 20px;
	padding-top: 10px;
}

.header-style-11 .top-bar .wt-topbar-left-info li:first-child {
	padding-left: 0px;
}

.header-style-11 .top-bar .wt-topbar-left-info li:last-child {
	border-right: 0px;
}

.header-style-11 .top-bar .wt-topbar-right-info {
	margin: 0px;
}

.header-style-11 .top-bar .wt-topbar-right-info ul {
	margin-bottom: 0px;
	display: flex;
	border-right: 1px solid #dcdcdc;
	list-style: none;
	background-color: #fff;
}

.header-style-11 .top-bar .wt-topbar-right-info ul li a {
	list-style: none;
	display: block;
	position: relative;
	width: 40px;
	height: 42px;
	line-height: 42px;
	border-left: 1px solid #dcdcdc;
	text-align: center;
}

.header-style-11 .top-bar .wt-topbar-right-info ul li a:hover {
	background-color: #ff5e15;
	color: #fff;
}

.header-style-11 .top-bar .container {
	position: relative;
	z-index: 1;
}

.header-style-11 .top-bar .container:after {
	content: '';
	background-color: #fff;
	left: 100%;
	margin-left: -15px;
	height: 100%;
	width: 3000px;
	bottom: 0px;
	position: absolute;
	z-index: 2;
}

.header-style-11 .header-style-11-content {
	position: relative;
}

.header-style-11 .header-middle-wraper {
	padding: 0px 0px;
	background-color: #fff;
	position: relative;
	z-index: 1;
}

.header-style-11 .header-info-wraper {
	display: flex;
}

.header-style-11 .header-info-wraper .header-info .icon-content {
	padding-top: 0px;
}

.header-style-11 .header-info-wraper .header-info>ul {
	list-style: none;
	display: table;
}

.header-style-11 .header-info-wraper .header-info>ul li {
	display: table-cell;
	padding: 15px 10px;
	vertical-align: middle;
}

.header-style-11 .header-info-wraper .header-info>ul li:last-child {
	padding-right: 0px;
}

.header-style-11 .header-info-wraper .header-info>ul li p {
	margin-bottom: 0px;
}

.header-style-11 .header-info-wraper .header-info>ul li.wt-icon-box-xs {
	margin-right: 15px;
}

@media (max-width: 991px) {
	.header-style-11 .header-middle-wraper .container {
		max-width: 100%;
	}

	.header-style-11 .header-info-wraper .header-info>ul li .wt-tilte {
		font-size: 18px;
	}

	.header-style-11 .header-info-wraper .header-info>ul li p {
		font-size: 14px;
	}

}

.header-style-11 .header-bottom {
	border-top: 1px solid #e7e7e7;
}

.header-style-11 .header-search {
	padding: 15px 15px;
	border-left: 1px solid #e7e7e7;
	display: flex;
	align-items: center;
}

.header-style-11 .header-search .form-control {
	border: 1px solid #e7e7e7;
	height: 40px;
}

.header-style-11 .header-search .s-btn {
	border: none;
	padding-left: 15px;
	padding-right: 15px;
}

.header-style-11 .extra-nav {
	position: relative;
	z-index: 13;
	display: flex;
	height: auto;
}

.header-style-11 .extra-nav .extra-cell {
	display: flex;
}

.header-style-11 .extra-nav .extra-cell:first-child {
	padding-left: 0px;
}

.header-style-11 .logo-header {
	display: table;
	float: left;
	width: 215px;
	height: 70px;
	vertical-align: middle;
	padding: 0px;
	font-size: 36px;
	color: #000;
	margin: 0px;
	position: relative;
	z-index: 11;
}

.header-style-11.bg-half-block-bar .header-middle {
	position: relative;
}

.header-style-11.bg-half-block-bar .header-middle:after {
	content: '';
	background-color: #ff5e15;
	right: calc(100% - 250px);
	height: 100%;
	width: 3000px;
	bottom: 0px;
	position: absolute;
	z-index: -1;
}

.header-style-11 .logo-header .logo-header-inner {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
}

.header-style-11 .logo-header .logo-header-inner img {
	max-width: 100%;
	max-height: 100%;
}

.header-style-11.site-header {
	position: relative;
	z-index: 999;
}

.header-style-11.site-header ul,
.header-style-11.site-header ol {
	margin-bottom: 0px;
}

.header-style-11 .main-bar {
	position: relative;
	width: 100%;
	transition: all 0.5s ease;
	padding: 0px;
}

.header-style-11 .main-bar .container-block,
.header-style-11 .main-bar .container-block-fluid {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	max-width: 1110px;
}

.header-style-11 .main-bar .container-block .navigation-bar,
.header-style-11 .main-bar .container-block-fluid .navigation-bar {
	background-color: #fff;
	display: flex;
	width: 100%;
}

.header-style-11 .main-bar:after,
.header-style-11 .main-bar:after {
	clear: both;
	content: "";
	display: table;
}

.header-style-11 .navbar-toggler {
	border: none;
	margin: 24px 0px 24px 5px;
	padding: 12px 16px 12px 10px;
}

.header-style-11 .navbar-toggler .icon-bar {
	background: #000;
	height: 3px;
	border-radius: 0px;
	display: block;
	width: 22px;
}

.header-style-11 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-11 .is-fixed.mobile-sider-drawer-menu .header-nav .nav {
	height: 100vh !important;
	width: 100%;
}

.header-style-11 .is-fixed.mobile-sider-drawer-menu .header-nav .nav>li>a {
	padding: 12px 15px !important;
}

.header-style-11 .header-nav {
	position: relative;
	padding: 0px;
	z-index: 10;
}

.header-style-11 .header-nav .nav {
	display: flex;
	justify-content: flex-end;
	flex-direction: inherit;
	border-left: 1px solid #e7e7e7;
}

.header-style-11 .header-nav .nav li i {
	font-size: 9px;
	margin-left: 3px;
	margin-top: -3px;
	vertical-align: middle;
	opacity: 0.7;
}

.header-style-11 .header-nav .nav>li {
	position: relative;
	margin: 0px;
	padding: 0px 0px;
	border-right: 1px solid #e7e7e7;
}

.header-style-11 .header-nav .nav>li>a {
	color: #000;
	font-size: 16px;
	padding: 15px 15px;
	cursor: pointer;
	margin: 0px 0px;
	font-weight: 600;
	display: block;
}

.header-style-11 .header-nav .nav>li>a:hover {
	background-color: transparent;
	color: #000;
}

.header-style-11 .header-nav .nav>li>a:active,
.header-style-11 .header-nav .nav>li>a:focus {
	background-color: transparent;
}

.header-style-11 .header-nav .nav>li.active>a,
.header-style-11 .header-nav .nav>li.current-menu-item>a {
	background-color: transparent;
	color: #ff5e15;
}

.header-style-11 .header-nav .nav>li:hover>a {
	color: #ff5e15;
}

.header-style-11 .header-nav .nav>li:hover>.sub-menu {
	opacity: 1;
	visibility: visible;
	margin-top: 0px;
	transition: all 0.3s ease;
}

.header-style-11 .header-nav .nav>li .sub-menu {
	background-color: #fff;
	display: block;
	left: 0px;
	top: 100%;
	list-style: none;
	margin: 20px 0 0;
	opacity: 0;
	padding: 0px;
	position: absolute;
	visibility: hidden;
	width: 250px;
	z-index: 10;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.header-style-11 .header-nav .nav>li .sub-menu li {
	border-bottom: 1px solid #f4f4f4;
	position: relative;
	text-transform: none;
	height: 0px;
}

.header-style-11 .header-nav .nav>li .sub-menu li a {
	color: #000;
	display: block;
	font-size: 13px;
	padding: 14px 20px;
	font-weight: 600;
}

.header-style-11 .header-nav .nav>li .sub-menu li a:hover {
	color: #000;
	text-decoration: none;
}

.header-style-11 .header-nav .nav>li .sub-menu li:hover>a {
	color: #ff5e15;
}

.header-style-11 .header-nav .nav>li .sub-menu li:last-child {
	border-bottom: 0px;
}

.header-style-11 .header-nav .nav>li .sub-menu li .fa {
	color: #000;
	display: block;
	float: right;
	position: absolute;
	right: 10px;
	top: 2px;
}

.header-style-11 .header-nav .nav>li .sub-menu li>.sub-menu {
	left: 250px;
	margin: 0px 0px 0px 20px;
	transition: all 0.1s ease;
}

.header-style-11 .header-nav .nav>li .sub-menu li:hover>.sub-menu {
	left: 250px;
	margin: 0px;
	opacity: 1;
	top: 0px;
	visibility: visible;
}

.header-style-11 .header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
	background-color: transparent;
	bottom: 0px;
	content: '';
	display: block;
	height: 100%;
	left: -6px;
	position: absolute;
	top: 0px;
	width: 6px;
}

.header-style-11 .header-nav .nav>li:hover .sub-menu li {
	height: auto;
}

.header-style-11 .header-nav .submenu-direction .sub-menu {
	left: auto;
	right: 0px;
}

.header-style-11 .header-nav .submenu-direction .sub-menu li>.sub-menu {
	left: auto;
	right: 250px;
	margin: 0px 20px 0px 0px;
}

.header-style-11 .header-nav .submenu-direction .sub-menu li:hover>.sub-menu {
	left: auto;
	right: 250px;
	margin: 0px 0px 0px 0px;
}

.header-style-11 .header-nav.nav-animation .nav>li>ul.sub-menu li>a {
	transition: all 0.5s ease-out;
	position: relative;
	z-index: 1;
}

.header-style-11 .header-nav.nav-animation .nav>li>ul.sub-menu li>a:hover:before {
	opacity: 1;
	left: 20px;
}

.header-style-11 .header-nav.nav-animation .nav>li:hover>ul.sub-menu li:hover>a {
	padding-left: 30px;
}

.header-style-11 .has-child .submenu-toogle {
	position: absolute;
	right: 4px;
	top: 4px;
	color: #000;
	background-color: transparent;
	z-index: 900;
	cursor: pointer;
	padding: 10px;
	font-size: 14px;
	display: none;
}

.header-style-11 .has-child li .submenu-toogle {
	opacity: 0.9;
}

.header-style-11 .has-child.nav-active>a+.submenu-toogle.glyphicon-chevron-right:before {
	content: "\e114";
}

.header-style-11 .has-child.nav-active>a+.submenu-toogle.glyphicon-plus::before {
	content: "\2212";
}

@media (max-width: 991px) {
	.header-style-11 .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right::before {
		content: "\f107";
	}
}

.header-style-11 .sticky-no .main-bar {
	position: static !important;
}

.header-style-11 .is-fixed .main-bar {
	position: fixed;
	top: 0px;
	left: 0px;
}

.header-style-11 .is-fixed.color-fill .header-nav .nav>li {
	margin: 0px;
	padding: 0px;
}

.header-style-11 .wt-cart {
	border-left: 1px solid #e7e7e7;
	border-right: 1px solid #e7e7e7;
	display: flex;
	align-items: center;
}

.header-style-11 .wt-cart .woo-cart-total {
	display: inline-block;
	font-weight: 600;
	color: #000;
}

.header-style-11 .wt-cart .woo-cart-count {
	color: #000;
	font-weight: 800;
	display: inline-block;
	min-width: 40px;
	padding: 0px 10px;
	position: relative;
	text-align: center;
	position: relative;
}

.header-style-11 .wt-cart .woo-cart-count i {
	font-size: 16px;
}

.header-style-11 .wt-cart .woo-cart-count .shopping-bag.wcmenucart-count {
	padding-left: 3px;
	position: relative;
	top: -5px;
}


@media only screen and (max-width: 360px) {
	.header-style-11 .top-bar {
		display: none;
	}

	.header-style-11 .logo-header {
		width: 120px;
	}
}

@media only screen and (max-width: 420px) {
	.header-style-11 .navbar-toggler {
		margin-left: 10px;
	}
}

@media only screen and (max-width: 540px) {
	.header-style-11 .top-bar .wt-topbar-left-info li {
		padding: 2px 0px 0px 0px;
		width: 100%;
		border: 0px;
	}

	.header-style-11 .is-fixed .navbar-toggler {
		margin-bottom: 0px;
	}
}

@media only screen and (max-width: 640px) {
	.header-style-11 .header-info-wraper {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	.header-style-11 .main-bar {
		padding: 0px;
	}

	.header-style-11 .main-bar .container {
		max-width: 100%;
	}

	.header-style-11 .is-fixed .main-bar {
		padding: 0px;
	}

	.header-style-11 .is-fixed.color-fill .main-bar {
		padding: 0px;
	}
}

@media only screen and (max-width: 768px) {
	.header-style-11 .header-middle-wraper .header-middle {
		display: block !important;
	}

	.header-style-11 .header-middle-wraper .logo-header {
		display: block;
		width: 100%;
		max-width: 150px;
		float: inherit;
		margin: 0px auto;
		height: auto;
	}

	.header-style-11 .header-info-wraper {
		justify-content: center;
	}
}

@media only screen and (max-width: 991px) {
	.header-style-11 .top-bar .container:after {
		display: none;
	}

	.header-style-11 .top-bar .wt-topbar-left-info li {
		padding: 10px 10px 0px 10px;
		font-size: 14px;
	}

	.header-style-11 .header-middle-wraper:after {
		display: none;
	}

	.header-style-11 .main-bar .container-block,
	.header-style-11 .main-bar .container-block-fluid {
		display: block;
	}

	.header-style-11 .main-bar .container-block .navigation-bar,
	.header-style-11 .main-bar .container-block-fluid .navigation-bar {
		flex-direction: row-reverse;
	}

	.header-style-11 .navbar-toggler {
		display: block;
		margin-top: 5px;
		margin-bottom: 5px;
		float: right;
		outline: none !important;
	}

	.header-style-11 .header-nav {
		clear: both;
		border-bottom: 1px solid #E9E9E9;
	}

	.header-style-11 .header-nav.navbar-collapse {
		align-items: start;
	}

	.header-style-11 .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		display: block;
		width: 100%;
		left: 0px;
	}

	.header-style-11 .header-nav .nav li {
		float: none;
	}

	.header-style-11 .header-nav .nav li.has-child.nav-active>a {
		color: #ff5e15;
	}

	.header-style-11 .header-nav .nav>li ul {
		display: none;
		position: static;
		visibility: visible;
		width: auto;
		background: #f9f9f9;
	}

	.header-style-11 .header-nav .nav li i {
		float: right;
	}

	.header-style-11 .header-nav .nav>li>a {
		padding: 12px 10px;
		color: #000;
	}

	.header-style-11 .header-nav .nav>li.active>a,
	.header-style-11 .header-nav .nav>li.current-menu-item>a {
		color: #000;
	}

	.header-style-11 .header-nav .nav>li>a:hover,
	.header-style-11 .header-nav .nav>li>a:active,
	.header-style-11 .header-nav .nav>li>a:focus {
		background-color: #f0f0f0;
		text-decoration: none;
	}

	.header-style-11 .header-nav .nav>li .sub-menu {
		display: none;
		position: static;
		visibility: visible;
		width: auto;
		background: #f9f9f9;
		opacity: 1;
		margin: 0px !important;
		box-shadow: none;
	}

	.header-style-11 .header-nav .nav>li .sub-menu li {
		height: auto;
	}

	.header-style-11 .header-nav .nav>li .sub-menu li>.sub-menu {
		transition: none;
	}

	.header-style-11 .header-nav .nav>li .sub-menu li>.sub-menu>li {
		float: none;
		display: block;
		width: auto;
	}

	.header-style-11 .header-nav.nav-animation .nav>li>ul.sub-menu li>a:hover:before {
		left: 0;
	}

	.header-style-11 .header-nav.nav-animation .nav>li:hover>ul.sub-menu li:hover>a {
		padding-left: 20px;
	}

	.header-style-11 .has-child {
		position: relative;
	}

	.header-style-11 .has-child .submenu-toogle {
		display: block;
	}

	.header-style-11 .mobile-full-width-menu .header-nav .nav {
		position: inherit;
	}

	.header-style-11.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
		margin: 0px;
	}

	.header-style-11.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-11.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-11.mobile-sider-drawer-menu.active .header-nav {
		left: -1px;
	}

	.header-style-11.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	.header-style-11.mobile-sider-drawer-menu #mobile-side-drawer {
		position: relative;
	}

	.header-style-11.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-11.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-11.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-11.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-11.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-11.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-11 .header-nav .nav>li {
		margin: 0px;
		padding: 0px;
	}

	.header-style-11 .header-nav .nav>li>a {
		margin: 0px;
		padding: 10px 12px;
	}

	.header-style-11 .is-fixed.color-fill .header-nav .nav>li {
		margin: 0px;
		padding: 0px;
	}
}

@media only screen and (max-width: 1200px) {
	.header-style-11 .logo-header {
		width: 150px;
	}
}

/*Cart dropdown=============================================================*/
.cart-dropdown-item-wraper {
	top: 110%;
	right: 0px;
	position: absolute;
	z-index: 999;
	background-color: #f9f9f9;
	padding: 2px;
	border: 3px solid #eaeaea;
	width: 280px;
	display: none;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
	text-align: left;
}

@media only screen and (max-width: 480px) {
	.cart-dropdown-item-wraper {
		right: -50px;
	}
}

.nav-cart-items {
	position: relative;
}

.nav-cart-items:before {
	border-bottom: 8px solid #efefef;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	content: "";
	height: 0;
	position: absolute;
	right: 15px;
	top: -12px;
	width: 0;
}

@media only screen and (max-width: 480px) {
	.nav-cart-items:before {
		right: 65px;
	}
}

.nav-cart-title {
	border-bottom: 1px solid #eee;
}

.nav-cart-title h4 {
	font-size: 24px;
	font-weight: 400;
	margin: 0px;
	margin-right: 5px;
}

.nav-cart-title h5 {
	font-size: 24px;
	font-weight: 500;
	margin: 0px;
}

.nav-cart-item {
	border-top: 1px solid #f5f5f5;
	padding: 10px;
	background-color: #fff;
}

.nav-cart-item-image {
	float: left;
	height: 48px;
	margin-right: 15px;
	transition: border-color 0.2s linear 0s;
	width: 48px;
}

.nav-cart-item-image a {
	display: block;
	height: 44px;
	width: 44px;
}

.nav-cart-item-image img {
	display: block;
	height: 44px;
	width: 44px;
}

.nav-cart-item-image:hover {
	border-color: #243447;
}

.nav-cart-item-desc {
	position: relative;
	padding-right: 15px;
}

.nav-cart-item-desc a {
	font-size: 13px;
	font-weight: 600;
	height: auto;
	text-align: left;
	width: auto;
}

.nav-cart-item-desc span.nav-cart-item-price {
	color: #000;
	display: block;
	font-size: 12px;
	line-height: 20px;
}

.nav-cart-item-desc a.nav-cart-item-quantity {
	color: #000;
	display: block;
	font-size: 12px;
	position: absolute;
	right: -5px;
	top: 2px;
	padding: 0px 6px 2px 6px;
}

.nav-cart-action {
	border-top: 1px solid #eee;
}

.nav-cart-action button {
	padding: 8px 10px;
	font-size: 12px;
}

/*====MID SLIDER ====*/
.mid-slider {
	text-align: center !important;
}

.mid-slider .rev_slider_wrapper {
	max-width: 1460px !important;
	margin: 0px auto !important;
	left: auto !important;
	position: relative !important;
}

.mid-slider .tp-fullwidth-forcer {
	height: auto !important;
}


/*Search Popup*/
#search-two {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #fff;
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search-two.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 999;
	display: block;
}

#search-two form {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 50%;
	margin-left: 25%;
	color: #fff;
	border: 1px solid #e7e7e7;
	font-size: 30px;
	font-weight: 300;
	text-align: left;
	outline: none;
	padding: 10px;
	border-radius: 0px;
}

@media only screen and (max-width: 640px) {
	#search-two form {
		width: 90%;
		margin-left: 5%;
	}
}

#search-two input[type="search"] {
	background: none;
	border: none;
	padding: 0px 12px;
	outline: none;
	color: #000;
	font-size: 24px;
}

@media only screen and (max-width: 575px) {
	#search-two input[type="search"] {
		font-size: 14px;
	}
}

#search-two input[type="search"]:focus {
	box-shadow: none;
}

#search-two input[type="search"]::placeholder {
	color: #000;
}

#search-two .search-btn {
	border: none;
	background: none;
	padding: 0px 30px;
	outline: none;
}

#search-two .search-btn i {
	font-size: 36px;
	color: #ff5e15;
}

#search-two .close {
	position: fixed;
	top: 30px;
	right: 30px;
	opacity: 1;
	font-size: 27px;
	color: #fff;
	width: 60px;
	height: 60px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	background-color: #ff5e15;
}

#search-two .close:after,
#search-two .close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 32px;
	background-color: #fff;
	right: 28px;
	top: 16px;
}

#search-two .close:after {
	transform: rotate(45deg);
}

#search-two .close:before {
	transform: rotate(-45deg);
}

#search-two .close:hover:after,
#search-two .close:hover:before {
	cursor: pointer;
}

/*About 11 Section*/
.about-max-11-position {
	display: flex;
	align-items: center;
	position: relative;
	padding: 50px 50px 50px 0px;
}

.about-max-11-position:before {
	background-image: url(../images/home-11/bg-plus.png);
	content: '';
	position: absolute;
	z-index: 1;
	right: 10px;
	top: 0px;
	height: 100%;
	width: 45%;
	opacity: 0.4;
}

.about-max-11 {
	z-index: 1;
}

.about-max-11 .about-max-11-media {
	position: relative;
}

.about-max-11 .about-max-11-media img {
	width: 100%;
}

.about-max-11 .about-max-11-video {
	position: absolute;
	width: 120px;
	height: 120px;
	left: 10px;
	border-radius: 50%;
	bottom: 0;
}

@media only screen and (max-width: 1270px) {
	.about-max-11 .about-max-11-video {
		bottom: 0px;
	}
}

.about-section-11-right {
	padding-left: 20px;
}

.about-section-11-right .section-head {
	margin-bottom: 40px;
}

.about-section-11-right .ab-11-info p {
	margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
	.about-section-11-right {
		padding-left: 0px;
	}

	.about-max-11-position {
		padding: 50px 50px 50px 15px;
	}
}

/*Description list*/
.description-list {
	margin: 30px 0px;
}

.description-list li {
	padding-left: 40px;
	font-weight: 600;
	color: #000;
	list-style: none;
	position: relative;
	margin-bottom: 15px;
	letter-spacing: 1px;
}

.description-list li:last-child {
	margin-bottom: 0px;
}

.description-list li i {
	width: 26px;
	color: #fff;
	height: 26px;
	line-height: 26px;
	border-radius: 50%;
	position: absolute;
	left: 0px;
	top: 0px;
	text-align: center;
	background-color: #ff5e15;
	font-size: 10px;
}

/*--Play button--*/
.play-now-video .icon {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 65px;
	width: 65px;
	text-align: center;
	line-height: 65px;
	background-color: #fff;
	color: #ff5e15;
	z-index: 1;
	font-size: 20px;
	padding-left: 5px;
	display: block;
	transform: translateX(-50%) translateY(-50%);
	transform-origin: center center;
	border-radius: 50%;
	box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
}

.play-now-video .ripple,
.play-now-video .ripple:before,
.play-now-video .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 65px;
	width: 65px;
	transform: translateX(-50%) translateY(-50%);
	transform-origin: center center;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgb(255, 94, 21);
	animation: ripple-video 3s infinite;
	z-index: 1;
}

.play-now-video .ripple:before {
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.play-now-video .ripple:after {
	animation-delay: .6s;
	content: "";
	position: absolute;
}

@keyframes ripple-video {
	70% {
		box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

/*services-box-one*/
.half-wide-bg-outer {
	position: relative;
}

@media only screen and (max-width: 991px) {
	.half-wide-bg-outer {
		padding: 38px 30px;
	}

	.half-wide-bg-outer .service-box-style2 .service-content {
		padding: 20px;
	}
}

.half-wide-bg {
	background-color: #ff5e15;
	position: absolute;
	height: 100%;
	width: 4000px;
	right: 65%;
	top: 0px;
}

@media only screen and (max-width:991px) {
	.half-wide-bg {
		background-color: #ff5e15;
		position: absolute;
		height: 100%;
		width: auto;
		right: 15px;
		left: 15px;
		top: 0px;
	}
}

.service-gallery-style1-wrapper {
	overflow: hidden;
}

.services-gallery-style2 {
	padding-left: 50px;
	position: relative;
}

.services-gallery-style2 .owl-carousel .owl-stage-outer {
	display: table;
}

.services-gallery-style2 .services-gallery-two.owl-btn-vertical-center .owl-nav .owl-next {
	right: -1px;
	transition: all 0.2s linear;
}

.services-gallery-style2 .services-gallery-two.owl-btn-vertical-center .owl-nav .owl-prev {
	transition: all 0.2s linear;
}

.services-gallery-style2 .services-gallery-two:hover.owl-btn-vertical-center .owl-nav .owl-next {
	right: 5%;
}

.services-gallery-style2 .services-gallery-two:hover.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 5%;
}

.services-gallery-style2 .services-gallery-two .owl-nav {
	opacity: 0;
	transition: all 0.2s linear;
}

.services-gallery-style2 .services-gallery-two:hover .owl-nav {
	opacity: 1;
}

.service-box-style2 {
	position: relative;
	background-color: #000;
	transition: 0.5s all ease;
}

.service-box-style2 .service-content {
	padding: 20px 0px 0px 0px;
	background-color: #fff;
}

.service-box-style2 .service-content p {
	color: #000;
	margin-bottom: 20px;
}

.service-box-style2 .site-button-2 {
	display: inline-block;
	color: #000;
	border: 1px solid #000;
	padding: 5px 20px;
	font-weight: 700;
	font-size: 15px;
}

.service-box-style2 .service-title-large {
	margin-bottom: 15px;
	position: relative;
}

.service-box-style2 .service-title-large a {
	color: #000;
	transition: 0.5s all ease;
}

.service-box-style2 .service-title-large a:hover {
	color: #ff5e15;
}

.service-box-style2 .service-media img {
	width: 100%;
}

.site-button-2 {
	display: inline-block;
	color: #fff;
	border: 1px solid #fff;
	padding: 5px 20px;
	font-weight: 700;
	font-size: 15px;
}

@media only screen and (max-width:991px) {
	.services-gallery-style2 {
		padding-left: 0px;
	}

	.services-gallery-style2 .owl-carousel .owl-stage-outer {
		display: block;
	}
}

.site-button-2:hover {
	color: #ff5e15;
}

/*client logo slider*/
.home-client-carousel2 .client-logo {
	max-width: 130px;
	margin-left: auto;
	margin-right: auto;
}

.home-client-carousel2 .client-logo img {
	width: auto;
}

/*Team Carousel*/
.team-carousel-wraper {
	margin-left: 60px;
}

@media only screen and (max-width: 480px) {
	.team-carousel-wraper {
		margin-left: 0px;
	}
}

.team-carousel-wraper .owl-btn-bottom-left .owl-nav {
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
	background: green;
	width: 0px;
	margin: 0px;
}

.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-prev,
.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-next {
	color: #000;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 2px;
	cursor: pointer;
	font-weight: 500;
	margin: 0px;
	margin-bottom: 0px;
	min-width: 100px;
	text-align: center;
	padding: 8px;
	border: 1px solid #000;
	line-height: normal;
	background-color: transparent;
}

.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-prev {
	position: absolute;
	left: -90px;
	top: 33px;
	transform: rotate(-90deg);
}

.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-next {
	position: absolute;
	left: -90px;
	bottom: 30px;
	transform: rotate(-90deg);
}


.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-prev i,
.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-next i {
	font-style: normal;
}

.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-prev:hover,
.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-next:hover {
	border: 1px solid #000;
	background-color: #000;
	color: #fff;
}

.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-prev:active,
.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-next:active {
	border: 1px solid transparent;
	background-color: #000;
	color: #fff;
}

.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-prev:focus,
.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-next:focus {
	border: 1px solid transparent;
	color: #fff;
}

.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-next {
	margin-bottom: 0px;
}

@media only screen and (max-width: 480px) {
	.team-carousel-wraper .owl-btn-bottom-left .owl-nav {
		position: relative;
		margin: 20px 0px 30px 0px;
		bottom: auto;
		display: flex;
		text-align: inherit;
		justify-content: space-between;
		max-width: inherit;
		align-items: baseline;
		background-color: transparent;
		width: 100%;
	}
}

@media only screen and (max-width:480px) {
	.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-prev {
		position: inherit;
		left: auto;
		top: auto;
		transform: none;
	}

	.team-carousel-wraper .owl-btn-bottom-left .owl-nav .owl-next {
		position: inherit;
		left: auto;
		right: 0px;
		bottom: auto;
		transform: none;
	}
}

/*Team 2*/
.wt-team-2 {
	margin: 90px 0px 0px 0px;
	background-color: #e6e6e6;
}

.wt-team-2 .wt-media {
	padding-bottom: 20px;
	position: relative;
	z-index: 1;
}

.wt-team-2 .wt-media img {
	margin-top: -110px;
	position: relative;
	z-index: 2;
	transition: all 0.3s linear;
}

.wt-team-2 .team-social-center {
	padding-top: 0px;
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 50px;
	z-index: 1;
	transition: all 0.3s linear;
}

.wt-team-2 .team-detail {
	padding-bottom: 20px;
}

.wt-team-2 .team-detail a:hover {
	color: #ff5e15;
}

.wt-team-2 .team-detail p {
	margin-bottom: 0px;
	color: #ff5e15;
}

.wt-team-2 .team-social-icon {
	text-align: center;
	list-style: none;
	margin-bottom: 0px;
}

.wt-team-2 .team-social-icon li {
	display: inline-block;
	margin: 0px 5px;
}

.wt-team-2 .team-social-icon li a {
	color: #fff;
	display: block;
	font-size: 14px;
	background-color: #ff5e15;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	border: 1px solid transparent;
}

.wt-team-2 .team-social-icon li a:hover {
	background-color: #f9f9f9;
	color: #000;
	border: 1px solid #e7e7e7;
}

.wt-team-2:hover .wt-media img {
	transform: translateX(0px) translateY(-30px);
}

.wt-team-2:hover .team-social-center {
	bottom: 10px;
}

/*get-in-touch*/
.get-in-touch-wraper {
	position: relative;
	z-index: 1;
}

.get-in-touch-wraper .get-in-touch {
	position: relative;
	margin-bottom: 30px;
}

.get-in-touch-wraper .get-in-touch-pic {
	position: relative;
	overflow: hidden;
}

.get-in-touch-wraper .get-in-touch-pic:before {
	content: '';
	background-image: url(../images/ui-9/background/bg-dott2.png);
	background-color: #f9f9f9;
	left: 0px;
	top: 40px;
	bottom: 0px;
	width: 180px;
	position: absolute;
	z-index: -1;
	opacity: 0.5;
}

.get-in-touch-wraper .get-in-touch .get-in-touch-info {
	margin-left: 30px;
	padding-right: 20px;
}

.get-in-touch-wraper .get-in-touch .get-in-touch-info .wt-title {
	margin-bottom: 30px;
}

@media only screen and (max-width: 1024px) {
	.get-in-touch-wraper .get-in-touch .get-in-touch-info .wt-title {
		font-size: 30px;
	}
}

@media only screen and (max-width: 991px) {
	.get-in-touch-wraper .get-in-touch .get-in-touch-info .wt-title {
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 991px) {
	.get-in-touch-wraper .get-in-touch-pic {
		margin-bottom: 30px;
	}

	.get-in-touch-wraper .get-in-touch .get-in-touch-info {
		margin-left: 0px;
	}
}

.get-in-touch-wraper .get-in-touch .get-in-touch-pic img {
	position: relative;
}

@media only screen and (max-width: 767px) {
	.get-in-touch-wraper .get-in-touch .get-in-touch-pic img {
		position: relative;
		right: auto;
		bottom: auto;
		padding-top: 30px;
	}
}

/*Service Box Icon*/
.service-icon-box-three {
	position: relative;
	padding: 40px 40px 40px 40px;
	background-color: #fff;
	transition: all 0.3s linear;
	z-index: 0;
}

@media only screen and (max-width:1199px) {
	.service-icon-box-three {
		padding: 30px 20px 30px 20px;
	}
}

.service-icon-box-three .wt-icon-box-wraper {
	margin-bottom: 60px;
}

.service-icon-box-three .wt-icon-box-wraper.circle-bg {
	position: relative;
	z-index: 1;
}

.service-icon-box-three .wt-icon-box-wraper.circle-bg:before {
	content: '';
	position: absolute;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	background-color: #ffecd1;
	z-index: -1;
	top: 5px;
	left: 50%;
	margin-left: -42px;
}

.service-icon-box-three .service-icon-box-title .wt-title {
	margin-bottom: 15px;
}

@media only screen and (max-width: 767px) {
	.service-icon-box-three .service-icon-box-title .wt-title {
		padding-right: 0px;
	}
}

.service-icon-box-three .service-icon-box-content p {
	margin-bottom: 15px;
}

.service-icon-box-three.site-bg-black .service-icon-box-title .wt-title a {
	color: #fff;
}

.service-icon-box-three.site-bg-black .service-icon-box-content p {
	color: #fff;
}

.service-icon-box-three:hover {
	background-color: #ff5e15;
}

.service-icon-box-three:hover .wt-title a {
	color: #fff;
}

.service-icon-box-three:hover .service-icon-box-content .site-text-primary {
	color: #fff;
}

.service-icon-box-three:hover .service-icon-box-content p {
	color: #fff;
}

.service-icon-box-three .wt-icon-box-wraper.circle-bg .icon-cell {
	color: #000;
}

.service-icon-box-three:hover .wt-icon-box-wraper.circle-bg .icon-cell {
	color: #fff;
}

.service-icon-box-three:hover .wt-icon-box-wraper.circle-bg:before {
	background-color: rgba(0, 0, 0, 0.1);
}

/*Project 2 */

.project-carousel .owl-stage-outer {
	padding-bottom: 30px;
}

.project-new-2 {
	position: relative;
}

.project-new-2 .wt-img-effect {
	position: relative;
}

.project-new-2 .project-new-content2 {
	position: relative;
	background-color: #fff;
	padding: 0px;
}

.project-new-2 .project-new-content2 span {
	display: inline-block;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	background-color: #ff5e15;
	padding: 5px 15px;
	margin-bottom: 20px;
}

.project-new-2 .project-new-content2 .wt-title {
	margin-bottom: 10px;
}

.project-new-2 .project-view {
	opacity: 1;
	transform: scaleY(0);
	transition: transform 0.5s cubic-bezier(0.57, 0.04, 0.06, 0.84), opacity 0s 0.5s;
	position: absolute;
	right: 0px;
	top: 0px;
}

.project-new-2 .project-view .project-view-btn {
	position: relative;
	display: block;
	width: 60px;
	height: 60px;
}

.project-new-2 .project-view .project-view-btn i {
	width: 60px;
	height: 60px;
	font-size: 28px;
	line-height: 60px;
	display: block;
	text-align: center;
	background-color: #ff5e15;
	position: relative;
	color: #fff;
}

.project-new-2:hover .project-view {
	opacity: 1;
	z-index: 3;
	transform: scaleY(1);
}

/*Help Block*/
.number-block-content .number-block-count {
	margin-bottom: 30px;
}

.number-block-content .number-block-count span {
	font-size: 72px;
	line-height: 72px;
	font-weight: 900;
	color: #ff5e15;
	display: inline-block;
	position: relative;
	padding: 20px 0px 20px 20px;
	z-index: 1;
}

@media only screen and (max-width: 767px) {
	.number-block-content .number-block-count span {
		font-size: 30px;
		line-height: 30px;
		padding: 20px;
	}
}

.number-block-content .number-block-count span:after {
	content: '';
	position: absolute;
	border: 3px solid #fff;
	top: 0px;
	bottom: 0px;
	left: 0px;
	width: 60px;
	z-index: -1;
}

@media only screen and (max-width: 767px) {
	.number-block-content .number-block-count span:after {
		width: 100%;
	}
}

.number-block-content .number-block-count span.hide-line-block {
	padding-left: 0px;
	padding-bottom: 0px;
}

.number-block-content .number-block-count span.hide-line-block:after {
	display: none;
}

.number-block-content.white {
	color: #acacac;
}

.number-block-content.white .wt-tilte {
	color: #fff;
	margin-bottom: 15px;
}

.number-block-content .number-block-info p {
	margin-bottom: 0px;
}

.testimonial11-outer {
	padding: 50px;
}

@media only screen and (max-width: 575px) {
	.testimonial11-outer {
		padding: 30px;
	}
}

.testimonial-11-content {
	position: relative;
}

@media only screen and (max-width: 991px) {
	.testimonial-11-content {
		padding-top: 20px;
	}
}

.testimonial-11-content .testimonial-11 {
	position: relative;
	padding: 20px;
	border: 1px solid #e8e8e8;
}

.testimonial-11-content .testimonial-11 .testimonial-pic-block {
	margin-right: 30px;
}

.testimonial-11-content .testimonial-11 .testimonial-pic {
	background: #fff;
	width: 100px;
	height: 100px;
	position: relative;
	display: inline-block;
	box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	padding: 5px;
}

@media only screen and (max-width: 640px) {
	.testimonial-11-content .testimonial-11 .testimonial-pic {
		margin-bottom: 20px;
	}
}

.testimonial-11-content .testimonial-11 .testimonial-pic.radius {
	border-radius: 100%;
}

.testimonial-11-content .testimonial-11 .testimonial-pic img {
	width: 100%;
	border-radius: 100%;
}

.testimonial-11-content .testimonial-11 .testimonial-info {
	padding: 0px;
}

.testimonial-11-content .testimonial-11 .testimonial-info .testimonial-name {
	display: block;
	margin-bottom: 10px;
	color: #000;
	font-size: 24px;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
}

.testimonial-11-content .testimonial-11 .testimonial-info .testimonial-name:after {
	display: none;
}

.testimonial-11-content .testimonial-11 .testimonial-info .testimonial-position {
	display: block;
	font-size: 16px;
	font-weight: 500;
	color: #ff5e15;
}

.testimonial-11-content .testimonial-11 .testimonial-text {
	padding: 0px 0px 0px 0px;
	position: relative;
	z-index: 1;
}

@media only screen and (max-width: 991px) {
	.testimonial-11-content .testimonial-11 .testimonial-text {
		padding: 0px 0px 0px 0px;
	}
}




.testimonial-11-content .testimonial-11 .testimonial-text p {
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: 500;
	padding-left: 100px;
}

.testimonial-11-content .testimonial-11 .testimonial-text i {
	color: #ff5e15;
	font-size: 60px;
	line-height: 60px;
	opacity: 0.2;
	position: absolute;
	left: 0px;
	top: 0px;
}


@media only screen and (max-width: 991px) {
	.testimonial-11-content .testimonial-11 .testimonial-text i {
		font-size: 48px;
		transform: none;
		line-height: 40px;
		top: 0px;
	}

	.testimonial-11-content .testimonial-11 .testimonial-text p {
		padding-left: 65px;
	}
}

@media only screen and (max-width: 575px) {
	.testimonial-11-content .testimonial-11 .testimonial-text {
		padding: 0px;
	}

	.testimonial-11-content .testimonial-11 .testimonial-text p {
		padding-left: 0px;
	}
}

@media only screen and (max-width: 575px) {
	.testimonial-11-content .testimonial-11 .testimonial-text i {
		position: relative;
	}
}

.testimonial-11-content .testimonial-11 .testimonial-detail {
	padding: 0px;
	padding-bottom: 30px;
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 640px) {
	.testimonial-11-content .testimonial-11 .testimonial-detail {
		display: block;
	}
}

/*Counter Style One*/
@media only screen and (max-width: 767px) {
	.counter-outer-one [class*='icon-xl'] {
		width: 50px;
	}
}

@media only screen and (max-width: 767px) {
	.counter-outer-one [class*='icon-xl'] i {
		font-size: 50px;
		line-height: 50px;
	}
}

.counter-outer-one.icon-circle {
	position: relative;
	z-index: 1;
}

.counter-outer-one.icon-circle:before {
	content: '';
	position: absolute;
	width: 85px;
	height: 85px;
	border-radius: 50%;
	background-color: #ffecd1;
	z-index: -1;
	top: 5px;
	left: 0%;
}

@media only screen and (max-width: 767px) {
	.counter-outer-one.icon-circle:before {
		width: 60px;
		height: 60px;
	}
}

.counter-outer-one .counter {
	font-size: 40px;
	font-weight: 700;
}

@media only screen and (max-width: 768px) {
	.counter-outer-one .counter {
		font-size: 24px;
	}
}

.counter-outer-one .icon-content-info {
	font-weight: 600;
	color: #000;
}

.counter-outer-one .icon-content .counter {
	color: #000;
}

.counter-outer-one .icon-content.site-text-white .icon-content-info {
	color: #acacac;
}

.counter-outer-one .icon-content.site-text-white .counter {
	color: #fff;
}

/*Blog Gallery*/
.blog-gallery-style1 {
	padding-left: 0px;
}

@media only screen and (max-width: 991px) {
	.blog-gallery-style1 {
		padding-left: 0px;
	}
}

.blog-gallery-style1 .owl-carousel .owl-stage-outer {
	display: table;
	position: relative;
}

@media only screen and (max-width:991px) {
	.blog-gallery-style1 .owl-carousel .owl-stage-outer {
		display: block;
		position: relative;
	}
}

.blog-gallery-style1 .blog-gallery-one.owl-btn-vertical-center .owl-nav .owl-next {
	right: -1px;
	transition: all 0.2s linear;
	transform: translateY(-50%);
}

.blog-gallery-style1 .blog-gallery-one.owl-btn-vertical-center .owl-nav .owl-prev {
	transition: all 0.2s linear;
	transform: translateY(-50%);
}

.blog-gallery-style1 .blog-gallery-one:hover.owl-btn-vertical-center .owl-nav .owl-next {
	right: 5%;
	line-height: 30px;
}

.blog-gallery-style1 .blog-gallery-one:hover.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 5%;
	line-height: 30px;
}

.blog-gallery-style1 .blog-gallery-one .owl-nav {
	opacity: 0;
	transition: all 0.2s linear;
}

.blog-gallery-style1 .blog-gallery-one:hover .owl-nav {
	opacity: 1;
}

.blog-post-4-outer .wt-post-media img {
	width: 100%;
}

.blog-post-4-outer .wt-post-info {
	background-color: #fff;
	padding: 20px;
	margin-right: 40px;
	position: relative;
	z-index: 1;
	top: -40px;
	margin-bottom: -40px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 575px) {
	.blog-post-4-outer .wt-post-info {
		margin-right: 20px;
	}
}

.blog-post-4-outer .wt-post-info .wt-post-meta {
	position: relative;
}

.blog-post-4-outer .wt-post-info .wt-post-meta ul {
	list-style: none;
	margin-bottom: 0px;
}

.blog-post-4-outer .wt-post-info .wt-post-meta ul li.post-date {
	background-color: #ff5e15;
	color: #fff;
	display: inline-table;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	padding: 8px 15px;
	font-weight: 700;
	position: absolute;
	left: -20px;
	top: -56px;
}

.blog-post-4-outer .wt-post-info .wt-post-meta ul li.post-category {
	font-size: 14px;
	font-weight: 600;
	color: #ff5e15;
}

.blog-post-4-outer .wt-post-info .wt-post-title .post-title {
	margin-bottom: 15px;
}

.blog-post-4-outer.post-bg-dark .wt-post-info {
	background-color: #000;
}

.blog-post-4-outer.post-bg-dark .wt-post-info .post-title a {
	color: #fff;
}

.blog-post-4-outer.post-bg-dark .wt-post-info .post-title a:hover {
	color: #ff5e15;
}

.blog-post-4-outer.post-bg-dark .wt-post-info a.site-text-primary:hover {
	color: #fff;
}

.blog-list-style .wt-post-info .wt-post-title .post-title {
	margin-bottom: 15px;
	font-size: 30px;
	line-height: 36px;
}

@media only screen and (max-width: 767px) {
	.blog-list-style .wt-post-info .wt-post-title .post-title {
		font-size: 22px;
		line-height: 28px;
	}
}

/*circle-animation Start*/

.ripple-background {
	position: absolute;
	left: 50%;
	top: 50%;
	opacity: 0.5;
	z-index: -1;
}

.pricingtable-inner:hover .ripple-background {
	opacity: 0.9;
}

.ls-circle {
	position: absolute;
	border-radius: 50%;
	background: #ff5e15;
	animation: ripple-1 5s infinite;
}

.ls-small {
	width: 80px;
	height: 80px;
	left: -40px;
	top: -40px;
}

.ls-medium {
	width: 160px;
	height: 160px;
	left: -80px;
	top: -80px;
}

.ls-large {
	width: 240px;
	height: 240px;
	left: -120px;
	top: -120px;
}

.ls-xlarge {
	width: 320px;
	height: 320px;
	left: -160px;
	top: -160px;
}

.ls-xxlarge {
	width: 400px;
	height: 400px;
	left: -200px;
	top: -200px;
}

@media only screen and (max-width:540px) {
	.ls-small {
		width: 60px;
		height: 60px;
		left: -30px;
		top: -30px;
	}

	.ls-medium {
		width: 120px;
		height: 120px;
		left: -60px;
		top: -60px;
	}

	.ls-large {
		width: 180px;
		height: 180px;
		left: -90px;
		top: -90px;
	}

	.ls-xlarge {
		width: 240px;
		height: 240px;
		left: -120px;
		top: -120px;
	}

	.ls-xxlarge {
		width: 300px;
		height: 300px;
		left: -150px;
		top: -150px;
	}
}

@media only screen and (max-width:380px) {
	.ls-small {
		width: 40px;
		height: 40px;
		left: -20px;
		top: -20px;
	}

	.ls-medium {
		width: 80px;
		height: 80px;
		left: -40px;
		top: -40px;
	}

	.ls-large {
		width: 120px;
		height: 120px;
		left: -60px;
		top: -60px;
	}

	.ls-xlarge {
		width: 160px;
		height: 160px;
		left: -80px;
		top: -80px;
	}

	.ls-xxlarge {
		width: 200px;
		height: 200px;
		left: -100px;
		top: -100px;
	}
}

.ls-shade1 {
	opacity: 0.5;
}

.ls-shade2 {
	opacity: 0.6;
}

.ls-shade3 {
	opacity: 0.7;
}

.ls-shade4 {
	opacity: 0.8;
}

.ls-shade5 {
	opacity: 0.9;
}

@keyframes ripple-1 {
	0% {
		transform: scale(0.8);
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(0.8);
	}
}

/*circle-animation End*/
.about-max-12 {
	position: relative;
	z-index: 1;
	padding: 0px 50px 50px 30px;
}

@media only screen and (max-width:991px) {
	.about-max-12 {
		max-width: 550px;
		margin: 0px auto;
	}
}

@media only screen and (max-width:540px) {
	.about-max-12 {
		max-width: 350px;
		margin: 0px auto;
	}
}

.about-max-12:before {
	position: absolute;
	content: '';
	bottom: 0px;
	right: 0px;
	background-image: url(../images/home-11/background/scale-h.png);
	background-repeat: no-repeat;
	z-index: -1;
	width: 100%;
	height: 38px;
}

.about-max-12:after {
	position: absolute;
	content: '';
	bottom: 0px;
	right: 0px;
	background-image: url(../images/home-11/background/scale-v.png);
	background-position: right bottom 34px;
	background-repeat: no-repeat;
	z-index: -1;
	width: 38px;
	height: 100%;
}

/*Ipad Section*/
.ser-ipad-warp {
	position: relative;
	background-color: #fff;
	padding: 60px 20px;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	max-width: 360px;
	width: 100%;
	box-shadow: 0 16px 48px rgba(0, 0, 0, .15);
	margin-left: 60px;
	margin-bottom: 40px;
}

.ser-ipad-pic {
	height: 400px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
}

.ser-ipad-overlay {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
}

.ser-ipad-cam {
	position: absolute;
	top: 35px;
	left: 50%;
	width: 10px;
	height: 10px;
	margin: -5px 0px;
	background-color: #777777;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	transform: translateX(-50%);
}

.ser-ipad-btn {
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	width: 36px;
	height: 36px;
	margin: -18px 0px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-webkit-box-shadow: inset 3px 3px 10px 5px rgba(0, 0, 0, 0.03);
	box-shadow: inset 3px 3px 10px 5px rgba(0, 0, 0, 0.03);
}


/*Ipad Pencil*/
.ipad-pencil {
	position: absolute;
	left: 0px;
	top: 0px;
}

.ipad-pencil-jump {
	-webkit-animation: ipad-pencil-jump 1s ease-in-out infinite alternate both;
	animation: ipad-pencil-jump 1s ease-in-out infinite alternate both;
}

@-webkit-keyframes ipad-pencil-jump {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}

@keyframes ipad-pencil-jump {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	100% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
}


@media only screen and (max-width:540px) {
	.about-max-12 .ser-ipad-warp {
		margin-left: 10px;
		margin-bottom: 0px;
	}

	.about-max-12 .ser-ipad-warp .ser-ipad-pic {
		height: 300px;
	}

	.ipad-pencil img {
		width: 75%;
	}

	.about-max-12:after,
	.about-max-12:before {
		background-size: 90%;
	}
}

/* Client Logo Style 2*/
.client-logo2 {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.client-logo2-media {
	margin-bottom: 30px;
	padding: 10px 70px;
	position: relative;
	z-index: 1;
}

@media only screen and (max-width:991px) {
	.client-logo2-media {
		padding: 10px 40px;
	}
}

.client-logo2-media:after,
.client-logo2-media:before {
	content: '';
	position: absolute;
	width: 0px;
	height: 0px;
	background-color: transparent;
	border: none;
	opacity: 0;
	transition: all 1s;
	-moz-transition: all 1s;
	-webkit-transition: all 1s;
	-o-transition: all 1s;
}

.client-logo2-media:before {
	left: 10px;
	top: 10px;
	border-top: 4px solid #ff5e15;
	border-left: 4px solid #ff5e15;
}

.client-logo2-media:after {
	right: 10px;
	bottom: 10px;
	border-bottom: 4px solid #ff5e15;
	border-right: 4px solid #ff5e15;
}

.client-logo2-media:hover:after,
.client-logo2-media:hover:before {
	width: 30px;
	height: 30px;
	opacity: 1;
}

.client-logo2-media img {
	max-width: 100% !important;
	margin: 0px auto !important;
	width: auto !important;
}

.client-logo2-media:hover {
	background-color: #fff;
}

.client-logo2-border {
	border-top: 1px dashed #c5c5c5;
	border-right: 1px dashed #c5c5c5;
}

.client-logo2-border .client-logo2-media {
	margin-bottom: 0px;
	border-left: 1px dashed #c5c5c5;
	border-bottom: 1px dashed #c5c5c5;
}

.client-logo2-media.active:after,
.client-logo2-media.active:before {
	opacity: 1;
	width: 30px;
	height: 30px;
}

.client-logo2-media.active {
	background-color: #fff;
}

/*blog css*/

.latest-blog-11 {
	display: table;
	width: 100%
}

.latest-blog-11 .wt-post-media {
	display: table-cell;
	vertical-align: middle;
	width: 50%
}

.latest-blog-11 .wt-post-info {
	display: table-cell;
	vertical-align: middle;
	padding: 30px;
	width: 50%
}

@media only screen and (max-width: 991px) {
	.latest-blog-11 .wt-post-media {
		display: block;
		width: 100%
	}

	.latest-blog-11 .wt-post-info {
		display: block;
		width: 100%
	}
}

.block-shadow {
	-webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, .16);
	box-shadow: 0 10px 30px 0 rgba(50, 50, 50, .16);
}

.slider-block-12 {
	border: 16px solid #c8c8c8;
}

.ab-call-us {
	box-shadow: 10px 20px 30px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width:480px) {
	.ab-call-us .icon-xl {
		width: 50px;
		line-height: 40px;
	}

	.ab-call-us .icon-xl i {
		font-size: 40px;
		line-height: 40px;
	}

	.ab-call-us .wt-tilte {
		font-size: 18px;
	}

	.ab-call-us .wt-icon-box-wraper {
		padding: 20px;
	}
}

/*imag hover 12*/
.imghvr1 {
	position: relative;
	display: inline-block;
	margin: 0;
	max-width: 100%;
	background-color: rgba(230, 230, 230, .7);
	color: #666;
	overflow: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}

.imghvr1 img {
	vertical-align: top;
	max-width: 100%
}

.imghvr1 figcaption {
	background-color: rgba(255, 255, 255, .9);
	padding: 20px;
	position: absolute;
	top: 15px;
	bottom: 15px;
	left: 15px;
	right: 15px;
	border: 1px solid rgba(0, 0, 0, .1);
	-webkit-transform: translateY(120%);
	transform: translateY(120%);
	display: flex;
	justify-content: center;
	align-items: center;
}

.imghvr1,
.imghvr1 * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: all .35s ease;
	transition: all .35s ease
}

.imghvr1:hover figcaption {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0)
}

.imghvr1:hover img {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}

/*service gallery style12 */
.services-gallery-style12 {
	position: relative;
}

.services-gallery-style12 .services-gallery-two.owl-btn-vertical-center .owl-nav .owl-next {
	right: -1px;
	transition: all 0.2s linear;
}

.services-gallery-style12 .services-gallery-two.owl-btn-vertical-center .owl-nav .owl-prev {
	transition: all 0.2s linear;
}

.services-gallery-style12 .services-gallery-two:hover.owl-btn-vertical-center .owl-nav .owl-next {
	right: 5%;
}

.services-gallery-style12 .services-gallery-two:hover.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 5%;
}

.services-gallery-style12 .services-gallery-two .owl-nav {
	opacity: 0;
	transition: all 0.2s linear;
}

.services-gallery-style12 .services-gallery-twelve:hover .owl-nav {
	opacity: 1;
}

.service-box-style12 {
	position: relative;
	background-color: #000;
	transition: 0.5s all ease;
}

.service-box-style12 .service-content {
	padding: 20px 0px 0px 0px;
	background-color: #fff;
}

.service-box-style12 .service-content p {
	color: #000;
	margin-bottom: 20px;
}

.service-box-style12 .site-button-2 {
	display: inline-block;
	color: #000;
	border: 1px solid #000;
	padding: 5px 20px;
	font-weight: 700;
	font-size: 15px;
}

.service-box-style12 .service-title-large {
	margin-bottom: 15px;
	position: relative;
}

.service-box-style12 .service-title-large a {
	color: #000;
	transition: 0.5s all ease;
}

.service-box-style12 .service-title-large a:hover {
	color: #ff5e15;
}

.service-box-style12 .service-media img {
	width: 100%;
}

/*Services Tabs*/
.services-tabs {
	position: relative;
	display: inline-block;
}

.sr-btn-filter-wrap {
	display: flex;
	list-style: none;
	align-items: center;
	-ms-user-select: none;
	user-select: none;
	margin-bottom: 50px;
}

.sr-btn-filter-wrap li {
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 2px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.sr-btn-filter-wrap li img {
	width: 40px;
	margin: 0px 10px;
}

.sr-btn-filter-wrap li.sr-btn-circle {
	border-radius: 50%;
	background-color: #959190;
	width: 70px;
	height: 70px;
	color: #fff;
	line-height: 70px;
	padding: 0px;
	text-align: center;
	display: block;
	position: absolute;
	left: 50%;
	z-index: 1;
	transform: translateX(-50%);
}

.sr-btn-filter-wrap li.btn-active.sr-btn-circle {
	background-color: #959190;
}

.sr-btn-filter-wrap li:first-child.btn-active {
	background-color: #fff;
	border-radius: 50px 0px 0px 50px;
	color: #959190;
	border: 1px solid #959190;
}

.sr-btn-filter-wrap li:first-child {
	padding: 20px 50px 20px 20px;
	border: 1px solid #b3b3b3;
	border-radius: 50px 0px 0px 50px;
}

.sr-btn-filter-wrap li:last-child.btn-active {
	background-color: #fff;
	border-radius: 0px 50px 50px 0px;
	color: #ff6d55;
	border: 1px solid #ff6d55;
}

.sr-btn-filter-wrap li:last-child {
	flex-direction: row-reverse;
	padding: 20px 20px 20px 50px;
	border: 1px solid #b3b3b3;
	border-radius: 0px 50px 50px 0px;
}

@media only screen and (max-width:400px) {
	.sr-btn-filter-wrap li img {
		width: 24px;
	}

	.sr-btn-filter-wrap li:first-child {
		padding: 10px 30px 10px 10px;
	}

	.sr-btn-filter-wrap li:last-child {
		padding: 10px 10px 10px 30px;
	}

	.sr-btn-filter-wrap li.sr-btn-circle {
		width: 40px;
		height: 40px;
		line-height: 40px;
	}
}

/*ap-section*/
.ap-section-outer {
	background-image: url(../images/home-11/ap-section.jpg);
	background-repeat: no-repeat;
	background-position: 100%;
	background-size: cover;
	width: 100%;
	height: 100%;

}

.ap-section {
	max-width: 580px;
}

.ap-section p {
	padding-bottom: 20px;
}

.ap-section h2 {
	max-width: 600px;
}

.ap-section-outer {
	position: relative;
	z-index: 1;
}

/*Testimonial Style 12*/
.testimonial-12-content {
	position: relative;
	margin: 0px -15px 30px -15px;
	width: auto;
}

.testimonial-12-content .testimonial-12 {
	position: relative;
	padding: 30px;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
	margin: 15px;
	opacity: 0.8;
}

@media only screen and (max-width: 420px) {
	.testimonial-12-content .testimonial-12 {
		padding: 20px;
	}
}

.testimonial-12-content .testimonial-12 .testimonial-pic-block {
	margin-right: 30px;
}

.testimonial-12-content .testimonial-12 .testimonial-pic {
	background: #fff;
	width: 70px;
	height: 70px;
	position: relative;
	display: inline-block;
	border-radius: 50%;
	overflow: hidden;
}

.testimonial-12-content .testimonial-12 .testimonial-pic.radius {
	border-radius: 100%;
}

.testimonial-1-content .testimonial-12 .testimonial-pic img {
	width: 100%;
	border-radius: 100%;
}

@media only screen and (max-width: 640px) {
	.testimonial-2-content .testimonial-12 .testimonial-pic {
		margin-bottom: 20px;
	}
}

.testimonial-12-content .testimonial-12 .testimonial-info {
	padding: 0px;
}

.testimonial-12-content .testimonial-12 .testimonial-info .testimonial-name {
	display: block;
	font-family: 'Roboto', sans-serif;
	margin-bottom: 0px;
	color: #000;
	font-size: 24px;
	font-weight: 500;
}

.testimonial-12-content .testimonial-12 .testimonial-info .testimonial-name:after {
	display: none;
}

.testimonial-12-content .testimonial-12 .testimonial-info .testimonial-position {
	display: block;
	font-size: 16px;
	font-weight: 500;
	color: #959190;
}

.testimonial-12-content .testimonial-12 .testimonial-text {
	padding: 0px 0px 0px 0px;
	position: relative;
	z-index: 1;
}

.testimonial-12-content .testimonial-12 .testimonial-text p {
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: 500;
}

.testimonial-12-content .testimonial-12 .testimonial-text i {
	color: #959190;
	font-size: 90px;
	line-height: 60px;
	display: flex;
	justify-content: flex-end;
	opacity: 0.1;
}

@media only screen and (max-width: 640px) {
	.testimonial-12-content .testimonial-12 .testimonial-text i {
		font-size: 48px;
		top: -40px;
	}
}

.testimonial-12-content .testimonial-12 .testimonial-detail {
	padding: 0px;
	padding-bottom: 30px;
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 640px) {
	.testimonial-12-content .testimonial-12 .testimonial-detail {
		display: block;
	}
}

.testimonial-12-content .owl-item.active.center .testimonial-12 {
	opacity: 1;
}

/*home12-contact-section*/
.home12-contact-section .form-control {
	background-color: #e6e6e6;
}

.home12-contact-section {
	margin-left: 15px;
}

.home12-contact-section .form-control::-webkit-input-placeholder {
	color: #000;
	font-size: 14px;
}

.home12-contact-section .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #000;
	font-size: 14px;
}

.home12-contact-section .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #000;
	font-size: 14px;
}

.home12-contact-section .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #000;
	font-size: 14px;
}

.home12-contact-section-wrap .contact-right12-section {
	position: relative;
	z-index: 1;
	margin-left: 40px;
	margin-bottom: 70px;
}

.home12-contact-section-wrap .contact-right12-section:before {
	position: absolute;
	content: '';
	z-index: -1;
	left: -40px;
	bottom: -40px;
	width: 100%;
	height: 100%;
	background-image: url(../images/home-11/bg-plus.png);
}

@media only screen and (max-width:400px) {
	.home12-contact-section {
		margin-left: 0px;
	}
}

/*Post Style 12*/
.post-style-12 .wt-post-info {
	margin: 0px 20px -30px 20px;
	position: relative;
	top: -30px;
	z-index: 1;
}

.post-style-12 .wt-post-info .wt-post-content {
	position: relative;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	border: 1px solid #efefef;
}

.post-style-12 .wt-post-info:before {
	position: absolute;
	z-index: -1;
	content: '';
	left: 25px;
	right: 25px;
	bottom: 0px;
	border-radius: 0px 0px 10px 10px;
	height: 100%;
	background-color: #959190;
	-webkit-transition: all .5s ease-in-out;
	-moz-transition: all .5s ease-in-out;
	-ms-transition: all .5s ease-in-out;
	-o-transition: all .5s ease-in-out;
	transition: all .5s ease-in-out;
}

.post-style-12 .wt-post-info .wt-post-title {
	margin-bottom: 0px;
}

.post-style-12:hover .wt-post-info:before {
	bottom: -8px;
}

.logos-slider.owl-carousel .owl-stage {
	display: flex;
	align-items: center;
}